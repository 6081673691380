export function makeJsonAPIPayload(
  type,
  attributes,
  relationships = undefined,
  id = null
) {
  return {
    data: {
      type: type,
      id,
      attributes: {
        ...attributes,
      },
      relationships: {
        ...relationships,
      },
    },
  };
}

export function v1(str) {
  return `/api/v1/${str}`;
}

export function v2(str) {
  return `/api/v2/${str}`;
}

export function v3(str) {
  return `/api/v3/${str}`;
}

export function v4(str) {
  return `/api/v4/${str}`;
}

export function v5(str) {
  return `/api/v5/${str}`;
}

export function populateRelations(element, relations) {
  const populatedRelationships = {};

  Object.keys(element.relationships).forEach(key => {
    const relationship = element.relationships[key].data;
    populatedRelationships[key] = {
      data: Array.isArray(relationship)
        ? relationship.map(relationship => findRelation(relations, relationship))
        : findRelation(relations, relationship),
    };
  });

  return {
    ...element,
    relationships: populatedRelationships,
  };
}
export function findRelation(relations, relationship) {
  return (
    (relations &&
      relations.find(
        relation => relation.id === relationship.id && relation.type === relationship.type
      )) ||
    null
  );
}

export const ErrorCodes = {
  NETWORK_ERROR: "network_error",
};
