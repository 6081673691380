import store from "../state/store";
import {useEffect, useMemo, useState} from "react";
import {requestGroupProducts} from "../state/actions";
import {getGroupProductsBySlug} from "@menu/state/selectors";
import useDeliveryInfo from "src/core/deliveries/hooks/useDeliveryInfo";
import {useProductsSortBy} from "@menu/hooks/useSortBy";
import useServerContext from "src/server/hooks/useServerContext";
import {getInitialGroups} from "@menu/utils/getInitialGroups";
import {PrefetchedDataKeys} from "src/server/constants";

export default function useGroupProducts({slug, type, filters, inView}) {
  const {data} = useServerContext();

  const {dispatch} = store;

  const [state, setState] = useState(
    getGroupProductsBySlug(
      {
        // force params to be equal between prefetched and current groups
        productGroups: getInitialGroups({
          prefetchedGroups: {...data[PrefetchedDataKeys.SHOWCASED_GROUPS], params: {}},
          currentParams: {},
          currentGroups: store.getState().productGroups,
        }),
      },
      {slug, type}
    )
  );

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const newState = getGroupProductsBySlug(store.getState(), {slug, type});

      if (!newState?.data?.length && state?.prefetched) return;

      setState(newState);
    });
    return () => {
      unsubscribe();
    };
  }, [state?.prefetched]);

  const deliveryInfo = useDeliveryInfo();
  const [currentOrder] = useProductsSortBy();

  const _requestGroupProducts = ({filters, options = {}}) => {
    dispatch(
      requestGroupProducts({
        type,
        slug,
        limit: 10,
        filters,
        order: currentOrder,
        deliveryInfo,
        options,
      })
    );
  };

  const products = useMemo(() => {
    if (!state) return [];

    return state.data.map(product => product.productCardObject);
  }, [state, inView]);

  useEffect(() => {
    if (!deliveryInfo || !inView || !filters) return;

    _requestGroupProducts({filters: filters, options: {reset: true}});
  }, [deliveryInfo, filters, currentOrder, inView]);

  return {
    state: {
      data: products,
      loading: state?.loading ?? true,
      error: state?.error || null,
      hasMore: state?.hasMore || false,
      prefetched: state?.prefetched || false,
    },
    actions: {
      requestGroupProducts: _requestGroupProducts,
    },
  };
}
