import {CacheEventBus} from "src/core/common/cache";
import EventBus from "src/core/common/eventBus";
import debounce from "lodash/debounce";

let refreshValue = 1;
export const refresh = debounce(() => {
  refreshValue++;
  CacheEventBus.notify({tag: "products", operation: "invalidate"});
  eventBus.notify({refreshValue});
}, 300);
export const eventBus = new EventBus();
