import config from "./config";
import {toast} from "react-toastify";
import {dialogEventBus} from "src/core/notifications/components/Dialog";

let lastMessage = null;
export function info(message, options) {
  if (debounceMessages(message)) return;
  toast.info(message, options || config.getDefaultOptions());
}

export function warn(message, options) {
  if (debounceMessages(message)) return;
  toast.warn(message, options || config.getDefaultOptions());
}

export function error(message, options) {
  if (debounceMessages(message)) return;
  toast.error(message, options || config.getDefaultOptions());
}

export function success(message, options) {
  if (debounceMessages(message)) return;
  toast.success(message, options || config.getDefaultOptions());
}

function debounceMessages(newMessage) {
  if (newMessage === lastMessage) {
    return true;
  }

  lastMessage = newMessage;
  setTimeout(() => {
    lastMessage = null;
  }, 2000);
  return false;
}

export function showDialog(params) {
  dialogEventBus.notify(params);
}
