import Separator from "src/core/common/components/elements/Separator";
import React from "react";
import styled from "styled-components";
import ColumnLayout from "src/core/common/components/elements/container/column";

function SeparatorLabel({children, className, styles}) {
  return (
    <OptionalSeparator className={className} styles={styles}>
      <Separator />
      <span>{children}</span>
      <Separator />
    </OptionalSeparator>
  );
}

const OptionalSeparator = styled(ColumnLayout)`
  display: flex;
  align-items: center;

  ${Separator} {
    flex: 1;
  }

  > span {
    margin: 0 ${({theme}) => theme.v2.spacing(6)};
    color: ${({theme}) => theme.v2.color.base.grey["600"]};
    font-size: ${({styles, theme}) =>
      styles?.fontSize?.lg || theme.v2.typography.sizing.m.lg};
  }
`;

export default SeparatorLabel;
