import useSite from "src/core/sites/hooks/useSite";
import Link from "src/core/common/components/modules/Link";
import routes from "src/core/common/routes";
import {DealTypes} from "@deals/constants";

export default function useDealCard() {
  const site = useSite();

  function getDealPathDefinition(deal) {
    if (!deal?.slug) return null;
    return {
      pathname: routes.dealDetail,
      params: {
        dealType:
          deal?.type !== DealTypes.REWARD ? DealTypes.PROMOTIONS : DealTypes.REWARDS,
        deal: deal?.slug.toString(),
      },
    };
  }

  return {
    loading: false,
    LinkComponent: Link,
    site,
    getDealPathDefinition,
  };
}
