import {bindActionCreators} from "redux";
import * as Actions from "./state/actions";
import store from "./state/store";
import {
  deliveryAddressSelector,
  deliveryModeSelector,
  deliveryTypeSelector,
} from "./state/selectors";
import Address from "../common/models/address";
import localStorage from "src/core/common/localStorage";
import backend from "./backend";
import {FeatureToggles} from "../common/featureToggles";
import {DeliveryTypes, makeDeliveryType} from "../common/models/DeliveryType";
import routes from "../common/routes";
import {storeSwitcher} from "../common/services/storeSwitcher";
import DeliveryConstants from "./constants";
import {isClient} from "src/server/utils/isClient";

const {dispatch} = store;

async function init(router, site) {
  const toggles = FeatureToggles.getInstance(site);

  const {env} = site;

  DeliveryConstants.init({
    storePrefix: toggles.deliveriesOnly()
      ? "deliveries"
      : env.getEcommerceId().substr(0, 6) + (env.getAppMode() || ""),
  });
  backend.init(env);

  const defaultParams = getDefaultParams(router);
  await initDeliveryAddress(
    defaultParams[DeliveryConstants.DELIVERY_ADDRESS_QUERY_PARAM]
  );
  initDeliveryType(defaultParams[DeliveryConstants.DELIVERY_TYPE_QUERY_PARAM]);
  initDeliveryMode(
    defaultParams[DeliveryConstants.DELIVERY_MODE_QUERY_PARAM],
    env,
    router
  );

  if (
    isClient &&
    toggles.forceDeliveryTypeSelection() &&
    !getLocalStorageDeliveryType()
  ) {
    goToDeliveryCheck(router, {from: window.location.pathname});
  }
}

function getDefaultParams(router) {
  const defaultParams = {};
  const sanitizedParams = {};
  let sanitized = false;
  Object.keys(router.preQuery).forEach(key => {
    if (DeliveryConstants.getQueryParams().indexOf(key) === -1) {
      sanitizedParams[key] = router.preQuery[key];
    } else {
      sanitized = true;
      defaultParams[key] = router.preQuery[key];
    }
  });
  sanitized && router.replace({pathname: router.pathname, params: sanitizedParams});
  return defaultParams;
}

export const Deliveries = {
  searchAddress: bindActionCreators(Actions.requestAddressAutoComplete, dispatch),
  verifyAddress: bindActionCreators(Actions.requestVerifyAddress, dispatch),
  selectDeliveryType: bindActionCreators(Actions.selectDeliveryType, dispatch),
  selectDeliveryAddress: bindActionCreators(Actions.selectDeliveryAddress, dispatch),
  setDeliveryMode: bindActionCreators(Actions.setDeliveryMode, dispatch),
  init: init,

  getDeliveryType: () => deliveryTypeSelector(store.getState()),
  getDeliveryMode: () => deliveryModeSelector(store.getState()),
  getDeliveryAddress: () => deliveryAddressSelector(store.getState()),
  getAvailableDeliveryTypes: backend.getAvailableDeliveryTypes,
  getDefaultDeliveryType: backend.getDefaultDeliveryType,
  getDeliveryTypeByName: backend.getDeliveryTypeByName,

  setAddressVerification: bindActionCreators(
    Actions.requestVerifyAddressSuccessful,
    dispatch
  ),
};

async function initDeliveryAddress(b64InitialAddress) {
  let address = getLocalStorageAddress();
  if (b64InitialAddress) {
    address = Address.fromBase64(b64InitialAddress);
  }

  Deliveries.selectDeliveryAddress(
    await backend.maybeEnhanceAddressWithCoordinates(address)
  );
}
function getLocalStorageAddress() {
  const addressStr = localStorage.getItem(
    DeliveryConstants.DELIVERY_ADDRESS_LOCAL_STORAGE_KEY
  );
  if (addressStr) {
    return new Address(JSON.parse(addressStr));
  } else {
    return null;
  }
}
function initDeliveryType(initialDeliveryType) {
  const toggles = FeatureToggles.getCurrentInstance();
  const address = Deliveries.getDeliveryAddress();
  const deliveryTypeName = initialDeliveryType || getLocalStorageDeliveryType();
  const defaultDeliveryType = backend.getDefaultDeliveryType(toggles, address);
  if (deliveryTypeName) {
    const deliveryType = Deliveries.getDeliveryTypeByName(deliveryTypeName, toggles);
    if (deliveryType) {
      Deliveries.selectDeliveryType(deliveryType);
    } else {
      Deliveries.selectDeliveryType(defaultDeliveryType, {isDefault: true});
    }
  } else {
    Deliveries.selectDeliveryType(defaultDeliveryType, {isDefault: true});
  }
}
function initDeliveryMode(initialDeliveryMode, env, router) {
  const deliveryType = Deliveries.getDeliveryType();
  const deliveryAddress = Deliveries.getDeliveryAddress();
  const deliveryMode = initialDeliveryMode || getLocalStorageDeliveryMode();
  const toggles = FeatureToggles.getCurrentInstance();

  const _deliveryMode = toggles.allowsDeliveryMode(deliveryMode, deliveryType)
    ? deliveryMode
    : backend.getDefaultDeliveryMode(toggles, deliveryType);
  if (
    deliveryType &&
    deliveryType.code === DeliveryTypes.DELIVERY &&
    deliveryAddress &&
    toggles.allowsDeliveryMode(_deliveryMode, deliveryType)
  ) {
    Deliveries.setDeliveryMode({
      mode: _deliveryMode,
      address: deliveryAddress,
      deliveryType,
    });

    setupModeTypeAddress({
      deliveryMode,
      shopId: env.getEcommerceId(),
      deliveryAddress,
      router: router,
      toggles: toggles,
    });
  } else if (toggles.allowsDeliveryMode(_deliveryMode, deliveryType)) {
    Deliveries.setDeliveryMode({
      mode: _deliveryMode,
      deliveryType,
    });
    maybeGoToDeliveryCheck(deliveryAddress, toggles, router);
  } else {
    maybeGoToDeliveryCheck(deliveryAddress, toggles, router);
  }
}

function maybeGoToDeliveryCheck(deliveryAddress, toggles, router) {
  if (!deliveryAddress && toggles.deliveriesOnly()) {
    goToDeliveryCheck(router, {}, [routes.login, routes.signup]);
  }
}

function goToDeliveryCheck(router, params = {}, ignoreRoutes = []) {
  const currentPath = router.query.from || router.pathname;
  if (!isDeliveryCheckRoute(currentPath, ignoreRoutes)) {
    router.push({
      pathname: routes.deliveryCheck,
      params: {shop: router.query.shop, ...params},
    });
  }
}

const isDeliveryCheckRoute = (path, ignoreRoutes) => {
  let _path = path;
  if (!_path.endsWith("/")) _path = `${_path}/`;
  const deliveryRoutes = [
    routes.deliveryCheck,
    routes.deliveryType,
    routes.storeSelection,
    routes.checkout,
    ...ignoreRoutes,
  ].map(routes.toNextFormat);
  return Boolean(deliveryRoutes.find(route => _path.indexOf(route) > -1));
};

function getLocalStorageDeliveryType() {
  return localStorage.getItem(DeliveryConstants.DELIVERY_TYPE_LOCAL_STORAGE_KEY);
}

function getLocalStorageDeliveryMode() {
  return localStorage.getItem(DeliveryConstants.DELIVERY_MODE_LOCAL_STORAGE_KEY);
}

export function setupModeTypeAddress({
  deliveryMode,
  shopId,
  deliveryAddress,
  router,
  toggles,
}) {
  const deliveryType = Deliveries.getDeliveryType();
  return backend
    .verifyAddress({address: deliveryAddress, mode: deliveryMode})
    .then(verification => {
      Deliveries.setAddressVerification({shops: verification, address: deliveryAddress});
      const _deliveryMode = toggles.allowsDeliveryMode(deliveryMode, deliveryType)
        ? deliveryMode
        : backend.getDefaultDeliveryMode(toggles, deliveryType);
      if (
        verification.deliversToShop(shopId, _deliveryMode) &&
        toggles.allowsDeliveryMode(_deliveryMode, deliveryType)
      ) {
        Deliveries.setDeliveryMode({
          mode: _deliveryMode,
          address: deliveryAddress,
          deliveryType,
        });
      } else if (toggles.groupAllowsDeliveryMode(_deliveryMode)) {
        if (toggles.deliveriesOnly() && verification.deliversToAny(_deliveryMode)) {
          const anyShop = verification.getAnyDeliveryShop(_deliveryMode);
          storeSwitcher.switchStore(anyShop.getId());
          Deliveries.selectDeliveryAddress(deliveryAddress);
          Deliveries.selectDeliveryType(makeDeliveryType(DeliveryTypes.DELIVERY));
          Deliveries.setDeliveryMode({
            mode: _deliveryMode,
            address: deliveryAddress,
            deliveryType,
          });
        } else if (
          toggles.allowExpressDelivery() &&
          verification.deliversToAny(_deliveryMode)
        ) {
          const url = verification.getAnyDeliveryShopUrl(
            {
              deliveryType: DeliveryTypes.DELIVERY,
              deliveryMode: _deliveryMode,
              address: deliveryAddress,
            },
            toggles
          );
          if (url) {
            router.pushExternal(url);
          }
        } else {
          Deliveries.selectDeliveryAddress(null);
          Deliveries.selectDeliveryType(backend.getDefaultDeliveryType(toggles, null), {
            isDefault: true,
          });

          Deliveries.setDeliveryMode({
            mode: _deliveryMode,
            deliveryType,
          });
          maybeGoToDeliveryCheck(null, toggles, router);
        }
      }
      return verification;
    });
}
