import React from "react";
import useFeatureToggles from "../../../../common/hooks/useFeatureToggles";
import {useForm} from "../../../../common/components/FormContext";

const RegexInput = React.forwardRef(
  ({regex, hasErrors, clickable, disableAutoComplete, disabled, ...props}, ref) => {
    const _onChange = regex ? validate(regex, props.onChange) : props.onChange;
    const toggles = useFeatureToggles();

    const onFocus = event => {
      (toggles.isKiosk() || disableAutoComplete) &&
        event.target.setAttribute("autocomplete", "off");
      if (props.onFocus) {
        props.onFocus(event);
      }
    };

    const formContext = useForm();
    const _disabled = formContext.disabled || disabled;

    return (
      <input
        {...props}
        disabled={Boolean(_disabled)}
        ref={ref}
        onChange={_onChange}
        onFocus={onFocus}
      />
    );
  }
);

const validate = (regexStr, onChange) => evt => {
  const regex = new RegExp(regexStr, "g");
  if (onChange && (regex.test(evt.target.value) || evt.target.value === "")) {
    onChange(evt);
  }
};

export default RegexInput;
