import React, {useCallback, useEffect, useState} from "react";
import {Field} from "react-final-form";
import {required} from "../../../validations";
import AutoComplete from "../../elements/auto-complete/Input";
import Error from "../../elements/Error";
import debounce from "lodash/debounce";

function AddressSearch({
  className,
  initialAddress,
  onChange,
  onSelect,
  name,
  state,
  search,
  loading,
}) {
  const [_loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(
    initialAddress ? initialAddress.toStringWithoutLine2() : ""
  );

  const _search = useCallback(debounce(search, 1000), [search]);

  const _onChange = callback => value => {
    const searchText = value?.trim();
    callback(undefined);
    setSearchText(value);
    if (searchText && searchText.length > 3) _search(searchText);
    setLoading(true);
    onChange && onChange(value);
  };

  const _onSelect = callback => value => {
    callback(value);
    setSearchText(value.toString());
    onSelect(value);
  };

  useEffect(() => {
    if (!state.loading) {
      setLoading(false);
    }
  }, [state.loading, setLoading]);

  function renderEntry({entry}) {
    return <span>{entry.toString()}</span>;
  }

  return (
    <Field
      name={name || "address"}
      placeholder={"Address"}
      validate={required}
      initialValue={initialAddress}
      render={({input, meta}) => {
        return (
          <>
            <AutoComplete
              renderEntry={renderEntry}
              onSelect={_onSelect(input.onChange)}
              onChange={_onChange(input.onChange)}
              placeholder={"Search your address"}
              value={
                searchText || (input.value ? input.value.toStringWithoutLine2() : "")
              }
              results={state.data || []}
              loading={_loading || loading}
            />
            {meta.touched && <Error>{meta.error}</Error>}
          </>
        );
      }}
    />
  );
}
export default AddressSearch;
