import {makeDeliveryType} from "./DeliveryType";
import {makeScheduleTime} from "./scheduleTime";
import DeliverySlot from "./deliverySlot";
import {makeOrderStatus} from "./orderStatus";
import get from "lodash/get";
import Address from "src/core/common/models/address";

class DeliverySpecification {
  constructor(obj) {
    this._obj = obj;
  }

  getType() {
    return makeDeliveryType(this._obj.attributes.type);
  }

  getStatus() {
    if (!this._obj.attributes.status) return null;

    return makeOrderStatus(this._obj.attributes.status, this.getType());
  }

  getMode() {
    const attrs = {
      deliverySlot: new DeliverySlot({
        start_time: this._obj?.attributes.scheduled_start_time,
        end_time: this._obj?.attributes.scheduled_end_time,
      }),
    };
    return makeScheduleTime(this._obj?.attributes.mode, attrs);
  }

  getAddress() {
    const addressLine1 = get(this._obj, "attributes.address.address");
    if (!addressLine1) return null;

    return new Address(this._obj.attributes.address);
  }

  serialize() {
    return this._obj.attributes;
  }
}

export default DeliverySpecification;
