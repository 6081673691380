import dayjs from "dayjs";

class DeliverySlotGroup {
  constructor(date, deliverySlots) {
    this._date = date;
    this._deliverySlots = deliverySlots;
  }

  get deliverySlots() {
    return this._deliverySlots;
  }

  firstSlotAvailable() {
    return this.deliverySlots.find(slot => slot.isAvailable);
  }

  get date() {
    return this._date;
  }

  get label() {
    const today = new Date();
    const tomorrow = dayjs(today).add(1, "day");

    const d = dayjs(this._date);

    if (d.isSame(today, "day")) return "Today";
    if (d.isSame(tomorrow, "day")) return "Tomorrow";

    return d.format("dddd, MMMM DD");
  }
}

export default DeliverySlotGroup;
