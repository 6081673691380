import {ActionTypes} from "@deals/state/actions";

const initialState = {
  products: [],
  hasMore: true,
  loading: true,
  error: null,
  noResults: false,
  totalCount: 0,
  showSkeleton: true,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_PROMOTION_PRODUCTS:
      const reset = action.payload.options?.reset || false;
      return {
        ...state,
        products: reset ? [] : state.products,
        loading: true,
        error: null,
        noResults: false,
        showSkeleton: reset || state.products.length === 0,
      };
    case ActionTypes.REQUEST_PROMOTION_PRODUCTS_SUCCESS:
      const {productsPage} = action.payload;
      const products = productsPage.getElements();
      return {
        ...state,
        products: state.products.concat(products),
        hasMore: productsPage.hasNextPage(),
        loading: false,
        noResults: state.products.length === 0 && products.length === 0,
        totalCount: productsPage.getTotalCount(),
        showSkeleton: false,
      };
    case ActionTypes.REQUEST_PROMOTION_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        showSkeleton: false,
        error: action.payload.error,
      };
    case ActionTypes.CLEAR_DEAL:
      return initialState;
    default:
      return state;
  }
}
