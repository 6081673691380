import React, {useCallback} from "react";
import PropTypes from "prop-types";
import CategoryButton from "@ui/components/CategoryBar/CategoryButton";
import SelectionBar from "@ui/components/SelectionBar/SelectionBar";

function CategoryBar({
  categories,
  CategoryButtonComponent,
  isCategorySelected,
  ...props
}) {
  const getSelectionBarButtonProps = useCallback(category => {
    return {
      categoryName: category.name,
      categoryUrl: category.url,
      categoryImageUrl: category.imageUrl,
      selected: isCategorySelected(category),
      category: category,
    };
  }, []);

  return (
    <SelectionBar
      {...props}
      entries={categories}
      SelectionBarButtonComponent={CategoryButtonComponent}
      getSelectionBarButtonProps={getSelectionBarButtonProps}
    />
  );
}

CategoryBar.propTypes = {
  ...SelectionBar.propTypes,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      imageUrl: PropTypes.string,
    })
  ),
  CategoryButtonComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  isCategorySelected: PropTypes.func,
};

CategoryBar.defaultProps = {
  categories: [],
  CategoryButtonComponent: CategoryButton,
  isCategorySelected: () => false,
};

export default CategoryBar;
