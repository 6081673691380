import React from "react";
import useProductGroups from "@mock/hooks/useProductGroups";
import ShowcaseSection from "@themes/boost/components/ShowcaseProductsList/ShowcaseSection";

export default function BoostThemeShowcaseProductsList({showBrands, type, ...props}) {
  const {groups, disableLazyRender} = useProductGroups({displayBrands: showBrands}, type);

  const hasGroups = groups.length > 0;

  if (!hasGroups) {
    [1, 2].map((v, index) => <ShowcaseSection key={index} loading={true} />);
  } else {
    return groups.map((group, index) => (
      <ShowcaseSection
        minHeight={props.minHeight}
        sectionSpacing={props.sectionSpacing}
        {...group}
        {...props}
        key={`${group.name}-${group.type}`}
        limit={10}
        forceDisplay={disableLazyRender || index === 0}
      />
    ));
  }
}
