import React from "react";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import {mediaDimensions} from "@ui/utils/media";
import {isClient} from "@ui/utils/isClient";
import merge from "lodash/merge";
import mediaHelper from "styled-media-helper";

export function DisplayOnly({
  dims,
  children,
  fullWidth,
  className,
  overrideDimensions = {},
}) {
  const dimensions = merge({}, mediaDimensions, overrideDimensions);
  const media = mediaHelper(dimensions);

  if (isClient()) {
    return (
      <MediaWrapper dims={dims} mediaDimensions={dimensions}>
        <Container dims={dims} fullWidth={fullWidth} className={className} media={media}>
          {children}
        </Container>
      </MediaWrapper>
    );
  } else {
    return (
      <Container dims={dims} fullWidth={fullWidth} className={className} media={media}>
        {children}
      </Container>
    );
  }
}

const MediaWrapper = ({children, dims, mediaDimensions}) => {
  const matches = {
    lg: useMediaQuery({minWidth: mediaDimensions.lg}) && dims.includes("lg"),
    md:
      useMediaQuery({
        minWidth: mediaDimensions.md,
        maxWidth: mediaDimensions.lg - 1,
      }) && dims.includes("md"),
    sm: useMediaQuery({maxWidth: mediaDimensions.md - 1}) && dims.includes("sm"),
  };

  // TODO: Hydration error
  return !isClient() || matchesAnyQuery(matches) ? children : null;
};

const matchesAnyQuery = matches => Object.values(matches).includes(true);

const Container = styled.div`
  width: ${({fullWidth}) => (fullWidth ? "100%" : "auto")};

  ${({media, dims}) =>
    `${media.up("lg")} { ${
      dims.indexOf("lg") === -1 ? "display: none;" : "display: block;"
    } }`};

  ${({media, dims}) =>
    `${media.down("md")} { ${
      dims.indexOf("md") === -1 ? "display: none;" : "display: block;"
    } }`};

  ${({media, dims}) =>
    `${media.down("sm")} { ${
      dims.indexOf("sm") === -1 ? "display: none;" : "display: block;"
    } }`};
`;
