function memoize(fn) {
  const cache = new Map();
  const result = function (...args) {
    const key = JSON.stringify(args);
    if (cache.has(key)) {
      return cache.get(key);
    }
    const result = fn.apply(this, args);
    cache.set(key, result);
    return result;
  };
  result.cache = cache;
  return result;
}

export default function memoizeClassMethods(target, exclude = []) {
  const methods = Object.getOwnPropertyNames(target.prototype).filter(
    prop =>
      typeof target.prototype[prop] === "function" &&
      prop !== "constructor" &&
      !exclude.includes(prop)
  );

  for (const method of methods) {
    const originalMethod = target.prototype[method];
    target.prototype[method] = memoize(originalMethod);
  }

  target.prototype.clearAllCaches = function () {
    methods.forEach(method => {
      target.prototype[method].cache?.clear();
    });
  };
}
