import useAppliedFilters, {ProductFilters} from "src/core/common/hooks/useAppliedFilters";
import useSearch from "@menu/hooks/useSearch";
import {getGroupsFromBrands, getGroupsFromCategories} from "@menu/utils/productGroups";
import {useMemo} from "react";

function useShowcaseSearchResults(type) {
  const {search, state: searchState, hasMore} = useSearch();
  const [filters] = useAppliedFilters();

  const result = useMemo(() => {
    let result = {
      groups: [],
      hasMore: false,
    };

    if (type === "categories") {
      result.groups = getGroupsFromCategories(
        searchState.categories.data,
        new ProductFilters({})
      );
      result.hasMore = hasMore.categories;
    } else if (type === "brands") {
      result.groups = getGroupsFromBrands(
        searchState.brands.data,
        new ProductFilters({})
      );
      result.hasMore = hasMore.brands;
    }

    return result;
  }, [type, searchState.categories.data, searchState.brands.data]);

  return {
    ...result,
    requestNextPage: () => {
      search(filters.search, {filters, type});
    },
  };
}

export default useShowcaseSearchResults;
