import React from "react";
import {DealCard} from "@ui";
import styles from "@themes/default/components/DealCard/styles";
import useTheme from "@mock/hooks/useTheme";
import useDealCard from "@mock/hooks/useDealCard";

export default function DefaultDealCard(props) {
  const theme = useTheme();
  const {site, getDealPathDefinition, LinkComponent} = useDealCard();
  return (
    <DealCard
      {...props}
      dealDetailUrl={getDealPathDefinition(props)}
      skeleton={props.skeleton}
      LinkComponent={LinkComponent}
      styles={styles(theme, site)}
    />
  );
}
