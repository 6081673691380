import useSite from "src/core/sites/hooks/useSite";

export default function useAnnouncementMessage() {
  const site = useSite();

  const options = site.getOptions();

  return {
    site,
    title: options.announcementMessageTitle(),
    description: options.announcementMessageDescription(),
  };
}
