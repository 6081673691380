import usStates from "states-us";
import caStates from "provinces-ca";
import validateCard from "card-validator";
import {cardTypes, Countries, UkRegions} from "src/core/payments/constants";
import {
  composeValidators,
  required,
  validateCardHolderName,
  validateCreditCardCvc,
  validateCreditCardExpirationDate,
  validateCreditCardNumber,
  validateCreditCardPostalCode,
} from "src/core/common/validations";
import {RoundedCloseIcon} from "@ui/components/IconsSVGs";

const CANADA = "ca";
const UNITED_STATES = "us";
const UNITED_KINGDOM = "gb";
export const getStateOptions = country => {
  let states;
  switch (country?.toLowerCase()) {
    case CANADA:
      states = caStates.filter(x => !x.territory);
      break;
    case UNITED_STATES:
      states = usStates.filter(state => !state.territory && state.abbreviation !== "DC");
      break;
    case UNITED_KINGDOM:
      states = UkRegions;
      break;
    default:
      states = [];
  }

  return states.map(state => ({
    label: state.name,
    value: state.abbreviation,
  }));
};

export const paymentSourcesToCreditCards = ({
  paymentSources,
  input,
  disabled,
  removeAccount,
  dataCyRemove,
}) => {
  return paymentSources?.map(pSource =>
    paymentSourceToCreditCard(pSource, input, disabled, removeAccount, dataCyRemove)
  );
};

export const paymentSourceToCreditCard = (
  pSource,
  input,
  disabled,
  removeAccount,
  dataCyRemove
) => {
  return {
    icon: pSource.getIconComponent(),
    label: pSource.getMaskLabel(),
    selected: !!input.value && input.value.id === pSource.id,
    expiryDate: `Expires in ${pSource.expiryDate}`,
    isExpired: pSource.isExpired,
    onClick: () => {
      input.onChange(pSource);
    },
    action: {
      label: "remove",
      type: "button",
      dataCy: dataCyRemove,
      ActionComponent: RoundedCloseIcon,
      onClick: event => {
        if (!disabled) {
          event.stopPropagation();
          removeAccount(pSource);
        }
      },
    },
  };
};

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }
  const clearValue = clearNumber(value);
  const nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
    4,
    8
  )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
  return nextValue.trim();
}

export function clearNumber(value = "") {
  if (!value) return "";
  return value.replace(/\D+/g, "");
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatCVC(value) {
  const clearValue = clearNumber(value);
  const maxLength = 4;

  return clearValue.slice(0, maxLength);
}

export const getCardType = value => {
  const validation = validateCard.number(value);
  const type = validation?.card?.type || "";
  return cardTypes[type];
};

export const getErrorMessage = meta => {
  if (!meta.touched || !meta.error) return null;

  let msg;
  if (typeof meta.error === "string") {
    msg = meta.error;
  } else if (typeof meta.error === "object") {
    if (meta.active && meta.error.validation?.isPotentiallyValid) {
      return null;
    }
    msg = meta.error.message;
  } else {
    msg = "Invalid";
  }
  return msg;
};

export const getCreditCardFormProps = ({selectedCountry, initialCountry}) => ({
  cardInputProps: {
    validateCard: composeValidators(required, validateCreditCardNumber),
    formatCard: formatCreditCardNumber,
    getCardErrorMessage: getErrorMessage,
    cardPlaceholder: "Card number",
    getCardType,
  },
  cardNameInputProps: {
    validateCardName: composeValidators(required, validateCardHolderName),
    getCardNameErrorMessage: getErrorMessage,
    cardNamePlaceholder: "Name on card",
  },
  cardExpirationDateProps: {
    validateCardDate: composeValidators(required, validateCreditCardExpirationDate),
    getCardDateErrorMessage: getErrorMessage,
    formatExpirationDate: formatExpirationDate,
    cardDatePlaceholder: "MM/YY",
  },
  cardCvcProps: {
    validateCardCvc: composeValidators(required, validateCreditCardCvc),
    getCardCvcErrorMessage: getErrorMessage,
    formatCVC: formatCVC,
    cardCvcPlaceholder: "CVC",
  },
  addressProps: {
    validateAddress: required,
    getAddressErrorMessage: getErrorMessage,
    onChangeAddress: undefined,
    addressPlaceholder: "Street Name",
  },
  cityProps: {
    validateCity: required,
    getCityErrorMessage: getErrorMessage,
    onChangeCity: undefined,
    cityPlaceholder: "City",
  },
  stateProps: {
    options: getStateOptions(selectedCountry),
    validateState: required,
    getStateErrorMessage: getErrorMessage,
    onChangeState: undefined,
    visible: getStateOptions(selectedCountry).length > 0,
    statePlaceholder: countryLabels[selectedCountry?.toLowerCase()]?.state || "State",
  },
  cardPostalCodeProps: {
    validatePostalCode: composeValidators(required, value =>
      validateCreditCardPostalCode(value, selectedCountry)
    ),
    getCardPostalCodeErrorMessage: getErrorMessage,
    cardPostalCodePlaceholder:
      countryLabels[selectedCountry?.toLowerCase()]?.postalCode || "Zip code",
  },
  buildingNumberProps: {
    validateBuildingNumber: required,
    getBuildingNumberErrorMessage: getErrorMessage,
    buildingNumberPlaceholder: "Street No.",
  },
  countryProps: {
    validateCountry: required,
    getCountryErrorMessage: getErrorMessage,
    countryPlaceholder: "Country",
    options: Countries,
    initialValue: Countries.find(c => c.value === initialCountry?.toLowerCase()),
  },
  addressMode: "full",
});

const countryLabels = {
  us: {
    state: "State",
    postalCode: "Zip code",
  },
  ca: {
    state: "Province",
    postalCode: "Postal code",
  },
  gb: {
    state: "Region",
    postalCode: "Postal code",
  },
};

export function getPaymentOptionCode(paymentOption) {
  if (typeof paymentOption === "string") {
    return paymentOption;
  } else if (typeof paymentOption === "object" && "code" in paymentOption) {
    return paymentOption.code;
  }
}
