import {renderNodeOrComponent} from "src/core/common/utils";
import useComponentOverride from "src/core/common/hooks/useComponentOverride";

export default function ProductDetailHeaderActions({styles}) {
  const {Component: ProductDetailHeaderActionsOverride} = useComponentOverride(
    "product-detail-header-actions"
  );

  if (ProductDetailHeaderActionsOverride) {
    return renderNodeOrComponent(ProductDetailHeaderActionsOverride, {styles});
  }

  return null;
}
