import {initialProductsListState} from "@menu/state/reducers/productsList";
import {getCachedProducts} from "@menu/services/productsService";
import {matchProductListState} from "@menu/utils/matchProductListState";

const getProductsState = state => state.search.products;
const getCategoriesState = state => state.search.categories;
const getBrandsState = state => state.search.brands;

const getProductsOffset = state => {
  const productsState = getProductsState(state);
  return productsState.offset;
};

const getCategoriesOffset = state => {
  const categoriesState = getCategoriesState(state);
  return categoriesState.offset;
};

const getBrandsOffset = state => {
  const brandsState = getBrandsState(state);
  return brandsState.offset;
};

const getSearchTerm = state => {
  return state.search.searchTerm;
};

const getProductGroups = state => {
  return state.productGroups;
};

const getGroupProductsBySlug = (state, {slug, type}) => {
  return state.productGroups.products[`${slug}-${type}`];
};

const getProductBySlug = (state, slug) => {
  return state.allProducts[slug];
};

const getProductsListState = (state, params = {}) => {
  const result = state.productsList;
  const matchState = matchProductListState(result, params);

  if (!matchState) {
    const cached = getCachedProducts({
      limit: 20,
      order: params.order,
      filters: params.filters,
      deliveryInfo: params.deliveryInfo,
    });

    if (!cached) return initialProductsListState;

    return {
      ...initialProductsListState,
      showSkeleton: false,
      loading: false,
      data: cached.getElements(),
      type: params.type,
      slug: params.slug,
      shop: params.shop,
      lastFilters: params.filters,
    };
  }

  return state.productsList;
};

export {
  getSearchTerm,
  getProductsState,
  getCategoriesState,
  getBrandsState,
  getProductsOffset,
  getCategoriesOffset,
  getBrandsOffset,
  getProductGroups,
  getGroupProductsBySlug,
  getProductsListState,
  getProductBySlug,
};
