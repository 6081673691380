class EventBus {
  handlers = [];

  subscribe(handler) {
    this.handlers.push(handler);
    return () => {
      const index = this.handlers.indexOf(handler);
      if (index > -1) {
        this.handlers.splice(index, 1);
      }
    };
  }

  notify(payload) {
    this.handlers.forEach(handler => {
      handler(payload);
    });
  }
}

export default EventBus;
