import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import TranslucentOnHover from "@ui/components/Decorators/TranslucentOnHover";
import spacing from "@ui/utils/spacing";
import PropTypes from "prop-types";

function NavigationButton({
  styles,
  children,
  onClick,
  hideTextInSmallerViewport,
  highlight,
  outline,
  hasText,
  dataCy,
}) {
  return (
    <TranslucentOnHover onClick={onClick}>
      <Container
        data-cy={dataCy}
        styles={styles}
        role={"button"}
        aria-pressed={"false"}
        hideTextInSmallerViewport={hideTextInSmallerViewport}
        outline={outline}
        highlight={highlight}
        hasText={hasText}
      >
        {children}
      </Container>
    </TranslucentOnHover>
  );
}

const Container = styled.div.attrs(() => ({
  className: "navigation-button navigation-button__container",
}))`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: ${({styles}) => styles.textTransform || "none"} !important;

  > span {
    ${media.down("sm")} {
      ${({hideTextInSmallerViewport}) =>
        hideTextInSmallerViewport ? "display: none !important;" : ""}
    }
  }

  color: ${({styles, highlight}) =>
    highlight ? styles.highlightTextColor : styles.color} !important;
  background-color: ${({styles, highlight}) =>
    highlight ? styles.highlightColor : "transparent"};
  padding: ${({highlight, outline, styles}) =>
    outline || highlight ? styles.padding?.lg || `${spacing(1)} ${spacing(1)}` : 0};
  border-radius: ${({styles}) => styles.borderRadius || "4px"};
  border: ${({styles}) => styles.borderWidth} solid
    ${({styles, outline}) => (outline ? styles.outlineColor : "transparent")};
  letter-spacing: ${({styles}) => styles.letterSpacing};
  height: ${({styles}) => styles?.height || "auto"};
  box-sizing: border-box;
  transition: ${({styles}) => styles.transition};

  &:hover {
    color: ${({styles}) => styles.hoverOptionColor} !important;
  }
`;

NavigationButton.propTypes = {
  styles: PropTypes.shape({
    textTransform: PropTypes.string,
    highlightTextColor: PropTypes.string,
    highlightColor: PropTypes.string,
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    outlineColor: PropTypes.string,
    letterSpacing: PropTypes.string,
    color: PropTypes.string,
    padding: PropTypes.string,
  }),
  children: PropTypes.node,
  onClick: PropTypes.func,
  hideTextInSmallerViewport: PropTypes.bool,
  highlight: PropTypes.bool,
  outline: PropTypes.bool,
  hasText: PropTypes.bool,
};

export default NavigationButton;
