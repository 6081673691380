import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";

const getWeightKey = option => {
  return option?.value?.weightKey || option?.value?.sizeObj?.weightKey; // Make sure to handle both cases
};
export default function GiftCardSelector({
  styles,
  options = [],
  value,
  onChange,
  handleCustomAmountChange,
}) {
  const initialWeightKey = getWeightKey(value);
  const [selectedWeightKey, setSelectedWeightKey] = useState(initialWeightKey);
  const [customAmount, setCustomAmount] = useState("");
  useEffect(() => {
    if (value) {
      setSelectedWeightKey(initialWeightKey);
    }
  }, [value]);

  useEffect(() => {
    const initialKeyExists = options.some(
      option => getWeightKey(option) === initialWeightKey
    );

    if (!initialKeyExists && !customAmount) {
      setCustomAmount(initialWeightKey);
      setSelectedWeightKey(initialWeightKey);
    }
  }, []);

  const handleOptionClick = option => {
    const newWeightKey = getWeightKey(option);
    setSelectedWeightKey(newWeightKey);
    setCustomAmount("");
    onChange(option);
  };

  const handleCustomSelection = () => {
    setSelectedWeightKey(customAmount);
  };

  const safeOptions = Array.isArray(options) ? options : [];

  return (
    <Wrapper styles={styles}>
      {safeOptions.map(option => (
        <ToggleButton
          key={getWeightKey(option)}
          isSelected={selectedWeightKey === getWeightKey(option)}
          onClick={() => handleOptionClick(option)}
          styles={styles}
        >
          {option.label || option}
        </ToggleButton>
      ))}
      <ToggleButton
        isSelected={selectedWeightKey === customAmount}
        onClick={handleCustomSelection}
        styles={styles}
        hasInput
      >
        <DollarContainer styles={styles} isSelected={selectedWeightKey === customAmount}>
          $
        </DollarContainer>
        <Input
          styles={styles}
          type="text"
          value={customAmount}
          onChange={e =>
            handleCustomAmountChange(e, setCustomAmount, setSelectedWeightKey, onChange)
          }
          placeholder="Other"
          maxLength={3}
          style={{textAlign: "center"}}
        />
      </ToggleButton>
    </Wrapper>
  );
}

GiftCardSelector.propTypes = {
  styles: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ])
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  gap: 8px;

  ${media.down("sm")} {
    flex-direction: row;
    margin-left: ${({styles}) => styles?.marginLeft?.sm || "0"};
  }
`;

const ToggleButton = styled.button`
  padding: ${({hasInput}) => (hasInput ? "0" : "10px 20px")};
  width: ${({hasInput}) => (hasInput ? "100px" : "80px")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({isSelected, styles, hasInput}) =>
    isSelected && hasInput
      ? "white"
      : isSelected
      ? styles?.selectedBackgroundColor
      : styles?.backgroundColor};
  color: ${({isSelected, styles}) => (isSelected ? styles.selectedColor : styles?.color)};
  border: 1px solid
    ${({isSelected, styles}) =>
      isSelected ? styles?.selectedBackgroundColor : styles?.borderColor || "#000"};
  border-left: ${({hasInput, isSelected, styles}) =>
    hasInput
      ? "none"
      : isSelected
      ? `1px solid ${styles?.selectedBackgroundColor}`
      : `1px solid ${styles?.borderColor}`};
  border-radius: ${({styles}) => styles?.borderRadius};
  font-size: ${({styles}) => styles?.fontSize};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({isSelected, styles, hasInput}) =>
      isSelected && hasInput
        ? "white"
        : isSelected
        ? styles?.selectedBackgroundColor
        : styles?.backgroundColor};
  }
`;

const DollarContainer = styled.div`
  color: ${({isSelected, styles}) => (isSelected ? styles.selectedColor : styles.color)};
  background-color: ${({isSelected, styles}) =>
    isSelected ? styles.selectedBackgroundColor : styles?.borderColor};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
`;

const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  padding: 8px;
  font-size: ${({styles}) => styles?.fontSize};
  color: ${({styles}) => styles?.color || "#000"};

  &:focus {
    outline: none;
    border: none;
  }

  &::placeholder {
    color: ${({styles}) => styles.inputPlaceholderColor};
  }
`;
