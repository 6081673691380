import {bindActionCreators} from "redux";
import * as Actions from "./state/actions";
import store from "./state/store";
import AuthenticationConfig from "./config";
import {
  isInitialized,
  isLoggedInSelector,
  isLoggingInSelector,
  isValidatingSelector,
  profileSelector,
} from "./state/selectors";

const {dispatch} = store;

export {default} from "./config";

export const Authentication = {
  login: bindActionCreators(Actions.requestUserLogin, dispatch),
  logout: bindActionCreators(Actions.requestUserLogout, dispatch),
  register: bindActionCreators(Actions.requestUserRegistration, dispatch),
  recoverPassword: bindActionCreators(Actions.requestRecoverPassword, dispatch),
  resetPassword: bindActionCreators(Actions.requestResetPassword, dispatch),
  changePassword: bindActionCreators(Actions.requestChangePassword, dispatch),
  accountVerification: bindActionCreators(Actions.requestAccountVerification, dispatch),
  accountVerificationCheck: bindActionCreators(
    Actions.requestAccountVerificationCheck,
    dispatch
  ),

  isLoggedIn: () => isLoggedInSelector(store.getState()),
  isValidating: () => isValidatingSelector(store.getState()),
  userProfile: () => profileSelector(store.getState()),
  isLoggingIn: () => isLoggingInSelector(store.getState()),

  loadUser: bindActionCreators(Actions.loadUser, dispatch),
  updateUser: bindActionCreators(Actions.requestUpdateUser, dispatch),
  deactivateUser: bindActionCreators(Actions.requestDeactivateUser, dispatch),
  loadUserProfile: bindActionCreators(Actions.loadUserProfile, dispatch),

  resetErrors: bindActionCreators(Actions.resetErrors, dispatch),

  setConfig: AuthenticationConfig.setConfig,

  isInitialized: () => isInitialized(store.getState()),

  addListener: listener => {
    const {handlers} = AuthenticationConfig.config;
    AuthenticationConfig.setHandlers([...handlers, listener]);
    return () => {
      const {handlers} = AuthenticationConfig.config;
      AuthenticationConfig.setHandlers(handlers.filter(l => l !== listener));
    };
  },
  store,
};
