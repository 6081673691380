import React from "react";
import {PromotionalBanner} from "@ui";
import usePromotionalBanners, {
  parseDimensions,
  parseImages,
} from "src/core/common/hooks/usePromotionalBanners";
import useSite from "src/core/sites/hooks/useSite";

export default function BaseThemePromotionalBanner() {
  const {promotionalBanners} = usePromotionalBanners();
  const bannerInfo = promotionalBanners.getBannerInfo();
  const site = useSite();
  const siteUrl = site.getSiteUrl();

  return (
    <PromotionalBanner
      siteUrl={siteUrl}
      banners={parseImages(bannerInfo.images)}
      dimensions={parseDimensions(bannerInfo.dimensions)}
    />
  );
}
