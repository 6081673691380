import React from "react";
import styled from "styled-components";
import {CategoryBar as CategoryBarUI, CategoryButton, media} from "@ui";
import Link from "@mock/components/modules/Link.js";
import categoryBarStyles from "@themes/default/components/CategoryBar/styles.js";
import useCategoryBar from "@mock/hooks/useCategoryBar.js";
import useTheme from "@mock/hooks/useTheme.js";

const CategoryBar = () => {
  const theme = useTheme();
  const {site, linkProps, ...categoryBarProps} = useCategoryBar();

  let {categories} = categoryBarProps;
  categories = categories.map(c => ({...c, imageUrl: null}));

  return (
    <Container>
      <CategoryBarUI
        {...categoryBarProps}
        categories={categories}
        styles={categoryBarStyles(theme, categoryBarProps, site)}
        CategoryButtonComponent={
          <CategoryButton linkProps={linkProps} LinkComponent={Link} />
        }
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 0 24px 0;

  ${media.down("sm")} {
    padding: 0;

    ul {
      padding: 0 16px;
    }
  }
`;

export default CategoryBar;
