import React from "react";
import {useTheme} from "styled-components";
import {CartIconActions, ProductCard, ResponsiveProductCard} from "@ui";
import useSite from "src/core/sites/hooks/useSite";
import styles from "src/themes/medleaf/components/ProductCard/styles";
import useProductCard from "src/core/common/hooks/useProductCard";
import {getFlowerTypeIndicatorProps} from "src/themes/medleaf/flowerType";

export default function MedLeafProductCard({product, ...props}) {
  const {actionProps, cartProps, ...productCardProps} = useProductCard({product});
  const theme = useTheme();
  const site = useSite();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site, theme);

  const DesktopProductCard = (
    <div>
      <ProductCard
        {...props}
        {...productCardProps}
        styles={styles(theme, site, {
          mode: props.mode,
        })}
        potencyTagsPosition="top"
        displayShadow={true}
        flowerTypeIndicatorProps={flowerTypeIndicatorProps}
        ActionsComponent={props => <CartIconActions {...props} {...actionProps} />}
        alignBrandWithSize={true}
        brandNamePosition={"below-product-name"}
      />
    </div>
  );

  return (
    <ResponsiveProductCard
      DesktopProductCard={DesktopProductCard}
      MobileProductCard={DesktopProductCard}
    />
  );
}
