import {useState} from "react";
import PropTypes from "prop-types";

export default function Collapsible({defaultCollapsed, children}) {
  const [isCollapsed, setCollapsed] = useState(defaultCollapsed);

  function toggle() {
    setCollapsed(!isCollapsed);
  }

  return children({isCollapsed, toggle});
}

Collapsible.defaultProps = {
  defaultCollapsed: true,
};

Collapsible.propTypes = {
  defaultCollapsed: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
};
