import React, {useMemo, useRef, useState} from "react";
import {useTheme} from "styled-components";
import {FiltersList as Filters} from "@ui";
import useClickOutside from "@ui/utils/useClickOutside";
import FiltersModalService from "@menu/services/filtersModalService";
import useModalService from "src/core/common/hooks/useModalService";
import useSite from "src/core/sites/hooks/useSite";
import {useForm} from "src/core/common/components/FormContext";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useBaseProductFilters from "@menu/hooks/useBaseProductFilters";
import useAppliedFilters, {
  ProductFilters as ProductFilter,
} from "src/core/common/hooks/useAppliedFilters";
import styles from "src/themes/legacy/components/ProductFilters/styles";
import {FilterNames} from "@menu/utils/constants";

export default function FiltersList({
  isInline,
  header,
  displayOptions = {},
  onChangeFilter,
}) {
  const theme = useTheme();
  const site = useSite();
  const _styles = styles(theme, site);
  const toggles = useFeatureToggles();
  const [appliedFilters, , clearFilters] = useAppliedFilters();
  const defaultDisplayOptions = {
    brandsVisible: true,
    typesVisible: true,
    categoriesVisible: true,
    featuredTagsVisible: true,
    tagsVisible: true,
    weightVisible: true,
    pricesVisible: toggles.priceFilterVisible(),
    potencyThcVisible: true,
    potencyCbdVisible: true,
    onSaleVisible: true,
  };
  const _displayOptions = {...defaultDisplayOptions, ...displayOptions};

  const collapseTagFilters = site.getUiConfiguration().filters.collapseTagFilters;

  const [isOpen, setIsOpen] = useState(false);

  const formContext = useForm();
  const disabled = formContext.disabled;

  const {
    meta,
    filters,
    filterOptions,
    hasOnSaleProducts,
    hasWeightFilters,
    onChangeProductFilter,
  } = useBaseProductFilters(onChangeFilter);

  const subcategoriesConfig = useMemo(() => {
    if (!filterOptions(FilterNames.SUBCATEGORY).values.length) {
      return {};
    }
    return {
      type: "option",
      renderCondition: _displayOptions.categoriesVisible,
      name: filterOptions(FilterNames.SUBCATEGORY).name + " subcategories",
      value: filters.subcategories,
      options: filterOptions(FilterNames.SUBCATEGORY).values,
      onChange: onChangeProductFilter(FilterNames.SUBCATEGORY),
      disabled: disabled || meta.loading,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    };
  }, [filterOptions(FilterNames.SUBCATEGORY)]);

  const config = [
    {
      type: "option",
      renderCondition: _displayOptions.onSaleVisible && hasOnSaleProducts,
      isSingleOption: true,
      id: "on-sale",
      name: "On Sale",
      value: filters.onSale,
      onChange: onChangeProductFilter(FilterNames.ON_SALE),
      disabled: disabled || meta.loading,
      checkedMarkColor: _styles?.section?.checkmarkColor,
    },
    {
      type: "option",
      renderCondition: _displayOptions.featuredTagsVisible,
      name: "Featured",
      value: filters.tags,
      options: filterOptions(FilterNames.FEATURE_TAG),
      onChange: onChangeProductFilter(FilterNames.FEATURE_TAG),
      disabled: disabled || meta.loading,
      collapseFilters: collapseTagFilters,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No featured products available right now",
    },
    subcategoriesConfig,
    {
      type: "option",
      renderCondition: _displayOptions.typesVisible,
      name: "Type",
      value: filters.types,
      options: filterOptions(FilterNames.TYPE),
      onChange: onChangeProductFilter(FilterNames.TYPE),
      disabled: disabled || meta.loading,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    },
    {
      type: "option",
      renderCondition: _displayOptions.brandsVisible,
      name: "Brands",
      value: filters.brands,
      options: filterOptions(FilterNames.BRAND),
      onChange: onChangeProductFilter(FilterNames.BRAND),
      disabled: disabled || meta.loading,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    },
    {
      type: "option",
      renderCondition: _displayOptions.tagsVisible,
      name: "Tags",
      value: filters.tags,
      options: filterOptions(FilterNames.NON_FEATURE_TAG),
      onChange: onChangeProductFilter(FilterNames.NON_FEATURE_TAG),
      disabled: disabled || meta.loading,
      collapseFilters: collapseTagFilters,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    },
    {
      type: "option",
      renderCondition: _displayOptions.weightVisible && hasWeightFilters,
      isGridLayout: true,
      name: "Weights",
      value: filters.weights,
      options: filterOptions(FilterNames.WEIGHT),
      onChange: onChangeProductFilter(FilterNames.WEIGHT),
      disabled: disabled || meta.loading,
      collapsedOptionsToDisplay: 6,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    },
    {
      type: "range",
      renderCondition: _displayOptions.pricesVisible,
      name: "Price",
      ranges: [
        {
          name: "From",
          value: filters.prices,
          range: filterOptions(FilterNames.PRICE_RANGE),
          onChange: onChangeProductFilter(FilterNames.PRICE_RANGE),
          hideIcon: true,
          unit: "$",
          unitPosition: "pre",
          disabled: disabled || meta.loading,
        },
      ],
    },
    {
      type: "range",
      renderCondition:
        _displayOptions.potencyThcVisible && _displayOptions.potencyCbdVisible,
      name: "Potency",
      ranges: [
        {
          name: "THC",
          value: filters.potencyThc,
          range: filterOptions(FilterNames.THC_RANGE),
          onChange: onChangeProductFilter(FilterNames.THC_RANGE),
          hideIcon: true,
          unit: filters.thcUnit,
          unitPosition: "post",
          disabled: disabled || meta.loading,
        },
        {
          name: "CBD",
          value: filters.potencyCbd,
          range: filterOptions(FilterNames.CBD_RANGE),
          onChange: onChangeProductFilter(FilterNames.CBD_RANGE),
          hideIcon: true,
          unit: filters.cbdUnit,
          unitPosition: "post",
          disabled: disabled || meta.loading,
        },
      ],
    },
  ];

  useModalService(FiltersModalService, () => {
    setIsOpen(true);
  });

  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const _filters = useMemo(
    () =>
      _displayOptions.brandsVisible
        ? ProductFilter.fromPrototype(appliedFilters)
        : ProductFilter.fromPrototype(appliedFilters, {brand: null}),
    [_displayOptions.brandsVisible, appliedFilters]
  );

  return (
    <Filters
      wrapperRef={wrapperRef}
      isInline={isInline}
      isOpen={isOpen}
      header={header}
      options={config}
      clearFilters={clearFilters}
      hasAppliedFilters={_filters.hasFilters()}
      filtersCounter={_filters.getCounters().total}
      onClose={() => setIsOpen(false)}
      styles={_styles}
    />
  );
}
