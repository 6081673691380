import autoBind from "auto-bind";
import OnlinePayment from "src/core/payments/OnlinePayment";
import EventBus from "src/core/common/eventBus";
import * as PaymentsAPI from "src/core/api/payments";
import {makePaymentSource} from "src/core/payments/factories/paymentSource";
import {PaymentOptions} from "src/core/payments/constants";

const AdyenEventBus = new EventBus();

class Adyen extends OnlinePayment {
  constructor(code) {
    super(code);
    this.eventBus = AdyenEventBus;
    autoBind(this);
  }

  async getConfiguration() {
    const {
      data: {
        attributes: {public_key: clientKey},
      },
    } = await PaymentsAPI.getIntegrationConfiguration(PaymentOptions.ADYEN);

    return {
      locale: "en_US",
      clientKey,
      environment: this.isProduction() ? "live-us" : "test",
    };
  }

  async preparePayment(orderInfo, paymentData, cartId, isGuestCheckout) {
    let paymentSpec = {};

    if (paymentData?.source?.id) {
      paymentSpec = {
        payment_source_id: paymentData.source.id,
      };
    } else {
      const encryptedCardData = this.makeEncryptedCardData(paymentData);
      encryptedCardData.cardholder_name = paymentData.holderName;

      if (!encryptedCardData) {
        throw new Error("Invalid payment data");
      }

      const params = [cartId, paymentData, encryptedCardData];
      if (!isGuestCheckout) {
        paymentSpec = await this.prepareAuthenticatedCheckoutPayment(...params);
      } else {
        paymentSpec = await this.prepareGuestCheckoutPayment(...params);
      }
    }

    const result = {
      ...orderInfo,
      payment_specification: paymentSpec,
    };

    this.maybeAttachTip(result, paymentData);
    this.maybeAttachFailPayment(result);
    return result;
  }

  async prepareGuestCheckoutPayment(cartId, paymentData, encryptedCardData) {
    return {
      payment_source_token: encryptedCardData.payment_source_token,
      cardholder_name: encryptedCardData.cardholder_name,
      billing_address: {
        address: paymentData.billingAddress,
        city: paymentData.billingCity,
        state: paymentData.billingState?.value,
        zip_code: paymentData.cardPostalCode,
        country: paymentData.billingCountry.value,
        building_number: paymentData.buildingNumber,
      },
    };
  }
  async prepareAuthenticatedCheckoutPayment(cartId, paymentData, encryptedCardData) {
    if (cartId) {
      await PaymentsAPI.createCustomer(PaymentOptions.ADYEN, cartId, {
        address: {
          address: paymentData.billingAddress,
          city: paymentData.billingCity,
          state: paymentData.billingState?.value,
          zip_code: paymentData.cardPostalCode,
          country: paymentData.billingCountry.value,
          building_number: paymentData.buildingNumber,
        },
      });
    }
    const paymentSource = await this.addPaymentSource(encryptedCardData);

    return {
      payment_source_id: paymentSource.id,
    };
  }

  async addPaymentSource(encryptedCardData) {
    const response = await PaymentsAPI.addPaymentSource(
      PaymentOptions.ADYEN,
      encryptedCardData
    );
    await PaymentsAPI.paymentSources(PaymentOptions.ADYEN); // trigger a sync to get the new payment source info
    return makePaymentSource(response.data, PaymentOptions.ADYEN);
  }

  async pay(orderId, paymentData) {
    const {payment_specification} = await this.preparePayment({}, paymentData);
    try {
      await PaymentsAPI.pay(PaymentOptions.ADYEN, orderId, payment_specification);
    } catch (e) {
      await this.maybeHandle(payment_specification, e);
    }
  }

  async removeAccount(paymentSource) {
    await PaymentsAPI.removePaymentSource(PaymentOptions.ADYEN, paymentSource.internalId);
    this.notify("removed-account-success", paymentSource);
  }

  makeEncryptedCardData(paymentData) {
    if (!paymentData.source.creditCard.data.paymentMethod) return null;

    const encryptedCardData = {
      encryptedCardNumber:
        paymentData.source.creditCard.data.paymentMethod.encryptedCardNumber,
      encryptedExpiryMonth:
        paymentData.source.creditCard.data.paymentMethod.encryptedExpiryMonth,
      encryptedExpiryYear:
        paymentData.source.creditCard.data.paymentMethod.encryptedExpiryYear,
      encryptedSecurityCode:
        paymentData.source.creditCard.data.paymentMethod.encryptedSecurityCode,
    };

    const json = JSON.stringify(encryptedCardData);
    return {
      payment_source_token: btoa(json),
    };
  }
}

export default Adyen;
