const {getSiteInCluster} = require("../clusters");
const getEnv = (shop, domain) => {
  const {envs} = getSiteInCluster(domain);

  if (envs) {
    return envs[shop] || envs[Object.keys(envs)[0]] || process.env;
  }

  return process.env;
};

module.exports = {
  getEnv,
};
