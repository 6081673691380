import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export default function InlineHeader({clearFilters}) {
  return (
    <InlineHeaderContainer>
      <HeaderContainer>
        <InlineTitle>Filters</InlineTitle>
      </HeaderContainer>
      <InlineClearButton onClick={clearFilters}>Clear all</InlineClearButton>
    </InlineHeaderContainer>
  );
}

const InlineHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5px;
`;

const HeaderContainer = styled.h2`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.v2.color.filters};
  font-family: ${({theme}) => theme.v2.typography.titles.family};
  font-size: ${({theme}) => theme.v2.typography.sizing["3xl"].lg};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.light};
  column-gap: ${({theme}) => theme.v2.spacing(4)};
  margin: 0;
`;

const InlineTitle = styled.span`
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
`;

const InlineClearButton = styled.a`
  font-size: ${({theme}) => theme.v2.typography.sizing.l.md};
  color: ${({theme}) => theme.v2.color.primary};
  text-decoration: underline;
  cursor: pointer;
`;

InlineHeader.defaultProps = {
  clearFilters: () => {},
};

InlineHeader.propTypes = {
  clearFilters: PropTypes.func,
};
