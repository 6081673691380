import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import styled from "styled-components";
import {Form} from "react-final-form";
import DeliveryTypeSelector from "./DeliveryTypeSelector";
import DeliveryAddressInput from "./DeliveryAddressInput";
import StoreSelector from "./StoreSelector";
import Button from "../Button";
import ConfigurableImage from "@ui/components/ConfigurableImage";

const DeliveryCheckDropdown = ({
  styles,
  routerOnOpen,
  routerOnClose,
  selectedDeliveryType,
  overrideDeliveryType,
  deliveryTypePickerProps,
  deliveryModePickerProps,
  LinkComponent,
  image,
  storeList,
  onSubmitPickupStore,
  onSubmitDeliveryOptions,
  deliversToAddress,
  deliveryShopIsExternal,
  internalShopRoute,
  loading,
  NumberComponent,
  AddressInputComponent,
}) => {
  const initialDeliveryType = overrideDeliveryType || selectedDeliveryType?.code;
  const selectedDeliveryMode = deliveryModePickerProps.selectedMode;
  const setDeliveryMode = deliveryModePickerProps.setDeliveryMode;
  const allowScheduleDelivery = deliveryModePickerProps.allowScheduleDelivery;
  const allowExpressDelivery = deliveryModePickerProps.allowExpressDelivery;
  const successMessage = deliveryModePickerProps.successMessage;
  const errorMessage = deliveryModePickerProps.errorMessage;

  const _styles = merge({}, defaultStyles, styles);
  const [selectedType, setSelectedType] = useState(initialDeliveryType);
  const [isShopExternal, setIsShopExternal] = useState(false);
  const [shopRoute, setShopRoute] = useState({pathname: internalShopRoute});

  const [currentShop, setCurrentShop] = useState(null);

  const changeSelectedType = type => {
    let routerChange = routerOnOpen[type];
    routerChange();
    setSelectedType(type);
  };

  const isSelectedShopExternal = shop => {
    let isExternal = deliveryShopIsExternal(shop);
    setIsShopExternal(isExternal);
  };

  const createStoreRoute = shop => {
    let route = internalShopRoute;
    if (isShopExternal) {
      route = `${shop?.getMenuUrl()}?delivery_type=pickup`;
    }
    setShopRoute({pathname: route});
  };

  const onSubmit = values => {
    if (selectedType === "delivery") {
      let formValues = values;
      if (!values.mode) formValues.mode = "asap";

      onSubmitDeliveryOptions(formValues, () => routerOnClose({submitting: true}));
    }
  };

  useEffect(() => {
    if (selectedType === "pickup") {
      isSelectedShopExternal(currentShop);
      createStoreRoute(currentShop);
    }
  }, [currentShop, isShopExternal]);

  // This should be interpreted as a modal being open
  return (
    <div className={"ReactModal__Body--open"}>
      <Overlay onClick={routerOnClose} />
      <Form
        onSubmit={onSubmit}
        validate={() => {}}
        render={({handleSubmit, values}) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <OuterContainer>
              <InnerContainer styles={_styles.root}>
                <Triangle styles={_styles.root} />
                <ImageContainer>
                  <ConfigurableImage source={image} alt="" width={170} />
                </ImageContainer>
                <Content>
                  <DeliveryTypeSelector
                    styles={_styles.typeSelector}
                    selectedDeliveryType={selectedType}
                    allowDelivery={deliveryTypePickerProps.allowDelivery}
                    allowPickup={deliveryTypePickerProps.allowPickup}
                    onChange={changeSelectedType}
                  />
                  {selectedType === "delivery" && (
                    <>
                      <DeliveryAddressInput
                        styles={_styles}
                        deliveryMode={selectedDeliveryMode}
                        setDeliveryMode={setDeliveryMode}
                        allowExpressDelivery={allowExpressDelivery}
                        allowScheduleDelivery={allowScheduleDelivery}
                        AddressInputComponent={AddressInputComponent}
                        NumberComponent={NumberComponent}
                      />
                      <Button
                        label="Shop"
                        variant="primary"
                        type="submit"
                        loading={loading}
                        disabled={!deliversToAddress}
                        styles={{root: {width: "100%"}}}
                      />
                      {deliversToAddress && (
                        <SuccessfulText>
                          <span>
                            {successMessage || "Good news, we deliver to that address."}
                          </span>
                        </SuccessfulText>
                      )}
                      {/* deliversToAddress can be undefined, which represents a different state, explicit check is necessary here */}
                      {deliversToAddress === false && errorMessage && !loading && (
                        <ErrorText>
                          <span>{errorMessage}</span>
                        </ErrorText>
                      )}
                    </>
                  )}
                  {selectedType === "pickup" && (
                    <>
                      <StoreSelector
                        defaultStore={null}
                        storeList={storeList}
                        setCurrentShop={setCurrentShop}
                        styles={_styles.storeSelector}
                      />
                      <LinkComponent
                        external={isShopExternal}
                        underlined={false}
                        to={shopRoute}
                        onClick={() => onSubmitPickupStore(values.store)}
                      >
                        <Button
                          label="Shop"
                          variant="primary"
                          type="button"
                          onClick={() => {
                            !isShopExternal && routerOnClose({submitting: true});

                            return true;
                          }}
                          disabled={!currentShop}
                          loading={loading}
                          styles={{root: {width: "100%"}}}
                        />
                      </LinkComponent>
                    </>
                  )}
                </Content>
              </InnerContainer>
            </OuterContainer>
          </form>
        )}
      />
    </div>
  );
};

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    borderRadius: "0 0 8px 8px",
    color: "#000",
  },
  typeSelector: {
    title: {
      color: "#000",
      fontFamily: "sans-serif",
      fontWeight: "700",
      fontSize: {
        lg: "22px",
        md: "16px",
        sm: "12px",
      },
    },
    options: {
      textShadowColor: "#f9d645",
      borderColor: "#000",
      color: "#000",
      fontFamily: "sans-serif",
      fontWeight: "700",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "12px",
      },
    },
  },
  addressInput: {
    title: {
      color: "#000",
      fontFamily: "sans-serif",
      fontWeight: "700",
      fontSize: {
        lg: "22px",
        md: "16px",
        sm: "12px",
      },
    },
    modeSelector: {
      mode: {
        borderColor: "#000",
        color: "#000",
        selectedColor: "#D8D8D8",
        fontFamily: "sans-serif",
        fontWeight: "400",
        fontSize: {
          lg: "16px",
          md: "16px",
          sm: "12px",
        },
      },
      description: {
        color: "#7f7f7f",
        fontFamily: "sans-serif",
        fontWeight: "400",
        fontSize: {
          lg: "14px",
          md: "14px",
          sm: "12px",
        },
      },
    },
  },
  storeSelector: {
    title: {
      color: "#000",
      fontFamily: "sans-serif",
      fontWeight: "700",
      fontSize: {
        lg: "22px",
        md: "16px",
        sm: "12px",
      },
    },
    options: {
      selectedColor: "#D8D8D8",
      borderColor: "#000",
      color: "#000",
      fontFamily: "sans-serif",
      fontWeight: "400",
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "12px",
      },
    },
  },
};

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  opacity: 0.6;
`;

const OuterContainer = styled.div.attrs(() => ({
  className: "delivery-check-dropdown__outerContainer",
}))`
  position: fixed;
  width: 450px;
  top: 33px;
  z-index: 6;
  left: 50%;
  transform: translateX(-50%);
`;

const InnerContainer = styled.div.attrs(() => ({
  className: "delivery-check-dropdown__innerContainer",
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${({styles}) => styles.backgroundColor};
  border-radius: ${({styles}) => styles.borderRadius};
  width: 450px;
  max-width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
  z-index: 1;
  box-sizing: border-box;
`;

const Content = styled.div.attrs(() => ({
  className: "delivery-check-dropdown__content",
}))`
  width: 350px;
  margin: 20px auto;
  box-sizing: border-box;
`;

const ImageContainer = styled.div.attrs(() => ({
  className: "delivery-check-dropdown__imageContainer",
}))`
  display: flex;
  justify-content: center;
  min-height: 72px;

  > img {
    object-fit: contain;
    max-width: 170px;
  }
`;

const Triangle = styled.div.attrs(() => ({
  className: "delivery-check-dropdown__triangle",
}))`
  position: absolute;
  top: 0;
  left: 48%;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: ${({styles}) => `13px solid ${styles.color}`};
`;

const SuccessfulText = styled.div`
  margin-top: 16px;
  text-align: center;
  span,
  p {
    margin: 0;
    color: #0b8535;
    font-weight: 100;
  }
  span:hover {
    text-decoration: none !important;
  }

  margin-bottom: 0;
`;
const ErrorText = styled(SuccessfulText)`
  span,
  p {
    color: red;
  }
`;

DeliveryCheckDropdown.propTypes = {
  routerOnOpen: PropTypes.shape({
    pickup: PropTypes.func,
    delivery: PropTypes.func,
  }),
  routerOnClose: PropTypes.func,
  selectedDeliveryType: PropTypes.shape({
    code: PropTypes.string,
  }),
  overrideDeliveryType: PropTypes.string,
  deliveryTypePickerProps: PropTypes.object,
  deliveryModePickerProps: PropTypes.object,
  LinkComponent: PropTypes.elementType,
  NumberComponent: PropTypes.elementType,
  AddressInputComponent: PropTypes.elementType,
  image: PropTypes.string,
  onSubmitPickupStore: PropTypes.func,
  onSubmitDeliveryOptions: PropTypes.func,
  deliversToAddress: PropTypes.bool,
  deliveryShopIsExternal: PropTypes.func,
  internalShopRoute: PropTypes.string,
  storeList: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    typeSelector: PropTypes.shape({
      title: PropTypes.shape({
        color: PropTypes.string,
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
      options: PropTypes.shape({
        textShadowColor: PropTypes.string,
        borderColor: PropTypes.string,
        color: PropTypes.string,
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
    }),
    addressInput: PropTypes.shape({
      title: PropTypes.shape({
        color: PropTypes.string,
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
      modeSelector: PropTypes.shape({
        mode: PropTypes.shape({
          selectedColor: PropTypes.string,
          borderColor: PropTypes.string,
          color: PropTypes.string,
          fontFamily: PropTypes.string,
          fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            md: PropTypes.string,
            sm: PropTypes.string,
          }),
        }),
        description: PropTypes.shape({
          color: PropTypes.string,
          fontFamily: PropTypes.string,
          fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            md: PropTypes.string,
            sm: PropTypes.string,
          }),
        }),
      }),
    }),
    storeSelector: PropTypes.shape({
      title: PropTypes.shape({
        color: PropTypes.string,
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
      options: PropTypes.shape({
        selectedColor: PropTypes.string,
        borderColor: PropTypes.string,
        color: PropTypes.string,
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
    }),
  }),
};

DeliveryCheckDropdown.defaultProps = {
  styles: defaultStyles,
  LinkComponent: ({children}) => <a href="#">{children}</a>,
};

export default DeliveryCheckDropdown;
