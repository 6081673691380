import get from "lodash/get";
import {removeTrailingSlash} from "src/core/common/utils";

class Routes {
  constructor(routesObject) {
    this.routesObject = routesObject;
    this.makeGetters(this.getBasePath());
  }

  getBasePath() {
    if (process.env.NEXT_PUBLIC_MENU_BASEPATH) {
      return `/${process.env.NEXT_PUBLIC_MENU_BASEPATH}/:shop`;
    }

    return "/:shop";
  }

  loadPageOptions(pageOptions) {
    if (pageOptions?.productDetail?.isModal) {
      this.routesObject.productDetail = modal(this.routesObject.productDetail);
      this.routesObject.noBrandProductDetail = modal(
        this.routesObject.noBrandProductDetail
      );
    }
  }

  setPrefix(prefix) {
    this.makeGetters(prefix);
  }

  makeGetters = (prefix = "") => {
    Object.keys(this.routesObject).forEach(k => {
      Object.defineProperty(this, k, {
        get: () => {
          if (this.routesObject[k].noPrefix) return this.routesObject[k].path;

          return `${prefix}${this.routesObject[k].path}`;
        },
        configurable: true,
      });
    });
  };

  modals() {
    return Object.keys(this.routesObject)
      .filter(key => get(this.routesObject, `${key}.options.isModal`, false))
      .map(key => this[key]);
  }

  isModal(pathname) {
    return Boolean(
      this.modals().find(
        route => removeTrailingSlash(route) === removeTrailingSlash(pathname)
      )
    );
  }

  getProductDetailPath({product, brand}) {
    if (brand || product) {
      const _brand = brand || product.getBrandSlug();
      return _brand
        ? get(this, "productDetail", this.routesObject.productDetail.path)
        : get(this, "noBrandProductDetail", this.routesObject.noBrandProductDetail.path);
    } else {
      return get(
        this,
        "noBrandProductDetail",
        this.routesObject.noBrandProductDetail.path
      );
    }
  }

  toNextFormat(path) {
    return path
      .split("/")
      .map(entry => {
        if (entry.indexOf(":") === 0) {
          return `[${entry.replace(":", "")}]`;
        } else {
          return entry;
        }
      })
      .join("/");
  }

  isAuthenticationRoute(path) {
    const paths = [
      this.onBoarding,
      this.signup,
      this.login,
      this.resetPasswordWithToken,
      this.resetPassword,
      this.forgotPassword,
      this.accountVerification,
    ].map(this.toNextFormat);
    const _path = path.endsWith("/") ? path : path + "/";

    return !!paths.find(p => _path.includes(p));
  }
}

const routesObject = {
  home: {path: "/"},

  productReader: {path: "/product-reader/"},

  checkout: {path: "/checkout/"},
  paymentVerification: {path: "/checkout/payment-verification/"},
  checkoutFromCartId: {path: "/checkout/:cartId/"},
  orderDetail: {path: "/orders/:orderId/"},

  search: {path: "/search/"},
  products: {path: "/"},
  allCategories: {path: "/categories/"},
  productCategory: {path: "/categories/:category/"},
  productSubcategory: {path: "/categories/:category/:subcategory/"},
  productDetail: {path: "/products/:brand/:category/:productId/"},
  noBrandProductDetail: {path: "/products/:category/:productId/"},
  brandDetail: {path: "/brands/:brand/"},

  productsByTag: {path: "/tags/:tag/"},

  onBoarding: modal({path: "/on-boarding/"}),
  signup: modal({path: "/signup/"}),
  login: modal({path: "/login/"}),
  resetPasswordWithToken: modal({path: "/reset-password/:token/"}),
  resetPassword: modal({path: "/reset-password/"}),
  forgotPassword: modal({path: "/forgot-password/"}),
  accountVerification: modal({path: "/verification/phone-number/"}),

  deliveryType: modal({path: "/delivery-type/"}),
  deliveryCheck: modal({path: "/delivery-check/"}),

  storeSelection: modal({path: "/store-selection/"}),

  inventoryType: modal({path: "/inventory-type/"}),

  myAccount: modal({path: "/my-account/"}),
  personalInfo: modal({path: "/my-account/personal-info/"}),
  identityVerification: modal({path: "/my-account/identity-verification/"}),
  changePassword: modal({path: "/my-account/change-password/"}),
  userOrders: modal({path: "/my-account/orders/"}),
  userOrderRating: modal({path: "/orders/:orderId/rating/"}),
  userOrderTip: modal({path: "/orders/:orderId/tip/"}),
  accountDeliveryCheck: modal({path: "/my-account/delivery-check/"}),
  accountBillingAddress: modal({path: "/my-account/billing-address/"}),
  paymentMethods: modal({path: "/my-account/payment-methods/"}),
  accountCreditCards: modal({path: "/my-account/credit-cards/:creditCardType/"}),
  deactivateAccount: modal({path: "/deactivate-account/"}),

  deals: {path: "/deals/:shop/", noPrefix: true},
  dealDetail: {path: "/deals/:dealType/:deal/"},
};

function modal(routeDefinition) {
  return {
    ...routeDefinition,
    options: {
      ...routeDefinition.options,
      isModal: true,
    },
  };
}

export default new Routes(routesObject);
