import initInStoreExperience from "src/integrations/inStoreExperience";

class IntegrationComponentRegistry {
  constructor() {
    this.components = [];
    this.menuAddons = [];
    this.basePageAddons = [];
  }

  init(integrations, siteOptions) {
    integrations.forEach(integration => {
      switch (integration) {
        case "instore-experience":
          initInStoreExperience(siteOptions);
          break;
        default:
          break;
      }
    });
  }

  registerComponentOverride(componentName, {Component, enabled}) {
    if (this.components.some(component => component.name === componentName)) {
      throw new Error("The component is already registered");
    } else {
      this.components.push({name: componentName, Component, enabled});
    }
  }

  registerMenuAddon(element) {
    this.menuAddons.push(element);
  }

  registerBasePageAddon(element) {
    this.basePageAddons.push(element);
  }

  getComponent(componentName) {
    return (
      this.components.filter(component => component.name === componentName)[0] || null
    );
  }

  getMenuAddons() {
    return this.menuAddons;
  }

  getBasePageAddons() {
    return this.basePageAddons;
  }
}

export default new IntegrationComponentRegistry();
