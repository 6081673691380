import * as ShopsApi from "src/core/api/shops";
import ShopFullSettings from "src/core/common/models/shopFullSettings";
import TymberAPIConfig from "src/core/api/config";
import {isClient} from "src/server/utils/isClient";
import {searchAddress} from "src/core/api/geolocation";
import get from "lodash/get";

export const getShopDetails = () => {
  return ShopsApi.fullSettings()
    .then(data => new ShopFullSettings(data))
    .then(fullSettings => {
      const storeId = TymberAPIConfig.headers["X-Store"];
      const shop = fullSettings.getShopById(storeId);
      const address = shop.getAddress();
      if (isClient && address && address.zipCode) {
        const encodedAddress = address.toString();
        searchAddress(encodedAddress, {country: address.country}).then(features => {
          const center = get(features, "0.center");
          if (center) {
            shop.shopObject.data.attributes.coordinates = {
              lat: center[1],
              lng: center[0],
            };
          }
        });
      }
      return shop;
    });
};
