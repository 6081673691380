import store from "@deals/state/store";
import {useEffect, useState} from "react";
import {requestOnSaleProducts} from "@deals/state/actions";

export default function useOnSaleProducts() {
  const dispatch = store.dispatch;
  const [state, setState] = useState(store.getState().onSaleProducts);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState().onSaleProducts);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const fetchOnSaleProducts = options => {
    dispatch(requestOnSaleProducts(options));
  };

  return {
    fetchOnSaleProducts,
    state,
  };
}
