import dayjs from "dayjs";
import {DeliveryModes} from "../../common/models/deliveryMode";

class ScheduleTime {
  constructor(attrs) {
    this._attrs = attrs;
  }

  get deliverySlot() {
    return null;
  }

  get code() {
    return null;
  }

  toString() {
    return null;
  }

  get name() {
    return null;
  }

  getTimeRange() {
    return null;
  }

  getStartTime8601() {
    return null;
  }

  getEndTime8601() {
    return null;
  }
}

class AsapTime extends ScheduleTime {
  get code() {
    return DeliveryModes.ASAP;
  }

  get name() {
    return "ASAP";
  }

  toString() {
    return "ASAP";
  }
}

class ExpressTime extends ScheduleTime {
  get code() {
    return DeliveryModes.EXPRESS;
  }

  get name() {
    return "Express";
  }

  toString() {
    return "Express";
  }
}

class ScheduledTime extends ScheduleTime {
  get name() {
    return "Scheduled";
  }

  get code() {
    return DeliveryModes.SCHEDULED;
  }

  get deliverySlot() {
    return this._attrs.deliverySlot;
  }

  getTimeRange() {
    return this.deliverySlot.timeRange;
  }

  getStartTime8601() {
    return dayjs(this.deliverySlot.timeRange.start).format("YYYY-MM-DD[T]HH:mm:ss");
  }

  getEndTime8601() {
    return dayjs(this.deliverySlot.timeRange.end).format("YYYY-MM-DD[T]HH:mm:ss");
  }

  toString() {
    const today = new Date();
    const tomorrow = dayjs(today).add(1, "day");

    const timeRange = this._attrs.deliverySlot.timeRange;

    let dayLabel = "";
    if (timeRange.isSameDay(today)) dayLabel = "Today, ";
    if (timeRange.isSameDay(tomorrow)) dayLabel = "Tomorrow, ";

    return `${dayLabel}${dayjs(timeRange.start).format(
      "MMMM DD"
    )}, ${timeRange.toString()}`;
  }
}

export function makeScheduleTime(type, attrs) {
  switch (type) {
    case DeliveryModes.ASAP:
      return new AsapTime(attrs);
    case DeliveryModes.SCHEDULED:
      return new ScheduledTime(attrs);
    case DeliveryModes.EXPRESS:
      return new ExpressTime(attrs);
    default:
      return new ScheduledTime(attrs);
  }
}
