import React from "react";
import styled from "styled-components";
import {useInView} from "react-intersection-observer";
import ProductsList from "@themes/boost/components/ShowcaseProductsList/ProductsList";

export default function BoostThemeShowcaseSection(props) {
  const {ref, inView} = useInView({triggerOnce: true});

  const {name, description, slug, url, forceDisplay} = props;
  const displayList = inView || forceDisplay;

  return (
    <ProductListContainer
      ref={ref}
      minHeight={props.minHeight}
      spacing={props.sectionSpacing}
    >
      {displayList && (
        <ProductsList
          slug={slug}
          url={url}
          categoryName={name}
          description={description}
          {...props}
        />
      )}
    </ProductListContainer>
  );
}

const ProductListContainer = styled.div`
  min-height: ${({minHeight}) => minHeight || "600px"};

  &:not(:last-of-type) {
    margin-bottom: ${({spacing}) => spacing || "20px"};
  }
`;
