import React, {Suspense, lazy} from "react";
const Dropdown = lazy(() => import("react-select"));
function SelectField(props) {
  return (
    <Suspense fallback={<div />}>
      <Dropdown {...props} />
    </Suspense>
  );
}

export default SelectField;
