import React, {useEffect} from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import ModeSelector from "./ModeSelector";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

const DeliveryAddressInput = ({
  AddressInputComponent,
  NumberComponent,
  allowExpressDelivery,
  allowScheduleDelivery,
  deliveryMode,
  setDeliveryMode,
  styles,
}) => {
  useEffect(() => {
    const allowedModes = ["express", "scheduled"];
    if (allowedModes.includes(deliveryMode)) return;

    if (!allowExpressDelivery && !allowScheduleDelivery) {
      setDeliveryMode("");
    } else if (allowScheduleDelivery) {
      setDeliveryMode("scheduled");
    } else if (allowExpressDelivery) {
      setDeliveryMode("express");
    }
  }, []);

  const showErrorHighlight = meta => {
    return Boolean(meta && meta.touched && meta.error);
  };

  const modeRequired = value => {
    if (!allowExpressDelivery && !allowScheduleDelivery) return undefined;

    if (value !== "express" && value !== "scheduled") return "Required";
  };

  return (
    <Container>
      <Title styles={styles.addressInput.title}>Enter Your Delivery Address</Title>
      <Field
        name="mode"
        initialValue={deliveryMode}
        validate={modeRequired}
        render={({input, meta}) => {
          return (
            <FieldContainer>
              <ModeContainer>
                {allowExpressDelivery && (
                  <ModeSelector
                    {...input}
                    styles={styles.addressInput.modeSelector}
                    hasErrors={showErrorHighlight(meta)}
                    isSelected={input.value === "express"}
                    changeDeliveryMode={value => {
                      setDeliveryMode("express");
                      input.onChange(value);
                    }}
                    mode="express"
                    description="Delivery as soon as possible"
                  />
                )}
                {allowScheduleDelivery && (
                  <ModeSelector
                    styles={styles.addressInput.modeSelector}
                    hasErrors={showErrorHighlight(meta)}
                    isSelected={input.value === "scheduled"}
                    changeDeliveryMode={value => {
                      setDeliveryMode("scheduled");
                      input.onChange(value);
                    }}
                    mode="scheduled"
                    description="Delivery on scheduled time"
                  />
                )}
              </ModeContainer>
              {meta.touched && <ErrorContainer>{meta.error}</ErrorContainer>}
            </FieldContainer>
          );
        }}
      />
      {AddressInputComponent && (
        <InputContainer>
          {renderNodeOrComponent(AddressInputComponent, {})}
        </InputContainer>
      )}
      {NumberComponent && (
        <>
          <OptionalLine>
            <OptionalText styles={styles}>Optional</OptionalText>
          </OptionalLine>
          <Field
            name="number"
            render={({input, meta}) => (
              <InputContainer>
                <NumberComponent
                  {...input}
                  hasErrors={showErrorHighlight(meta)}
                  onChange={input.onChange}
                  style={{width: "100%"}}
                  placeholder={`Apt. number`}
                />
                {meta.touched && <ErrorContainer>{meta.error}</ErrorContainer>}
              </InputContainer>
            )}
          />
        </>
      )}
    </Container>
  );
};

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;

const Container = styled.div.attrs(() => ({
  className: "delivery-address-input__container",
}))`
  display: flex;
  flex-direction: column;
`;

const ModeContainer = styled.div.attrs(() => ({
  className: "delivery-address-input__modeContainer",
}))`
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled.div.attrs(() => ({
  className: "delivery-address-input__errorContainer",
}))`
  color: red;
  font-size: 14px;
`;

const Title = styled.div.attrs(() => ({
  className: "delivery-address-input__title",
}))`
  margin: 0 0 20px 0;
  text-align: center;
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const OptionalLine = styled.div.attrs(() => ({
  className: "delivery-address-input__optionalLine",
}))`
  color: #8d8d8d;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.01em;
  margin: 10px 0 20px;
`;

const OptionalText = styled.span.attrs(() => ({
  className: "delivery-address-input__optionalText",
}))`
  color: #7f7f7f;
  background-color: ${({styles}) => styles.root.backgroundColor};
  font-family: ${({styles}) => styles.root.fontFamily};
  font-weight: 400;
  font-size: 14px;
  padding: 0 5px;
`;

const InputContainer = styled.div.attrs(() => ({
  className: "delivery-address-input__inputContainer",
}))`
  margin: 0 0 20px 0;
`;

DeliveryAddressInput.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default DeliveryAddressInput;
