import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import useDeliveryMode from "src/core/deliveries/hooks/useDeliveryMode";
import useDeliveryAddress from "src/core/deliveries/hooks/useDeliveryAddress";
import {useMemo} from "react";

const useDeliveryInfo = () => {
  const [deliveryType] = useDeliveryType();
  const [deliveryMode] = useDeliveryMode();
  const [address] = useDeliveryAddress();
  return useMemo(() => {
    if (!deliveryType) return null;
    return {
      deliveryType: deliveryType?.code,
      deliveryMode,
      deliveryAddress: address?.zipCode,
    };
  }, [deliveryType?.code, deliveryMode, address?.zipCode]);
};

export default useDeliveryInfo;
