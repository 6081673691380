import React from "react";
import {ViewAllLink} from "@ui";
import Link from "@mock/components/modules/Link";
import useViewAllLink from "@mock/hooks/useViewAllLink";

export default function DefaultViewAllLink({to, keepQuery, accessibilityIdentifier}) {
  const {isDesktop} = useViewAllLink();
  return (
    <ViewAllLink
      to={to}
      keepQuery={keepQuery}
      accessibilityIdentifier={accessibilityIdentifier}
      LinkComponent={Link}
      isDesktop={isDesktop}
    />
  );
}
