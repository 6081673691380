import React from "react";
import OptionFilter from "./FilterTypes/OptionFilter";
import RangeFilter from "./FilterTypes/RangeFilter";

export default function makeFilter(config) {
  const {type, renderCondition} = config;

  if (!renderCondition) return null;

  switch (type) {
    case "option":
      return (
        <li>
          <OptionFilter {...config} />
        </li>
      );
    case "range":
      return (
        <li>
          <RangeFilter {...config} />
        </li>
      );
    default:
      return null;
  }
}
