import mediaHelper from "styled-media-helper";

export const mediaDimensions = {
  sm: 320,
  md: 768,
  lg: 1024,
};
const media = mediaHelper(mediaDimensions);

export const getCurrentDim = () => {
  if (window.innerWidth >= mediaDimensions.lg) {
    return "lg";
  } else if (window.innerWidth >= mediaDimensions.md) {
    return "md";
  } else {
    return "sm";
  }
};

export default media;
