import defaultConfig from "@themes/default/config";
import {getFlowerTypeIndicatorProps} from "@themes/colorful/themeStyles/flowerType";
import ShowcaseProductsList from "@themes/colorful/components/ShowcaseProductsList";
import ProductCard from "@themes/colorful/components/ProductCard";
import CategoryDetailPage from "@themes/colorful/pages/CategoryDetailPage";

const config = {
  inherits: "boost",
  components: {
    ShowcaseProductsList,
    ProductCard,
  },
  pages: {
    ProductsPage: CategoryDetailPage,
    SubcategoryPage: CategoryDetailPage,
    ProductDetailPage: CategoryDetailPage,
  },
  options: {
    flowerType: {
      getFlowerTypeIndicatorProps,
    },
  },
};

export const components = {
  ...defaultConfig.components,
  ...defaultConfig.pages,

  ...config.components,
  ...config.pages,
};

export default config;
