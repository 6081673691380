import makeBrandSchemaObject from "src/core/seo/schema-objects/objects/brand";

function makeBrandDetailSchema(brand, ctx) {
  if (!brand) return [];

  return [
    {
      "@context": "https://schema.org/",
      "@graph": [makeBrandSchemaObject(brand, ctx)],
    },
  ].map(schema => ({
    type: "application/ld+json",
    innerHTML: JSON.stringify(schema),
  }));
}

export default makeBrandDetailSchema;
