import React from "react";
import styled from "styled-components";
import {PromotionalBanner} from "@ui";
import usePromotionalBanners from "@mock/hooks/usePromotionalBanners";

export default function DefaultThemePromotionalBanner() {
  const {banners, dimensions, skeleton, siteUrl} = usePromotionalBanners();

  return (
    <Container>
      <PromotionalBanner
        siteUrl={siteUrl}
        banners={banners}
        dimensions={dimensions}
        skeleton={skeleton}
      />
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
`;
