import React from "react";
import DefaultDealCard from "@themes/default/components/DealCard";
import useSite from "src/core/sites/hooks/useSite";

export default function DealCard(props) {
  const site = useSite();
  return (
    <DefaultDealCard
      {...props}
      zoomOnHover={site?.getUiConfiguration().productCard.zoomOnHover}
    />
  );
}
