import React from "react";
import {atom, useRecoilState} from "recoil";
import useAgeGate from "src/core/user/hooks/useAgeGate";

const defaultModalContext = {
  modalBasePath: "/",
  modalParams: {},
  hidden: false,
  allowedToClose: true,
};

export const ModalContext = React.createContext(defaultModalContext);

export const ModalGlobalState = atom({
  key: "modalGlobalState",
  default: {
    allowedToClose: true,
  },
});

export function ModalContextProvider({value = defaultModalContext, children}) {
  const props = useModalGlobalProps();
  const [hasAllowedAge] = useAgeGate();

  return (
    <ModalContext.Provider value={{...value, hidden: !hasAllowedAge, ...props}}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModalGlobalProps() {
  const [state] = useRecoilState(ModalGlobalState);

  return state;
}
