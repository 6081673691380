import React from "react";
import {
  ProductCard as ProductCardUI,
  CartIconActions,
  SimpleFlowerTypeIndicator,
  SaleIndicator,
} from "@ui";
import productCardStyles from "@themes/default/components/ProductCard/styles.js";
import useTheme from "@mock/hooks/useTheme.js";
import useProductCard from "@mock/hooks/useProductCard.js";

export default function ProductCard(props) {
  const {site, actionProps, cartProps, flowerTypeIndicatorProps, ...productCardProps} =
    useProductCard(props);
  const {product, ...otherProps} = props;

  const theme = useTheme();

  return (
    <ProductCardUI
      {...otherProps}
      {...productCardProps}
      styles={productCardStyles(theme, {mode: props.mode}, site)}
      ActionsComponent={<CartIconActions {...props} {...actionProps} />}
      SaleIndicatorComponent={<SaleIndicator position={"right"} />}
      potencyTagsPosition="top"
      flowerTypeIndicatorProps={flowerTypeIndicatorProps}
      FlowerTypeIndicatorComponent={SimpleFlowerTypeIndicator}
    />
  );
}
