const {getEnv} = require("src/server/utils/getEnv");

function isKiosk(shop, host) {
  const env = getEnv(shop, host);
  return env?.REACT_APP_MODE === "kiosk";
}

module.exports = {
  isKiosk,
};
