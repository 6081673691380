import routes from "src/core/common/routes";
import useRouter from "src/core/common/hooks/useRouter";

export default function useProductCategory() {
  const router = useRouter();
  const {category, subcategory} = router.query;

  function goToProductCategory(category) {
    router.push({
      pathname: routes.productCategory,
      params: {category},
    });
  }

  return {
    category: category || "",
    subcategory: subcategory || "",
    goToProductCategory,
  };
}
