import React from "react";
import styles from "@themes/default/components/ShowcaseDealsList/styles";
import useTheme from "@mock/hooks/useTheme";
import useShowcaseDeals from "@mock/hooks/useShowcaseDeals";
import DealsListHeader from "@ui/components/DealsList/DealsListHeader";
import SimpleCardList from "@ui/components/SimpleCardList";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

export default function DefaultShowcaseDealsList() {
  const theme = useTheme();
  const {ViewAllLink} = useThemeComponents();
  const {
    deals,
    loading,
    site,
    DealCardComponent,
    showDealsList,
    dealsListTitle,
    allDealsLink,
    dealsListDescription,
  } = useShowcaseDeals();

  if (!showDealsList) return null;

  const dealsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg || 6,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md || 6,
    sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm || 6,
  };

  return (
    <SimpleCardList
      styles={styles(theme, site)}
      maxEntries={dealsPerSlide}
      skeleton={loading}
      entries={deals}
      renderCard={({element, skeleton, isFirst}) => (
        <DealCardComponent
          {...element}
          skeleton={skeleton}
          deal={element}
          isFirst={isFirst}
        />
      )}
      HeaderComponent={
        <DealsListHeader
          title={dealsListTitle}
          description={dealsListDescription}
          ViewAllComponent={<ViewAllLink to={allDealsLink} />}
        />
      }
    />
  );
}
