import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import {useProductDetailResource} from "src/state/hooks/useAsyncResource";
import store from "@menu/state/store";
import {getProductBySlug} from "@menu/state/selectors";

export default function usePrefetchedProduct(productId) {
  const {data} = useServerContext();

  const prefetchedProduct =
    productId && data[PrefetchedDataKeys.PRODUCT]?.getSlug?.() === productId
      ? data[PrefetchedDataKeys.PRODUCT]
      : getProductBySlug(store.getState(), productId);

  const {data: fetchedProduct, meta} = useProductDetailResource({
    productId: prefetchedProduct ? null : productId,
  });

  if (prefetchedProduct) {
    return {
      data: prefetchedProduct,
      meta: {
        error: null,
        loading: false,
      },
    };
  }

  return {data: fetchedProduct, meta: meta};
}
