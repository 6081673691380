import React from "react";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import styled from "styled-components";
import {renderNodeOrComponent, SimpleProductsList} from "@ui";
import useTheme from "@mock/hooks/useTheme.js";
import recommendedProductsListStyles from "@themes/default/components/RecommendedProductsList/styles";
import media from "@ui/utils/media";

export default function RecommendedProductsList({
  site,
  products,
  meta,
  title,
  linkToAll,
  ProductsListComponent,
}) {
  const {ProductCard} = useThemeComponents();
  const theme = useTheme();

  let productsList;
  if (!ProductsListComponent) {
    productsList = (
      <SimpleProductsList
        styles={recommendedProductsListStyles(theme, site)}
        maxProducts={{
          lg: 4,
          md: 4,
          sm: 4,
        }}
        products={products}
        skeleton={meta.loading}
        ProductCardComponent={ProductCard}
      />
    );
  } else {
    productsList = renderNodeOrComponent(ProductsListComponent, {
      styles: recommendedProductsListStyles(theme, site),
      products: products,
      skeleton: meta.loading,
      recommended: true,
      ProductCardComponent: ProductCard,
    });
  }

  return (
    <div>
      {(meta.loading || products.length) > 0 && (
        <div>
          <CustomListHeader
            title={title}
            linkToAll={linkToAll}
            styles={recommendedProductsListStyles(theme, site)}
          />
        </div>
      )}
      {productsList}
    </div>
  );
}

function ListHeader({title, linkToAll, styles}) {
  const {ViewAllLink, Title} = useThemeComponents();

  return (
    <>
      <HeaderContainer>
        <TitleContainer styles={styles.header}>
          <Title styles={styles.header}>{title}</Title>
        </TitleContainer>

        {linkToAll && (
          <ViewAllLink
            to={linkToAll}
            defaultUnderlined={false}
            color={styles.header.viewAllColor}
          />
        )}
      </HeaderContainer>
    </>
  );
}

let CustomListHeader = styled(ListHeader)``;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
`;

let TitleContainer = styled.div`
  background-color: ${({styles}) => styles.backgroundColor};
  transform: skewX(${({styles}) => `${Number(styles.skewness) * -1}deg`});

  > h2,
  > h2 > span {
    font-size: ${({theme}) => theme.v2.typography.title5.size.lg};
    ${media.down("md")} {
      font-size: ${({theme}) => theme.v2.typography.title5.size.md};
    }
    ${media.down("sm")} {
      font-size: ${({theme}) => theme.v2.typography.title5.size.sm};
    }
  }
`;
