function getCluster() {
  return require("../../devCache/cluster.json");
}

const getSiteInCluster = domain => {
  const cluster = getCluster();
  if (!domain) return cluster[0];
  const domainWithoutPort = domain.split(":")[0];
  const entry = cluster.find(entry => entry.domains?.indexOf(domainWithoutPort) > -1);
  return entry ? entry : cluster[0];
};

module.exports = {
  getCluster,
  getSiteInCluster,
};
