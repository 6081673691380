import autoBind from "auto-bind";
import get from "lodash/get";
import {makeOrderStatus} from "./orderStatus";
import Address from "./address";
import {makeDeliveryType} from "../../common/models/DeliveryType";
import DeliverySpecification from "../../common/models/deliverySpecification";
import dayjs from "dayjs";
import {DeliveryTypes} from "./DeliveryType";
import {populateRelations} from "../../api/utils";
import PaymentSpecification from "../../common/models/paymentSpecification";
import WARNING_ACTION_TYPES from "src/core/common/models/warningActionTypes";
import {PaymentOptions} from "src/core/payments/constants";

class SimpleOrder {
  cachedReviews = null;

  constructor(orderCardObject, included = []) {
    this.orderCardObject = populateRelations(orderCardObject, included);
    this.included = included;
    autoBind(this);
  }

  getId() {
    return get(this.orderCardObject, "id");
  }

  getDeliveryType() {
    return makeDeliveryType(get(this.orderCardObject, "attributes.delivery_type"));
  }

  pickupAtShop() {
    return this.getDeliveryType().pickupAtShop();
  }

  getDeliveryAddress() {
    return new Address(get(this.orderCardObject, "attributes.delivery_address"));
  }

  getStoreName() {
    return get(this.orderCardObject, "relationships.store.data.attributes.name");
  }

  getTotal() {
    return get(this.orderCardObject, "attributes.total");
  }

  getItemsCount() {
    return get(this.orderCardObject, "relationships.items.data").length;
  }

  getDeliverySpecification() {
    return new DeliverySpecification(
      get(this.orderCardObject, "relationships.delivery_specification.data")
    );
  }

  getStatus() {
    const deliveryType = this.getDeliveryType().code;
    if (deliveryType === DeliveryTypes.DELIVERY) {
      const status = this.getDeliverySpecification().getStatus();
      return (
        status ||
        makeOrderStatus(get(this.orderCardObject, "attributes.status"), deliveryType)
      );
    } else {
      return makeOrderStatus(
        get(this.orderCardObject, "attributes.status"),
        deliveryType
      );
    }
  }

  getOrderNumber() {
    return get(this.orderCardObject, "attributes.order_number");
  }

  getPOSOrderNumber() {
    return get(this.orderCardObject, "attributes.pos_order_number");
  }

  getCreatedDate() {
    return get(this.orderCardObject, "attributes.created");
  }

  getDeliveryDate() {
    const mode = this.getDeliverySpecification().getMode();
    if (mode.code === "scheduled") {
      return mode.deliverySlot.timeRange.start;
    } else {
      return this.getCreatedDate();
    }
  }

  getScheduleTimeRangeString() {
    const mode = this.getDeliverySpecification().getMode();
    if (mode.code === "scheduled") {
      const startTime = dayjs(mode.deliverySlot.timeRange.start).format("h:mm a");
      const endTime = dayjs(mode.deliverySlot.timeRange.end).format("h:mm a");
      return `${startTime} - ${endTime}`;
    } else {
      return null;
    }
  }

  isCanceled() {
    return this.getStatus().isCanceled() || this.isPaymentCanceled();
  }

  isReady() {
    return this.getStatus().isReady();
  }

  isCompleted() {
    return !this.isPendingPayment() && this.getStatus().isCompleted();
  }

  isProcessing() {
    return this.getStatus().isProcessing();
  }

  getReviews() {
    return this.orderCardObject.relationships.reviews.data;
  }

  hasReviews() {
    return this.getReviews().length > 0;
  }

  getFirstReview() {
    return this.getReviews()[0].attributes;
  }

  getPaymentSpecification() {
    const spec = get(this.orderCardObject, "relationships.payment_specification.data");
    if (spec) {
      return new PaymentSpecification({
        data: spec,
        included: this.included,
      });
    } else {
      return null;
    }
  }

  hasLastCharge() {
    return this.getPaymentSpecification()?.getLastCharge();
  }

  hasTip() {
    const spec = this.getPaymentSpecification();
    if (!spec) return false;
    return spec.hasTip();
  }

  getTip() {
    if (!this.hasTip()) return null;

    return this.getPaymentSpecification().getLastCharge().getTip();
  }

  getConvenienceFee() {
    const paymentSource = this.getPaymentSpecification()?.getPaymentSource();
    const lastCharge = this.getPaymentSpecification()?.getLastCharge();

    if (!paymentSource || !lastCharge || !paymentSource.displayConvenienceFee())
      return null;

    const fee = lastCharge.getConvenienceFee();
    return fee?.amount > 0 ? fee : null;
  }

  getSavings() {
    const lastCharge = this.getPaymentSpecification()?.getLastCharge();

    if (!lastCharge) return null;

    const credit = lastCharge.getCredit();
    return credit?.amount > 0 ? credit : null;
  }

  getTipValue() {
    return this.getTip() ? get(this.getTip(), "attributes.amount") : null;
  }

  isPendingRefund() {
    const pSpec = this.getPaymentSpecification();
    if (!pSpec) return undefined;
    return pSpec.isPendingRefund();
  }

  isRefunded() {
    const pSpec = this.getPaymentSpecification();
    if (!pSpec) return undefined;
    return pSpec.isRefunded();
  }

  isProcessingPayment() {
    const pSpec = this.getPaymentSpecification();
    if (!pSpec) return undefined;
    return pSpec.isProcessing();
  }

  isPaymentCanceled() {
    const pSpec = this.getPaymentSpecification();
    if (!pSpec) return undefined;
    return pSpec.isCanceled();
  }

  isPaid() {
    const pSpec = this.getPaymentSpecification();
    if (!pSpec) return undefined;
    return pSpec.isPaid();
  }

  isPendingPayment() {
    return (
      !this.isProcessingPayment() &&
      !this.isRefunded() &&
      !this.isPendingRefund() &&
      this.isPaid() === false &&
      !this.isCanceled()
    );
  }

  getPaymentOption() {
    const pSpec = this.getPaymentSpecification();
    if (!pSpec) return undefined;
    return pSpec.getPaymentOption();
  }

  isOnlinePayment() {
    const payment = this.getPaymentOption();
    if (!payment) return false;
    const onlinePaymentSources = Object.values(PaymentOptions);
    return onlinePaymentSources.includes(payment);
  }

  getDisplayStatus() {
    if (this.isPendingPayment()) {
      return "Waiting for payment";
    }

    if (this.isProcessingPayment()) {
      return "Processing payment";
    }

    if (this.isPaymentCanceled()) {
      return "Payment was canceled";
    }

    if (this.isPendingRefund()) {
      return "Refund Pending";
    }

    if (this.isRefunded()) {
      return "Refunded";
    }

    return this.getStatus().toString();
  }

  getStatusMessage(errors) {
    if (this.isPendingPayment()) {
      if (!errors || errors.length === 0)
        return makeStatusMessage(
          "Waiting for payment",
          this.hasLastCharge() ? "Your payment failed, please try again." : "",
          this.hasLastCharge() ? "error" : "warning"
        );

      return makeStatusMessage(errors, "Your payment failed, please try again.");
    }

    if (this.isProcessingPayment()) {
      return makeStatusMessage(
        "We're processing your payment",
        "Your payment has been approved by your bank and will be charged when your order is fulfilled.",
        "success"
      );
    }

    if (this.isPaymentCanceled()) {
      return makeStatusMessage("Payment was canceled");
    }

    if (this.isPendingRefund()) {
      return makeStatusMessage("Your order is pending a refund");
    }

    if (this.isRefunded()) {
      return makeStatusMessage("Your order was refunded");
    }

    if (this.isCanceled()) {
      return makeStatusMessage(
        "Order is canceled",
        `Order number ${this.getOrderNumber()} has been canceled.`
      );
    }

    return null;
  }

  getTipMessage(paymentOptions) {
    if (this.hasTipStatusMessage(paymentOptions)) {
      return makeStatusMessage(
        "Show support with a tip",
        "We work hard to ensure that every experience is nothing less than excellent.",
        "success"
      );
    }
    return null;
  }

  hasTipStatusMessage(paymentOptions) {
    let paymentOption;
    if (Array.isArray(paymentOptions)) {
      paymentOption = paymentOptions.find(
        opt => opt.getType() === this.getPaymentOption()
      );
    }
    return (
      !this.isCanceled() &&
      paymentOption?.supportsTippingAfterCheckout() &&
      !this.hasTip()
    );
  }

  hasWarningAction(statusMessage) {
    return (
      !this.isPaid() &&
      statusMessage?.status === "error" &&
      statusMessage?.title !== "Payment was canceled" &&
      this.isOnlinePayment()
    );
  }

  getTipAction(paymentOptions) {
    return (
      this.hasTipStatusMessage(paymentOptions) && {
        type: WARNING_ACTION_TYPES.tip,
        label: "Give tip",
      }
    );
  }

  getWarningAction() {
    return {
      type: WARNING_ACTION_TYPES.payment,
      label: this.getPaymentSpecification()?.getPaymentInfo()?.getRetryLabel(),
    };
  }
}

const makeStatusMessage = (title, description, type = "error") => {
  return {
    title,
    description,
    status: type,
  };
};

export default SimpleOrder;
