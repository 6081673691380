import React from "react";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import PropTypes from "prop-types";
import {callFunctionWithDefault} from "@ui/utils/callFunctionWithDefault";
import {WheelIcon} from "@ui/components/IconsSVGs";

const FLOWER_TYPE_ABBR = {
  "Indica Dominant": "ID",
  Indica: "I",
  "Sativa Dominant": "SD",
  Sativa: "S",
  Hybrid: "H",
  CBD: "CBD",
};

export default function WheelFlowerTypeIndicator({
  flowerType,
  flowerTypeIndicatorProps,
  styles,
  small,
  showIcon,
  hideNameOnSmallerScreens,
}) {
  const name = flowerType.name;
  const abbreviation = FLOWER_TYPE_ABBR[flowerType.name];
  const FlowerIcon = flowerType.icon;

  const {getColor, getBackground, displayAbbreviation} = flowerTypeIndicatorProps;

  const _styles = merge({}, defaultStyles, styles, {
    backgroundColor: callFunctionWithDefault(
      getBackground,
      [name],
      styles.backgroundColor || defaultStyles.backgroundColor
    ),
    color: callFunctionWithDefault(getColor, [name], styles.color || defaultStyles.color),
  });

  return (
    <Container small={small} displayAbbreviation={displayAbbreviation} styles={_styles}>
      {showIcon && FlowerIcon && <WheelIcon abbreviation={abbreviation} />}
      <FlowerTypeName
        hideNameOnSmallerScreens={hideNameOnSmallerScreens}
        styles={_styles}
      >
        {displayAbbreviation ? abbreviation : name}
      </FlowerTypeName>
    </Container>
  );
}

const defaultStyles = {
  fontWeight: "600",
  padding: "2px 4px",
  margin: "0",
  border: "0",
  borderRadius: "12px",
  fontSize: {
    lg: "8px",
    md: "8px",
    sm: "8px",
  },
  color: "#333",
  fontFamily: "sans-serif",
  iconColor: "#000",
  backgroundColor: "#fff",
  textTransform: "uppercase",
  letterSpacing: "0",
};

const FlowerTypeName = styled.span.attrs(() => ({
  className: "simple-flower-type-indicator__name",
  "data-keep-cart": "true",
}))`
  letter-spacing: ${({styles}) => styles.letterSpacing};
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.down("sm")} {
    display: ${({hideNameOnSmallerScreens}) =>
      hideNameOnSmallerScreens ? "none" : "inherit"};
  }
`;

const Container = styled.div.attrs(() => ({
  className: "simple-flower-type-indicator__container",
  "data-keep-cart": "true",
}))`
  display: ${({displayAbbreviation}) => (displayAbbreviation ? "flex" : "inline-flex")};
  flex-direction: ${({displayAbbreviation}) => displayAbbreviation && "column"};
  align-items: center;
  justify-content: center;
  background-color: ${({styles}) => styles.backgroundColor};
  text-transform: ${({styles}) => styles.textTransform};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  padding: ${({styles}) => styles.padding};
  margin: ${({styles}) =>
    typeof styles.margin === "string" ? styles.margin : styles.margin?.sm};
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  color: ${({styles}) => styles.color};
  height: ${({styles}) => styles?.height};
  width: ${({styles}) => styles?.width};
  box-sizing: ${({displayAbbreviation}) => displayAbbreviation && "border-box"};
  line-height: ${({displayAbbreviation}) => !displayAbbreviation && "15px"};
  letter-spacing: ${({styles}) => styles.letterSpacing};
  white-space: nowrap;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  > div {
    margin: ${({displayAbbreviation, styles}) =>
      displayAbbreviation &&
      (typeof styles.margin === "string" ? styles.margin : styles.margin?.sm)};
  }

  max-width: ${({small}) => (small ? "100px" : "100%")};
`;

WheelFlowerTypeIndicator.defaultProps = {
  styles: {},
};

WheelFlowerTypeIndicator.propTypes = {
  styles: PropTypes.shape({
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    padding: PropTypes.string,
    margin: PropTypes.string,
    border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderRadius: PropTypes.string,
    fontSize: {
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    },
    color: PropTypes.string,
    fontFamily: PropTypes.string,
    iconColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    textTransform: PropTypes.string,
    letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  flowerTypeIndicatorProps: PropTypes.object,
  flowerType: PropTypes.shape({
    icon: PropTypes.elementType,
    color: PropTypes.string,
    name: PropTypes.string,
  }),
};
