import {makeAction} from "src/core/common/utils";
import ActionTypes from "./actionTypes";

export const requestAddressAutoComplete = payload =>
  makeAction(ActionTypes.REQUEST_ADDRESS_AUTO_COMPLETE, payload);

export const requestAddressAutoCompleteSuccessful = payload =>
  makeAction(ActionTypes.REQUEST_ADDRESS_AUTO_COMPLETE_SUCCESSFUL, payload);

export const requestAddressAutoCompleteFailure = error =>
  makeAction(ActionTypes.REQUEST_ADDRESS_AUTO_COMPLETE_FAILURE, {error});

export const requestVerifyAddress = payload =>
  makeAction(ActionTypes.REQUEST_VERIFY_ADDRESS, payload);

export const requestVerifyAddressSuccessful = payload =>
  makeAction(ActionTypes.REQUEST_VERIFY_ADDRESS_SUCCESSFUL, payload);

export const requestVerifyAddressFailure = error =>
  makeAction(ActionTypes.REQUEST_VERIFY_ADDRESS_FAILURE, {error});

export const selectDeliveryType = (
  deliveryType,
  options = {save: true, isDefault: false}
) => makeAction(ActionTypes.SELECT_DELIVERY_TYPE, {deliveryType, options});

export const selectDeliveryTypeSuccessful = (deliveryType, options) =>
  makeAction(ActionTypes.SELECT_DELIVERY_TYPE_SUCCESSFUL, {deliveryType, options});

export const selectDeliveryAddress = deliveryAddress =>
  makeAction(ActionTypes.SELECT_DELIVERY_ADDRESS, {deliveryAddress});

export const selectDeliveryAddressSuccessful = deliveryAddress =>
  makeAction(ActionTypes.SELECT_DELIVERY_ADDRESS_SUCCESSFUL, {deliveryAddress});

export const setDeliveryMode = payload =>
  makeAction(ActionTypes.SET_DELIVERY_MODE, payload);
