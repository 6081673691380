import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";
import merge from "lodash/merge";
import widthLimited from "@ui/components/Decorators/widthLimited";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";
import SelectionBarButton from "@ui/components/SelectionBar/SelectionBarButton";

function SelectionBar({
  styles,
  entries,
  SelectionBarButtonComponent,
  getSelectionBarButtonProps,
}) {
  const _styles = merge({}, defaultStyles, styles);
  const {padding, ...listStyles} = _styles.root;
  return (
    <Container styles={_styles.root}>
      <List styles={listStyles}>
        {entries.map((entry, index) => (
          <li key={index}>
            {renderNodeOrComponent(SelectionBarButtonComponent, {
              ...getSelectionBarButtonProps(entry),
              styles: _styles.button,
            })}
          </li>
        ))}
      </List>
    </Container>
  );
}

const defaultStyles = {
  root: {
    scroll: {
      lg: false,
      md: false,
      sm: true,
    },
    backgroundColor: "pink",
    minHeight: {
      lg: "70px",
      md: "70px",
      sm: "70px",
    },
    columnGap: {
      lg: spacing(2),
      md: spacing(2),
      sm: spacing(2),
    },
    padding: {
      lg: `${spacing(2)} 0`,
      md: `${spacing(2)} 0`,
      sm: `${spacing(2)} 0`,
    },
  },
  button: {},
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({styles}) => styles.backgroundColor};
  width: 100%;

  min-height: ${({styles}) => styles.minHeight.lg};

  padding: ${({styles}) => styles.padding.lg};

  ${media.down("md")} {
    min-height: ${({styles}) => styles.minHeight.md};
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    min-height: ${({styles}) => styles.minHeight.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;
const List = widthLimited(styled.ul`
  margin: 0;
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-top: 2px !important;
  padding-bottom: 2px !important;

  overflow-y: hidden;
  overflow-x: ${({styles}) => (styles.scroll.lg ? "scroll" : "hidden")};
  flex-flow: ${({styles}) => (styles.scroll.lg ? "nowrap" : "wrap")};
  column-gap: ${({styles}) => styles.columnGap.lg};
  row-gap: ${({styles}) => styles.columnGap.lg};

  ${media.down("md")} {
    column-gap: ${({styles}) => styles.columnGap.md};
    row-gap: ${({styles}) => styles.columnGap.md};
    overflow-x: ${({styles}) => (styles.scroll.md ? "scroll" : "hidden")};
    flex-flow: ${({styles}) => (styles.scroll.md ? "nowrap" : "wrap")};
  }

  ${media.down("sm")} {
    column-gap: ${({styles}) => styles.columnGap.sm};
    row-gap: ${({styles}) => styles.columnGap.sm};
    overflow-x: ${({styles}) => (styles.scroll.sm ? "scroll" : "hidden")};
    flex-flow: ${({styles}) => (styles.scroll.sm ? "nowrap" : "wrap")};
    padding: 0 4px;
  }
`);

SelectionBar.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      scroll: PropTypes.shape({
        lg: PropTypes.bool,
        md: PropTypes.bool,
        sm: PropTypes.bool,
      }),
      backgroundColor: PropTypes.string,
      minHeight: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      columnGap: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    button: PropTypes.object,
  }),
  entries: PropTypes.arrayOf(PropTypes.object),
  SelectionBarButtonComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.node,
  ]),
  getSelectionBarButtonProps: PropTypes.func,
};

SelectionBar.defaultProps = {
  styles: defaultStyles,
  entries: [],
  SelectionBarButtonComponent: SelectionBarButton,
  getSelectionBarButtonProps: () => {},
};

export default SelectionBar;
