import React from "react";
import {BrandHeader} from "@ui";
import DetailProductsList from "@themes/default/components/DetailProductsList/index.js";
import useDetailProductsList from "@mock/hooks/useDetailProductsList";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

export default function BrandProductsList(props) {
  const {Title} = useThemeComponents();
  const listProps = useDetailProductsList();
  return (
    <DetailProductsList
      data-cy="brandProductsList"
      {...listProps}
      Header={<BrandHeader {...props} TitleComponent={Title} />}
    />
  );
}
