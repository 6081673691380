import get from "lodash/get";

class Reward {
  constructor(obj) {
    this.obj = obj;
  }

  get name() {
    return get(this.obj, "attributes.name");
  }

  get id() {
    return get(this.obj, "id");
  }

  get description() {
    return get(this.obj, "attributes.description");
  }

  get image() {
    return get(this.obj, "attributes.main_image_url");
  }

  get isFeatured() {
    return get(this.obj, "attributes.is_featured");
  }

  get pointsRequired() {
    return get(this.obj, "attributes.points_required");
  }

  get isStackable() {
    return get(this.obj, "attributes.is_stackable");
  }

  get withLoyaltyServiceMapping() {
    return get(this.obj, "attributes.loyalty_service_mapping");
  }

  get slug() {
    return this.obj?.attributes?.slug;
  }

  toString() {
    return (
      `${this.name} | ${this.pointsRequired} pts ` +
      (this.description ? `| ${this.description}` : "")
    );
  }

  equals(other) {
    if (!other || !(other instanceof Reward)) return false;

    return other.id === this.id;
  }
}

export class NullReward extends Reward {
  constructor(description) {
    super({});
    this._description = description;
  }

  get name() {
    return null;
  }

  get id() {
    return null;
  }

  get description() {
    return this._description;
  }

  get pointsRequired() {
    return null;
  }

  get isStackable() {
    return true;
  }

  toString() {
    return this._description;
  }

  equals(other) {
    return other instanceof NullReward;
  }
}

export default Reward;
