import IntegrationComponentRegistry from "src/integrations/IntegrationComponentRegistry";

export default function useAddons(type) {
  let addons;

  switch (type) {
    case "menu":
      addons = IntegrationComponentRegistry.getMenuAddons();
      break;
    case "base-page":
      addons = IntegrationComponentRegistry.getBasePageAddons();
      break;
    default:
      addons = null;
  }

  return {addons};
}
