import get from "lodash/get";

export class PromotionalBanners {
  constructor(promotionalBannersObj) {
    this.obj = promotionalBannersObj;
  }

  isDefined() {
    return Boolean(this.obj);
  }

  hasImages() {
    return get(this.obj, "images", []).length > 0;
  }

  getMobileWidth() {
    return get(this.obj, "config.attributes.mobile_banner_width");
  }

  getMobileHeight() {
    return get(this.obj, "config.attributes.mobile_banner_height");
  }

  getDesktopWidth() {
    return get(this.obj, "config.attributes.desktop_banner_width");
  }

  getDesktopHeight() {
    return get(this.obj, "config.attributes.desktop_banner_height");
  }

  getTimeInterval() {
    return get(this.obj, "config.attributes.time_per_banner_ms", 3000);
  }

  getBannerInfo() {
    const images = get(this.obj, "images", []).map(image => ({
      desktopUrl: get(image, "attributes.desktop_image_url"),
      mobileUrl: get(image, "attributes.mobile_image_url"),
      destinationUrl: get(image, "attributes.destination_url"),
      description: get(image, "attributes.description"),
    }));

    return {
      images,
      dimensions: {
        desktop: {
          width: this.getDesktopWidth(),
          height: this.getDesktopHeight(),
        },
        mobile: {
          width: 767,
          height: 346,
        },
      },
      timeInterval: this.getTimeInterval(),
    };
  }
}

function makePromotionalBanners(attrs) {
  return new PromotionalBanners(attrs);
}

export default makePromotionalBanners;
