import * as ShopsApi from "src/core/api/shops";
import get from "lodash/get";
import {findRelation} from "src/core/api/utils";

export const getTheme = async axiosInstance => {
  const obj = await ShopsApi.site(axiosInstance);

  return makeThemeObject(obj);
};

export const makeThemeObject = obj => {
  const themeConfigurationRelation = get(
    obj,
    "data.relationships.theme_configuration.data"
  );
  if (!themeConfigurationRelation) return null;

  const themeConfiguration = findRelation(obj.included, themeConfigurationRelation);
  const themeVariables = get(themeConfiguration, "attributes.variables");
  const themeRelation = get(themeConfiguration, "relationships.theme.data");
  if (!themeRelation) return null;

  return {
    baseTheme: findRelation(obj.included, themeRelation),
    themeVariables,
  };
};
