import {makeThemeProps} from "src/themes/utils/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getProductListProps(theme, site);
};

export const noResultsPlaceholderStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getNoResultsPlaceholderProps(theme);
};

export const subcategoriesStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getSubcategoriesProps(theme);
};
