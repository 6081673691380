import React, {useEffect, useState} from "react";
import EventBus from "src/core/common/eventBus";
import dynamic from "next/dynamic";
import {useTheme} from "styled-components";

const ConfirmationModal = dynamic(() => import("@ui/components/ConfirmationModal"));

const initialDialogState = {
  open: false,
  title: "",
  subtitle: "",
  cancelText: "Cancel",
  helperText: "",
  onConfirm: () => {},
  confirmText: "Ok",
};
export const dialogEventBus = new EventBus();
function Dialog() {
  const [state, setState] = useState(initialDialogState);
  const theme = useTheme();
  const onCancel = () => {
    setState(initialDialogState);
  };

  const onConfirm = () => {
    state.onConfirm && state.onConfirm();
    setState(initialDialogState);
  };

  useEffect(() => {
    dialogEventBus.subscribe(params => {
      setState({
        open: true,
        ...params,
      });
    });
  }, []);

  return (
    <ConfirmationModal
      isOpen={state.open}
      onRequestClose={onCancel}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={state.title}
      subtitle={state.subtitle}
      helperText={state.helperText}
      cancelText={state.cancelText}
      confirmText={state.confirmText}
      styles={styles(theme)}
    />
  );
}

const styles = theme => ({
  title: {
    textAlign: "center",
    fontFamily: theme.v2.typography.title2.family,
    fontSize: theme.v2.typography.title2.size,
    fontWeight: theme.v2.typography.title2.weight,
    letterSpacing: theme.v2.typography.title2.letterSpacing,
  },
  subtitle: {
    textAlign: "center",
    fontFamily: theme.v2.typography.title6.family,
    fontSize: theme.v2.typography.title6.size,
    fontWeight: theme.v2.typography.title6.weight,
    letterSpacing: theme.v2.typography.title6.letterSpacing,
  },
  helperText: {
    textAlign: "center",
    fontFamily: theme.v2.typography.bodyText2.family,
    fontSize: theme.v2.typography.bodyText2.size,
    fontWeight: theme.v2.typography.bodyText2.weight,
    letterSpacing: theme.v2.typography.bodyText2.letterSpacing,
    lineHeight: theme.v2.typography.bodyText2.lineHeight,
  },
});

export default Dialog;
