import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import media from "@ui/utils/media";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";
import PropTypes from "prop-types";
import spacing from "@ui/utils/spacing";
import merge from "lodash/merge";

function BrandHeader({
  brand,
  showSortFilters,
  SortFiltersComponent,
  RatingComponent,
  TitleComponent,
  styles,
}) {
  function createMarkup(description) {
    return {__html: description};
  }

  const _styles = merge({}, defaultStyles, styles);

  if (!brand) {
    return (
      <HeaderContainer>
        <CentralHeader logo={false} description={false}>
          <Skeleton height={32} width={250} />
        </CentralHeader>
      </HeaderContainer>
    );
  } else {
    const logo = brand.getLogo();
    const description = brand.getDescription();
    const name = brand.getName();
    const BrandNameComponent = TitleComponent || BrandName;
    return (
      <HeaderContainer>
        {logo && (
          <BrandContainer>
            {logo && <BrandLogo src={logo} alt={"brand logo"} />}
          </BrandContainer>
        )}
        <CentralHeader logo={logo} description={description}>
          <BrandNameComponent
            logo={logo}
            description={description}
            styles={{..._styles, textAlign: "left"}}
          >
            {name}
          </BrandNameComponent>
          <RateReview style={{display: "none"}}>
            {RatingComponent && renderNodeOrComponent(RatingComponent)}
          </RateReview>

          <Description dangerouslySetInnerHTML={createMarkup(description)} />
        </CentralHeader>

        <FiltersContainer>
          {showSortFilters &&
            SortFiltersComponent &&
            renderNodeOrComponent(SortFiltersComponent)}
        </FiltersContainer>
      </HeaderContainer>
    );
  }
}

const defaultStyles = {
  brandTitle: {
    fontSize: {
      lg: "32px",
      sm: "24px",
    },
    fontWeight: "700",
  },
};

const FiltersContainer = styled.div`
  display: flex;
  justify-content: "flex-start";
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 ${spacing(4)};

  ${media.down("md")} {
    padding: 0 ${spacing(2)} ${spacing(2)} ${spacing(2)};
  }

  @media (max-width: 768px) {
    padding: ${({logo}) => (logo ? spacing(2) : 0)} ${spacing(2)};
    flex-direction: column;
    align-items: center;
  }
`;

const BrandContainer = styled.div`
  min-width: 200px;
  max-width: 200px;
  height: 200px;
`;

const BrandLogo = styled.img`
  width: 200px;
  height: 100%;
  object-fit: contain;
`;

const CentralHeader = styled.div`
  flex-grow: 1;
  padding-left: ${({logo}) => (logo ? spacing(4) : 0)};
  display: flex;
  flex-direction: column;
  justify-content: ${({description}) => (description ? "space-between" : "center")};

  @media (max-width: 768px) {
    padding: ${({logo}) => (logo ? spacing(4) : 0)} 0;
    padding-bottom: ${({description}) => (description ? spacing(4) : 0)};
    flex-direction: column;
    width: 100%;
  }
`;

const BrandName = styled.h1`
  font-size: ${({styles}) => styles.brandTitle.fontSize.lg};
  font-weight: ${({styles}) => styles.brandTitle.fontWeight};
  text-transform: capitalize;
  margin: ${({logo, description}) => (!logo && !description ? "0" : "")};

  @media (max-width: 768px) {
    padding-bottom: ${spacing(2)};
    font-size: ${({styles}) => styles.brandTitle.fontSize.sm};
  }
`;

const RateReview = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${spacing(1)};

  @media (max-width: 768px) {
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: ${spacing(2)};
  }
`;

const Description = styled.div`
  text-align: justify;
`;

BrandHeader.propTypes = {
  styles: PropTypes.shape({
    brandTitle: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  brand: PropTypes.object,
  showSortFilters: PropTypes.bool,
  SortFiltersComponent: PropTypes.elementType,
  RatingComponent: PropTypes.elementType,
  TitleComponent: PropTypes.elementType,
};

BrandHeader.defaultProps = {
  styles: defaultStyles,
  brand: {},
  showSortFilters: false,
  SortFiltersComponent: () => {},
  RatingComponent: () => {},
};

export default BrandHeader;
