import {formatPrice} from "src/core/common/price";
import useCartState from "src/core/checkout/hooks/useCartState";
import useAddItem from "src/core/checkout/hooks/useAddItem";
import useUpdateItem from "src/core/checkout/hooks/useUpdateItem";
import useSite from "src/core/sites/hooks/useSite";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

function useProductCardActions({product, setLoading}) {
  const _setLoading = () => setLoading(product);

  const [cartState] = useCartState();
  const cart = cartState.data;
  const isInCart = cart && cart.isInCart(product);
  const item = cart && cart.getItem(product);
  const quantity = item ? item.getQuantity() : 0;
  const hasWeightSizes = product ? product.hasWeightPrices() : null;
  const site = useSite();
  const toggles = useFeatureToggles();

  const {addItem} = useAddItem({product, quantity: 1});
  const updateItemProps = useUpdateItem({item, setLoading: _setLoading});

  let price = "";
  let discountedPrice = "";
  if (product) {
    if (product.hasWeightPrices()) {
      const priceRange = product.getPriceRange();
      price = `${formatPrice(priceRange.min)} - ${formatPrice(priceRange.max)}`;
    } else if (product.hasUnitPrices()) {
      price = formatPrice(product.getUnitPrice(quantity));
    } else {
      price = formatPrice(product.getUnitPrice());
    }
    if (!product.hasUnitPrices && !product.hasWeightPrices) {
      discountedPrice = formatPrice(product.getDiscountPrice());
    } else {
      discountedPrice = formatPrice(product.getDiscountPrice(quantity));
    }
  }

  if (discountedPrice === "-") {
    discountedPrice = "";
  }

  return {
    price,
    discountedPrice,
    hasWeightSizes,
    showQuantityPicker: isInCart,
    allowQuantityChange: !toggles.allowOnlySingleUnitCartItems() && !hasWeightSizes,
    cartIcon: site.getUiConfiguration().productCard.cartIcon,
    onAdd: addItem,
    onRemove: updateItemProps.removeItem,
    quantity: updateItemProps.data.quantity || 1,
    onChangeQuantity: updateItemProps.setQuantity,
  };
}

export default useProductCardActions;
