import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import {AppStoreIcon, PlayStoreIcon} from "@ui/components/IconsSVGs";
import media from "@ui/utils/media";
import dynamic from "next/dynamic";

const QRCodeSVG = dynamic(() => import("qrcode.react").then(mod => mod.QRCodeSVG));

export default function MobileAppPromotion({
  styles,
  url,
  showAppStorePromotionSection,
  showPlayStorePromotionSection,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container styles={_styles.root}>
      <LeftContainer>
        <Title styles={_styles.title}>Download our App!</Title>
        <Description styles={_styles.description}>
          Access exclusive content and get order updates right to your phone!
        </Description>
        <IconContainer>
          {showAppStorePromotionSection && <AppStoreIcon height="28px" width="80px" />}
          {showPlayStorePromotionSection && <PlayStoreIcon height="28px" width="80px" />}
        </IconContainer>
      </LeftContainer>
      <RightContainer>
        <QRCodeSVG value={url} size={110} />
      </RightContainer>
    </Container>
  );
}

const defaultStyles = {
  root: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "14px 14px 18px",
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: "24px",
    fontWeight: "700",
    color: "#333333",
  },
  description: {
    fontFamily: "sans-serif",
    fontSize: "10px",
    fontWeight: "400",
    color: "#000",
  },
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  column-gap: 24px;
  padding: 16px;

  ${media.down("sm")} {
    column-gap: 16px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 16px;
`;

const Title = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Description = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

MobileAppPromotion.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      border: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      padding: PropTypes.string,
    }),
    title: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    description: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  url: PropTypes.string,
  showAppStorePromotionSection: PropTypes.bool,
  showPlayStorePromotionSection: PropTypes.bool,
};
