import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import Shop from "src/core/common/models/shop";

class ShopFullSettings {
  constructor(obj) {
    this._obj = obj;
  }
  getShopById(id) {
    return this.group.find(s => s.getId() === id);
  }
  get group() {
    if (!this._group) {
      this._group = this.filterByType("stores").map(s => {
        const settings = this.getShopSettingsById(s.id);
        return new Shop(
          {
            data: s,
            included: this._obj.included,
          },
          {
            data: settings,
          }
        );
      });
    }
    return this._group;
  }

  get groupSettings() {
    if (!this._groupSettings) {
      this._groupSettings = this.filterByType("group_settings")[0];
    }
    return this._groupSettings;
  }

  getShopSettingsById(id) {
    return this.filterByType("store_settings").find(s => s.id === id);
  }

  filterByType = (type, idAttr = "id") => {
    const filtered = this._obj.included.filter(entry => entry.type === type);

    return uniqBy(filtered, function (entry) {
      return get(entry, idAttr);
    });
  };
}

export default ShopFullSettings;
