import TymberAPIConfig from "./config";
import {makeJsonAPIPayload, v1, v4, v5} from "./utils";

const Endpoints = {
  v1: {
    detail: uuid => v1(`orders/${uuid}/`),
    list: () => v1(`orders/`),
    reviews: () => v1(`reviews/`),
  },
  v5: {
    create: () => v5("orders/"),
  },
  v4: {
    create: () => v4("orders/"),
  },
};

export function create(cart, orderInfo) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("orders", {
    cart_uuid: cart.getId(),
    ...orderInfo,
  });
  const version = getAPIVersion(TymberAPIConfig.ordersEndpointV5);
  return axios.post(version.create(), jsonApiPayload).then(response => response.data);
}

export function get(uuid) {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.detail(uuid)).then(response => response.data);
}

export function list(params) {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.list(), {params}).then(response => response.data);
}

export function review(payload) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload(
    "reviews",
    {
      rating: payload.rating,
      comment: payload.comment,
    },
    {
      order: {
        data: {
          id: payload.orderId,
          type: "orders",
        },
      },
    }
  );
  return axios
    .post(Endpoints.v1.reviews(), jsonApiPayload)
    .then(response => response.data);
}

const getAPIVersion = useV5 => {
  if (useV5) {
    return Endpoints.v5;
  } else {
    return Endpoints.v4;
  }
};
