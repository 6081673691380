import {useEffect} from "react";
import useRouter from "src/core/common/hooks/useRouter";

function useCanonicalUrl(url) {
  const router = useRouter();

  useEffect(() => {
    document.querySelectorAll("link[rel=canonical]").forEach(element => element.remove());
    const element = document.createElement("link");
    element.rel = "canonical";
    element.href = url;

    document.head.appendChild(element);
  }, [url, router.asPath]);
}

export default useCanonicalUrl;
