import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import merge from "lodash/merge";
import {MapPin2} from "@styled-icons/remix-line/MapPin2";

export default function DeliveryBannerButton({
  DeliveryBannerTextComponent,
  onClick,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Button
      styles={_styles.button}
      variant="secondary"
      size="large"
      LeftIconComponent={DeliveryBannerTextComponent ? null : MapPin2}
      onClick={onClick}
      label={DeliveryBannerTextComponent || "Set your delivery address"}
    />
  );
}

const defaultStyles = {
  button: {
    root: {},
    secondary: {},
    large: {},
  },
};

DeliveryBannerButton.defaultProps = {
  styles: defaultStyles,
  disabled: false,
};

DeliveryBannerButton.propTypes = {
  DeliveryBannerTextComponent: PropTypes.elementType,
  onClick: PropTypes.func,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontSize: PropTypes.string,
      fontFamily: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      textTransform: PropTypes.string,
      width: PropTypes.string,
      hoverBorderColor: PropTypes.string,
    }),
    secondary: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
    }),
    large: PropTypes.shape({
      padding: PropTypes.string,
      height: PropTypes.string,
    }),
  }),
};
