import React from "react";
import Head from "next/head";
import useSite from "src/core/sites/hooks/useSite";
import once from "lodash/once";
import {isClient} from "src/server/utils/isClient";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import PwaJSON from "public/pwa.json";
import Metadata from "src/core/seo/components/Metadata";

export function PageHead({title, description, image, schemas, titleTemplate, priority}) {
  return (
    <React.Fragment>
      {isClient && (
        <Metadata
          priority={priority}
          title={title}
          description={description}
          image={image}
          titleTemplate={titleTemplate}
        />
      )}
      {schemas}
    </React.Fragment>
  );
}

export function SiteHead() {
  const site = useSite();

  const html = loadCustomHeadHtml(site.getHtmlHead());
  const pwaHtml = loadCustomPWAHtml(site.getGroupName());

  return (
    <React.Fragment>
      <Head>
        {html}
        {pwaHtml}
      </Head>
      {isClient && (
        <Metadata
          priority={0}
          title={site.getTitle()}
          description={site.getDescription()}
          image={site.getBannerUrl() || site.getLogoUrl()}
        />
      )}
    </React.Fragment>
  );
}

const Html = dynamic(() => import("./Html").catch(logModuleLoadError("Html")));
const loadCustomHeadHtml = once(html => {
  if (!isClient) {
    return <Html html={html} transform={transform} />;
  }

  const head = document.createElement("head");
  head.innerHTML = html;
  let node = head.firstChild;
  while (node) {
    const next = node.nextSibling;
    if (node.tagName === "SCRIPT") {
      const newNode = document.createElement("script");
      if (node.src) {
        newNode.src = node.src;
      }
      while (node.firstChild) {
        newNode.appendChild(node.firstChild.cloneNode(true));
        node.removeChild(node.firstChild);
      }
      node = newNode;
    }

    if (node.tagName === "LINK") {
      document.body.appendChild(node);
    } else {
      document.head.appendChild(node);
    }

    node = next;
  }
  return <></>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
});

function transform(node) {
  if (node.type === "tag" && node.name === "link") {
    return null;
  }
}

const loadCustomPWAHtml = once(groupName => {
  if (isClient) {
    PwaJSON.forEach((element, index) => {
      const pwaElement = getElementFromString(element);
      const id = `pwa-${index}`;

      const repeatedElement = document.getElementById(id);
      if (repeatedElement) {
        repeatedElement.remove();
      }

      if (groupName && process.env.NODE_ENV !== "development") {
        try {
          const url = new URL(pwaElement.href);
          url.pathname = `/pwa-${groupName}${url.pathname}`;
          pwaElement.href = url.toString();
        } catch (e) {
          return;
        }
      }

      pwaElement.setAttribute("id", id);
      document.head.appendChild(pwaElement);
    });
  }

  return <></>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
});

function getElementFromString(htmlString) {
  const div = document.createElement("div");
  div.innerHTML = htmlString.trim();

  return div.firstChild;
}
