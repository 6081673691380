import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LazyRender from "../../Decorators/LazyRender";

export default function Wrapper({children, wrapperRef, isInline, isOpen}) {
  if (!isInline) {
    return (
      <>
        <Overlay isOpen={isOpen} />
        <DrawerFiltersContainer isOpen={isOpen} ref={wrapperRef}>
          <LazyRender>{children}</LazyRender>
        </DrawerFiltersContainer>
      </>
    );
  }

  return <InlineFiltersContainer>{children}</InlineFiltersContainer>;
}

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translate3d(${({isOpen}) => (isOpen ? "0" : "100%")}, 0, 0);
  opacity: ${({isOpen}) => (isOpen ? "0.6" : "0")};
  transition: opacity 300ms ease-out;
`;

const DrawerFiltersContainer = styled.div`
  left: 0;
  top: 0;
  width: 300px;
  padding: ${({theme}) => theme.v2.spacing(4)};
  margin: 0;
  position: fixed;
  transform: translate3d(${({isOpen}) => (isOpen ? "0" : "-100%")}, 0, 0);
  transition: transform 300ms ease-out;
  z-index: 10;
  background-color: white;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.3);

  .filter--title {
    font-weight: ${({theme}) => theme.v2.typography.titles.weight.heavy};
  }
`;

const InlineFiltersContainer = styled.div.attrs(() => ({
  className: "inline-filters__container",
}))`
  margin: 0 ${({theme}) => theme.v2.spacing(8)} 0 0;
  min-width: 190px;
  width: 180px;
`;

Wrapper.defaultProps = {};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  wrapperRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isInline: PropTypes.bool,
  isOpen: PropTypes.bool,
};
