import get from "lodash/get";
import {PaymentOptions} from "src/core/payments/constants";

class PaymentOption {
  constructor(paymentOptionObj) {
    this.obj = paymentOptionObj;
  }
  getId() {
    return get(this.obj, "id", undefined);
  }

  getType() {
    return get(this.obj, "attributes.payment_option", undefined);
  }

  getName() {
    return get(this.obj, "attributes.name", undefined);
  }

  getBanners() {
    return get(this.obj, "attributes.promotional_banner", undefined);
  }

  isOnline() {
    const onlinePaymentList = Object.values(PaymentOptions);
    return onlinePaymentList.includes(this.getType());
  }

  supportsGuestCheckout() {
    const noGuestCheckoutList = [
      PaymentOptions.STRONGHOLD,
      PaymentOptions.SPENCE,
      PaymentOptions.SWIFTER,
      PaymentOptions.LEDGERGREEN,
    ];
    return !noGuestCheckoutList.includes(this.getType());
  }

  supportsTipping() {
    return get(this.obj, "attributes.allows_tips", false);
  }

  supportsTippingAfterCheckout() {
    const noTipsAfterCheckoutList = [PaymentOptions.ADYEN];
    return this.supportsTipping() && !noTipsAfterCheckoutList.includes(this.getType());
  }

  supportsPromotions() {
    return get(this.obj, "attributes.allows_promotions", false);
  }
}

function makePaymentOption(attrs) {
  return new PaymentOption(attrs);
}

export default makePaymentOption;
