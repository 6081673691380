export default function addImgixParams(baseUrl, dimensions, dpr, fit = "crop") {
  try {
    const url = new URL(baseUrl);
    const {width, height} = dimensions;

    url.searchParams.append("auto", "format");
    url.searchParams.append("fit", fit);
    url.searchParams.append("ixlib", "react-9.0.2");

    if (width && height) {
      url.searchParams.append("ar", `${width}:${height}`);
    }

    if (dpr) {
      url.searchParams.append("dpr", dpr);
    }

    width && url.searchParams.append("w", `${width}`);
    height && url.searchParams.append("h", `${height}`);
    return url;
  } catch (e) {
    return baseUrl;
  }
}
