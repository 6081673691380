import useCartState from "../hooks/useCartState";
import CartModalService from "src/core/checkout/components/cart/cartModalService";

function useAddItem({product, quantity, weightKey = undefined, openCart = true}) {
  const [cartState, actions] = useCartState();
  const addItem = () => {
    if (!product) return;
    const _weightKey =
      weightKey ||
      (product.hasWeightPrices() ? product.getFirstWeightPrice().weightKey : null);

    actions.addItem(cartState.data, {product, quantity, weightKey: _weightKey});
    if (openCart) {
      CartModalService.show();
    }
  };

  return {addItem};
}

export default useAddItem;
