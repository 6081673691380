import {DeliveryTypes} from "./DeliveryType";

class OrderStatus {
  static Status = {
    status_in_progress: "in_progress",
    status_created: "created",
    status_accepted: "accepted",
    status_declined: "declined",
    status_ready: "ready",
    status_completed: "completed",
    status_canceled_by_buyer: "canceled_by_buyer",
    status_canceled_by_seller: "canceled_by_seller",
    delivery_at_store: "delivered_at_store",
    delivery_at_buyer_location: "delivered_at_buyer_location",

    status_delivered: "delivered",
    status_on_the_way: "on_the_way",
    status_packaged: "packaged",
  };

  static StatusDisplayNames = {
    order_placed: "Order Placed",
    processing: "Processing",
    ready: "Ready",
    completed: "Completed",

    placed: "Placed",
    accepted: "Accepted",
    out_for_delivery: "Out For Delivery",
    delivered: "Completed",

    canceled: "Canceled",
  };

  constructor(statusCode, deliveryType) {
    this._statusCode = statusCode;
    this._deliveryType = deliveryType;
  }

  toString() {
    if (this._deliveryType === DeliveryTypes.DELIVERY) {
      if (this.isProcessing() && !this.isAccepted())
        return OrderStatus.StatusDisplayNames.order_placed;
      if (this.isAccepted()) return OrderStatus.StatusDisplayNames.accepted;
    }

    if (this.isProcessing()) return OrderStatus.StatusDisplayNames.processing;

    if (this.isReady()) return OrderStatus.StatusDisplayNames.ready;
    if (this.isCompleted()) return OrderStatus.StatusDisplayNames.completed;
    if (this.isCanceled()) return OrderStatus.StatusDisplayNames.canceled;
  }

  isCanceled() {
    const {Status} = OrderStatus;
    const canceledStates = [
      Status.status_declined,
      Status.status_canceled_by_buyer,
      Status.status_canceled_by_seller,
    ];
    return canceledStates.indexOf(this._statusCode) > -1;
  }

  isReady() {
    const {Status} = OrderStatus;
    const readyStates = [Status.status_ready, Status.status_packaged];
    return readyStates.indexOf(this._statusCode) > -1;
  }

  isCompleted() {
    const {Status} = OrderStatus;
    const completedStates = [
      Status.status_completed,
      Status.delivery_at_store,
      Status.delivery_at_buyer_location,
      Status.status_delivered,
    ];
    return completedStates.indexOf(this._statusCode) > -1;
  }

  isProcessing() {
    const {Status} = OrderStatus;
    const processingStates = [
      Status.status_in_progress,
      Status.status_created,
      Status.status_accepted,
    ];
    return processingStates.indexOf(this._statusCode) > -1;
  }

  isAccepted() {
    const {Status} = OrderStatus;
    const processingStates = [Status.status_accepted];
    return processingStates.indexOf(this._statusCode) > -1;
  }

  isOutForDelivery() {
    const {Status} = OrderStatus;
    const processingStates = [
      Status.status_on_the_way,
      Status.delivery_at_store,
      Status.delivery_at_buyer_location,
    ];
    return processingStates.indexOf(this._statusCode) > -1;
  }
}

export function makeOrderStatus(statusCode, deliveryType) {
  if (!statusCode) throw new Error("Missing status code for order status");

  return new OrderStatus(statusCode, deliveryType);
}

export function getStepsForOrder(order) {
  if (!order) return [];

  if (order.getDeliveryType().code === DeliveryTypes.PICK_UP) {
    return [
      {name: OrderStatus.StatusDisplayNames.processing},
      {name: OrderStatus.StatusDisplayNames.ready},
      {
        name: order.isCanceled()
          ? OrderStatus.StatusDisplayNames.canceled
          : OrderStatus.StatusDisplayNames.completed,
      },
    ];
  } else {
    return [
      {name: OrderStatus.StatusDisplayNames.order_placed},
      {name: OrderStatus.StatusDisplayNames.ready},
      {name: OrderStatus.StatusDisplayNames.out_for_delivery},
      {
        name: order.isCanceled()
          ? OrderStatus.StatusDisplayNames.canceled
          : OrderStatus.StatusDisplayNames.delivered,
      },
    ];
  }
}

export function getProgressStep(order) {
  if (order.getDeliveryType().code === DeliveryTypes.PICK_UP) {
    return getPickupProgressStep(order);
  } else {
    return getDeliveryProgressStep(order);
  }
}

function getPickupProgressStep(order) {
  if (!order) return 0;
  if (order.isProcessing()) return 0;
  if (order.isReady()) return 1;
  if (order.isCompleted()) return 2;
  if (order.isCanceled()) return 2;
}

function getDeliveryProgressStep(order) {
  if (!order) return 0;
  if (order.getStatus().isProcessing() && !order.getStatus().isAccepted()) return 0;
  if (order.getStatus().isReady() || order.getStatus().isAccepted()) return 1;
  if (order.getStatus().isOutForDelivery()) return 2;
  if (order.getStatus().isCompleted()) return 3;
  if (order.getStatus().isCanceled()) return 3;
}
