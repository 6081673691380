import store from "../state/store";
import {useEffect, useMemo, useState} from "react";
import useProductFiltersParams from "src/core/common/hooks/useAppliedFilters";
import {requestProducts} from "../state/actions";
import {getProductsListState} from "@menu/state/selectors";
import useDeliveryInfo from "src/core/deliveries/hooks/useDeliveryInfo";
import {useProductsSortBy} from "@menu/hooks/useSortBy";
import useRouter from "src/core/common/hooks/useRouter";

function useFullPageProductsList(overrides = {}) {
  const {dispatch} = store;

  const router = useRouter();
  const {category, brand} = router.query;

  const [filterParams] = useProductFiltersParams();
  const deliveryInfo = useDeliveryInfo();
  const [currentOrder] = useProductsSortBy();

  const type = getType(router);
  const slug = category || brand || null;
  const shop = router.query.shop;

  const filters = overrides?.filters || filterParams;

  const getMatchingState = () => {
    return getProductsListState(store.getState(), {
      type,
      slug,
      order: currentOrder,
      shop,
      filters,
      deliveryInfo,
    });
  };

  const [state, setState] = useState(getMatchingState());

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(getMatchingState());
    });
    return () => {
      unsubscribe();
    };
  }, [type, slug, shop, filters?.toString(), deliveryInfo, currentOrder]);

  const _requestProducts = (options = {}) => {
    dispatch(
      requestProducts({
        type,
        slug,
        limit: 20,
        shop,
        filters,
        order: currentOrder,
        deliveryInfo,
        options,
        ...overrides,
      })
    );
  };

  useEffect(() => {
    _requestProducts();
  }, [filters.toString(), deliveryInfo]);

  const products = useMemo(() => {
    return state.data.map(product => product.productCardObject);
  }, [state.data]);

  return {
    state: {
      loading: state.loading || state.type !== type || state.slug !== slug,
      error: state.error,
      data: products,
      hasMore: state.hasMore,
      showSkeleton: state.showSkeleton,
      totalCount: state.totalCount,
    },
    actions: {
      requestProducts: _requestProducts,
    },
  };
}

function getType(router) {
  const {brand, category} = router.query;

  if (category) return "category";
  if (brand) return "brand";

  return "any";
}

export default useFullPageProductsList;
