import React from "react";
import PropTypes from "prop-types";

const Link = ({to, external, underlined, children}) => {
  return (
    <a
      href={to.pathname}
      target={external ? "_blank" : ""}
      rel="noreferrer"
      style={underlined ? {textDecoration: "underlined"} : {textDecoration: "none"}}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  external: PropTypes.bool,
  underlined: PropTypes.bool,
  children: PropTypes.node,
};

export default Link;
