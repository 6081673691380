import CannabisFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/CannabisFlowerTypeIndicator";
import {AirplaneFlowerTypeIndicator, SimpleFlowerTypeIndicator} from "@ui/components";
import WheelFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/WheelFlowerTypeIndicator";

export default function makeFlowerTypeIndicator(type) {
  switch (type) {
    case "airplane":
      return AirplaneFlowerTypeIndicator;
    case "cannabis":
      return CannabisFlowerTypeIndicator;
    case "wheel":
      return WheelFlowerTypeIndicator;
    default:
      return SimpleFlowerTypeIndicator;
  }
}

export const FLOWER_TYPE_ABBR = {
  "Indica Dominant": "ID",
  Indica: "I",
  "Sativa Dominant": "SD",
  Sativa: "S",
  Hybrid: "H",
  CBD: "CBD",
};
