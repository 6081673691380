import {useEffect} from "react";
import {useShop} from "src/core/shops";
import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import useDeliveryAddress from "src/core/deliveries/hooks/useDeliveryAddress";
import useDeliveryMode from "src/core/deliveries/hooks/useDeliveryMode";
import useDeliveryModeSelector from "src/core/deliveries/hooks/useDeliveryModeSelector";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useDeliverySettingsRoutes from "src/core/deliveries/hooks/useDeliverySettingsRoutes";
import useAvailableDeliveryTypes from "src/core/deliveries/hooks/useAvailableDeliveryTypes";
import {DeliveryTypes} from "src/core/common/models/DeliveryType";
import Link from "src/core/common/components/modules/Link";
import useSelectPickup from "src/core/deliveries/hooks/useSelectPickup";
import useRouter from "src/core/common/hooks/useRouter";
import useInventoryType from "src/core/inventories/useInventoryType";
import routes from "src/core/common/routes";
import useShopGroup from "../../shops/hooks/useShopGroup";
import useSite from "src/core/sites/hooks/useSite";

export default function useDeliveryBanner() {
  const router = useRouter();
  const [shop] = useShop();
  const [deliveryType] = useDeliveryType();
  const [deliveryAddress] = useDeliveryAddress();
  const [deliveryMode] = useDeliveryMode();
  const [availableDeliveryTypes] = useAvailableDeliveryTypes();
  const [shops, Shop] = useShopGroup();
  const toggles = useFeatureToggles();
  const site = useSite();

  /* eslint-disable */
  useEffect(() => {
    if (!shops.data) {
      Shop.loadShopGroup();
    }
  }, []);
  /* eslint-enable */

  const storesWithPickupEnabled = shops.data
    ? shops.data.filter(shop => shop.isPickupAllowed())
    : [];

  const allowDeliveryType = type => {
    return availableDeliveryTypes.filter(t => t.code === type).length > 0;
  };

  const {goToDeliveryCheck, verification} = useDeliveryModeSelector();

  const {addressSelectionRoute} = useDeliverySettingsRoutes();

  const {selectStoreOrPickup} = useSelectPickup(() => {
    router.safeBack();
  });

  const {selectPickup} = useSelectPickup({});

  const getDeliveryLocationString = () => {
    if (!deliveryType) return null;

    //TODO THIS SHOULD NOT BE HERE, replicating the makeDeliveryType switch
    if (deliveryType.code === DeliveryTypes.PICK_UP) {
      return shop.data ? shop.data.getName() : null;
    } else if (deliveryType.code === DeliveryTypes.DELIVERY) {
      return deliveryAddress ? deliveryAddress.toSimpleString() : "none";
    } else {
      return null;
    }
  };

  const deliveryCounter =
    verification && verification.getNumberOfProductsAvailable(shop.data, deliveryMode);

  const [inventoryType, , inventoryTypeSelectionEnabled] = useInventoryType();

  const inventoryTypePickerPath = {pathname: routes.inventoryType};

  const deliveryShopIsExternal = otherShop => {
    return toggles.deliveriesOnly() || !otherShop?.equals(shop?.data);
  };

  const routerOnOpen = {
    delivery: () => router.push(routes.deliveryCheck),
    pickup: () => router.push(routes.storeSelection),
  };

  const {hideDeliveryBanner} = site.getUiConfiguration();
  const displayDeliveryBanner = !toggles.kioskMode() && !hideDeliveryBanner;

  return {
    site,
    displayDeliveryBanner,
    routerOnOpen,
    selectedDeliveryType: deliveryType,
    deliveryAddress: deliveryAddress,
    storeList: storesWithPickupEnabled,
    onSubmitPickupStore: selectPickup,
    deliveryShopIsExternal: deliveryShopIsExternal,
    internalShopRoute: routes.products,
    deliveryLocation: getDeliveryLocationString(),
    locationOptionsRoute: addressSelectionRoute
      ? {pathname: addressSelectionRoute}
      : null,
    LinkComponent: Link,
    deliveryMode,
    deliveryModePickerProps: {
      allowScheduleDelivery: toggles.allowScheduledDelivery(),
      allowExpressDelivery: toggles.allowExpressDelivery(),
    },
    inventoryType,
    inventoryTypePickerPath,
    showInventoryPicker: inventoryTypeSelectionEnabled,
    deliveryTypePickerProps: {
      allowPickup: allowDeliveryType("pickup"),
      allowDelivery: allowDeliveryType("delivery"),
      deliveryCounter: deliveryCounter,
      onSelectDelivery: goToDeliveryCheck,
      onSelectPickup: selectStoreOrPickup,
    },
  };
}
