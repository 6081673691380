import React from "react";
import {useTheme} from "styled-components";
import {CartIconActions, ProductCard, ResponsiveProductCard, SaleIndicator} from "@ui";
import useProductCard from "src/core/common/hooks/useProductCard";
import styles from "src/themes/flight/components/ProductCard/styles";
import useSite from "src/core/sites/hooks/useSite";
import {getFlowerTypeIndicatorProps} from "src/themes/flight/elements/flowerType";
import makeFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/makeFlowerTypeIndicator";

export default function BaseThemeProductCard({product, ...props}) {
  const site = useSite();
  const {actionProps, cartProps, ...productCardProps} = useProductCard({product});
  const theme = useTheme();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site, theme);

  const iconType = site.getUiConfiguration().flowerTypeIndicator.iconType;
  const FlowerTypeIndicator = makeFlowerTypeIndicator(iconType);

  const DesktopProductCard = (
    <div>
      <ProductCard
        {...props}
        {...productCardProps}
        product={product}
        styles={styles(theme, site, {mode: props.mode})}
        potencyTagsPosition="bottom"
        flowerTypePosition={"custom"}
        brandNamePosition={site.getUiConfiguration().productCard.brandNamePosition}
        sizesPosition={"below-potency-tags"}
        zoomOnHover={false}
        flowerTypeIndicatorProps={flowerTypeIndicatorProps}
        FlowerTypeIndicatorComponent={FlowerTypeIndicator}
        ActionsComponent={props => (
          <CartIconActions {...props} {...actionProps} discountPricePosition={"bottom"} />
        )}
        SaleIndicatorComponent={props =>
          productCardProps.onSale && <SaleIndicator {...props} position={"right"} />
        }
      />
    </div>
  );

  return (
    <ResponsiveProductCard
      DesktopProductCard={DesktopProductCard}
      MobileProductCard={DesktopProductCard}
    />
  );
}
