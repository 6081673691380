import React from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import styled from "styled-components";
import ConfigurableImage from "../ConfigurableImage";
import widthLimited from "@ui/components/Decorators/widthLimited";

export default function CheckoutHeader({
  styles,
  imageUrl,
  largeButtonText,
  smallButtonText,
  onBackClick,
  buttonDisabled,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <>
      <div style={{height: "70px"}}></div>
      <Container styles={_styles.root}>
        <Content styles={_styles.root}>
          <FlexSpacer>
            <LargeBackButton
              disabled={buttonDisabled}
              onClick={onBackClick}
              styles={_styles.backButton}
            >
              {largeButtonText}
            </LargeBackButton>
            <BackButton
              disabled={buttonDisabled}
              onClick={onBackClick}
              styles={_styles.backButton}
            >
              {smallButtonText}
            </BackButton>
          </FlexSpacer>
          <ImageContainer source={imageUrl} styles={_styles.image} height={50} />
          <FlexSpacer />
        </Content>
      </Container>
    </>
  );
}

const Content = widthLimited(styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`);
const Container = styled.div`
  background: ${({styles}) => styles.background};
  box-shadow: ${({styles}) => styles.boxShadow};
  z-index: ${({styles}) => styles.zIndex};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const FlexSpacer = styled.div`
  flex: 1;
`;

const ImageContainer = styled(ConfigurableImage)`
  box-sizing: border-box;
  max-width: 400px;
  object-fit: contain;
  height: ${({styles}) => styles.height.lg};

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
  }

  ${media.down("sm")} {
    max-width: 200px;
    height: ${({styles}) => styles.height.sm};
  }

  border-radius: ${({styles}) => styles.borderRadius};
`;

const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  background: ${({styles}) => styles.background};
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};

  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  text-transform: ${({styles}) => styles.textTransform};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
    font-size: ${({styles}) => styles.fontSize.lg};
    display: none;
  }

  ${media.between("md", "lg")} {
    padding: ${({styles}) => styles.padding.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  &:disabled {
    opacity: 0.4;
    cursor: inherit;
  }
`;

const LargeBackButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  background: ${({styles}) => styles.background};
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};

  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  text-transform: ${({styles}) => styles.textTransform};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    padding: ${({styles}) => styles.padding.md};
    font-size: ${({styles}) => styles.fontSize.md};
    display: none;
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
    display: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: inherit;
  }
`;

const defaultStyles = {
  root: {
    zIndex: 2,
    background: "#FFFFFF",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
    padding: {
      lg: "3px 170px 3px 170px",
      md: "3px 10px 3px 10px",
      sm: "3px 10px 3px 10px",
    },
  },
  backButton: {
    background: "#ffffff",
    border: "1px solid #000000",
    borderRadius: "8px",
    padding: {
      lg: "16px 32px 16px 32px;",
      md: "16px 32px 16px 32px;",
      sm: "16px 16px 16px 16px",
    },
    fontFamily: "sans-serif",
    fontSize: {
      lg: "13px",
      md: "13px",
      sm: "13px",
    },
    fontWeight: "700",
    textTransform: "none",
    color: "#000000",
  },
  image: {
    borderRadius: "0px",
    height: {
      lg: "50px",
      md: "50px",
      sm: "50px",
    },
    maxHeight: {
      lg: "50px",
      md: "50px",
      sm: "50px",
    },
    maxWidth: {
      lg: "auto",
      md: "auto",
      sm: "auto",
    },
  },
};

CheckoutHeader.defaultProps = {
  largeButtonText: "Back To Shopping",
  smallButtonText: "Back",
  styles: defaultStyles,
};

CheckoutHeader.propTypes = {
  imageUrl: PropTypes.string,
  largeButtonText: PropTypes.string,
  smallButtonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  onBackClick: PropTypes.func,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      zIndex: PropTypes.number,
      background: PropTypes.string,
      boxShadow: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    backButton: PropTypes.shape({
      background: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      textTransform: PropTypes.string,
      color: PropTypes.string,
    }),
    image: PropTypes.shape({
      borderRadius: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      maxHeight: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      maxWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
};
