import React from "react";
import Input from "../Inputs";
import {Field} from "react-final-form";
import {InputContainer} from "@ui/components/FormFields/InputContainer";
import {Error} from "@ui/components/FormFields/Error";

function PostalCodeField(props) {
  const {
    onChangeCardPostalCode,
    validatePostalCode,
    getCardPostalCodeErrorMessage,
    cardPostalCodePlaceholder,
    styles,
    disabled,
  } = props;
  return (
    <Field
      validate={validatePostalCode}
      name="payment.cardPostalCode"
      type="text"
      render={({input, meta}) => (
        <InputContainer>
          <Input
            {...input}
            styles={styles.input}
            placeholder={cardPostalCodePlaceholder}
            hasErrors={getCardPostalCodeErrorMessage(meta)}
            disabled={disabled}
            onChange={
              onChangeCardPostalCode
                ? onChangeCardPostalCode(input.onChange)
                : input.onChange
            }
          />
          {getCardPostalCodeErrorMessage(meta) && (
            <Error styles={styles.error}>{getCardPostalCodeErrorMessage(meta)}</Error>
          )}
        </InputContainer>
      )}
    />
  );
}

export default PostalCodeField;
