import get from "lodash/get";

class MarketingSource {
  constructor(marketingSourceObj) {
    this.marketingSourceObj = marketingSourceObj;
  }
  getName() {
    return get(this.marketingSourceObj, "attributes.name");
  }
}

export default MarketingSource;
