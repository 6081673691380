import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";
import PropTypes from "prop-types";

function NavigationOptions({
  options,
  styles,
  LinkComponent,
  hideOnSmallerViewport,
  onHoverOption,
  isExternalPage,
}) {
  return (
    <Container
      hideOnSmallerViewport={hideOnSmallerViewport}
      styles={styles}
      hasHoverOption={onHoverOption}
      isExternalPage={isExternalPage}
    >
      <ul>
        {options.map((option, index) => (
          <li key={index}>{makeOption(option, {key: index, styles, LinkComponent})}</li>
        ))}
      </ul>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "top-nav top-nav__navigation-options",
}))`
  height: 100%;

  > ul {
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: ${({styles, isExternalPage}) => (isExternalPage ? styles.padding : 0)};
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  > ul > li {
    height: 44px;
  }

  > ul > li > a.loginButton {
    width: ${({styles}) => styles.width};
    &:hover {
      background-color: white;
    }
  }

  > ul > li > * {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacing(1)};
    height: 100%;
    width: ${({styles, isExternalPage}) =>
      isExternalPage ? styles.width : styles.color};
    box-sizing: border-box;
  }

  > ul > li:last-child > * {
    padding-right: ${({hideOnSmallerViewport}) =>
      hideOnSmallerViewport ? spacing(2) : 0};
  }

  > ul > li > a {
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
    color: ${({styles}) => styles.color};
    text-transform: ${({styles}) => styles.textTransform};
    font-family: ${({styles}) => styles.fontFamily};
    letter-spacing: ${({styles}) => styles.letterSpacing};
    text-decoration: none;
    border-radius: 50px;
    transition: ${({styles}) => styles.transition};

    &:hover {
      text-decoration: underline;
      background-color: ${({styles, hasHoverOption}) =>
        hasHoverOption ? styles.hoverOptionBackgroundColor : "transparent"};
      color: ${({styles, hasHoverOption}) =>
        hasHoverOption ? styles.hoverOptionColor : styles.color};
    }
  }

  ${media.down("sm")} {
    ${({hideOnSmallerViewport}) => (hideOnSmallerViewport ? "display: none;" : "")}

    > ul {
      column-gap: 2px;
    }
  }
`;

function makeOption(option, props = {}) {
  if (option.component) {
    const Component = option.component;
    return <Component {...props} />;
  } else if (option.label && option.url) {
    return (
      <LinkOption
        LinkComponent={props.LinkComponent}
        external={option.external}
        label={option.label}
        url={option.url}
        {...props}
      />
    );
  } else {
    return null;
  }
}

function LinkOption({label, url, external, styles, LinkComponent}) {
  if (LinkComponent) {
    return (
      <LinkComponent external={external} to={url}>
        {label}
      </LinkComponent>
    );
  }

  return (
    <Link href={url} styles={styles}>
      {label}
    </Link>
  );
}

const Link = styled.a.attrs(() => ({
  className: "top-nav top-nav__link-option",
}))``;

NavigationOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.object,
      component: PropTypes.elementType,
    })
  ),
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    color: PropTypes.string,
    textTransform: PropTypes.string,
    letterSpacing: PropTypes.string,
  }),
  hideOnSmallerViewport: PropTypes.bool,
};

LinkOption.propTypes = {
  label: PropTypes.string,
  url: PropTypes.object,
  external: PropTypes.bool,
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    color: PropTypes.string,
    textTransform: PropTypes.string,
    letterSpacing: PropTypes.string,
  }),
  onHoverOption: PropTypes.bool,
};

export default NavigationOptions;
