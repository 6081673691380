import React from "react";
import styled, {useTheme} from "styled-components";
import CustomOption from "./CustomOption";
import ShoppingCart from "./ShoppingCart";
import {SearchIconButton} from "../../../elements/button/SearchIconButton";
import * as ThemedComponents from "src/themes/utils/themedComponents";
import useFeatureToggles from "../../../../hooks/useFeatureToggles";
import Navigation from "src/core/common/components/collections/menu/menu-elements/Navigation";
import {useMediaQuery} from "react-responsive";

function OptionsList({
  isSearchEnabled,
  toggleSearchVisibility,
  componentsColor,
  cartButtonColor,
  navigationMinimumWidth = 0,
  headerPages = {},
  themeProperties = {},
}) {
  const theme = useTheme();
  const LoginSection = ThemedComponents.get(theme.v1.components.loginButton);
  const toggles = useFeatureToggles();
  const isDesktop = useMediaQuery({minWidth: navigationMinimumWidth});

  return (
    <>
      {themeProperties && themeProperties.searchBarFirst ? (
        <OptionsListContainer>
          {isSearchEnabled && (
            <CustomOption>
              <SearchIconButton
                onClick={toggleSearchVisibility}
                color={componentsColor}
              />
            </CustomOption>
          )}

          {headerPages && isDesktop && (
            <NavigationContainer>
              <Navigation headerPages={headerPages} themeProperties={themeProperties} />
            </NavigationContainer>
          )}

          {!toggles.isKiosk() && (
            <CustomOption data-cy="loginSection">
              <LoginSection
                themeProperties={themeProperties}
                componentsColor={componentsColor}
              />
            </CustomOption>
          )}

          <CustomOption>
            <ShoppingCart
              componentsColor={componentsColor}
              cartButtonColor={cartButtonColor}
              themeProperties={themeProperties}
            />
          </CustomOption>
        </OptionsListContainer>
      ) : (
        <OptionsListContainer>
          {!toggles.isKiosk() && (
            <CustomOption data-cy="loginSection">
              <LoginSection
                themeProperties={themeProperties}
                componentsColor={componentsColor}
              />
            </CustomOption>
          )}

          {isSearchEnabled && (
            <CustomOption>
              <SearchIconButton
                onClick={toggleSearchVisibility}
                color={componentsColor}
              />
            </CustomOption>
          )}

          <CustomOption>
            <ShoppingCart
              componentsColor={componentsColor}
              cartButtonColor={cartButtonColor}
              themeProperties={themeProperties}
            />
          </CustomOption>
        </OptionsListContainer>
      )}
    </>
  );
}

const NavigationContainer = styled.div`
  > ul {
    list-style: none;
    display: flex;
    align-items: center;
  }
`;

export const OptionsListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;

  > li {
    display: flex;
    align-items: center;
  }
`;

export default OptionsList;
