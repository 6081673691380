import {useRecommendedProductsListResource} from "src/state/hooks/useAsyncResource";

export default function BaseRecommendedProductsList({limit, offset, filters, render}) {
  const {data: products, meta} = useRecommendedProductsListResource({
    limit,
    offset,
    filters,
  });
  const _products = products?.getElements() ?? [];

  return render({
    meta,
    products: _products,
    showLoading: _products.length === 0 && Boolean(meta.loading),
  });
}
