import makeDealSchemaObject from "src/core/seo/schema-objects/objects/deal";
import {getDealDetailUrl, getDealPageUrl} from "src/core/seo/meta";
import makeBreadCrumbList from "src/core/seo/schema-objects/objects/breadcrumbList";

function makeDealDetailSchema(deal, ctx) {
  if (!deal) return [];

  const items = makeDealDetailBreadCrumbItems(deal, ctx);
  return [
    {
      "@context": "https://schema.org/",
      "@graph": [makeBreadCrumbList(items, ctx), makeDealSchemaObject(deal, ctx)],
    },
  ].map(schema => ({
    type: "application/ld+json",
    innerHTML: JSON.stringify(schema),
  }));
}

export function makeDealDetailBreadCrumbItems(deal, ctx) {
  if (!deal) return [];
  return [
    {
      id: getDealPageUrl(ctx),
      name: "Deals Page",
    },
    {
      id: getDealDetailUrl(deal, ctx),
      name: deal.name,
    },
  ];
}

export default makeDealDetailSchema;
