import dynamic from "next/dynamic";
import React from "react";
import widthLimited from "@ui/components/Decorators/widthLimited";
import styled from "styled-components";
import SortFilters from "src/themes/medleaf/elements/filters/SortFilters/SortFilters";

const ProductFiltersWrapper = dynamic(() => import("@menu/components/ProductFilters"));

export default function ActionsSortFilter({
  filtersDisplayOptions,
  showSort = true,
  onChangeFilter = () => {},
}) {
  return (
    <Container>
      <ProductFiltersWrapper
        mode={"drawer"}
        displayOptions={filtersDisplayOptions}
        onChangeFilter={onChangeFilter}
      />
      {showSort && <SortFilters />}
    </Container>
  );
}

const Container = widthLimited(styled.div`
  margin: 0 auto 32px;
`);
