import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";
import NavigationOptions from "@ui/components/TopNavigationBar/NavigationOptions";
import MenuButton from "@ui/components/TopNavigationBar/MenuButton";
import merge from "lodash/merge";
import addImgixParams from "@ui/utils/imgix";
import LoginButton from "@ui/components/TopNavigationBar/LoginButton";
import CartButton from "@ui/components/TopNavigationBar/CartButton";
import SearchButton from "@ui/components/TopNavigationBar/SearchButton";
import widthLimited from "@ui/components/Decorators/widthLimited";
import {
  addCartButton,
  addLoginButton,
  addSearchButton,
} from "@ui/components/TopNavigationBar/optionsBuilder";

const TopNavigationBar = ({
  SearchButtonComponent,
  searchButtonProps,
  CartButtonComponent,
  cartButtonProps,
  fixed,
  styles,
  logoUrl,
  logoPosition,
  logoLinkUrl,
  loginButtonProps,
  LoginButtonComponent,
  hideLoginButton,
  largeScreenOptionsAlignment,
  largeScreenOptions,
  mainOptions,
  mobileLogoUrl,
  hideMenuButtonOnLargerViewport,
  hideMenuButton,
  MenuButtonComponent,
  SearchComponent,
  searchComponentDisplay,
  LinkComponent,
  onClickMenuButton,
  searchBarFirst,
  onHoverOption,
  isExternalPage,
}) => {
  const [searching, setSearching] = useState(false);
  const _searching = searching && SearchComponent;
  const toggleSearch = () => {
    setSearching((currentSearching) => !currentSearching);
  };
  const _styles = merge({}, defaultStyles, styles);
  const _mainOptions = useMemo(() => {
    const _mainOptions = [...mainOptions];
    if (!hideLoginButton) {
      addLoginButton(_mainOptions, {
        styles: _styles,
        props: loginButtonProps,
        Component: LoginButtonComponent,
      });
    }
    if (SearchComponent) {
      addSearchButton(_mainOptions, {
        searchBarFirst,
        toggleSearch,
        styles: _styles,
        props: searchButtonProps,
        Component: SearchButtonComponent,
      });
    }
    addCartButton(_mainOptions, {
      styles: _styles,
      props: cartButtonProps,
      Component: CartButtonComponent,
    });

    return _mainOptions;
  }, [
    mainOptions,
    loginButtonProps,
    searchButtonProps,
    cartButtonProps,
    hideLoginButton,
    SearchComponent,
  ]);

  const displayFullBar =
    searchComponentDisplay === SEARCH_COMPONENT_DISPLAY.partial || !_searching;

  const displayBarSearch =
    searchComponentDisplay === SEARCH_COMPONENT_DISPLAY.partial && _searching;

  return (
    <>
      <Container styles={_styles.root} fixed={fixed}>
        {displayFullBar && (
          <Content styles={_styles.root}>
            <LeftSection>
              {!hideMenuButton && (
                <MenuButtonContainer
                  hideMenuButtonOnLargerViewport={hideMenuButtonOnLargerViewport}
                >
                  <MenuButtonComponent onClick={onClickMenuButton} />
                </MenuButtonContainer>
              )}
              <Logo
                hideInLargerViewport={logoPosition !== "left"}
                linkUrl={logoLinkUrl}
                styles={_styles.logo}
                logoUrl={logoUrl}
                mobileLogoUrl={mobileLogoUrl}
              />
            </LeftSection>
            {logoPosition === "center" && (
              <CenterSection>
                <Logo
                  linkUrl={logoLinkUrl}
                  styles={_styles.logo}
                  logoUrl={logoUrl}
                  mobileLogoUrl={mobileLogoUrl}
                />
              </CenterSection>
            )}
            <RightSection largeScreenOptionsAlignment={largeScreenOptionsAlignment}>
              <>
                <NavigationOptions
                  onHoverOption={onHoverOption}
                  isExternalPage={isExternalPage}
                  hideOnSmallerViewport={true}
                  options={largeScreenOptions}
                  LinkComponent={LinkComponent}
                  styles={_styles.root}
                  optionProps={{}}
                />
                <div />
                {displayBarSearch && <SearchComponent toggleSearch={toggleSearch} />}
                {!_searching && (
                  <NavigationOptions
                    options={_mainOptions}
                    styles={_styles.root}
                    optionProps={{}}
                  />
                )}
              </>
            </RightSection>
          </Content>
        )}
        {!displayFullBar && (
          <SearchComponent toggleSearch={toggleSearch} styles={_styles.search} />
        )}
      </Container>
      {fixed && <Placeholder styles={_styles.root} />}
    </>
  );
};

export const SEARCH_COMPONENT_DISPLAY = {
  partial: "partial",
  full: "full",
};

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    borderBottomColor: "#272727",
    boxShadow: "none",
    borderWidth: "2px",
    fontFamily: "sans-serif",
    color: "white",
    fontWeight: "700",
    letterSpacing: "",
    textTransform: "capitalize",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "14px",
    },
    height: {
      lg: "70px",
      md: "70px",
      sm: "65px",
    },
  },
  cartButton: {
    badgeFontSize: "14px",
    highlightColor: "#000",
    highlightTextColor: "#fff",
  },
  searchButton: {},
  loginButton: {},
  logo: {
    maxWidth: {
      lg: "180px",
      md: "180px",
      sm: "150px",
    },
  },
};

const Placeholder = styled.div.attrs(() => ({
  className: "top-nav top-nav__placeholder",
}))`
  height: ${({styles}) => styles.height.lg};
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
  }
`;
const Container = styled.div.attrs(() => ({
  className: "top-nav top-nav__container",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: ${({styles}) => styles.height.lg};
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
  }
  width: 100%;

  text-transform: ${({styles}) => styles.textTransform};

  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  background-color: ${({styles}) => styles.backgroundColor};
  border-bottom: solid ${({styles}) => styles.borderBottomColor};
  border-width: ${({styles}) => styles.borderWidth};
  box-shadow: ${({styles}) => styles.boxShadow};

  position: ${({fixed}) => (fixed ? "fixed" : "relative")};
  left: 0;
  z-index: 5;
`;
const Content = widthLimited(styled.div.attrs(() => ({
  className: "top-nav top-nav__content",
}))`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`);

function Logo({linkUrl, styles, hideInLargerViewport, ...props}) {
  return (
    <LogoLink href={linkUrl} styles={styles} hideInLargerViewport={hideInLargerViewport}>
      <LogoImg styles={styles} {...props} />
    </LogoLink>
  );
}

Logo.propTypes = {
  linkUrl: PropTypes.string,
  hideInLargerViewport: PropTypes.bool,
  styles: PropTypes.shape({}),
};

const LogoLink = styled.a.attrs(() => ({
  className: "top-nav__logo-link",
}))`
  ${media.up("md")} {
    ${({hideInLargerViewport}) =>
      hideInLargerViewport ? "display:none; > * {display: none !important;}" : ""}
  }
  margin-right: ${spacing(1)};
`;

const LogoImg = styled.img.attrs(() => ({
  className: "top-nav top-nav__logo",
  alt: "logo",
}))`
  display: inherit;
  height: auto;
  max-height: 50px;

  ${media.up("md")} {
    content: url(${({logoUrl}) => addImgixParams(logoUrl, {height: 70}, 3)});
  }
  ${media.down("sm")} {
    content: url(${({mobileLogoUrl}) => addImgixParams(mobileLogoUrl, {height: 50}, 3)});
  }
  ${media.up("lg")} {
    max-width: ${({styles}) => styles.maxWidth.lg};
  }
  ${media.down("md")} {
    max-width: ${({styles}) => styles.maxWidth.md};
  }
  ${media.down("sm")} {
    max-width: ${({styles}) => styles.maxWidth.sm};
  }
`;

const RightSection = styled.div.attrs(() => ({
  className: "top-nav top-nav__right-section",
}))`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: ${({largeScreenOptionsAlignment}) =>
    largeScreenOptionsAlignment === "right" ? "flex-end" : "space-between"};
`;
const CenterSection = styled.div.attrs(() => ({
  className: "top-nav top-nav__center-section",
}))`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  ${media.down("sm")} {
    display: none;
  }
`;
const LeftSection = styled.div.attrs(() => ({
  className: "top-nav top-nav__left-section",
}))`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: ${spacing(2)};
  }

  ${media.down("sm")} {
    > *:first-child {
      margin-right: ${spacing(1)};
    }
  }
`;
const MenuButtonContainer = styled.div`
  ${media.up("md")} {
    ${({hideMenuButtonOnLargerViewport}) =>
      hideMenuButtonOnLargerViewport
        ? `
      display: none !important;
    `
        : ""}
  }
`;

TopNavigationBar.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderBottomColor: PropTypes.string,
      borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      boxShadow: PropTypes.string,
      fontFamily: PropTypes.string,
      color: PropTypes.string,
      textTransform: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      horizontalPadding: PropTypes.shape({
        lg: PropTypes.number,
        md: PropTypes.number,
        sm: PropTypes.number,
      }),
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    cartButton: PropTypes.object,
    searchButton: PropTypes.object,
    loginButton: PropTypes.object,
    logo: PropTypes.shape({
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
  logoUrl: PropTypes.string.isRequired,
  mobileLogoUrl: PropTypes.string.isRequired,
  logoLinkUrl: PropTypes.string,
  logoPosition: PropTypes.oneOf(["center", "left"]),
  largeScreenOptionsAlignment: PropTypes.oneOf(["left", "right"]),
  largeScreenOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.object,
      component: PropTypes.elementType,
    })
  ),
  mainOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      component: PropTypes.elementType,
    })
  ),
  hideMenuButtonOnLargerViewport: PropTypes.bool,
  hideMenuButton: PropTypes.bool,
  LoginButtonComponent: PropTypes.elementType,
  MenuButtonComponent: PropTypes.elementType,
  CartButtonComponent: PropTypes.elementType,
  SearchButtonComponent: PropTypes.elementType,
  SearchComponent: PropTypes.elementType,
  searchComponentDisplay: PropTypes.oneOf([
    SEARCH_COMPONENT_DISPLAY.full,
    SEARCH_COMPONENT_DISPLAY.partial,
  ]),
  onClickMenuButton: PropTypes.func,
  loginButtonProps: PropTypes.shape(LoginButton.propTypes),
  cartButtonProps: PropTypes.shape(CartButton.propTypes),
  searchButtonProps: PropTypes.shape(SearchButton.propTypes),
  hideLoginButton: PropTypes.bool,
  fixed: PropTypes.bool,
  LinkComponent: PropTypes.elementType,
  searchBarFirst: PropTypes.bool,
};

TopNavigationBar.defaultProps = {
  styles: defaultStyles,
  largeScreenOptions: [],
  mainOptions: [],
  hideMenuButtonOnLargerViewport: true,
  hideMenuButton: false,
  hideLoginButton: false,
  largeScreenOptionsAlignment: "left",
  MenuButtonComponent: MenuButton,
  LoginButtonComponent: LoginButton,
  CartButtonComponent: CartButton,
  SearchButtonComponent: SearchButton,
  logoPosition: "left",
  logoLinkUrl: "/",
  loginButtonProps: {},
  cartButtonProps: {},
  searchButtonProps: {},
  fixed: true,
  searchComponentDisplay: SEARCH_COMPONENT_DISPLAY.partial,
  searchBarFirst: false,
};

export default TopNavigationBar;
