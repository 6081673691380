import React from "react";
import styled from "styled-components";
import merge from "lodash/merge";

function GridGroup({options, onChange, disabled, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container disabled={disabled}>
      {options?.length > 0 &&
        options.map(option => (
          <OptionsContainer onClick={() => onChange(option.value)}>
            <Text checked={option.isSelected} styles={_styles.root}>
              {option.name}
            </Text>
          </OptionsContainer>
        ))}
    </Container>
  );
}

const Container = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  gap: 10px;
  padding: ${({theme}) => theme.v2.spacing(2)} 0;
  > li {
    padding: ${({theme}) => theme.v2.spacing(2)} 0;
  }
`;

const OptionsContainer = styled.li`
  width: 50px;
  height: 40px;
  cursor: pointer;
`;

const Text = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: ${({styles}) => styles.borderRadius};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  border: 2px solid;
  border-color: ${({styles, checked}) =>
    checked ? styles.checkedBorderColor : styles.borderColor};
  background-color: ${({styles, checked}) =>
    checked ? styles.checkedBackgroundColor : styles.backgroundColor};
  color: ${({styles, checked}) => (checked ? styles.backgroundColor : styles.color)};
`;

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    borderColor: "#BFCAD1",
    color: "#000",
    checkedBackgroundColor: "#000",
    checkedBorderColor: "#000",
    borderRadius: "4px",
  },
};

export default GridGroup;
