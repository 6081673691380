import Moneris from "src/core/payments/moneris/Moneris";
import {PaymentOptions} from "src/core/payments/constants";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const MonerisPaymentForm = suspensify(
  lazy(() => import("src/core/payments/moneris/MonerisPaymentForm"))
);

export default {
  type: PaymentOptions.MONERIS,
  PaymentClass: Moneris,
  PaymentForm: MonerisPaymentForm,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
