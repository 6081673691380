import useFiltersCatalog from "src/menu/hooks/useFiltersCatalog";
import {ProductFilters} from "src/core/common/hooks/useAppliedFilters";
import useProductCategory from "src/menu/hooks/useProductCategory";
import {useMemo} from "react";

function useRange(rangeField) {
  const {category, subcategory} = useProductCategory();

  const filters = useMemo(
    () => new ProductFilters({category, subcategory}),
    [category, subcategory]
  );

  const [catalog, meta] = useFiltersCatalog({filters});

  const range = catalog ? catalog[rangeField] : null;
  return [range, meta];
}
export default useRange;
