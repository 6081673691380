import React from "react";
import PropTypes from "prop-types";
import SingleOptionFilter from "./SingleOptionFilter";
import MultiOptionFilter from "@ui/components/FiltersList/FilterTypes/MultiOptionFilter";

export default function OptionFilter({
  name,
  disabled,
  isSingleOption,
  id,
  value,
  options,
  onChange,
  collapseFilters,
  checkedMarkColor,
  noOptionsText,
  isGridLayout,
  collapsedOptionsToDisplay,
  styles,
}) {
  if (isSingleOption) {
    return (
      <SingleOptionFilter
        name={name}
        id={id}
        disabled={disabled}
        value={value}
        onChange={onChange}
        checkedMarkColor={checkedMarkColor || styles.section?.checkmarkColor}
        styles={styles}
      />
    );
  }

  return (
    <MultiOptionFilter
      isGridLayout={isGridLayout}
      collapsedOptionsToDisplay={collapsedOptionsToDisplay}
      name={name}
      value={value}
      options={options}
      noOptionsText={noOptionsText}
      onChange={onChange}
      disabled={disabled}
      collapseFilters={collapseFilters}
      checkedMarkColor={checkedMarkColor}
      styles={styles}
    />
  );
}

const defaultStyles = {};

OptionFilter.defaultProps = {
  name: "",
  isSingleOption: false,
  styles: defaultStyles,
};

OptionFilter.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isSingleOption: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  collapseFilters: PropTypes.bool,
  checkedMarkColor: PropTypes.string,
  noOptionsText: PropTypes.string,
  isGridLayout: PropTypes.bool,
  collapsedOptionsToDisplay: PropTypes.number,
  styles: PropTypes.shape({}),
};
