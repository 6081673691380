import React, {useEffect, useState} from "react";
import Filters from "src/themes/legacy/components/ProductFilters/FiltersList";
import {useMediaQuery} from "react-responsive";
import * as ReactDOM from "react-dom";

export const RESPONSIVE_MODE = "responsive";
export const DRAWER_MODE = "drawer";
export const INLINE_MODE = "inline";
const FILTERS_PORTAL_DIV = "filters-drawer";

export default function ProductFilters({mode = RESPONSIVE_MODE, ...props}) {
  const [container, setContainer] = useState();
  useEffect(() => {
    setContainer(document.getElementById(FILTERS_PORTAL_DIV));
  }, [setContainer]);

  const isMobile = useMediaQuery({maxWidth: 1023});

  switch (mode) {
    case RESPONSIVE_MODE:
      if (isMobile) {
        return container ? (
          ReactDOM.createPortal(<Filters isInline={false} {...props} />, container)
        ) : (
          <div />
        );
      } else {
        return <Filters isInline={true} {...props} />;
      }
    case DRAWER_MODE:
      return container ? (
        ReactDOM.createPortal(<Filters isInline={false} {...props} />, container)
      ) : (
        <div />
      );
    case INLINE_MODE:
      return <Filters isInline={true} {...props} />;
    default:
      throw new Error("Invalid product filters mode");
  }
}
