import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import NavigationButton from "@ui/components/TopNavigationBar/NavigationButton";
import media from "@ui/utils/media";
import merge from "lodash/merge";
import {getCartIconComponent} from "@ui/components/Icons";

function CartButton({
  styles,
  badgeStyles,
  cartText,
  cartIcon,
  numberOfItemsInCart,
  onClick,
  highlightIfCartHasProducts,
  highlight,
  hideIconInLargerViewport,
  hideText,
  alignCartTextToRight = false,
  inStoreExperience = false,
  ...props
}) {
  const hasItemsInCart = numberOfItemsInCart > 0;
  const _highlight = highlight || (highlightIfCartHasProducts && hasItemsInCart);

  const _badgeStyles = merge({}, defaultBadgeStyles(styles), badgeStyles);
  const CartIcon = getCartIconComponent(cartIcon);
  const cartIconColor = _highlight ? styles.highlightTextColor : styles.color || "#fff";
  const overrideHideText = hideText !== undefined ? hideText : !!CartIcon;

  return (
    <NavigationButton
      styles={styles}
      onClick={onClick}
      hideTextInSmallerViewport={overrideHideText}
      highlight={_highlight}
      dataCy="openCartButton"
      {...props}
    >
      <Container inStoreExperience={inStoreExperience}>
        {cartText && !alignCartTextToRight && (
          <CartTextContainer styles={styles?.cartText}>{cartText}</CartTextContainer>
        )}
        {CartIcon && (
          <CartIcon
            color={cartIconColor}
            size={"24px"}
            hideIconInLargerViewport={hideIconInLargerViewport}
          />
        )}
        {cartText && alignCartTextToRight && !hasItemsInCart && (
          <CartTextContainer styles={styles?.cartText}>{cartText}</CartTextContainer>
        )}
        {hasItemsInCart && (
          <NumberOfItemsBadge styles={_badgeStyles}>
            {numberOfItemsInCart > 99 ? "99+" : numberOfItemsInCart}
          </NumberOfItemsBadge>
        )}
      </Container>
    </NavigationButton>
  );
}

const defaultBadgeStyles = cartButtonStyles => ({
  ...cartButtonStyles,
  backgroundColor: "transparent",
  color: "#fff",
  position: "relative",
  top: {
    lg: 0,
    md: 0,
    sm: 0,
  },
  right: {
    lg: 0,
    md: 0,
    sm: 0,
  },
  width: {
    lg: "auto",
    md: "auto",
    sm: "auto",
  },
  height: {
    lg: "auto",
    md: "auto",
    sm: "auto",
  },
  cartText: {
    textTransform: "capitalize",
    fontWeight: "400",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
  },
});

const Container = styled.div.attrs(() => ({
  className: "cart-btn cart-btn__container",
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${({inStoreExperience}) => (inStoreExperience ? "8px" : "0px")};
`;

const CartTextContainer = styled.span.attrs(() => ({
  className: "cart-btn cart-btn__cart-text",
}))`
  text-transform: ${({styles}) => styles?.textTransform};
  font-size: ${({styles}) => styles?.fontSize.lg};
  font-weight: ${({styles}) => styles?.fontWeight};
  font-family: ${({styles}) => styles?.fontFamily};

  ${media.down("md")} {
    font-size: ${({styles}) => styles?.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles?.fontSize.sm};
  }
`;

const NumberOfItemsBadge = styled.div.attrs(() => ({
  className: "cart-btn cart-btn__items-number",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 2px;
  font-weight: normal;
  min-width: 15px;
  min-height: 15px;
  letter-spacing: 0;
  color: ${({styles}) => styles.color};
  background-color: ${({styles}) => styles.backgroundColor};
  text-transform: ${({styles}) => styles.textTransform};
  position: ${({styles}) => styles.position};

  font-size: ${({styles}) => styles.fontSize.lg};
  top: ${({styles}) => styles.top?.lg};
  right: ${({styles}) => styles.right?.lg};
  width: ${({styles}) => styles.width?.lg};
  height: ${({styles}) => styles.height?.lg};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
    top: ${({styles}) => styles.top?.md};
    right: ${({styles}) => styles.right?.md};
    width: ${({styles}) => styles.width?.md};
    height: ${({styles}) => styles.height?.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    top: ${({styles}) => styles.top?.sm};
    right: ${({styles}) => styles.right?.sm};
    width: ${({styles}) => styles.width?.sm};
    height: ${({styles}) => styles.height?.sm};
  }
`;

CartButton.propTypes = {
  cartText: PropTypes.string,
  inStoreExperience: PropTypes.bool,
  hideText: PropTypes.bool,
  alignCartTextToRight: PropTypes.bool,
  onClick: PropTypes.func,
  styles: PropTypes.shape({
    textTransform: PropTypes.string,
    highlightTextColor: PropTypes.string,
    highlightColor: PropTypes.string,
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    outlineColor: PropTypes.string,
    color: PropTypes.string,
  }),
  badgeStyles: PropTypes.shape({
    fontSize: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
    position: PropTypes.string,
    top: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
    right: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
    width: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
    height: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  highlightIfCartHasProducts: PropTypes.bool,
  numberOfItemsInCart: PropTypes.number,
  highlight: PropTypes.bool,
  hideIconInLargerViewport: PropTypes.bool,
  cartIcon: PropTypes.string,
};

CartButton.defaultProps = {
  cartIcon: "bag-add-plus",
  highlightIfCartHasProducts: true,
  numberOfItemsInCart: 0,
  highlight: false,
  outline: false,
  styles: {},
  hideIconInLargerViewport: false,
};
export default CartButton;
