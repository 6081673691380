import merge from "lodash/merge";
import get from "lodash/get";

export const backButtonStyles = _theme => {
  const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.backButton"))};
  return {
    root: {
      fontSize: theme.v2.typography.sizing.l.lg,
      fontFamily: theme.v2.typography.title6.family,
      border: 0,
      borderRadius: theme.v2.decoration.border.radius.default,
      fontWeight: theme.v2.typography.title6.weight.heavy,
      textTransform: "capitalize",
      padding: 0,
    },
    secondary: {
      backgroundColor: theme.v2.color.background,
      color: theme.v2.color.base.grey["800"],
    },
    large: {
      padding: 0,
      height: "55px",
    },
  };
};
