import React from "react";
import styled, {useTheme} from "styled-components";
import Loader from "../Loader";
import Button from "@ui/components/Button";
import {primaryButtonStyles} from "@themes/default/components/Button/styles";

export default function PrimaryButton(props) {
  const theme = useTheme();

  return (
    <Container>
      <Button
        {...props}
        styles={styles(theme)}
        label={props.label || props.children}
        variant="primary"
        size="large"
        type={props.type}
        disabled={props.disabled}
        LeftIconComponent={props.LeftIconComponent}
        RightIconComponent={props.RightIconComponent}
        onClick={props.onClick}
        to={props.to}
      />
    </Container>
  );
}

export function PrimaryLoadingButton({loading, children, ...props}) {
  const loadingContent = (
    <div
      style={{
        display: "flex",
        columnGap: "8px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader />
      {props.loadingLabel ? <span>{props.loadingLabel}</span> : null}
    </div>
  );

  return (
    <PrimaryButton
      {...props}
      disabled={loading || props.disabled}
      label={loading ? loadingContent : props.label}
    />
  );
}

const styles = primaryButtonStyles;

const Container = styled.div`
  display: block;
  width: 100%;
`;
