import React from "react";
import DealsListHeader from "@ui/components/DealsList/DealsListHeader";

import {
  dealsListStyles,
  noResultsPlaceholderStyles,
} from "@themes/default/components/DealsList/styles";
import useTheme from "@mock/hooks/useTheme";
import styled from "styled-components";
import MultiRowCardList from "@ui/components/MultiRowCardList";
import Loader from "@ui/components/Spinner";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import {primaryButtonStyles} from "@themes/default/components/Button/styles";
import Button from "ui/src/components/Button";

export default function DefaultDealsList(props) {
  const theme = useTheme();
  const {DealCard} = useThemeComponents();
  const {deals, site} = props;

  return (
    <div>
      <MultiRowCardList
        loading={deals.loading}
        skeleton={deals.skeleton}
        styles={dealsListStyles(theme, site)}
        noResultsPlaceholderProps={{
          styles: noResultsPlaceholderStyles(theme, site),
          ...props.noResultsPlaceholderProps,
        }}
        LoadingComponent={CustomLoader}
        entryCardProp={"deal"}
        CardComponent={DealCard}
        entries={deals.noResults ? null : deals.data}
        HeaderComponent={
          <DealsListHeader title={deals.title} description={deals.description} />
        }
      />
      {deals.hasMore && !deals.skeleton && (
        <ButtonContainer>
          <Button
            variant="primary"
            size="large"
            loading={deals.loading}
            onClick={deals.loadMore}
            styles={primaryButtonStyles(theme)}
            label={"Load More"}
          />
        </ButtonContainer>
      )}
    </div>
  );
}

const ButtonContainer = styled.div`
  margin: 24px auto 0 auto;
  width: 200px;
`;
const CustomLoader = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
);
const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
