export class MemoryStorage {
  constructor() {
    this.map = {};
  }

  getItem(key) {
    return this.map[key];
  }

  setItem(key, value) {
    this.map[key] = value;
  }

  removeItem(key) {
    this.map[key] = undefined;
    delete this.map[key];
  }
}

export default new MemoryStorage();
