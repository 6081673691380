import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Plus} from "@styled-icons/boxicons-regular/Plus";
import {Minus} from "@styled-icons/boxicons-regular/Minus";
import Collapsible from "../../Collapsible";

export default function Filter({
  onChange,
  name,
  value,
  disabled,
  hideIcon,
  children,
  collapseFilters,
  color,
  styles,
}) {
  const _onChange = (...args) => {
    if (!disabled) {
      onChange(...args);
    }
  };

  return (
    <FilterContainer>
      <Collapsible defaultCollapsed={collapseFilters}>
        {({isCollapsed, toggle}) => (
          <>
            <FilterHeader onClick={toggle}>
              <FilterTitleCounterContainer>
                <FilterTitle styles={styles?.title}>{name}</FilterTitle>
              </FilterTitleCounterContainer>
              {hideIcon ? (
                <></>
              ) : isCollapsed ? (
                <PlusIcon data-cy="expandFilterGroup" />
              ) : (
                <MinusIcon />
              )}
            </FilterHeader>
            {!isCollapsed &&
              children({
                value,
                disabled,
                onChange: _onChange,
                color,
              })}
          </>
        )}
      </Collapsible>
    </FilterContainer>
  );
}

const PlusIcon = styled(Plus)`
  height: 20px;
  width: 20px;
`;

const MinusIcon = styled(Minus)`
  height: 20px;
  width: 20px;
`;

const FilterContainer = styled.div`
  padding: ${({theme}) => theme.v2.spacing(4)} 0;
  user-select: none;
  cursor: pointer;
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterTitleCounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-size: ${({theme}) => theme.v2.typography.sizing.xxl.md};
  color: ${({styles}) => styles?.color};
`;

const defaultStyles = {};

Filter.defaultProps = {
  onChange: () => {},
  name: "",
  collapseFilters: false,
  styles: defaultStyles,
};

Filter.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  hideIcon: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  collapseFilters: PropTypes.bool,
  color: PropTypes.string,
  styles: PropTypes.shape({}),
};
