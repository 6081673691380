export default class ThemeConfig {
  constructor(themeObj) {
    this.themeObj = themeObj;
  }

  get components() {
    return this.themeObj.components;
  }

  get pages() {
    return this.themeObj.pages;
  }

  get pageOptions() {
    return this.themeObj.options.pages;
  }

  get options() {
    return this.themeObj.options;
  }

  isDisabled(pageName) {
    if (this.pageOptions) {
      return !!this.pageOptions[pageName]?.disabled;
    } else {
      return false;
    }
  }
}
