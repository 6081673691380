import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import {useFiltersCatalogResource} from "src/state/hooks/useAsyncResource";
import {useEffect, useState} from "react";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import {EventTypes} from "src/events/constants";
import {subscribeEvent} from "src/events/utils";

let globalForceUpdate = 1; // TODO: remove and remove recoil
function useFiltersCatalog(props = {}) {
  const {data} = useServerContext();
  const [deliveryType] = useDeliveryType();

  const [forceUpdate, setForceUpdate] = useState(globalForceUpdate);

  const {data: newCatalog, meta} = useFiltersCatalogResource({
    ...props,
    forceUpdate,
    filters: clearFilters(props.filters),
    deliveryType,
  });

  const [catalog, setCatalog] = useState(
    data[PrefetchedDataKeys.FILTERS_CATALOG] || newCatalog
  );

  useEffect(() => {
    if (newCatalog) setCatalog(newCatalog);
  }, [newCatalog]);

  useEffect(() => {
    return subscribeEvent(EventTypes.STORE_CHANGED, () => {
      setForceUpdate(globalForceUpdate++);
    });
  }, []);

  return [catalog, meta];
}

const clearFilters = filters => {
  if (!filters) return null;
  return {...(filters.serialize ? filters.serialize() : filters), order: null};
};

export default useFiltersCatalog;
