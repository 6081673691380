import {NullPaymentSource} from "src/core/payments/models/paymentSource";
import PaymentServices from "src/core/payments/factories/registry";

export function makePaymentSource(sourceObj, sourceType) {
  if (!PaymentServices[sourceType]?.PaymentSource) return new NullPaymentSource();

  const {class: PaymentSourceClass, options} = PaymentServices[sourceType].PaymentSource;

  return new PaymentSourceClass(sourceObj, sourceType, options);
}
