import React, {startTransition, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import Head from "next/head";
import "react-smartbanner/dist/main.css";
import {addImgixParams} from "@ui";
import {isClient} from "src/server/utils/isClient";
import useSite from "src/core/sites/hooks/useSite";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";
import {isAndroid, isIOS, isMobile} from "src/core/common/utils";
import AndroidSmartBanner from "src/integrations/mobileApp/AndroidSmartBanner";
import {useShop} from "src/core/shops";

export default function SmartBanner() {
  const [shop] = useShop();
  const options = useSiteOptions();
  const site = useSite();

  const shopName = shop.data?.getGroupName();
  const appStoreUrl = options.appStoreUrl();
  const playStoreUrl = options.googlePlayUrl();
  const logo = site.getLogoUrl();
  const appLogo = addImgixParams(logo, {width: 80, height: 80}, 3, "clip")?.href;

  const showIOSSmartBanner = () => {
    return (
      (!isClient || (isMobile() && isIOS())) &&
      options.iosShowSmartBanner() &&
      appStoreUrl
    );
  };

  const [showAndroidSmartBanner, setShowAndroidSmartBanner] = useState(false);
  const updateShowAndroidSmartBanner = () => {
    setShowAndroidSmartBanner(
      isClient &&
        isMobile() &&
        isAndroid() &&
        options.androidShowSmartBanner() &&
        playStoreUrl
    );
  };

  useEffect(() => {
    startTransition(updateShowAndroidSmartBanner);
  }, []);

  if (showIOSSmartBanner()) {
    return (
      <Head>
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,maximum-scale=1"
        />
        <meta name="apple-itunes-app" content={`app-id=${options.appStoreAppId()}`} />
        <link rel="apple-touch-icon" href={appLogo} />
      </Head>
    );
  }

  if (showAndroidSmartBanner) {
    return createPortal(
      <AndroidSmartBanner
        appInfo={{
          imageUrl: appLogo,
          name: shopName,
          linkButtonText: "VIEW",
          linkUrl: playStoreUrl,
        }}
      />,
      document.body
    );
  }

  return null;
}
