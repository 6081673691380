import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "./reducers";
import once from "lodash/once";
import loggerMiddleware from "src/core/common/loggerMiddleware";
import {initialized} from "src/core/authentication/state/actions";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware, loggerMiddleware));

export const initStore = once(async () => {
  return import("./sagas").then(Sagas => {
    sagaMiddleware.run(Sagas.loginSaga);
    sagaMiddleware.run(Sagas.registerSaga);
    sagaMiddleware.run(Sagas.loadUserProfileSaga);
    sagaMiddleware.run(Sagas.loadUserSaga);
    sagaMiddleware.run(Sagas.recoverPasswordSaga);
    sagaMiddleware.run(Sagas.resetPasswordSaga);
    sagaMiddleware.run(Sagas.changePasswordSaga);
    sagaMiddleware.run(Sagas.updateUserSaga);
    sagaMiddleware.run(Sagas.deactivateUserSaga);
    sagaMiddleware.run(Sagas.accountVerificationSaga);
    sagaMiddleware.run(Sagas.accountVerificationCheckSaga);
    sagaMiddleware.run(Sagas.logoutSaga);

    return () => {
      store.dispatch(initialized());
    };
  });
});

export default store;
