import useSite from "src/core/sites/hooks/useSite";
import useThemeConfig from "src/themes/useThemeConfig";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useDeals from "@deals/hooks/useDeals";
import {useEffect} from "react";
import useDealsPageInfo from "@deals/hooks/useDealsPageInfo";
import routes from "src/core/common/routes";
import useRouter from "src/core/common/hooks/useRouter";
import usePrefetchedDeals from "@menu/hooks/usePrefetchedDeals";

export default function useShowcaseDeals() {
  const config = useThemeConfig();
  const toggles = useFeatureToggles();

  const {
    fetchDeals,
    state: {deals, loading},
  } = useDeals();

  const {prefetchedDeals, prefetchedMeta} = usePrefetchedDeals();
  const dealsToUse = prefetchedDeals || deals;

  const dealsLoading = prefetchedMeta?.loading ?? loading;

  useEffect(() => {
    if (deals.length === 0 && !prefetchedDeals?.length) {
      fetchDeals();
    }
  }, []);

  const site = useSite();

  const dealsPageInfo = useDealsPageInfo();

  const {shop} = useRouter().query;

  return {
    deals: dealsToUse,
    site,
    loading: dealsLoading,
    showDealsList: toggles.showDealsList() && (dealsLoading || dealsToUse.length > 0),
    DealCardComponent: config.components.DealCard,
    allDealsLink: {pathname: routes.deals, params: shop},
    ...dealsPageInfo,
  };
}
