import autoBind from "auto-bind";
import * as PaymentsAPI from "src/core/api/payments";
import {isClient} from "src/server/utils/isClient";
import OnlinePayment from "src/core/payments/OnlinePayment";
import * as Notifications from "src/core/notifications";
import {ErrorMessages, PaymentOptions} from "src/core/payments/constants";

class Merrco extends OnlinePayment {
  constructor(code) {
    super(code);
    autoBind(this);
  }

  async getConfiguration() {
    const config = await PaymentsAPI.getIntegrationConfiguration(PaymentOptions.MERRCO);
    return {
      publicKey: config.data.attributes.public_key,
    };
  }

  async setupMerrco() {
    if (isClient) {
      const isSetup = Boolean(window.PayfirmaCardEncryption);

      if (!isSetup) {
        let script = document.createElement("script");
        script.defer = true;
        script.src = `https://www.payfirma.com/payfirma-card-encryption-min.js`;
        document.body.appendChild(script);
      }
    }
  }

  async encryptToken(cardNumber, cardMonth, cardYear, cardCvc) {
    const {publicKey} = await this.getConfiguration();

    try {
      return window.PayfirmaCardEncryption(
        publicKey,
        cardNumber,
        cardMonth,
        cardYear,
        cardCvc
      );
    } catch (e) {
      throw ErrorMessages.FAILED_TOKEN_GENERATION;
    }
  }

  async pay(orderId, paymentData) {
    const {payment_specification} = await this.preparePayment({}, paymentData);
    try {
      await this.doPay(orderId, payment_specification);
    } catch (e) {
      await this.maybeHandle(payment_specification, e);
    }
  }

  doPay = (orderId, paymentSource) => {
    return PaymentsAPI.pay(PaymentOptions.MERRCO, orderId, paymentSource);
  };

  hasPaymentData(paymentData) {
    return paymentData?.source || paymentData?.cardNumber;
  }
  async preparePayment(orderInfo, paymentData) {
    if (!this.hasPaymentData(paymentData)) console.log("Missing payment information");
    let result;
    try {
      if (paymentData?.source?.id) {
        result = {
          ...orderInfo,
          payment_specification: {
            payment_source_id: paymentData.source.id,
          },
        };
      } else {
        const cardData = paymentData.source || paymentData;
        const number = cardData.cardNumber.replace(/ /g, "");
        const cvc = cardData.cardCvc.slice(0, 4);
        const date =
          paymentData.expirationDate?.slice(0, 5) ||
          `${paymentData.source.cardMonth}/${paymentData.source.cardYear}`;
        const splitDate = date.split("/");
        const token = await this.encryptToken(
          number,
          splitDate[0], //month
          splitDate[1], //year
          cvc
        );

        result = {
          ...orderInfo,
          payment_specification: {
            payment_source_token: token || null,
            postal_code: cardData.cardPostalCode,
          },
        };
      }
    } catch (e) {
      if (typeof e === "string") {
        Notifications.error(e);
      }
      throw e;
    }

    this.maybeAttachTip(result, paymentData);
    this.maybeAttachFailPayment(result);
    return result;
  }

  async addAccount(paymentData) {
    const {payment_specification} = await this.preparePayment({}, paymentData);
    try {
      await PaymentsAPI.addPaymentSource(PaymentOptions.MERRCO, payment_specification);
    } catch (e) {
      await this.maybeHandle(payment_specification, e);
    }
  }

  async removeAccount(paymentSource) {
    await PaymentsAPI.removePaymentSource(
      PaymentOptions.MERRCO,
      paymentSource.internalId
    );
    this.notify("removed-account-success", paymentSource);
  }

  async setDefaultAccount(paymentSource) {
    await PaymentsAPI.markAsDefaultPaymentSource(
      PaymentOptions.MERRCO,
      paymentSource.internalId
    );
    this.notify("changed-default-account-success", paymentSource);
  }
}

export default Merrco;
