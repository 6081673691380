import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import Input from "@ui/components/Inputs";

const MINIMUM_CUSTOM_TIP = {
  amount: 100,
  amountDisplay: "$1.00",
};

const getAbsoluteValue = value => {
  return value?.replace(/[^0-9]/g, "");
};

export default function CheckoutTips({
  disabled,
  headerLabel,
  defaultTips,
  selectTip,
  isTipSelected,
  onApplyCustomTip,
  footNote,
  selectedTip,
  customPriceFormatter,
  styles,
}) {
  const [customTipValue, setCustomTipValue] = useState(null);

  const _styles = merge({}, defaultStyles, styles);

  const onSelectDefaultTipHandler = tip => {
    selectTip(tip);
    setCustomTipValue("");
  };

  const onChangeCustomTip = evt => {
    const value = evt.target.value;
    let absoluteValue = getAbsoluteValue(value);

    setCustomTipValue(
      customPriceFormatter(
        {currency: "usd", amount: absoluteValue},
        {includeFractionDigits: true}
      )
    );
  };

  const defaultTip = defaultTips.find(tip => tip.percentage === selectedTip?.percentage);

  useEffect(() => {
    if (defaultTip && selectedTip?.amount !== defaultTip?.percentage) {
      selectTip(defaultTip);
    }
  }, [defaultTip, selectedTip]);

  const hasTip = selectedTip?.amount !== 0;

  const onSetCustomTip = () => {
    if (selectedTip?.custom) {
      selectTip({
        amount: 0,
        amountDisplay: 0,
        percentage: "0%",
      });
      setCustomTipValue("");
    } else {
      let absoluteValue = getAbsoluteValue(customTipValue);
      onApplyCustomTip({amount: absoluteValue, amountDisplay: customTipValue});
    }
  };

  return (
    <Container styles={_styles.root}>
      <Header styles={_styles.header}>{headerLabel}</Header>
      <Body styles={_styles.body}>
        <DefaultTips styles={_styles.tips}>
          {defaultTips.map(tip => (
            <Tip
              disabled={disabled}
              key={tip.percentage}
              isSelected={isTipSelected(tip)}
              onClick={disabled ? null : () => onSelectDefaultTipHandler(tip)}
              styles={_styles.tips}
            >
              {tip.amount === 0 ? (
                <>
                  <None styles={_styles.tips.noTip}>None</None>
                </>
              ) : (
                <>
                  <Percentage styles={_styles.tips.percentage}>
                    {tip.percentage}
                  </Percentage>
                  <Amount styles={_styles.tips.amount}>{tip.amountDisplay}</Amount>
                </>
              )}
            </Tip>
          ))}
        </DefaultTips>
        <BottomContainer>
          <CustomTip
            disabled={disabled || selectedTip?.custom}
            value={customTipValue}
            isSelected={selectedTip?.custom}
            placeholder={`Define a custom value of at least ${MINIMUM_CUSTOM_TIP.amountDisplay}`}
            onChange={onChangeCustomTip}
            styles={_styles.tips.customTip}
          />
          <ApplyTip
            disabled={
              disabled ||
              !customTipValue ||
              customTipValue === "$0.00" ||
              getAbsoluteValue(customTipValue) < MINIMUM_CUSTOM_TIP.amount
            }
            type="button"
            onClick={onSetCustomTip}
            styles={_styles.applyButton}
          >
            {selectedTip?.custom ? "Remove tip" : "Set tip"}
          </ApplyTip>
        </BottomContainer>
        {hasTip && <FootNote styles={_styles.footNote}>{footNote}</FootNote>}
      </Body>
    </Container>
  );
}

const Container = styled.div`
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
`;

const Header = styled.div`
  border-bottom: ${({styles}) => styles.border};
  padding: ${({styles}) => styles.padding};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: ${({styles}) => styles.backgroundColor};
  padding: ${({styles}) => styles.padding};
`;

const DefaultTips = styled.div`
  display: flex;
  justify-content: space-around;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  background-color: ${({styles}) => styles.backgroundColor};
`;

const Tip = styled.div`
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: ${({isSelected, styles}) => isSelected && styles.selectedBorder};
  padding: ${({styles}) => styles.padding};

  :not(:last-of-type) {
    border-right: ${({isSelected, styles}) => !isSelected && styles.border};
  }

  :first-of-type {
    border-top-left-radius: ${({isSelected, styles}) =>
      isSelected && styles.borderRadius};
    border-bottom-left-radius: ${({isSelected, styles}) =>
      isSelected && styles.borderRadius};
  }

  :last-of-type {
    border-top-right-radius: ${({isSelected, styles}) =>
      isSelected && styles.borderRadius};
    border-bottom-right-radius: ${({isSelected, styles}) =>
      isSelected && styles.borderRadius};
  }
`;

const Percentage = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Amount = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const None = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 14px;
`;

const CustomTip = styled(Input)`
  background-color: ${({styles}) => styles.backgroundColor};
  width: 100%;
  border-radius: ${({styles}) => styles.borderRadius};
  padding: ${({styles}) => styles.padding};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  border: ${({isSelected, styles}) =>
    isSelected ? styles.selectedBorder : styles.border};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ApplyTip = styled.button`
  white-space: nowrap;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({disabled}) => disabled && 0.65};
  border: none;
  background-color: ${({styles}) => styles.backgroundColor};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  border-radius: ${({styles}) => styles.borderRadius};
  padding: ${({styles}) => styles.padding};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const FootNote = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  root: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
  },
  header: {
    border: "1px solid #D9D9D9",
    padding: "20px",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#545454",
  },
  body: {
    backgroundColor: "#F5F5F5",
    padding: "20px",
  },
  tips: {
    border: "1px solid #D9D9D9",
    selectedBorder: "2px solid #000",
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: "12px",
    percentage: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#000",
    },
    amount: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "12px",
        md: "12px",
        sm: "12px",
      },
      fontWeight: 400,
      color: "#737373",
    },
    noTip: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#000",
    },
    customTip: {
      border: "1px solid #D9D9D9",
      selectedBorder: "2px solid #000",
      borderRadius: "5px",
      backgroundColor: "#fff",
      padding: "12px",
      fontFamily: "sans-serif",
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      fontWeight: 500,
      color: "#737373",
    },
  },
  applyButton: {
    backgroundColor: "#000",
    borderRadius: "5px",
    padding: "12px 20px",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#fff",
  },
  footNote: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#545454",
  },
};

CheckoutTips.defaultProps = {
  defaultTips: [],
  noTip: {
    onClick: () => {},
  },
  customTip: {
    onChange: () => {},
  },
  styles: defaultStyles,
};

CheckoutTips.propTypes = {
  headerLabel: PropTypes.string,
  defaultTips: PropTypes.arrayOf(
    PropTypes.shape({
      percentage: PropTypes.string,
      amount: PropTypes.string,
      isSelected: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  noTip: PropTypes.shape({
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  customTip: PropTypes.shape({
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
  }),
  onApply: PropTypes.func,
  footNote: PropTypes.string,
  styles: PropTypes.shape({}),
  selectedTip: PropTypes.shape({
    value: PropTypes.number,
    type: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  customPriceFormatter: PropTypes.func,
};
