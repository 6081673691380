import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from "redux-saga";

import {reducer} from "./reducers";
import {
  watchGroupProducts,
  watchProductGroups,
  watchProducts,
  watchSearch,
  watchSearchPreview,
} from "./sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// create a Redux store with our reducer and the saga middleware
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

// run the saga
sagaMiddleware.run(watchSearchPreview);
sagaMiddleware.run(watchSearch);
sagaMiddleware.run(watchProductGroups);
sagaMiddleware.run(watchGroupProducts);
sagaMiddleware.run(watchProducts);

export default store;
