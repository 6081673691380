import React from "react";
import {NewDeliveryBanner} from "@ui";
import styled from "styled-components";
import deliveryBannerStyles, {
  deliveryModePickerStyles,
} from "@themes/default/components/DeliveryBanner/styles";
import useTheme from "@mock/hooks/useTheme.js";
import useDeliveryBanner from "@mock/hooks/useDeliveryBanner.js";

function DeliveryBanner() {
  const theme = useTheme();

  const {site, displayDeliveryBanner, deliveryModePickerProps, ...deliveryBannerProps} =
    useDeliveryBanner();

  return (
    <Container>
      {displayDeliveryBanner && (
        <NewDeliveryBanner
          {...deliveryBannerProps}
          deliveryModePickerProps={{
            ...deliveryModePickerProps,
            styles: deliveryModePickerStyles(theme, site),
          }}
          fixed={true}
          styles={deliveryBannerStyles(theme, site)}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 70px;
`;

export default DeliveryBanner;
