import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media, {getCurrentDim} from "@ui/utils/media";
import {MinusIcon, PlusIcon, TrashIcon} from "@ui/components/Icons";
import QuantityPicker from "./QuantityPicker";
import QuantityPickerWithPrice from "./QuantityPickerWithPrice";
import WeightSelector from "./WeightSelector";
import FixedQuantityPicker from "@ui/components/ProductDetail/FixedQuantityPicker";
import GiftCardSelector from "@ui/components/ProductDetail/GiftCardSelector";

export default function BigQuantityPicker({
  styles,
  hasPriceDisplay,
  isInCart,
  loading,
  quantity,
  weight,
  isProductInStock,
  isProductValid,
  isWeightedProduct,
  IncreaseIconComponent,
  DecreaseIconComponent,
  RemoveIconComponent,
  pricePerUnit,
  savings,
  weights,
  isFixedToBottomOnMobile,
  onIncreaseQuantityHandler,
  onDecreaseQuantityHandler,
  onChangeQuantityHandler,
  onRemoveItemFromCartHandler,
  onChangeWeightHandler,
  onAddToCartHandler,
  cartIcon,
  addToCartText,
  quantityText,
  withBorder,
  allowQuantityChange,
  type,
  handleCustomAmountChange,
  ...props
}) {
  const _styles = merge({}, styles, props.styles);

  const commonProps = {
    styles: _styles,
    hasPriceDisplay,
    savings,
    isInCart,
    isProductInStock,
    isProductValid,
    isWeightedProduct,
    loading,
    quantity,
    onIncreaseQuantityHandler,
    onDecreaseQuantityHandler,
    onRemoveItemFromCartHandler,
    onChangeQuantityHandler: e => onChangeQuantityHandler(e.target.value),
    onAddToCartHandler,
    IncreaseIconComponent,
    DecreaseIconComponent,
    RemoveIconComponent,
    cartIcon,
    addToCartText,
    quantityText,
    allowQuantityChange,
    withBorder,
  };

  const [mobileFlag, setMobileFlag] = useState(true);

  useEffect(() => {
    const resizeListener = () => {
      setMobileFlag(getCurrentDim() === "sm");
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  const content = (
    <Container
      styles={_styles.root}
      isWeightedProduct={isWeightedProduct}
      isFixedToBottomOnMobile={isFixedToBottomOnMobile}
      hasPriceDisplay={hasPriceDisplay}
    >
      {isWeightedProduct && type !== "gift_card" ? (
        <WeightSelector
          styles={_styles.weightSelector}
          options={weights}
          value={weight}
          onChange={onChangeWeightHandler}
          isMobile={mobileFlag}
        />
      ) : (
        type === "gift_card" && (
          <GiftCardSelector
            styles={_styles.giftCardSelector}
            options={weights}
            value={weight}
            onChange={onChangeWeightHandler}
            handleCustomAmountChange={handleCustomAmountChange}
          />
        )
      )}
      {!hasPriceDisplay ? (
        <QuantityPicker {...commonProps} />
      ) : (
        <QuantityPickerWithPrice pricePerUnit={pricePerUnit} {...commonProps} />
      )}
    </Container>
  );

  return (
    <>
      <InlineContainer isFixedToBottomOnMobile={isFixedToBottomOnMobile}>
        {content}
      </InlineContainer>
      {isFixedToBottomOnMobile && (
        <FixedQuantityPicker styles={_styles.fixedToBottomContainer}>
          {content}
        </FixedQuantityPicker>
      )}
    </>
  );
}

const Container = styled.div.attrs(() => ({
  className: "big-quantity-picker big-quantity-picker__container",
}))`
  box-sizing: border-box;
  display: flex;
  height: 100%;
  flex-direction: ${({hasPriceDisplay, isWeightedProduct}) =>
    hasPriceDisplay ? "column" : isWeightedProduct ? "row" : "column"};
  align-items: ${({hasPriceDisplay, isWeightedProduct}) =>
    hasPriceDisplay ? "flex-start" : isWeightedProduct ? "center" : "flex-start"};
  max-width: ${({isWeightedProduct}) => (isWeightedProduct ? "90vw" : "100vw")};
  column-gap: ${({isWeightedProduct}) => isWeightedProduct && "8px"};

  ${media.up("lg")} {
    width: ${({styles}) => styles.width.lg};
    padding: ${({styles}) => styles.padding.lg};
    height: ${({styles, isWeightedProduct}) =>
      isWeightedProduct ? "160px" : styles?.height?.lg};
  }

  ${media.down("md")} {
    width: ${({styles}) => styles.width.md};
    padding: ${({styles}) => styles.padding.md};
    height: ${({styles, isWeightedProduct}) =>
      isWeightedProduct ? "160px" : styles?.height?.md};
  }

  ${media.down("sm")} {
    width: ${({styles}) => styles.width.sm};
    padding: ${({styles}) => styles.padding.sm};

    align-items: ${({isWeightedProduct, isFixedToBottomOnMobile, hasPriceDisplay}) =>
      !isFixedToBottomOnMobile
        ? "flex-start"
        : hasPriceDisplay
        ? "flex-start"
        : isWeightedProduct
        ? "center"
        : "flex-start"};
    flex-direction: ${({isWeightedProduct, isFixedToBottomOnMobile, hasPriceDisplay}) =>
      !isFixedToBottomOnMobile
        ? "column"
        : hasPriceDisplay
        ? "column"
        : isWeightedProduct
        ? "row"
        : "column"};
  }

  > * {
    font-family: ${({styles}) => styles.fontFamily};
  }
`;

const InlineContainer = styled.div`
  ${media.down("sm")} {
    display: ${({isFixedToBottomOnMobile}) => isFixedToBottomOnMobile && "none"};
  }
`;

BigQuantityPicker.propTypes = {
  styles: PropTypes.shape({
    root: {
      fontFamily: PropTypes.string,
      flexDirection: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    },
    quantityPicker: {
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      borderRadius: PropTypes.string,
      quantitySize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      minusIconSize: PropTypes.string,
      plusIconSize: PropTypes.string,
    },
    addToCartButton: {
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      padding: PropTypes.string,
      inCartIconSize: PropTypes.string,
    },
    price: {
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
    },
    savings: {
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    },
    weightSelector: {
      borderRadius: PropTypes.string,
      fontSize: PropTypes.string,
      color: PropTypes.string,
    },
    fixedToBottomContainer: {
      background: PropTypes.string,
    },
  }),
  hasPriceDisplay: PropTypes.bool,
  isInCart: PropTypes.bool,
  loading: PropTypes.bool,
  quantity: PropTypes.number,
  weights: PropTypes.array,
  weight: PropTypes.object,
  isProductInStock: PropTypes.bool,
  isProductValid: PropTypes.bool,
  isWeightedProduct: PropTypes.bool,
  isFixedToBottomOnMobile: PropTypes.bool,
  IncreaseIconComponent: PropTypes.elementType,
  DecreaseIconComponent: PropTypes.elementType,
  RemoveIconComponent: PropTypes.elementType,
  pricePerUnit: PropTypes.string,
  savings: PropTypes.string,
  onIncreaseQuantityHandler: PropTypes.func,
  onDecreaseQuantityHandler: PropTypes.func,
  onChangeQuantityHandler: PropTypes.func,
  onRemoveItemFromCartHandler: PropTypes.func,
  onChangeWeightHandler: PropTypes.func,
  onAddToCartHandler: PropTypes.func,
  cartIcon: PropTypes.string,
  addToCartText: PropTypes.string,
  quantityText: PropTypes.bool,
  withBorder: PropTypes.bool,
  type: PropTypes.string,
  handleCustomAmountChange: PropTypes.func,
};

BigQuantityPicker.defaultProps = {
  IncreaseIconComponent: PlusIcon,
  DecreaseIconComponent: MinusIcon,
  RemoveIconComponent: TrashIcon,
};
