import TymberAPIConfig from "../api/config";
import {makeJsonAPIPayload, v1, v3, v4} from "../api/utils";

const Endpoints = {
  v1: {
    scheduleTimeSlots: deliveryType => v1(`store/availabilities/${deliveryType}/`),
  },
  v3: {
    verifyAddress: () => v3(`deliveries/stores`),
  },
  v4: {
    verifyAddress: () => v4(`deliveries/stores`),
  },
};

export function verifyAddress({address, mode}, options = {}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("addresses", {
    address: {
      address: address.address,
      address_line2: address.addressLine2,
      city: address.city,
      country: address.country,
      state: address.state,
      zip_code: address.zipCode,
      lat: address.latitude,
      lng: address.longitude,
    },
    mode: mode,
  });

  const endpoint =
    mode === null && !options.useRegionGeoZonesRestrictions
      ? Endpoints.v3.verifyAddress()
      : Endpoints.v4.verifyAddress();
  return axios.post(endpoint, jsonApiPayload).then(response => response.data);
}

export function scheduleTimeSlots({deliveryType, ...params}) {
  const axios = TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.scheduleTimeSlots(deliveryType), {params})
    .then(response => response.data);
}
