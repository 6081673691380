import React from "react";
import styled from "styled-components";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import range from "lodash/range";
import PropTypes from "prop-types";
import merge from "lodash/merge";

function HorizontalCardList({
  className,
  skeleton,
  styles,
  maxEntries,
  entries,
  renderCard,
  showLoader = false,
  dim,
  LoadingComponent,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <div>
      <List styles={_styles.list} className={className}>
        {!skeleton &&
          entries.slice(0, maxEntries).map((element, index) => (
            <ListElement
              key={`entry-${index}`}
              maxEntries={maxEntries}
              styles={{
                smFlexDirection: _styles.list.flexDirection.sm,
                ..._styles.element,
              }}
            >
              {renderCard({element, dim, isFirst: index === 0})}
            </ListElement>
          ))}
        {showLoader ? LoadingComponent : null}
        {skeleton &&
          range(maxEntries).map(n => (
            <ListElement
              key={`entry-${n}`}
              maxEntries={maxEntries}
              styles={{
                smFlexDirection: _styles.list.flexDirection.sm,
                ..._styles.element,
              }}
            >
              {renderCard({skeleton})}
            </ListElement>
          ))}
      </List>
    </div>
  );
}

export const List = styled.ul.attrs(() => ({
  className: "simple-card-list simple-card-list__list",
}))`
  width: 100%;

  list-style-type: none;
  margin: -${spacing(2)};
  padding: ${({styles}) => styles.padding};

  display: flex;
  justify-content: ${({styles}) => styles.justifyContent};
  align-items: center;

  column-gap: ${spacing(2)};
  row-gap: ${spacing(2)};

  overflow-x: auto;
  flex-direction: ${({styles}) => styles.flexDirection.lg};

  ${media.down("md")} {
    flex-direction: ${({styles}) => styles.flexDirection.md};
  }

  ${media.down("sm")} {
    justify-content: ${({styles}) =>
      styles.justifyContentMobile || styles.justifyContent};
    flex-direction: ${({styles}) => styles.flexDirection.sm};
    column-gap: ${({styles}) => styles.columnGap};
  }
`;
export const ListElement = styled.li.attrs(() => ({
  className: "horizontal-card-list horizontal-card-list__element",
}))`
  width: calc((100% / ${({maxEntries}) => maxEntries}) - ${spacing(2)});

  > a {
    text-decoration: none;
  }

  ${media.up("lg")} {
    max-width: ${({styles}) => styles.maxWidth.lg};
    min-width: ${({styles}) => styles.minWidth.lg};
  }

  ${media.down("md")} {
    max-width: ${({styles}) => styles.maxWidth.md};
    min-width: ${({styles}) => styles.minWidth.md};
  }

  ${media.down("sm")} {
    max-width: ${({styles}) => styles.maxWidth.sm};
    min-width: ${({styles}) => styles.minWidth.sm};
    box-sizing: border-box;
    width: ${({styles}) => styles.smFlexDirection === "column" && "100%"};
  }
`;

const defaultStyles = {
  list: {
    flexDirection: {
      lg: "row",
      md: "row",
      sm: "row",
    },
    padding: spacing(2),
    columnGap: spacing(0.75),
  },
};

HorizontalCardList.defaultProps = {
  styles: defaultStyles,
};

HorizontalCardList.propTypes = {
  className: PropTypes.string,
  skeleton: PropTypes.bool,
  styles: PropTypes.object,
  maxEntries: PropTypes.number,
  entries: PropTypes.array,
  dim: PropTypes.string,
  renderCard: PropTypes.func,
  showLoader: PropTypes.bool,
  LoadingComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
};

export default HorizontalCardList;
