import {makeThemeProps} from "src/themes/utils/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getShowcasedProductListProps(theme);
};

export function viewAllLinkButtonStyles(theme, site) {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getViewAllLinkProps(theme, site);
}
