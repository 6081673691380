import React, {useCallback, useEffect, useState} from "react";
import {useRefresh} from "src/core/common/hooks/useRefresh";
import {storeSwitcher} from "src/core/common/services/storeSwitcher";
import {SiteEnv} from "src/core/common/models/siteEnv";
import Tymber from "src/apps/common/tymber";

export const AppLoaderContext = React.createContext(null);

export function AppLoaderProvider({children, site, app}) {
  const [currentApp, setCurrentApp] = useState(app);
  const _refresh = useRefresh();

  const switchStore = useCallback(
    storeId => {
      const {env} = currentApp;
      storeSwitcher.saveStore(storeId);
      if (storeId === env.getEcommerceId()) return;
      const newEnv = SiteEnv.fromPrototype(env, {REACT_APP_TYMBER_ECOMMERCE_ID: storeId});
      const newApp = new Tymber();
      site.setEnv(newEnv);
      newApp.init(newEnv);
      newApp.initClient(site);
      setCurrentApp(newApp);
      _refresh();
    },
    [currentApp, site, _refresh]
  );
  useEffect(() => {
    return storeSwitcher.subscribe(({storeId}) => switchStore(storeId));
  }, [switchStore]);

  useEffect(() => {
    storeSwitcher.init(site.env);
  }, []);

  return (
    <AppLoaderContext.Provider value={{switchStore}}>
      {children}
    </AppLoaderContext.Provider>
  );
}
