import {makeThemeProps} from "@themes/default/props/utils";

export const searchBrowserStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getSearchProps(theme);
};

export const noResultsPlaceholderStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getNoResultsPlaceholderProps(theme);
};
