import React from "react";
import styled from "styled-components";
import ItemsInCart from "src/core/checkout/components/ItemsInCart";
import * as ThemedComponents from "src/themes/utils/themedComponents";
import {useMediaQuery} from "react-responsive";
import useSite from "src/core/sites/hooks/useSite";

function ShoppingCartButton({
  icon,
  width,
  height,
  onClick,
  componentsColor,
  cartButtonColor,
  className,
  showItems = true,
  themeProperties = {},
}) {
  const site = useSite();
  const ShoppingCartIcon = ThemedComponents.get(site.getUiConfiguration().icons.shopping);

  const isDesktop = useMediaQuery({query: "(min-width: 1026px)"});

  return (
    <ItemsInCart
      render={({numberOfItems}) => (
        <ShoppingCartContainer
          className={className}
          hasItems={numberOfItems > 0}
          onClick={onClick}
          cartButtonColor={cartButtonColor}
          themeProperties={themeProperties}
        >
          <ShoppingCartImage
            hasItems={numberOfItems > 0}
            width={width}
            height={height}
            componentsColor={componentsColor}
            themeProperties={themeProperties}
          >
            {themeProperties && themeProperties.showCartText ? (
              <>
                {isDesktop && <CartName hasItems={numberOfItems > 0}>cart</CartName>}
                {!isDesktop && (icon || <ShoppingCartIcon />)}
              </>
            ) : (
              icon || <ShoppingCartIcon />
            )}
          </ShoppingCartImage>
          {showItems && numberOfItems > 0 && (
            <ItemsBadge
              themeProperties={themeProperties}
              componentsColor={componentsColor}
            >
              {numberOfItems}
            </ItemsBadge>
          )}
        </ShoppingCartContainer>
      )}
    />
  );
}

const ShoppingCartContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.6;
  }

  background-color: ${({themeProperties, hasItems, cartButtonColor, theme}) =>
    themeProperties && themeProperties.circularCartIndicator
      ? "transparent"
      : hasItems
      ? cartButtonColor || theme.v2.color.primary
      : "transparent"};
  padding: ${({hasItems, theme, themeProperties}) =>
    themeProperties && themeProperties.cartPadding
      ? themeProperties.cartPadding
      : hasItems
      ? `${theme.v2.spacing(2)} ${theme.v2.spacing(2)}`
      : 0};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};

  @media (max-width: 1025px) {
    padding: ${({hasItems, theme}) =>
      hasItems ? `${theme.v2.spacing(2)} ${theme.v2.spacing(2)}` : 0};
  }
`;

const ShoppingCartImage = styled.div`
  > *:first-child {
    width: ${({width}) => (width ? `${width}px` : "24px")};
    height: ${({height}) => (height ? `${height}px` : "24px")};
    color: ${({themeProperties, hasItems, componentsColor}) =>
      themeProperties && themeProperties.circularCartIndicator
        ? "#333333"
        : hasItems
        ? componentsColor || "#fff"
        : componentsColor || "#333333"};
  }
`;

const ItemsBadge = styled.div`
  color: ${({componentsColor}) => componentsColor || "#fff"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({theme, themeProperties}) =>
    themeProperties && themeProperties.circularCartIndicator
      ? theme.v2.typography.sizing.m.lg
      : theme.v2.typography.sizing.xl.lg};
  font-weight: 500;
  margin-left: ${({theme}) => theme.v2.spacing(1)};
  width: ${({themeProperties}) =>
    themeProperties && themeProperties.circularCartIndicator ? "20px" : "auto"};
  height: ${({themeProperties}) =>
    themeProperties && themeProperties.circularCartIndicator ? "20px" : "auto"};
  background-color: ${({themeProperties, theme}) =>
    themeProperties && themeProperties.circularCartIndicator
      ? theme.v2.color.primary
      : "inherit"};
  letter-spacing: ${({themeProperties}) =>
    themeProperties && themeProperties.circularCartIndicator ? "normal" : "inherit"};
  margin-bottom: ${({themeProperties}) =>
    themeProperties && themeProperties.circularCartIndicator ? "15px" : "0px"};
`;

const CartName = styled.span`
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
  font-weight: 500;
  color: ${({theme}) => theme.v2.color.onPrimary} !important;
  letter-spacing: 0;
`;

export default ShoppingCartButton;
