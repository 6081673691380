export default class PaymentMethodPromotion {
  constructor(obj) {
    this._obj = obj;
  }

  get title() {
    return this._obj?.attributes?.title;
  }

  get description() {
    return this._obj?.attributes?.description;
  }

  get fixedAmount() {
    return this._obj?.attributes?.fixed_amount;
  }

  get savings() {
    return this._obj?.attributes?.savings;
  }

  get savingsDisplay() {
    return this._obj?.attributes?.savings_display;
  }
}
