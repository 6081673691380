import {useState, useEffect} from "react";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useProductSortingOptions from "src/menu/hooks/useProductSortOptions";
import {useProductsOrderSelector} from "src/menu/hooks/useSortBy";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import {logModuleLoadError} from "src/core/common/utils";
import FiltersModalService from "@menu/services/filtersModalService";
import dynamic from "next/dynamic";
import "react-dropdown/style.css";
import useRouter from "src/core/common/hooks/useRouter";
import useSite from "src/core/sites/hooks/useSite";

const Dropdown = dynamic(() =>
  import("react-dropdown").catch(logModuleLoadError("react-dropdown"))
);

const ProductFiltersWrapper = dynamic(() => import("@menu/components/ProductFilters"));

export default function useSortAndFilterHeader() {
  const site = useSite();
  const router = useRouter();
  const {productId} = router.query;
  const toggles = useFeatureToggles();
  const enabledFilters = toggles.enabledFilters();
  const orderOptions = useProductSortingOptions();
  const {onChangeOrder, currentOrder} = useProductsOrderSelector(orderOptions);
  const [paramFilters] = useAppliedFilters();
  const [filters, setFilters] = useState(paramFilters);
  const filtersCount = filters.getCounters().total;

  useEffect(() => {
    if (!productId && !paramFilters.equals(filters)) {
      setFilters(paramFilters);
    }
  }, [productId, setFilters, paramFilters, filters]);

  const onChangeFilters = () => {
    FiltersModalService.show();
  };

  return {
    site,
    enabledFilters,
    filtersCount,
    onChangeFilters,
    orderOptions,
    currentSortOrder: currentOrder,
    onChangeOrder,
    DropdownComponent: Dropdown,
    FiltersWrapperComponent: ProductFiltersWrapper,
  };
}
