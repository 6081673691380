import {useMemo} from "react";
import makePromotionalBanners from "../../common/models/promotionalBanners";
import {usePromotionalBannersResource} from "src/state/hooks/useAsyncResource";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import useSite from "src/core/sites/hooks/useSite";

export default function usePromotionalBanners() {
  const {data} = useServerContext();
  const {data: currentPromotionalBanners, meta} = usePromotionalBannersResource({
    prefetched: !!data[PrefetchedDataKeys.PROMOTIONAL_BANNERS],
  });
  const promotionalBanners = getPromotionalBanners(data, currentPromotionalBanners);

  const site = useSite();
  const siteUrl = site.getSiteUrl();

  return useMemo(() => {
    let _promotionalBanners;
    if (!promotionalBanners) {
      _promotionalBanners = makePromotionalBanners();
    } else {
      _promotionalBanners = promotionalBanners;
    }
    const bannerInfo = _promotionalBanners.getBannerInfo();
    const banners = parseImages(bannerInfo.images);
    const dimensions = parseDimensions(bannerInfo.dimensions);

    return {
      promotionalBanners: _promotionalBanners,
      meta,
      banners,
      dimensions,
      skeleton: !_promotionalBanners?.hasImages() && meta.loading && !meta.error,
      siteUrl,
    };
  }, [meta, promotionalBanners]);
}

function getPromotionalBanners(prefetchedData, currentData) {
  if (currentData) return currentData;

  const bannersData = prefetchedData[PrefetchedDataKeys.PROMOTIONAL_BANNERS];
  if (bannersData) return makePromotionalBanners(bannersData);

  return null;
}

export const parseDimensions = dimensions => {
  if (dimensions.desktop && dimensions.mobile)
    return {
      lg: {
        height: dimensions.desktop.height,
        width: dimensions.desktop.width,
      },
      md: {
        height: dimensions.desktop.height,
        width: dimensions.desktop.width,
      },
      sm: {
        height: dimensions.mobile.height,
        width: dimensions.mobile.width,
      },
    };
  else return dimensions;
};

export const parseImages = images =>
  images.map(i => {
    return {
      description: i.description,
      link: i.destinationUrl,
      srcMobile: i.mobileUrl,
      srcDesktop: i.desktopUrl,
    };
  });
