import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";

function ExpandableArea({styles, initialExpanded, MobileHeader, Content}) {
  const _styles = merge({}, defaultStyles, styles);

  const [open, setOpen] = useState(initialExpanded || false);

  return (
    <Container styles={_styles.container}>
      <ExpandableHeader onClick={() => setOpen(!open)}>
        {MobileHeader(open)}
      </ExpandableHeader>
      <ExpandableBody open={open}>
        <ExpandableContent styles={_styles.expandableContent}>
          {Content}
        </ExpandableContent>
      </ExpandableBody>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "expandable-area",
}))`
  background: ${({styles}) => styles.background};

  ${media.up("lg")} {
    width: ${({styles}) => styles.width.lg};
    padding: ${({styles}) => styles.padding.lg};

    > div {
      max-width: ${({styles}) => styles.contentMaxWidth.lg};
    }
  }
  ${media.between("md", "lg")} {
    width: ${({styles}) => styles.width.md};
    padding: ${({styles}) => styles.padding.md};

    > div {
      max-width: ${({styles}) => styles.contentMaxWidth.md};
    }
  }
  ${media.down("sm")} {
    width: ${({styles}) => styles.width.sm};
    padding: ${({styles}) => styles.padding.sm};

    > div {
      max-width: ${({styles}) => styles.contentMaxWidth.sm};
    }
  }
`;

const ExpandableHeader = styled.div.attrs(() => ({
  className: "expandable-area__header",
}))`
  ${media.up("md")} {
    display: none;
  }
`;

const ExpandableBody = styled.div.attrs(() => ({
  className: "expandable-area__body",
}))`
  ${media.down("sm")} {
    overflow: hidden;
    max-height: ${({open}) => (open ? "auto" : "0px")};
  }
`;

const ExpandableContent = styled.div.attrs(() => ({
  className: "expandable-area__content",
}))`
  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.between("md", "lg")} {
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const defaultStyles = {
  container: {
    background: "#f5f5f5",
    width: {
      lg: "auto",
      md: "auto",
      sm: "100%",
    },
    padding: {
      sm: "0",
      md: "36px",
      lg: "36px",
    },
    contentMaxWidth: {
      sm: "768px",
      md: "420px",
      lg: "420px",
    },
  },
  expandableContent: {
    padding: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
  },
};

ExpandableArea.defaultProps = {};

ExpandableArea.propTypes = {
  initialExpanded: PropTypes.bool,
  styles: PropTypes.shape({
    container: PropTypes.shape({
      background: PropTypes.string,
      padding: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      contentMaxWidth: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
    }),
    expandableContent: PropTypes.shape({
      padding: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
    }),
  }),
};

export default ExpandableArea;
