import autoBind from "auto-bind";

class Tracker {
  eventHandlers = {};
  constructor() {
    autoBind(this);
  }

  identify(payload) {
    this.validateInitialization();
    this.doIdentify(payload);
  }

  validateInitialization() {
    if (!this.doValidateInitialization()) {
      throw new Error("Didn't initialize target tracker.");
    }
  }

  pageView(payload) {
    this.validateInitialization();
    this.doPageView(payload);
  }

  track(eventName, payload) {
    this.validateInitialization();
    const handlers = this.eventHandlers[eventName];
    if (handlers) {
      for (let i = 0; i < handlers.length; i++) {
        const handler = handlers[i];
        if (handler(eventName, payload)) {
          return;
        }
      }
    }
    try {
      this.doTrack(eventName, payload);
    } catch (e) {
      console.error(e);
    }
  }

  addHandler(eventName, handler) {
    if (this.eventHandlers[eventName]) {
      this.eventHandlers[eventName].push(handler);
    } else {
      this.eventHandlers[eventName] = [handler];
    }
    return this;
  }

  doIdentify(payload) {}
  doTrack(eventName, payload) {}
  doPageView(payload) {}
  doValidateInitialization() {
    return true;
  }
}

export default Tracker;
