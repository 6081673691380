import {Footer as FooterUI} from "@ui";
import React from "react";
import styles from "./styles";
import useTheme from "@mock/hooks/useTheme";
import Link from "@mock/components/modules/Link";
import useFooter from "@mock/hooks/useFooter";

function Footer() {
  const {site, socialLinks, footerPages, version, licenseNumber} = useFooter();
  const logo = site.getLogoUrl();
  const theme = useTheme();
  const _socialLinks = socialLinks.filter(s => s.linkTo);
  const siteName = site.getPoweredByName();
  const showWarning = site.getUiConfiguration().footer?.showWarning;

  return (
    <FooterUI
      logo={logo}
      socialLinks={_socialLinks}
      footerPages={footerPages}
      ExternalLinkComponent={Link}
      version={version}
      licenseNumber={licenseNumber}
      siteName={siteName}
      styles={styles(theme, site)}
      warning={showWarning ? <FooterP65Warning site={site} /> : null}
    />
  );
}

const FooterP65Warning = ({site}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      paddingTop: "10px",
    }}
  >
    <div
      style={{
        width: "990px",
        textAlign: "center",
        fontFamily: "sans-serif",
        fontSize: "14px",
      }}
      dangerouslySetInnerHTML={{
        __html: `${site.getOptions().getCheckoutLegalWarning()}`,
      }}
    />
  </div>
);

export default Footer;
