import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from "redux-saga";

import {reducer} from "./reducers";
import {
  watchOnSaleProducts,
  watchPromotionProducts,
  watchRequestDeal,
  watchRequestDeals,
} from "./sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// create a Redux store with our reducer and the saga middleware
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

// run the saga
sagaMiddleware.run(watchRequestDeals);
sagaMiddleware.run(watchRequestDeal);
sagaMiddleware.run(watchPromotionProducts);
sagaMiddleware.run(watchOnSaleProducts);

export default store;
