import autoBind from "auto-bind";
import * as PaymentsAPI from "src/core/api/payments";
import {isClient} from "src/server/utils/isClient";
import {formatPrice} from "src/core/common/price";
import SpenceTransactionDetails from "src/core/payments/spence/spenceTransactionDetails";
import OnlinePayment from "src/core/payments/OnlinePayment";
import {PaymentOptions} from "src/core/payments/constants";

class Spence extends OnlinePayment {
  constructor(code) {
    super(code);
    autoBind(this);
  }

  async getConfiguration() {
    const config = await PaymentsAPI.getIntegrationConfiguration(PaymentOptions.SPENCE);
    return {
      publicKey: config.data.attributes.public_key,
    };
  }

  async preparePayment(orderInfo, paymentData) {
    return {
      ...orderInfo,
      payment_specification: {
        transaction_id: paymentData.source.transactionID,
      },
    };
  }

  async setupSpence() {
    if (isClient && !window.Spence) {
      let link = document.createElement("link");
      link.href =
        "https://spence-payment-sdk.gospence.com/integration-stable/sdk.styles.min.css";
      link.rel = "stylesheet";
      document.getElementsByTagName("head")[0].appendChild(link);

      const {publicKey} = await this.getConfiguration();
      let script = document.createElement("script");
      script.defer = true;
      script.src = `https://spence-payment-sdk.gospence.com/integration-stable/sdk.min.js?apiKey=${publicKey}&whitelabelDomain=https://integration-stable-app.gospence.com/`;
      document.body.appendChild(script);
    }
  }

  onApprove(result) {
    const transactionDetails = new SpenceTransactionDetails(result);
    if (transactionDetails.paymentApproved) {
      this.notify("transaction-approved", transactionDetails);
    } else {
      this.notify("transaction-failed", transactionDetails);
    }
  }

  async getPaymentDisclaimer({shop, cart, order, payment}) {
    const prices = cart ? cart.getPrices() : order ? order.getPrices() : null;
    if (!shop || !prices || !payment || !payment.source) return "";
    return `By clicking pay above I authorize ${shop.getName()} to debit ${formatPrice(
      prices.totalPrice
    )} from my Spence account registered under ${payment.source.payerPhoneNumber}.`;
  }
}

export default Spence;
