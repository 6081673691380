import autoBind from "auto-bind";
import get from "lodash/get";
import User from "src/core/authentication/models/user";
import Address from "../../common/models/address";
import IdentityVerification from "src/integrations/identityVerification/IdentityVerification";
import {makeIdentityVerification} from "src/integrations/identityVerification/factory";

class TymberUser extends User {
  constructor(userObject) {
    super(userObject);
    autoBind(this);
  }

  getCustomerType() {
    return get(this.userObject, "data.attributes.customer_type");
  }

  isMedicalCustomer() {
    return this.getCustomerType() !== "recreational";
  }

  getFirstName() {
    return get(this.userObject, "data.attributes.first_name");
  }

  getLastName() {
    return get(this.userObject, "data.attributes.last_name");
  }

  getEmail() {
    return get(this.userObject, "data.attributes.email");
  }

  getDateOfBirth() {
    const dob = get(this.userObject, "data.attributes.date_of_birth");
    if (dob) {
      return new Date(dob);
    } else {
      return undefined;
    }
  }

  getMedicalIdExpirationDate() {
    const expiration_date = get(
      this.userObject,
      "data.attributes.medical_id.expiration_date"
    );
    if (expiration_date) {
      return new Date(expiration_date);
    } else {
      return undefined;
    }
  }

  getMedicalIdNumber() {
    return get(this.userObject, "data.attributes.medical_id.number");
  }

  getMedicalIdState() {
    return get(this.userObject, "data.attributes.medical_id.state");
  }

  getStateResidency() {
    return get(this.userObject, "data.attributes.state_residency");
  }

  getDriversLicenseIdNumber() {
    return get(this.userObject, "data.attributes.drivers_license_id.number");
  }

  getDriversLicenseIdExpirationDate() {
    const date = get(
      this.userObject,
      "data.attributes.drivers_license_id.expiration_date"
    );
    if (date) {
      return new Date(date);
    } else {
      return undefined;
    }
  }

  getPhoneNumber() {
    return get(this.userObject, "data.attributes.phone_number");
  }

  getDeliveryAddress() {
    const originalAddressObj = get(this.userObject, "data.attributes.address");
    if (!originalAddressObj) return null;

    const addressObj = {...originalAddressObj};
    addressObj.building_number = addressObj.address && addressObj.address.split(" ")[0];
    return new Address(addressObj);
  }

  getBillingAddress() {
    const addressObj = get(this.userObject, "data.attributes.billing.address");
    return addressObj ? new Address(addressObj) : null;
  }

  getName() {
    return `${this.getFirstName() || ""} ${this.getLastName() || ""}`;
  }

  hasVerifiedDocs(deliveryType) {
    const identityVerification = this.getIdentityVerificationByDeliveryType(deliveryType);
    return get(identityVerification, "attributes.is_verified");
  }

  hasDriversLicense(deliveryType) {
    const identityVerification = this.getIdentityVerificationByDeliveryType(deliveryType);

    const hasDriversLicense = get(identityVerification, "attributes.has_drivers_license");

    if (!this.requiresVerification(deliveryType)) return hasDriversLicense;

    return (
      get(identityVerification, "attributes.is_verified") &&
      get(identityVerification, "attributes.has_drivers_license")
    );
  }

  hasMedicalId() {
    return this.hasDocument("medical-id");
  }

  hasSelfieId(deliveryType) {
    const identityVerification = this.getIdentityVerificationByDeliveryType(deliveryType);
    const hasSelfieId = get(identityVerification, "attributes.has_selfie_id");

    if (!this.requiresVerification(deliveryType)) return hasSelfieId;

    return get(identityVerification, "attributes.is_verified") && hasSelfieId;
  }

  requiresVerification(deliveryType) {
    const identityVerification = this.getIdentityVerificationByDeliveryType(deliveryType);
    const service = get(identityVerification, "attributes.service");
    return !service || service !== "tymber";
  }

  getIdentityVerificationByDeliveryType(deliveryType) {
    const identityVerificationData = get(this.userObject, "identityVerification.data");
    if (!identityVerificationData) return null;

    return identityVerificationData.filter(
      id => id.attributes.delivery_type === deliveryType
    )[0];
  }

  hasDocument(documentType) {
    const documents = get(this.userObject, "data.relationships.documents.data");
    return documents && documents.find(document => document.id === documentType);
  }

  getMarketingSmsOptIn() {
    return get(this.userObject, "data.attributes.marketing_sms_opt_in");
  }

  getMarketingEmailOptIn() {
    return get(this.userObject, "data.attributes.marketing_email_opt_in");
  }

  getIdentityVerificationObject() {
    return get(this.userObject, "identityVerification");
  }

  getIdentityVerification(deliveryType) {
    const identityVerificationObject = get(this.userObject, "identityVerification");
    if (!identityVerificationObject) return null;

    const idVerification = new IdentityVerification(identityVerificationObject);
    if (!deliveryType) return idVerification;

    return makeIdentityVerification(
      idVerification.getIdentityVerificationService(deliveryType),
      identityVerificationObject
    );
  }

  canSeeMenu(deliveryType) {
    const identityVerification = this.getIdentityVerificationByDeliveryType(deliveryType);
    return get(identityVerification, "attributes.can_see_menu");
  }

  getUniqueIdentifier() {
    return this.getPhoneNumber() || this.getEmail();
  }
}

export default TymberUser;
