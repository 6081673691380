import {
  getGroupsFromBrands,
  getGroupsFromCategories,
  getGroupsFromTags,
} from "@menu/utils/productGroups";

export async function fetchGroups({catalog, filters}, options = {}) {
  const tagGroups = !filters.tags ? getGroupsFromTags(catalog.tags, filters) : [];
  const categoryGroups = getGroupsFromCategories(catalog.categories, filters);
  const brandGroups =
    options.displayBrands && !filters.brands
      ? getGroupsFromBrands(catalog.brands, filters)
      : [];

  return [...tagGroups, ...brandGroups, ...categoryGroups];
}
