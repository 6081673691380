import {sameFilters} from "@menu/utils/sameFilters";

export function matchProductListState(state, params = {}) {
  return (
    sameFilters(state.lastFilters, params.filters) &&
    params.type === state.type &&
    params.slug === state.slug &&
    params.shop === state.shop
  );
}
