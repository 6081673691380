import React from "react";
import {SortFilters} from "@ui";
import useAppliedFilters from "@mock/hooks/useAppliedFilters";
import useSortAndFilterHeader from "@mock/hooks/useSortAndFilterHeader";
import styles from "@themes/boost/components/SortAndFilterActions/styles";
import useTheme from "@mock/hooks/useTheme";

export default function BoostThemeSortAndFilterActions({
  filtersDisplayOptions = {},
  onChangeFilter = () => {},
}) {
  const [, , , , , , , , hideFilters] = useAppliedFilters();
  const theme = useTheme();
  const {
    site,
    enabledFilters,
    filtersCount,
    onChangeFilters,
    orderOptions,
    currentSortOrder,
    onChangeOrder,
    DropdownComponent,
    FiltersWrapperComponent,
  } = useSortAndFilterHeader();

  if (hideFilters) return null;

  return (
    <>
      <FiltersWrapperComponent
        mode="drawer"
        displayOptions={filtersDisplayOptions}
        onChangeFilter={onChangeFilter}
      />
      <SortFilters
        styles={styles(theme, site, {
          withBorder: site.getUiConfiguration().sortFilters?.withBorder,
        })}
        enabledFilters={enabledFilters}
        filtersCount={filtersCount}
        onChangeFilters={onChangeFilters}
        orderOptions={orderOptions}
        currentSortOrder={currentSortOrder}
        onChangeOrder={onChangeOrder}
        mode={"full-width"}
        DropdownComponent={DropdownComponent}
      />
    </>
  );
}
