import React from "react";
import {PrefetchedDataKeys} from "src/server/constants";
import ProductFiltersCatalog from "src/core/common/models/productFiltersCatalog";

export const ServerContext = React.createContext({data: {}});

export const ServerContextProvider = ({data = {}, children}) => {
  return <ServerContext.Provider value={{data}}>{children}</ServerContext.Provider>;
};

export function getData(props = {}) {
  return Object.values(PrefetchedDataKeys).reduce((acc, key) => {
    return {
      ...acc,
      [key]: props[key] ? makeData(key, props[key]) : undefined,
    };
  }, {});
}

function makeData(key, data) {
  switch (key) {
    case PrefetchedDataKeys.FILTERS_CATALOG:
      return new ProductFiltersCatalog(data);
    default:
      return data;
  }
}
