import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";
import OrderState from "@ui/components/CheckoutOrderInfo/OrderState";
import CustomerInfo from "@ui/components/CheckoutOrderInfo/CustomerInfo";
import PaymentInfo from "@ui/components/CheckoutOrderInfo/PaymentInfo";
import SupportInfo from "@ui/components/CheckoutOrderInfo/SupportInfo";
import CheckoutOrderInfoSkeleton from "@ui/components/CheckoutOrderInfo/CheckoutOrderInfoSkeleton";
import InfoNotification from "@ui/components/CheckoutOrderInfo/InfoNotification";
import TimedInfoNotification from "@ui/components/CheckoutOrderInfo/TimedInfoNotification";
import {CircleCheckIcon} from "@ui/components/IconsSVGs";
import DownloadApp from "@ui/components/DownloadApp/DownloadApp";
import MobileAppPromotion from "@ui/components/CheckoutOrderInfo/MobileAppPromotion";

export default function CheckoutOrderInfo({
  isLoading,
  order,
  customer,
  saveInfo,
  messages,
  supportPhoneNumber,
  faqURL,
  downloadAppUrl,
  mobileAppQrcode,
  showAppStorePromotionSection,
  showPlayStorePromotionSection,
  LinkComponent,
  PaymentBannerComponent,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);
  const hasError = messages.some(message => message.status === "error");

  const showMobileAppPromotionSection =
    showAppStorePromotionSection || showPlayStorePromotionSection;

  if (isLoading) {
    return <CheckoutOrderInfoSkeleton />;
  }

  return (
    <Container>
      {!hasError && (
        <HeaderContainer>
          <CircleCheckIcon color={_styles.header.icon.color} />
          <Header>
            <TopText styles={_styles.header.topText}>{order.headerTopText}</TopText>
            <CustomerName
              styles={_styles.header.customerName}
              data-cy="purchaseConfirmation"
            >
              Thank you {customer.name}!
            </CustomerName>
          </Header>
        </HeaderContainer>
      )}

      {messages.map(message => renderMessage(message, _styles))}
      {showMobileAppPromotionSection && order.deliveryType === "kiosk" && (
        <MobileAppPromotion
          styles={_styles.mobileAppPromotion}
          url={mobileAppQrcode}
          showAppStorePromotionSection={showAppStorePromotionSection}
          showPlayStorePromotionSection={showPlayStorePromotionSection}
        />
      )}
      {order.deliveryType !== "kiosk" && (
        <OrderState
          states={order.states}
          hasError={!order.isCanceled && hasError}
          FooterComponent={
            downloadAppUrl
              ? renderNodeOrComponent(DownloadApp, {
                  url: downloadAppUrl,
                  styles: styles.order.footer,
                })
              : null
          }
          styles={_styles.order}
        />
      )}
      <CustomerInfo
        customer={customer}
        order={order}
        saveInfo={saveInfo}
        styles={_styles.customer}
      />
      <PaymentInfo
        order={order}
        BannerComponent={PaymentBannerComponent}
        styles={_styles.paymentInfo}
      />
      {(supportPhoneNumber || faqURL) && (
        <SupportInfo
          phoneNumber={supportPhoneNumber}
          faqURL={faqURL}
          styles={_styles.supportInfo}
          LinkComponent={LinkComponent}
        />
      )}
    </Container>
  );
}

function renderMessage(message, _styles) {
  if (message.type === "timed") {
    return (
      <TimedInfoNotification
        title={message.title}
        descriptionFn={message.descriptionFn}
        onTimerFinish={message.action}
        status={message.status}
        styles={_styles.infoNotification}
      />
    );
  } else {
    return (
      <InfoNotification
        title={message.title}
        description={message.description}
        status={message.status}
        action={message.action}
        styles={_styles.infoNotification}
      />
    );
  }
}

const Container = styled.div.attrs(() => ({
  className: "checkout-order-info__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 34px;
`;

const HeaderContainer = styled.div.attrs(() => ({
  className: "checkout-order-info__header-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  gap: 14px;
`;

const Header = styled.div.attrs(() => ({
  className: "checkout-order-info__header",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const TopText = styled.span.attrs(() => ({
  className: "checkout-order-info__top__text",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const CustomerName = styled.span.attrs(() => ({
  className: "checkout-order-info__header__customer-name",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  header: {
    icon: {
      color: "#000",
    },
    topText: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#545454",
    },
    customerName: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "18px",
        md: "18px",
        sm: "18px",
      },
      fontWeight: 600,
      color: "#333333",
    },
  },
};

CheckoutOrderInfo.defaultProps = {
  order: {
    headerTopText: "",
  },
  customer: {
    name: "",
  },
  messages: [],
  LinkComponent: ({children}) => <a href={"#"}>{children}</a>,
  styles: defaultStyles,
};

CheckoutOrderInfo.propTypes = {
  showEmail: PropTypes.bool,
  isLoading: PropTypes.bool,
  order: PropTypes.shape({
    headerTopText: PropTypes.string,
    deliveryTypeIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    states: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        title: PropTypes.string,
        info: PropTypes.string,
        isSelected: PropTypes.bool,
      })
    ),
    paymentMethod: PropTypes.shape({
      Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
    tip: PropTypes.string,
  }),
  customer: CustomerInfo.propTypes.customer,
  saveInfo: PropTypes.shape({
    show: PropTypes.bool,
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      descriptionFn: PropTypes.func,
      description: PropTypes.string,
      status: PropTypes.string,
      action: PropTypes.shape({
        type: PropTypes.string,
        onClick: PropTypes.func,
        label: PropTypes.string,
      }),
    })
  ),
  supportPhoneNumber: PropTypes.string,
  faqURL: PropTypes.string,
  downloadAppUrl: PropTypes.string,
  LinkComponent: PropTypes.elementType,
  PaymentBannerComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  styles: PropTypes.shape({
    shop: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
    header: PropTypes.shape({
      icon: PropTypes.shape({
        color: PropTypes.string,
      }),
      topText: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      customerName: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
    }),
    order: PropTypes.shape({
      title: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      info: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      states: PropTypes.shape({
        color: PropTypes.string,
      }),
      label: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
    }),
    customer: PropTypes.shape({
      title: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      info: PropTypes.shape({
        title: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            md: PropTypes.string,
            sm: PropTypes.string,
          }),
          fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          color: PropTypes.string,
        }),
        text: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            md: PropTypes.string,
            sm: PropTypes.string,
          }),
          fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          color: PropTypes.string,
        }),
      }),
      saveInfo: PropTypes.shape({
        root: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            md: PropTypes.string,
            sm: PropTypes.string,
          }),
          fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          color: PropTypes.string,
        }),
      }),
    }),
    paymentInfo: PropTypes.shape({
      title: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      info: PropTypes.shape({
        title: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            md: PropTypes.string,
            sm: PropTypes.string,
          }),
          fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          color: PropTypes.string,
        }),
        text: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            md: PropTypes.string,
            sm: PropTypes.string,
          }),
          fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          color: PropTypes.string,
        }),
      }),
      paymentTitle: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      paymentSubtitle: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      paymentDate: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
    }),
    infoNotification: PropTypes.shape({
      icon: PropTypes.shape({
        color: PropTypes.string,
        error: PropTypes.shape({
          color: PropTypes.string,
        }),
        info: PropTypes.shape({
          color: PropTypes.string,
        }),
      }),
      title: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      description: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      action: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
        background: PropTypes.string,
        border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    supportInfo: PropTypes.shape({
      root: PropTypes.shape({
        border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      title: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      info: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
    }),
  }),
};
