import React from "react";
import DefaultDealsPage from "@themes/default/pages/DealsPage";
import {createGlobalStyle} from "styled-components";
import media from "@ui/utils/media";

export default function DealsPage(props) {
  return (
    <>
      <CustomStyles />
      <DefaultDealsPage {...props} />
    </>
  );
}

const CustomStyles = createGlobalStyle`
  .deals-page__deals-list {
    ul {
      ${media.up("lg")} {
        padding: 0 32px
      }

      ${media.down("md")} {
        padding: 0 16px
      }
    }
  }
  .deals-page__products-list {
    ul {
      ${media.up("lg")} {
        padding: 0 48px
      }

      ${media.down("md")} {
        padding: 0 32px
      }
    }
  }
`;
