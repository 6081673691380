import Adyen from "src/core/payments/adyen/Adyen";
import {PaymentOptions} from "src/core/payments/constants";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const AdyenPaymentForm = suspensify(
  lazy(() => import("src/core/payments/adyen/AdyenPaymentForm"))
);

export default {
  type: PaymentOptions.ADYEN,
  PaymentClass: Adyen,
  PaymentForm: AdyenPaymentForm,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
