import React from "react";
import RecommendedProductsList from "@themes/default/components/RecommendedProductsList/RecommendedProductsList";
import styled from "styled-components";
import useRecommendedProductsList from "@mock/hooks/useRecommendedProductsList";

const RECOMMENDED_PRODUCT_LIMIT = 4;

export default function BrandRecommendations(props) {
  const {site, product, products, meta, getBrandPath} = useRecommendedProductsList(
    RECOMMENDED_PRODUCT_LIMIT
  );

  if (!product) return null;

  return (
    <CustomProductsList
      title={`More by ${product?.getBrandName()}`}
      linkToAll={getBrandPath(product)}
      products={products}
      meta={meta}
      limit={RECOMMENDED_PRODUCT_LIMIT}
      site={site}
      {...props}
    />
  );
}

const CustomProductsList = styled(RecommendedProductsList)`
  padding: 4px 0px 32px 3px;
`;
