import {combineReducers} from "redux";
import dealsReducer from "./reducers/deals";
import dealReducer from "./reducers/deal";
import onSaleProductsReducer from "./reducers/onSaleProducts";
import PromotionProductsReducer from "./reducers/promotionProducts";

export const reducer = combineReducers({
  deals: dealsReducer,
  deal: dealReducer,
  onSaleProducts: onSaleProductsReducer,
  promotionProducts: PromotionProductsReducer,
});
