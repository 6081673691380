import {useEffect, useState} from "react";
import EventBus from "src/core/common/eventBus";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import {ErrorTags} from "src/core/api/errorHandlers";
import useCartState from "src/core/checkout/hooks/useCartState";
import {useRefresh} from "src/core/common/hooks/useRefresh";
import localStorage from "src/core/common/localStorage";
import * as Notifications from "src/core/notifications";
import useSite from "src/core/sites/hooks/useSite";

function useInventoryType() {
  const site = useSite();
  const options = site.getOptions();
  const strictModeEnabled = options.strictMedicalOrdersEnabled();
  useEffect(() => {
    inventoryTypeState.setStrictMode(strictModeEnabled);
    setInventoryType(inventoryTypeState.get());
  }, [strictModeEnabled]);

  const [inventoryType, setInventoryType] = useState(inventoryTypeState.get());
  const [, authApi] = useAuthentication();
  const user = authApi.userProfile();
  const router = useRouter();
  const refresh = useRefresh();
  const [cartState, actions] = useCartState();

  useEffect(() => {
    return inventoryTypeState.subscribe(({inventoryType}) => {
      refresh();
      setInventoryType(inventoryType);
    });
  }, [actions, refresh]);

  const userEmail = user?.getEmail();

  useEffect(() => {
    if (!strictModeEnabled || (authApi.isLoggedIn() && !user)) return;

    if (cartState.data && cartState.data.getInventoryType() !== inventoryType) {
      actions.clearCart();
    }
    // eslint-disable-next-line
  }, [cartState.data, inventoryType, userEmail])

  useEffect(() => {
    if (authApi.isLoggedIn() && !user) return;

    inventoryTypeState.setPreferredInventoryType(user);
    // eslint-disable-next-line
  }, [strictModeEnabled, userEmail])

  function selectInventoryType(type) {
    try {
      inventoryTypeState.set(type, user);
      return true;
    } catch (e) {
      switch (e.code) {
        case InventoryTypeState.ERRORS.NOT_RECREATIONAL_CUSTOMER:
          Notifications.error(e.message);
          break;
        case InventoryTypeState.ERRORS.NOT_LOGGED_IN:
          Notifications.error(e.message);
          router.push(routes.login);
          break;
        case InventoryTypeState.ERRORS.NOT_MEDICAL_CUSTOMER:
          Notifications.error(e.message);
          router.push({
            pathname: routes.personalInfo,
            query: {
              error: e.message,
              tag: ErrorTags.DOCUMENTS,
              close: true,
            },
          });
          break;
        default:
          Notifications.error("Unexpected error has occurred");
      }

      return false;
    }
  }

  return [inventoryType, selectInventoryType, inventoryTypeState.isStrictModeEnabled()];
}

class InventoryTypeState {
  static ERRORS = {
    NOT_RECREATIONAL_CUSTOMER: "not_recreational_customer",
    NOT_LOGGED_IN: "not_logged_in",
    NOT_MEDICAL_CUSTOMER: "not_medical_customer",
  };
  constructor() {
    this.eventBus = new EventBus();
    this.current = this.getStored() || InventoryTypes.RECREATIONAL;
    this.strictModeEnabled = false;
  }

  isStrictModeEnabled() {
    return this.strictModeEnabled;
  }

  setStrictMode(strictModeEnabled) {
    this.strictModeEnabled = strictModeEnabled;
  }

  set(type, user) {
    // As a first iteration of this feature, the feature is only enabled when strict mode also is
    if (!this.isStrictModeEnabled()) return;

    let error;
    if (!user && type === InventoryTypes.MEDICAL) {
      error = new Error("You must have a valid medical id to buy medical products.");
      error.code = InventoryTypeState.ERRORS.NOT_LOGGED_IN;
    } else if (type === InventoryTypes.MEDICAL && !user.isMedicalCustomer()) {
      const error = new Error(
        "Please review your Medical ID information before proceeding."
      );
      error.code = InventoryTypeState.ERRORS.NOT_MEDICAL_CUSTOMER;
    }

    if (error) throw error;

    if (type === this.current) return;

    this.current = type;

    if (user) this.save(type);

    this.eventBus.notify({inventoryType: type});
  }

  setPreferredInventoryType(user) {
    // As a first iteration of this feature, the feature is only enabled when strict mode also is
    if (!this.isStrictModeEnabled()) return;

    if (!user) {
      this.set(InventoryTypes.RECREATIONAL);
    } else {
      const targetInventoryType =
        localStorage.getItem("INVENTORY_TYPE") ||
        (user.isMedicalCustomer() ? InventoryTypes.MEDICAL : InventoryTypes.RECREATIONAL);
      try {
        this.set(targetInventoryType, user);
      } catch (e) {
        this.handleError(e, user);
      }
    }
  }
  handleError(e, user) {
    try {
      if (e.code === InventoryTypeState.ERRORS.NOT_RECREATIONAL_CUSTOMER) {
        this.set(InventoryTypes.MEDICAL, user);
      } else {
        this.set(InventoryTypes.RECREATIONAL, user);
      }
    } catch (e) {
      // Unable to handle error
    }
  }

  get() {
    if (!this.strictModeEnabled) return null;

    return this.current;
  }

  getStored() {
    return localStorage.getItem("INVENTORY_TYPE");
  }

  save(inventoryType) {
    if (!inventoryType) {
      localStorage.removeItem("INVENTORY_TYPE");
    } else {
      localStorage.setItem("INVENTORY_TYPE", inventoryType);
    }
  }

  subscribe(handler) {
    return this.eventBus.subscribe(handler);
  }
}

export const InventoryTypes = {
  RECREATIONAL: "recreational",
  MEDICAL: "medical",
  NONE: null,
};

export const inventoryTypeState = new InventoryTypeState();

export default useInventoryType;
