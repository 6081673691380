import React, {useCallback} from "react";
import styled from "styled-components";
import useAppliedFilters from "@mock/hooks/useAppliedFilters";
import {media} from "@ui";
import {CloseIcon} from "@ui/components/IconsSVGs";

export default function AppliedSearchFilter() {
  const [filters, setFilter, , , FilterParams] = useAppliedFilters();
  const clearSearch = useCallback(() => {
    setFilter(FilterParams.PRODUCT_SEARCH, undefined);
  }, [setFilter]);

  return (
    <Container>
      {filters?.search ? (
        <SearchHeaderContainer>
          "{filters.search}" search
          <ClearSearch onClick={clearSearch}>
            <CloseIcon width="15px" height="15px" color="#000" />
          </ClearSearch>
        </SearchHeaderContainer>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
`;

const SearchHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  ${media.down("md")} {
    padding: 0 ${({theme}) => theme.v2.spacing(4)} ${({theme}) => theme.v2.spacing(4)}
      ${({theme}) => theme.v2.spacing(4)};
  }

  padding: 0 ${({theme}) => theme.v2.spacing(8)} ${({theme}) => theme.v2.spacing(8)}
    ${({theme}) => theme.v2.spacing(8)};
`;
const ClearSearch = styled.div`
  padding-top: 5px;
  cursor: pointer;
`;
