import * as ProductsApi from "src/core/api/products";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

export async function getFilters(axiosInstance, params = {}) {
  const filterParams = pickBy(sanitizeParams(params), identity);

  const data = await ProductsApi.filters(
    {...filterParams, delivery_type: params.delivery_type || undefined},
    axiosInstance
  );

  return {data, params: filterParams};
}

export function sanitizeParams(params) {
  if (!params) return {};
  const {delivery_type, ...rest} = params;
  return Object.entries(rest).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: typeof value === "string" && value.includes(",") ? value.split(",") : value,
    };
  }, {});
}
