import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import Skeleton from "react-loading-skeleton";
import media from "@ui/utils/media";

export default function CheckoutInfoTable({styles, data, skeleton, dataCy}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Table styles={_styles.table}>
      <TableBody styles={_styles.tableBody}>
        {data.map(row => (
          <StackedTableRow
            row={row}
            skeleton={skeleton}
            styles={_styles}
            dataCy={dataCy + row.label.replace(/\s/g, "")}
          />
        ))}
      </TableBody>
    </Table>
  );
}

const StackedTableRow = ({row, skeleton, styles, dataCy}) => {
  if (row.stackVertically) {
    return (
      <TableRow styles={styles.tableRow} key={`table-row-${row.label}`}>
        <Container>
          <Label styles={styles.label}>{row.label}</Label>
          <Value styles={styles.value} hasErrors={row.errors} data-cy={dataCy}>
            {!skeleton ? row.value : <Skeleton />}
          </Value>
        </Container>
        {!skeleton && row.action && (
          <Action styles={styles.actionButton}>
            <button
              type={row.action.type || "button"}
              onClick={() => row.action.onClick()}
            >
              {row.action.label}
            </button>
          </Action>
        )}
      </TableRow>
    );
  }

  return (
    <TableRow styles={styles.tableRow} key={`table-row-${row.label}`}>
      <Label styles={styles.label}>{row.label}</Label>
      <Value styles={styles.value} hasErrors={row.errors} data-cy={dataCy}>
        {!skeleton ? row.value : <Skeleton />}
      </Value>
      {!skeleton && row.action && (
        <Action styles={styles.actionButton}>
          <button type={row.action.type || "button"} onClick={() => row.action.onClick()}>
            {row.action.label}
          </button>
        </Action>
      )}
    </TableRow>
  );
};

const Table = styled.table.attrs(() => ({
  className: "checkout-info-table",
}))`
  display: flex;
  flex-direction: column;
  table-layout: auto;
  width: 100%;

  border-radius: ${({styles}) => styles.borderRadius};
  border: ${({styles}) => styles.border};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.between("md", "lg")} {
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const TableBody = styled.tbody.attrs(() => ({
  className: "checkout-info-table__table-body",
}))`
  > *:not(:last-child) {
    border-bottom: ${({styles}) => styles.borderBottom};
  }
`;

const TableRow = styled.tr.attrs(() => ({
  className: "checkout-info-table__table-row",
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.between("md", "lg")} {
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const Container = styled.div.attrs(() => ({
  className: "checkout-info-table__container",
}))`
  display: flex;
  flex-direction: row;
`;

const Label = styled.td.attrs(() => ({
  className: "checkout-info-table__table-row__label",
}))`
  width: ${({styles}) => styles.width};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-style: ${({styles}) => styles.fontStyle};
  color: ${({styles}) => styles.color};
  text-transform: ${({styles}) => styles.textTransform};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Value = styled.td.attrs(() => ({
  className: "checkout-info-table__table-row__value",
}))`
  flex: 1;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-style: ${({styles}) => styles.fontStyle};
  color: ${({styles, hasErrors}) => (!hasErrors ? styles.color : styles.errorColor)};
  text-transform: ${({styles}) => styles.textTransform};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Action = styled.td.attrs(() => ({
  className: "checkout-info-table__table-row__action",
}))`
  > button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    font-family: ${({styles}) => styles.fontFamily};
    font-weight: ${({styles}) => styles.fontWeight};
    font-style: ${({styles}) => styles.fontStyle};
    color: ${({styles}) => styles.color};
    text-transform: ${({styles}) => styles.textTransform};
    line-height: ${({styles}) => styles.lineHeight};

    ${media.up("lg")} {
      font-size: ${({styles}) => styles.fontSize.lg};
    }
    ${media.between("md", "lg")} {
      font-size: ${({styles}) => styles.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({styles}) => styles.fontSize.sm};
    }
  }
`;

const defaultStyles = {
  table: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: {
      lg: "0px 16px",
      md: "0px 16px",
      sm: "0px 16px",
    },
  },
  tableBody: {
    borderBottom: "1px solid #D9D9D9",
  },
  tableRow: {
    padding: {
      lg: "12px 0px",
      md: "12px 0px",
      sm: "12px 0px",
    },
  },
  label: {
    width: "75px",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#737373",
    textTransform: "capitalize",
  },
  value: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#333333",
    textTransform: "none",
  },
  actionButton: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    fontWeight: "400",
    lineHeight: 0,
    fontStyle: "normal",
    color: "#333333",
    textTransform: "capitalize",
  },
};

CheckoutInfoTable.defaultProps = {
  styles: defaultStyles,
  data: [],
};

CheckoutInfoTable.defaultPropTypes = {
  styles: {control: "object"},
  data: {control: "array"},
};

CheckoutInfoTable.propTypes = {
  styles: PropTypes.shape({
    table: PropTypes.shape({
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    tableBody: PropTypes.shape({
      borderBottom: PropTypes.string,
    }),
    tableRow: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    label: PropTypes.shape({
      width: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      textTransform: PropTypes.string,
    }),
    value: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      textTransform: PropTypes.string,
    }),
    actionButton: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      textTransform: PropTypes.string,
    }),
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      action: PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      }),
      stackVertically: PropTypes.bool,
    })
  ),
  skeleton: PropTypes.bool,
};
