import React from "react";
import styled, {useTheme} from "styled-components";
import {ProductListHeader, SimpleProductsList} from "@ui";
import styles from "src/themes/baseTheme/components/ShowCaseProductsList/ProductsList/styles";
import ThemedViewAllLink from "@menu/components/ThemedViewAllLink";
import useSite from "src/core/sites/hooks/useSite";
import {useInView} from "react-intersection-observer";
import useShowcaseProductsList from "src/core/common/hooks/useShowcaseProductsList";

export default function SenseProductsList(props) {
  const theme = useTheme();
  const site = useSite();
  const {name, description, type, slug, url, forceDisplay} = props;

  const {ref, inView} = useInView({triggerOnce: true});

  const baseProps = useShowcaseProductsList({
    inView: true,
    slug,
    type,
    url,
    filters: props.filters,
  });

  const productsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm,
  };

  return (
    <ProductListContainer ref={ref} hasProducts={true}>
      {(inView || forceDisplay) && (
        <SimpleProductsList
          {...props}
          {...baseProps}
          styles={styles(theme, site)}
          maxProducts={productsPerSlide}
          HeaderComponent={
            <ProductListHeader
              title={name}
              description={description}
              ViewAllComponent={
                <CustomViewAllLink
                  accessibilityIdentifier={slug}
                  to={url}
                  keepQuery={true}
                  defaultUnderlined={false}
                />
              }
            />
          }
        />
      )}
    </ProductListContainer>
  );
}

const ProductListContainer = styled.div`
  min-height: ${({hasProducts}) => (hasProducts ? "300px" : "0")};
  max-height: ${({hasProducts}) => (hasProducts ? "none" : "0")};
  visibility: ${({hasProducts}) => (hasProducts ? "visible" : "hidden")};
  &:not(:last-of-type) {
    margin-bottom: ${({hasProducts}) => (hasProducts ? "20px" : "0")};
  }
`;

const CustomViewAllLink = styled(ThemedViewAllLink)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  text-decoration: ${({underlined = false}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;
