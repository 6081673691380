import axios from "axios";
import get from "lodash/get";
import Address from "../common/models/address";
import memoize from "lodash/memoize";

class GeolocationConfigCls {
  config = {
    deliveryAddressSearchBBox: "",
    deliveryAddressSearchProximity: "",
    mapBoxAccessToken: "",
    country: "",
  };

  setConfig(newConfig) {
    this.config = {...this.config, ...newConfig};
  }

  get deliveryAddressSearchBBox() {
    return this.config.deliveryAddressSearchBBox;
  }

  get deliveryAddressSearchProximity() {
    return this.config.deliveryAddressSearchProximity;
  }

  get mapBoxAccessToken() {
    return this.config.mapBoxAccessToken;
  }

  get country() {
    return this.config.country;
  }
}
export const GeolocationConfig = new GeolocationConfigCls();

export function getStaticMap(searchText, options) {
  return searchAddress(searchText, {bbox: null}).then(features => {
    if (features && features.length) {
      const lat = features[0].center[1];
      const lng = features[0].center[0];

      const {mapBoxAccessToken} = GeolocationConfig;
      return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s-marker+000000(${lng},${lat})/${lng},${lat},11.5,0/800x600@2x?access_token=${mapBoxAccessToken}`;
    } else {
      return null;
    }
  });
}

export const searchAddress = memoize(
  (searchText, options) => {
    const {
      mapBoxAccessToken,
      deliveryAddressSearchBBox,
      country,
      deliveryAddressSearchProximity,
    } = GeolocationConfig;
    return axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${sanitizeAddress(
          searchText
        )}.json`,
        {
          params: {
            types: "address",
            bbox: options ? options.bbox : deliveryAddressSearchBBox,
            country: options ? options.country || country : country,
            proximity: options
              ? options.proximity || deliveryAddressSearchProximity
              : deliveryAddressSearchProximity,
            access_token: mapBoxAccessToken,
          },
        }
      )
      .then(response => get(response, "data.features", []))
      .catch(e => {
        console.log("Error searching address");
        console.log(e);
      });
  },
  searchText => searchText
);

export function parseAddress(addressObj) {
  const {place_name, context, address, center} = addressObj;
  const parts = place_name.split(",").map(part => part.trim());
  const addressContext = new MapBoxAddressContext(context);

  let coords = {lat: null, lng: null};
  if (center) {
    coords.lat = center[1];
    coords.lng = center[0];
  }

  return new Address({
    building_number: address,
    address: parts[0] || "",

    address_line2: "",
    city: addressContext.city,
    state: addressContext.state,
    zip_code: addressContext.zipCode,
    country: addressContext.country,
    ...coords,
  });
}
class MapBoxAddressContext {
  constructor(ctx) {
    this._ctx = ctx;
  }

  get country() {
    const countryObj = this._ctx.find(entry => entry.id.indexOf("country.") === 0);
    return countryObj ? countryObj.short_code : null;
  }

  get city() {
    const regionObj = this._ctx.find(entry => entry.id.indexOf("region.") === 0);
    const cityObj = this._ctx.find(entry => entry.id.indexOf("place.") === 0);
    const localityObj = this._ctx.find(entry => entry.id.indexOf("locality.") === 0);

    let result = cityObj;
    if (!regionObj && localityObj) result = localityObj;

    return result ? result.text : null;
  }

  get state() {
    let stateObj = this._ctx.find(entry => entry.id.indexOf("region.") === 0);
    if (!stateObj) stateObj = this._ctx.find(entry => entry.id.indexOf("place.") === 0);

    return stateObj ? stateObj.text : null;
  }

  get zipCode() {
    const postCode = this._ctx.find(entry => entry.id.indexOf("postcode.") === 0);
    return postCode ? postCode.text : null;
  }
}

export function sanitizeAddress(addressText) {
  return addressText.replace("#", "");
}
