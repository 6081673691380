import React from "react";
import useShowcaseDeals from "@mock/hooks/useShowcaseDeals";
import useShowcaseProductsList from "@mock/hooks/useShowcaseProductsList";
import styled, {useTheme} from "styled-components";
import ThemedViewAllLink from "@menu/components/ThemedViewAllLink";
import {SimpleProductsListHeader} from "@ui";
import styles, {
  viewAllLinkButtonStyles,
} from "src/themes/flight/components/ShowcaseDealsList/styles";
import Title from "src/core/common/components/modules/Title";
import CarouselCardList from "@ui/components/CarouselCardList";

export default function ShowcaseDealsList() {
  const theme = useTheme();
  const {
    deals,
    loading,
    site,
    DealCardComponent,
    showDealsList,
    dealsListTitle,
    allDealsLink,
  } = useShowcaseDeals();

  const {onLastSlide, LoadingComponent} = useShowcaseProductsList({
    url: {pathname: allDealsLink?.pathname, params: {shop: allDealsLink?.params}},
  });

  if (!showDealsList) return null;

  const dealsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: 1,
  };

  return (
    <Container>
      <CarouselCardList
        partiallyDisplayNextElement={true}
        styles={styles(theme, site)}
        maxEntriesPerSlide={dealsPerSlide}
        skeleton={loading}
        entries={deals}
        onLastSlide={onLastSlide}
        LoadingComponent={LoadingComponent}
        HeaderComponent={
          <SimpleProductsListHeader title={dealsListTitle} TitleComponent={Title} />
        }
        renderCard={({element, skeleton, isFirst}) => (
          <DealCardComponent
            skeleton={skeleton}
            {...element}
            deal={element}
            isFirst={isFirst}
            zoomOnHover={false}
          />
        )}
        displayNavigationDimensions={["lg", "md", "sm"]}
        SlideIndicatorComponent={null}
      />
      <CustomViewAllLink
        styles={viewAllLinkButtonStyles(theme, site)}
        accessibilityIdentifier={dealsListTitle}
        to={{pathname: allDealsLink?.pathname, params: {shop: allDealsLink?.params}}}
        keepQuery={true}
        defaultUnderlined={false}
        showIcon={false}
        text={dealsListTitle}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > *:first-child {
    margin: 0;
  }

  margin-bottom: ${({theme}) => theme.v2.spacing(12)};

  @media (max-width: 767px) {
    .product-card__skeleton {
      display: block;
    }
  }
`;
const CustomViewAllLink = styled(ThemedViewAllLink)`
  display: inline-flex !important;
  text-decoration: ${({underlined = false}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
  margin: 0 auto;
  background-color: ${({styles}) => styles.viewAllLink.backgroundColor};
  border-radius: ${({styles}) => styles.viewAllLink.borderRadius};

  span {
    color: ${({styles}) => styles.viewAllLink.color};
    font-weight: ${({styles}) => styles.viewAllLink.fontWeight};
    text-transform: capitalize;
  }

  > div {
    border: 0;
  }
`;
