import React from "react";
import BoostProductCard from "@themes/boost/components/ProductCard";
import {BuyNowActions} from "@ui";
import {useTheme} from "styled-components";
import * as iconSwitcher from "@ui/utils/iconSwitcher";

export default function ProductCard(props) {
  const ActionsComponent = (
    <BuyNowActions AddIconComponent={AddIconComponent} mode={props.mode || "big"} />
  );

  const MobileActionsComponent = (
    <BuyNowActions AddIconComponent={AddIconComponent} mode={"small"} />
  );

  return (
    <BoostProductCard
      {...props}
      ActionsComponent={ActionsComponent}
      MobileActionsComponent={MobileActionsComponent}
    />
  );
}

const AddIconComponent = () => {
  const theme = useTheme();

  const ShoppingCartIcon = iconSwitcher.get(theme.v1.icons.shopping);

  return <ShoppingCartIcon style={{height: "22px", width: "22px"}} color={"#fff"} />;
};
