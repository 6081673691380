import autoBind from "auto-bind";
import get from "lodash/get";

class ProductPricesRange {
  constructor(priceRangeObject) {
    this.priceRangeObject = priceRangeObject;
    autoBind(this);
  }

  getMin() {
    return get(this.priceRangeObject, "attributes.min");
  }

  getMax() {
    return get(this.priceRangeObject, "attributes.max");
  }
}

export default ProductPricesRange;
