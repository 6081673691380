import React from "react";
import styled from "styled-components";
import dynamic from "next/dynamic";
import {ShoppingCartOutline} from "@styled-icons/evaicons-outline/ShoppingCartOutline";

const HomeIcon = dynamic(() =>
  import("src/core/common/assets/home.svg").catch(console.error)
);

const InStoreIcon = dynamic(() =>
  import("src/integrations/inStoreExperience/assets/in-store.svg").catch(console.error)
);

const CarIcon = dynamic(() =>
  import("src/core/common/assets/car.svg").catch(console.error)
);
const ShoppingBagOutline = dynamic(() =>
  import("@styled-icons/evaicons-outline/ShoppingBagOutline").then(
    mod => mod.ShoppingBagOutline
  )
);
const ShoppingBag = dynamic(() =>
  import("styled-icons/feather/ShoppingBag").then(mod => mod.ShoppingBag)
);
const Search = dynamic(() =>
  import("styled-icons/heroicons-solid/Search").then(mod => mod.Search)
);
const SearchOutline = dynamic(() =>
  import("@styled-icons/evaicons-outline/SearchOutline").then(mod => mod.SearchOutline)
);
const MapPin = dynamic(() =>
  import("@styled-icons/remix-line/MapPin").then(mod => mod.MapPin)
);
const History = dynamic(() =>
  import("@styled-icons/material-rounded/History").then(mod => mod.History)
);
const User = dynamic(() =>
  import("@styled-icons/boxicons-regular/User").then(mod => mod.User)
);
const UserCircle = dynamic(() =>
  import("styled-icons/boxicons-solid/UserCircle").then(mod => mod.UserCircle)
);

const IconImage = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  path {
    stroke: ${({color}) => color};
  }
`;

const Car = ({color}) => {
  return (
    <IconImage color={color}>
      <CarIcon />
    </IconImage>
  );
};

const Home = ({color}) => {
  return (
    <IconImage color={color}>
      <HomeIcon />
    </IconImage>
  );
};

const InStoreExperience = ({color}) => {
  return (
    <IconImage color={color}>
      <InStoreIcon />
    </IconImage>
  );
};

const registry = {
  //icons
  home: Home,
  "shopping-cart": withColor(ShoppingCartOutline),
  "shopping-bag-outline": withColor(ShoppingBagOutline),
  "shopping-bag": withColor(ShoppingBag),
  search: withColor(Search),
  "search-alt": withColor(SearchOutline),
  history: withColor(History),
  user: withColor(User),
  "user-circle": withColor(UserCircle),
  delivery: Car,
  "map-pin": withColor(MapPin),
  inStoreExperience: InStoreExperience,
};

function withColor(Component) {
  return ({color, ...props}) => {
    return <Component style={{color}} {...props} />;
  };
}

export function get(name) {
  if (!registry[name]) {
    throw new Error(`Icon with name ${name} is not available`);
  } else {
    return registry[name];
  }
}
