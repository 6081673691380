import React from "react";
import PropTypes from "prop-types";
import SelectionBarButton from "@ui/components/SelectionBar/SelectionBarButton";

function CategoryButton({categoryName, categoryUrl, categoryImageUrl, ...props}) {
  return (
    <SelectionBarButton
      {...props}
      label={categoryName}
      url={categoryUrl}
      imageUrl={categoryImageUrl}
    />
  );
}

CategoryButton.propTypes = {
  ...SelectionBarButton.propTypes,
  categoryName: PropTypes.string,
  categoryUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  categoryImageUrl: PropTypes.string,
};
export default CategoryButton;
