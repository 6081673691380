import autoBind from "auto-bind";
import get from "lodash/get";

class ProductCategory {
  constructor(categoryObject) {
    this.categoryObject = categoryObject;
    autoBind(this);
  }

  getId() {
    return get(this.categoryObject, "attributes.id");
  }

  getSlug() {
    return get(this.categoryObject, "attributes.slug");
  }

  getDescription() {
    return get(this.categoryObject, "attributes.description");
  }

  getName() {
    return get(this.categoryObject, "attributes.name", "");
  }

  getParentCategoryId() {
    return get(this.categoryObject, "attributes.parent_category_id");
  }

  getTitle() {
    return this.getName();
  }

  getCount() {
    return get(this.categoryObject, "attributes.count");
  }

  getFilterValue() {
    return this.getSlug();
  }

  getIcon() {
    return get(this.categoryObject, "attributes.icon_url", "");
  }
}

export default ProductCategory;
