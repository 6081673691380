import autoBind from "auto-bind";
import TymberAPIConfig from "src/core/api/config";
import makeSite from "../common/tymberFactory/makeSite";
import * as ShopsApi from "src/core/api/shops";
import ShopFullSettings from "src/core/common/models/shopFullSettings";

class TymberSitesRepository {
  constructor() {
    autoBind(this);
  }

  async getSiteSettings() {
    return ShopsApi.fullSettings()
      .then(data => new ShopFullSettings(data))
      .then(fullSettings => {
        return {
          data: fullSettings.getShopById(TymberAPIConfig.headers["X-Store"]).getSite(),
        };
      });
  }

  async getSettings() {
    return ShopsApi.fullSettings()
      .then(data => new ShopFullSettings(data))
      .then(fullSettings => {
        return {
          data: fullSettings.getShopSettingsById(TymberAPIConfig.headers["X-Store"]),
        };
      });
  }

  async getGroupSettings() {
    return ShopsApi.fullSettings()
      .then(data => new ShopFullSettings(data))
      .then(fullSettings => {
        return {
          data: fullSettings.groupSettings,
        };
      });
  }

  async get() {
    return this.getFullSite();
  }

  getFullSite = () => {
    const promises = [];
    promises.push(
      this.getSettings()
        .then(settings => settings.data.attributes)
        .catch(() => {
          console.warn("ECommerce Site ID not set, skipping settings.");
          return {};
        })
    );
    promises.push(
      this.getGroupSettings()
        .then(settings => settings.data.attributes)
        .catch(() => {
          console.warn("ECommerce Site ID not set, skipping group settings.");
          return {};
        })
    );
    promises.push(
      this.getSiteSettings()
        .then(siteSettings => siteSettings.data)
        .catch(() => {
          console.warn("ECommerce Site ID not set, skipping site settings.");
          return {};
        })
    );

    return Promise.all(promises).then(values => {
      const [settings, groupSettings, siteSettings] = values;
      return makeSite(siteSettings, settings, groupSettings);
    });
  };

  getId = siteId => {
    return `sites/${siteId}`;
  };
}

export default TymberSitesRepository;
