import {useEffect, useState} from "react";
import {getKiosks, mapKiosk} from "src/apps/kiosk/utils";

export function useKiosks(env) {
  const [state, setState] = useState({
    requested: false,
    loading: false,
    error: null,
    data: [],
  });
  useEffect(() => {
    let mounted = true;
    setState({...state, loading: true, error: null, requested: true});
    getKiosks({
      basePath: env.getTymberEcommerceHost(),
      storeId: env.getEcommerceId(),
    })
      .then(response => {
        const kiosks = response.data.data
          .map(res => mapKiosk(res))
          .sort((a, b) => a.id - b.id);

        if (mounted) {
          setState({loading: false, error: null, requested: true, data: kiosks});
        }
      })
      .catch(e => {
        if (mounted) {
          setState({data: [], loading: false, error: e, requested: true});
        }
      });
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [env]);

  return [state];
}
