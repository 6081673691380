import {lazy} from "react";
import CategorizedProductsBrowser from "src/themes/legacy/components/CategorizedProductsBrowser";
import StoreSelectionModal from "src/themes/legacy/components/StoreSelectionModal";
import DeliveryModal from "src/themes/legacy/components/DeliveryModal";
import ProductFilters from "src/themes/legacy/components/ProductFilters";
import ExpressModeSelector from "src/themes/legacy/components/ExpressModeSelector";
import {
  HybridTypeIndicator,
  IndicaTypeIndicator,
  SativaTypeIndicator,
} from "src/themes/legacy/flowerTypeIcons";
import suspensify from "@ui/utils/suspensify";
import ProductsOrderSelector from "src/themes/legacy/components/ProductsOrderSelector";

const DefaultModal = suspensify(
  lazy(() =>
    import("src/themes/legacy/components/Modal").then(module => ({
      default: module.DefaultModal,
    }))
  )
);

export default {
  components: {
    Modal: DefaultModal,
    CategorizedProductsBrowser,
    StoreSelectionModal,
    ExpressModeSelector,
    DeliveryModal,
    ProductFilters,
    IndicaTypeIndicator,
    SativaTypeIndicator,
    HybridTypeIndicator,
    ProductsOrderSelector,
  },
};
