import {useEffect, useState} from "react";
import {MenuPages} from "src/core/common/models/pages";
import {deserialise} from "kitsu-core";
import * as ShopsApi from "src/core/api/shops";
import memoize from "lodash/memoize";
import {subscribeEvent} from "src/events/utils";
import {EventTypes} from "src/events/constants";
import routes from "src/core/common/routes";
import memoryStorage from "src/core/common/memoryStorage";

function usePages() {
  const [pages, setPages] = useState(
    memoryStorage.getItem(MENU_PAGES_KEY) || new MenuPages([], routes.getBasePath())
  );

  const fetchPages = () =>
    ShopsApi.pages()
      .then(makePagesResponse)
      .then(pages => {
        setPages(pages);
        memoryStorage.setItem(MENU_PAGES_KEY, pages);
      });
  useEffect(() => {
    if (pages.pages.length === 0) fetchPages();

    return subscribeEvent(EventTypes.STORE_CHANGED, () => {
      fetchPages();
    });
  }, []);
  return pages;
}

export const makePagesResponse = memoize(response => {
  const pagesData = deserialise(response).data;
  return new MenuPages(pagesData, routes.getBasePath());
});

const MENU_PAGES_KEY = "menu-pages";

export default usePages;
