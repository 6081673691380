import React, {lazy, Suspense} from "react";
import GenericLogo from "./GenericLogo";
import PropTypes from "prop-types";

const Logos = {
  weedmaps: lazy(() => import("./WeedMapsLogo")),
  facebook: lazy(() => import("./FacebookLogo")),
  google: lazy(() => import("./GoogleLogo")),
  instagram: lazy(() => import("./InstagramLogo")),
  linkedin: lazy(() => import("./Linkedin")),
  twitter: lazy(() => import("./TwitterLogo")),
  tiktok: lazy(() => import("./TikTokLogo")),
  yelp: lazy(() => import("./YelpLogo")),
};

export default function SocialNetworkLogo({useCustomIcons, item}) {
  if (useCustomIcons && item.icon) {
    return <GenericLogo item={item} />;
  }

  const Logo = Logos[item.type] || GenericLogo;

  return (
    <Suspense fallback={<div />}>
      <Logo item={item} />
    </Suspense>
  );
}

SocialNetworkLogo.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    icon: PropTypes.string,
  }),
  useCustomIcons: PropTypes.bool,
};
