import {useCallback, useEffect, useState} from "react";
import store from "../state/store";
import {Deliveries} from "../../deliveries/api";

function useVerifyAddress() {
  const [state, setState] = useState(store.getState());
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const getByAddress = useCallback(
    address => {
      if (!address) {
        return {loading: false, error: null, data: null};
      } else {
        return (
          state.verifyAddress[address.zipCode] || {
            loading: false,
            error: null,
            data: null,
          }
        );
      }
    },
    [state.verifyAddress]
  );
  return [state.verifyAddress, Deliveries.verifyAddress, getByAddress];
}

export default useVerifyAddress;
