import React from "react";
import PropTypes from "prop-types";
import SimpleCardList from "@ui/components/SimpleCardList";

function SimpleProductsList({maxProducts, products, ProductCardComponent, ...props}) {
  return (
    <SimpleCardList
      {...props}
      entries={products}
      maxEntries={maxProducts}
      renderCard={({element, skeleton, isFirst}) => (
        <ProductCardComponent
          skeleton={skeleton}
          {...element}
          product={element}
          isFirst={isFirst}
          headerProps={props.headerProps}
        />
      )}
    />
  );
}

SimpleProductsList.propTypes = {
  styles: SimpleCardList.propTypes.styles,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      brandName: PropTypes.string,
      brandUrl: PropTypes.string,
      productDetailUrl: PropTypes.string,
      sizes: PropTypes.arrayOf(PropTypes.string),
      potencyTags: PropTypes.arrayOf(PropTypes.string),
      onSale: PropTypes.bool,
      flowerType: PropTypes.shape({
        icon: PropTypes.elementType,
        color: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  maxProducts: SimpleCardList.propTypes.maxEntries,
  skeleton: PropTypes.bool,
  ProductCardComponent: PropTypes.elementType,
  loading: PropTypes.bool,
  HeaderComponent: SimpleCardList.propTypes.HeaderComponent,
};

export default SimpleProductsList;
