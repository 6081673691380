import React from "react";
import styled, {useTheme} from "styled-components";
import {CarouselProductsList} from "@ui";
import BaseRecommendedProductsList from "@menu/components/BaseRecommendedProductsList";
import ThemedViewAllLink from "@menu/components/ThemedViewAllLink";
import useThemeConfig from "src/themes/useThemeConfig";
import styles from "src/themes/baseTheme/elements/RecommendedProductsList/styles";
import useSite from "src/core/sites/hooks/useSite";

export default function SenseRecommendedProductsList({title, filters, linkToAll}) {
  const config = useThemeConfig();
  const theme = useTheme();
  const site = useSite();

  const ProductCardComponent = config.components.ProductCard;

  const productsPerSlide = {
    lg: 3,
    md: 3,
    sm: 1,
  };

  return (
    <BaseRecommendedProductsList
      limit={3}
      offset={0}
      filters={filters}
      render={({meta, products}) => (
        <>
          {(meta.loading || products.length) > 0 && (
            <>
              <HeaderContainer>
                <TitleContainer>
                  <Title>{title}</Title>
                </TitleContainer>
                {linkToAll && (
                  <CustomViewAllLink to={linkToAll} defaultUnderlined={false} />
                )}
              </HeaderContainer>
              <ListContainer>
                <CarouselProductsList
                  styles={styles(theme, site)}
                  maxProductsPerSlide={productsPerSlide}
                  products={products}
                  skeleton={meta.loading}
                  recommended={true}
                  ProductCardComponent={ProductCardComponent}
                />
              </ListContainer>
            </>
          )}
        </>
      )}
    />
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

let TitleContainer = styled.div`
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: ${({padding}) => padding};
  transform: skewX(${({backgroundSkewness}) => `${backgroundSkewness}deg`});

  > * {
    color: ${({theme, textColor}) => (textColor ? textColor : theme.v2.color.onPrimary)};
    font-weight: ${({theme, fontWeight}) =>
      fontWeight ? fontWeight : theme.v2.typography.titles.weight.heavy};
    font-size: ${({theme, fontSize}) =>
      fontSize ? fontSize : theme.v2.typography.sizing.l.lg};
    transform: skewX(
      ${({backgroundSkewness}) => `${Number(backgroundSkewness) * -1}deg`}
    );
  }
`;

const Title = styled.h3`
  font-family: ${({theme}) => theme.v2.typography.body.family};
  color: ${({theme}) => theme.v2.color.onSurface};
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  font-weight: ${({theme}) => theme.v2.typography.titles.weight.bold};
`;

let CustomViewAllLink = styled(ThemedViewAllLink)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  text-decoration: ${({underlined = true}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;

const ListContainer = styled.div`
  margin-top: 20px;
`;
