import React from "react";
import ProductsList from "./ProductsList";
import useProductGroups from "@mock/hooks/useProductGroups";
import {isClient} from "@ui/utils/isClient";

export default function ShowcaseProductsList({showBrands, type}) {
  const {groups} = useProductGroups({displayBrands: showBrands}, type);

  return <BaseShowcaseProductsList groups={groups} />;
}

export function BaseShowcaseProductsList({groups, keepQuery = true}) {
  const hasGroups = groups.length > 0;

  if (!hasGroups) {
    return [1, 2].map((v, index) => <ProductsList key={index} loading={true} />);
  }

  const groupLimit = isClient() ? groups.length : 2;
  return groups
    .slice(0, groupLimit)
    .map(group => (
      <ProductsList
        key={`${group.name}-${group.type}`}
        {...group}
        keepQuery={keepQuery}
      />
    ));
}
