import React from "react";
import {useTheme} from "styled-components";
import {
  CardCategoryButton,
  CategoryBar as CategoryBarUI,
  CategoryButton,
  isMobile,
  ScrollCategoryBar,
} from "@ui";
import Link from "@mock/components/modules/Link";
import styles from "./styles";
import useCategoryBar from "@mock/hooks/useCategoryBar";

export default function CategoryBar(props) {
  const theme = useTheme();
  const {site, linkProps, ...categoryBarProps} = useCategoryBar();
  const categoryBarConfig = site.getUiConfiguration().categoryBar;

  const buttonType = categoryBarConfig?.button.type;
  const options = {
    buttonShadow: categoryBarConfig?.button.shadow,
    overrideTheme: categoryBarConfig?.theme,
  };

  if (buttonType === "card" && !isMobile()) {
    return (
      <ScrollCategoryBar
        {...props}
        {...categoryBarProps}
        showIcon={true}
        fixed={false}
        styles={styles(theme, site, options)}
        CategoryButtonComponent={props => (
          <CardCategoryButton {...props} linkProps={linkProps} LinkComponent={Link} />
        )}
      />
    );
  } else {
    return (
      <CategoryBarUI
        {...props}
        {...categoryBarProps}
        styles={styles(theme, site, options)}
        CategoryButtonComponent={props => (
          <CategoryButton {...props} linkProps={linkProps} LinkComponent={Link} />
        )}
      />
    );
  }
}
