import get from "lodash/get";
import {formatPrice} from "src/core/common/price";

export function getItemErrors(itemErrors, itemId) {
  return itemErrors
    ? itemErrors.filter(error => get(error, "extra_info.item.uuid") === itemId)
    : [];
}

export const getDiscountInfo = (discount, formatPriceFunction = formatPrice) => {
  const labels = {
    coupon: "Coupon",
    reward: "Reward",
    group: "Group",
    automatic: "Automatic",
  };

  if (labels[discount.type]) {
    return {
      label: labels[discount.type],
      value: formatPriceFunction(discount.value),
      code: discount.name,
    };
  } else if (discount.name) {
    return {
      label: "Discount",
      value: formatPriceFunction(discount.value),
      code: discount.name,
    };
  } else {
    return {
      label: "Discount",
      value: formatPriceFunction(discount.value),
      code: null,
    };
  }
};

export const validateQuantity = (quantity, featureToggles) => {
  if (featureToggles.allowOnlySingleUnitCartItems() && quantity > 1) {
    return "You're not allowed to buy more than one of the same item";
  }

  return undefined;
};
