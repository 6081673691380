import styled from "styled-components";
import React from "react";
import {sharedStyle} from "@ui/components/Inputs/Input";

export function FakeInput({styles, className, placeholder, value, icon, hasErrors}) {
  return (
    <FakeInputContainer hasErrors={hasErrors} className={className} styles={styles}>
      {!value && (
        <FakeInputPlaceholder styles={styles}>{placeholder}</FakeInputPlaceholder>
      )}
      {value && <ValueContainer styles={styles}>{value}</ValueContainer>}
      {icon}
    </FakeInputContainer>
  );
}

const FakeInputPlaceholder = styled.span`
  color: #727272;
  font-size: ${({styles}) => styles?.fontSize};
`;
const ValueContainer = styled.span`
  color: black;
  font-size: ${({styles}) => styles?.fontSize};
`;
const FakeInputContainer = styled.div`
  ${sharedStyle};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${FakeInputPlaceholder} {
    color: ${({hasErrors}) => (hasErrors ? "red" : null)};
  }
`;
