import React from "react";
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import Input from "@ui/components/Inputs/Input";
import {InputContainer} from "@ui/components/FormFields/InputContainer";
import {Error} from "@ui/components/FormFields/Error";
import styled from "styled-components";
import DatePicker from "@ui/components/DatePicker";
import DropdownSelectInputField from "@ui/components/DropdownSelectInput/DropdownSelectInputField";
import SignatureField from "@ui/components/Signature/SignatureField";
import media from "@ui/utils/media";

function FormFields({fields, disabled, styles}) {
  const fieldComponents = fields.map((field, index) => {
    return (
      <FieldContainer key={index} span={field.width}>
        <FormField field={field} styles={styles} disabled={disabled} />
      </FieldContainer>
    );
  });

  return <FormContainer>{fieldComponents}</FormContainer>;
}
function FormField({field, styles, disabled}) {
  switch (field.type) {
    case FORM_FIELD_TYPES.SIGNATURE:
      return (
        <SignatureField
          {...field}
          styles={field.styles}
          errorStyles={styles.error}
          disabled={disabled || field.disabled}
        />
      );
    case FORM_FIELD_TYPES.SELECT:
      return (
        <DropdownSelectInputField
          {...field}
          styles={field.styles}
          errorStyles={styles.error}
          disabled={disabled || field.disabled}
        />
      );
    case FORM_FIELD_TYPES.DATE:
      return (
        <DatePicker
          {...field}
          styles={field.styles}
          errorStyles={styles.error}
          disabled={disabled || field.disabled}
        />
      );
    case FORM_FIELD_TYPES.TEXT:
    case FORM_FIELD_TYPES.STRING:
    default:
      return (
        <Field
          validate={field.validate}
          name={field.name}
          initialValue={field.initialValue}
          pattern={field.pattern}
          format={field.format}
          render={({input, meta}) => (
            <InputContainer>
              <Input
                {...input}
                styles={{...field.styles, height: "46px"}}
                placeholder={field.placeholder}
                disabled={disabled || field.disabled}
                onChange={input.onChange}
                hasErrors={(meta.touched && meta.error) || field.hasErrors}
              />
              {meta.error && meta.touched && (
                <Error styles={styles.error}>
                  {typeof meta.error === "object" ? meta.error.message : meta.error}
                </Error>
              )}
            </InputContainer>
          )}
        />
      );
  }
}

const FieldContainer = styled.div`
  ${media.up("lg")} {
    grid-column: span ${({span}) => span.lg};
  }
  ${media.down("md")} {
    grid-column: span ${({span}) => span.md};
  }
  ${media.down("sm")} {
    grid-column: span ${({span}) => span.sm};
  }
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  row-gap: 16px;
  column-gap: 16px;
`;

export const FORM_FIELD_TYPES = {
  TEXT: "text",
  STRING: "text",
  DATE: "date",
  SELECT: "select",
  SIGNATURE: "signature",
};

const fieldType = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(FORM_FIELD_TYPES)),
  name: PropTypes.string,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  format: PropTypes.func,
  width: PropTypes.shape({
    sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  }),
  styles: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
});
FormField.propTypes = {
  field: fieldType,
};
FormFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([fieldType, PropTypes.arrayOf(fieldType)])
  ),
};

export default FormFields;
