import ComponentsConfig from "../components/config";
import {ModalContext} from "src/core/common/components/modules/modal/modalContext";
import TymberRouter from "src/core/common/tymberRouter";
import {useRouter as useNextRouter} from "next/router";
import {useContext} from "react";
import RouteParamsContext from "src/core/common/routeParamsContext";

function useRouter() {
  const modalContext = useContext(ModalContext);
  const router = ComponentsConfig.getRouter();
  const nextRouter = useNextRouter();
  const globalParams = useContext(RouteParamsContext);
  return TymberRouter.withContext(router, nextRouter, {
    modal: modalContext,
    globalParams,
  });
}

let beforePopStateCallbacks = [];
export function addBeforePopStateListener(cb, router) {
  beforePopStateCallbacks.push(cb);
  router.beforePopState((...params) => {
    beforePopStateCallbacks.forEach(callback => callback(...params));
    return true;
  });
}
export function removeBeforePopStateListener(cb, router) {
  beforePopStateCallbacks = beforePopStateCallbacks.filter(callback => callback !== cb);
  router.beforePopState((...params) => {
    beforePopStateCallbacks.forEach(callback => callback(...params));
    return true;
  });
}

export default useRouter;
