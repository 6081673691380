import React, {useState} from "react";
import useRouter from "src/core/common/hooks/useRouter";
import {useBrandResource} from "src/state/hooks/useAsyncResource";
import Rating from "src/core/common/components/modules/Rating";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import {PageHead} from "src/core/seo/components/Head";
import BrandDetailSchema from "src/core/seo/components/BrandDetailSchema";
import useSite from "src/core/sites/hooks/useSite";

export default function useBrandProductsList() {
  const site = useSite();
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const {data: brand} = useBrandResource({brandSlug});

  const [paramFilters] = useAppliedFilters();
  const [filters, setFilters] = useState(paramFilters);

  return {
    filters,
    setFilters,
    showSortFilters: true,
    brand,
    RatingComponent: Rating,
    pageHead: (
      <PageHead
        title={brand?.getName()}
        description={brand?.getDescription()}
        image={brand?.getLogo()}
        titleTemplate={site.getOptions().getBrandTitleTemplate()}
        schemas={<BrandDetailSchema brand={brand} />}
      />
    ),
  };
}
