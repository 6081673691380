import autoBind from "auto-bind";
import get from "lodash/get";

const UNIT_MAPPING = {
  grams: "g",
  milligrams: "mg",
  // Add more mappings for other units as needed
};

class ProductWeight {
  constructor(weight) {
    this.weight = weight;
    autoBind(this);
  }

  getId() {
    return get(this.weight, "id");
  }

  getCount() {
    return get(this.weight, "attributes.count");
  }

  getAmount() {
    return get(this.weight, "attributes.amount");
  }

  getText() {
    return get(this.weight, "attributes.display_text");
  }

  getType() {
    return get(this.weight, "attributes.type");
  }

  getUnits() {
    return get(this.weight, "attributes.units");
  }

  getName() {
    return `${this.getAmount().toString()}g`;
  }

  getTitle() {
    return null;
  }

  getFilterValue() {
    return `${this.getAmount()}`;
  }

  getLabel() {
    const weightAmount = this.getAmount();
    const weightUnit = UNIT_MAPPING[this.getUnits()];
    return `${weightAmount}${weightUnit}`;
  }
}

export default ProductWeight;
