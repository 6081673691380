import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import {ChevronRight} from "@styled-icons/boxicons-regular/ChevronRight";
import {ChevronDown} from "@styled-icons/boxicons-regular/ChevronDown";
import OnSaleGroup from "@ui/components/FiltersPanel/OnSaleGroup";
import GridGroup from "@ui/components/FiltersPanel/GridGroup";
import Option from "@ui/components/FiltersPanel/FilterOption";
import {FilterTypeLayout} from "@ui/components/FiltersPanel/constants";
import Separator from "@ui/components/Separator/Separator";
import RangeFilter from "@ui/components/FiltersList/FilterTypes/RangeFilter";

export default function FilterGroup({
  type,
  label,
  OpenGroupIcon,
  CloseGroupIcon,
  options,
  onChange,
  showSeeMore,
  seeMoreShowLabeL,
  seeMoreHideLabeL,
  seeMoreFiltersCount,
  disabled,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onGroupClickHandler = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const toggleExpanded = () => {
    setExpanded(expanded => !expanded);
  };

  const isRangeFilter = type === FilterTypeLayout.FILTER_RANGE;
  const isOnSaleFilter = type === FilterTypeLayout.FILTER_ON_SALE;
  const isGridFilter = type === FilterTypeLayout.FILTER_GRID;

  const hasMoreOptionsToBeDisplayed = () =>
    isOpen && !isRangeFilter && showSeeMore && options.length > seeMoreFiltersCount;
  const collapsedOptions =
    !isRangeFilter && options.length > 0 && options.slice(0, seeMoreFiltersCount);

  const renderFilterLayout = () => {
    let layout = null;

    if (isGridFilter) {
      layout = (
        <div>
          {(!showSeeMore || (showSeeMore && expanded)) && (
            <GridGroup
              options={options}
              onChange={onChange}
              showSeeMore={showSeeMore}
              expanded={expanded}
              disabled={disabled}
              styles={_styles.options}
            />
          )}
          {showSeeMore && !expanded && (
            <GridGroup
              options={collapsedOptions}
              onChange={onChange}
              showSeeMore={showSeeMore}
              expanded={expanded}
              disabled={disabled}
              styles={_styles.options}
            />
          )}
        </div>
      );
    } else if (isRangeFilter) {
      const optionRange = [options].map(option => ({
        name: option.label,
        range: option.value,
        onChange: onChange,
        hideIcon: true,
      }));

      layout = <RangeFilter ranges={optionRange} />;
    } else {
      layout = (
        <Options styles={_styles.options.root}>
          {(!showSeeMore || (showSeeMore && expanded)) &&
            options.map(option => (
              <Option
                key={option.name}
                value={option.value}
                label={option.name}
                count={option.count}
                isSelected={option.isSelected}
                onClick={onChange}
                disabled={disabled}
                styles={_styles.options}
              />
            ))}
          {showSeeMore &&
            !expanded &&
            collapsedOptions.map(option => (
              <Option
                key={option.name}
                value={option.value}
                label={option.name}
                count={option.count}
                isSelected={option.isSelected}
                onClick={onChange}
                disabled={disabled}
                styles={_styles.options}
              />
            ))}
        </Options>
      );
    }

    return layout;
  };

  return (
    <Container>
      {isOnSaleFilter && (
        <OnSaleGroup
          label={label}
          isSelected={options.isSelected}
          onChange={onChange}
          disabled={disabled}
          styles={_styles.onSale}
        />
      )}
      {!isOnSaleFilter && (
        <Group>
          <GroupHeader onClick={onGroupClickHandler} styles={_styles.header}>
            <Label styles={_styles.header}>{label}</Label>
            <div>
              {isOpen ? (
                <CloseGroupIcon styles={_styles.header} />
              ) : (
                <OpenGroupIcon styles={_styles.header} />
              )}
            </div>
          </GroupHeader>
          {isOpen && renderFilterLayout()}

          {hasMoreOptionsToBeDisplayed() && (
            <ShowMore onClick={toggleExpanded} styles={_styles.showMore}>
              {expanded ? seeMoreHideLabeL : seeMoreShowLabeL}
            </ShowMore>
          )}
        </Group>
      )}
      <Separator styles={_styles.separator} />
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "filters-panel__filter-group__container",
}))`
  display: flex;
  flex-direction: column;
`;

const Group = styled.div.attrs(() => ({
  className: "filters-panel__filter-group__group",
}))`
  display: flex;
  flex-direction: column;
`;

const GroupHeader = styled.div.attrs(() => ({
  className: "filters-panel__filter-group__group-header",
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: ${({styles}) => styles.margin.lg};

  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
  }
`;

const Label = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Options = styled.ul.attrs(() => ({
  className: "filters-panel__filter-group__options",
}))`
  margin: ${({styles}) => styles.margin.lg};
  padding: ${({styles}) => styles.padding.lg};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
    padding: ${({styles}) => styles.padding.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const OpenIcon = styled(ChevronRight)`
  color: ${({styles}) => styles.color};
  width: 24px;
`;

const CloseIcon = styled(ChevronDown)`
  color: ${({styles}) => styles.color};
  width: 24px;
`;

const ShowMore = styled.span`
  cursor: pointer;
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color ${({styles}) => styles.color};
  text-decoration: ${({styles}) => styles.textDecoration};
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const defaultStyles = {
  header: {
    margin: {
      lg: "10px 0",
      sm: "10px 0",
    },
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      sm: "14px",
    },
    fontWeight: 700,
  },
  options: {
    root: {
      margin: {
        lg: "0 0 10px",
        sm: "0 0 10px",
      },
      padding: {
        lg: "0",
        sm: "0",
      },
      fontSize: {
        lg: "14px",
        sm: "12px",
      },
      fontWeight: 700,
    },
  },
  priceFilter: {},
  showMore: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#000",
    textDecoration: "underline",
    padding: {
      lg: "0 0 12px",
      sm: "0 0 12px",
    },
  },
  separator: {
    color: "#000",
    height: "2px",
  },
};

FilterGroup.defaultProps = {
  OpenGroupIcon: OpenIcon,
  CloseGroupIcon: CloseIcon,
  options: [],
  onChange: () => {},
  showSeeMore: true,
  seeMoreShowLabeL: "see more",
  seeMoreHideLabeL: "hide",
  seeMoreFiltersCount: 6,
  styles: defaultStyles,
};

FilterGroup.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  OpenGroupIcon: PropTypes.elementType,
  CloseGroupIcon: PropTypes.elementType,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number,
      isSelected: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func,
  showSeeMore: PropTypes.bool,
  seeMoreShowLabeL: PropTypes.string,
  seeMoreHideLabeL: PropTypes.string,
  seeMoreFiltersCount: PropTypes.number,
  disabled: PropTypes.bool,
  styles: PropTypes.shape({}),
};
