import AuthenticationBackend from "./authenticationBackend";
import User from "./models/user";

class AuthenticationConfig {
  config = {
    backend: new AuthenticationBackend(),
    handlers: [],
    userClass: User,
  };

  setConfig(config) {
    this.config = {...this.config, ...config};
  }
  setBackend(backend) {
    this.config.backend = backend;
    return this;
  }
  getBackend() {
    return this.config.backend;
  }
  setHandlers(handlers) {
    this.config.handlers = handlers;
    return this;
  }
  getHandlers() {
    return this.config.handlers;
  }
  setUserClass(userClass) {
    this.config.userClass = userClass;
    return this;
  }
  getUserClass() {
    return this.config.userClass;
  }

  onLogout(opts) {
    this.handlers.forEach(handler => handler.onLogout(opts));
  }

  onLoginSuccess(user, opts) {
    this.handlers.forEach(handler => handler.onLoginSuccess(user, opts));
  }

  onRegistrationSuccess(newUser, opts) {
    this.handlers.forEach(handler => handler.onRegistrationSuccess(newUser, opts));
  }

  onLoginFailure(error, opts) {
    this.handlers.forEach(handler => handler.onLoginFailure(error, opts));
  }

  onRegistrationFailure(error, opts) {
    this.handlers.forEach(handler => handler.onRegistrationFailure(error, opts));
  }

  onUserProfileFetched(userProfile, opts) {
    this.handlers.forEach(handler => handler.onUserProfileFetched(userProfile, opts));
  }

  get handlers() {
    return this.config.handlers || [];
  }
}

export default new AuthenticationConfig();
