import autoBind from "auto-bind";
import get from "lodash/get";

class ProductType {
  constructor(typeObject) {
    this.typeObject = typeObject;
    autoBind(this);
  }

  getName() {
    return get(this.typeObject, "attributes.name");
  }

  getCount() {
    return get(this.typeObject, "attributes.count");
  }

  getTitle() {
    return get(this._tag, "attributes.title") || this.getName();
  }
}

export default ProductType;
