import {useEffect} from "react";

function useModalService(modalService, openModal) {
  useEffect(() => {
    const unsubscribe = modalService.subscribe({
      show: params => {
        openModal && openModal(params);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [modalService, openModal]);
}

export default useModalService;
