import localStorage from "src/core/common/localStorage";
import useSite from "src/core/sites/hooks/useSite";
import {useEffect} from "react";
import {atom, useRecoilState} from "recoil";
import {DeliveryTypes} from "src/core/common/models/DeliveryType";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

const HAS_ALLOWED_AGE = "tymber-user-has-allowed-age";
export const hasAllowedAgeState = atom({
  key: "hasAllowedAgeState",
  default: localStorage.getItem(HAS_ALLOWED_AGE),
});

function useAgeGate() {
  const [hasAllowedAge, setHasAllowedAgeState] = useRecoilState(hasAllowedAgeState);
  const site = useSite();
  const toggles = useFeatureToggles();

  const hideAgeGate =
    site?.getOptions?.().getKioskDeliveryType() === DeliveryTypes.PICK_UP &&
    toggles.isKiosk();

  const setHasAllowedAge = value => {
    setHasAllowedAgeState(value);
    localStorage.setItem(HAS_ALLOWED_AGE, value);
  };

  useEffect(() => {
    if (site?.getOptions?.().inStoreExperienceEnabled()) {
      setHasAllowedAge(true);
    }
  }, [site, setHasAllowedAge]);

  return [
    hasAllowedAge || site?.getOptions?.().inStoreExperienceEnabled() || hideAgeGate,
    setHasAllowedAge,
    hideAgeGate,
  ];
}

export default useAgeGate;
