import React from "react";
import styled from "styled-components";
import {ChevronRightIcon} from "@ui/components/Icons";
import merge from "lodash/merge";
import PropTypes from "prop-types";

function ViewAllLink({
  to,
  keepQuery,
  accessibilityIdentifier,
  isDesktop,
  LinkComponent,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <LinkComponent
      aria-label={`View all ${
        to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
      } products`}
      to={to}
      keepQuery={keepQuery}
    >
      <ViewAllText styles={_styles}>
        <span>{isDesktop ? "View All" : ""}</span>
        <ChevronIcon
          styles={_styles}
          role="img"
          title="View all"
          aria-label={`View all ${
            to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
          } products`}
        />
      </ViewAllText>
    </LinkComponent>
  );
}

const defaultStyles = {
  color: "#000",
  fontSize: "16px",
  fontWeight: "700",
  textDecoration: "none",
  textDecorationColor: "#000",
};

const ViewAllText = styled.span`
  display: flex;
  color: ${({styles}) => styles.color};
  font-size: ${({styles}) => styles.fontSize};
  font-weight: ${({styles}) => styles.fontWeight};
  text-decoration: ${({styles}) => styles.textDecoration};
  text-decoration-color: ${({styles}) => styles.textDecorationColor};
  align-items: center;
  white-space: nowrap;
`;

const ChevronIcon = styled(ChevronRightIcon)`
  color: ${({styles}) => styles.color};
  height: 10px;
  width: 10px;
`;

ViewAllLink.defaultProps = {
  styles: defaultStyles,
  to: {},
  keepQuery: true,
  accessibilityIdentifier: "",
  isDesktop: true,
  LinkComponent: ({to, children}) => <a href={to}>{children}</a>,
};
ViewAllLink.propTypes = {
  styles: PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textDecoration: PropTypes.string,
    textDecorationColor: PropTypes.string,
  }),
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  keepQuery: PropTypes.bool,
  accessibilityIdentifier: PropTypes.string,
  isDesktop: PropTypes.bool,
  LinkComponent: PropTypes.elementType,
};
export default ViewAllLink;
