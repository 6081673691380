import {makeThemeProps} from "src/themes/utils/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getDealsListProps(theme, site);
};

export function dealTitleCardStyles(theme, site) {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getDealTitleCardProps(theme, site);
}
