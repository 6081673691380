import store from "@deals/state/store";
import {useEffect, useState} from "react";
import {requestPromotionProducts} from "@deals/state/actions";

export default function usePromotionProducts() {
  const dispatch = store.dispatch;
  const [state, setState] = useState(store.getState().promotionProducts);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState().promotionProducts);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const fetchPromotionProducts = (promotionId, options) => {
    dispatch(requestPromotionProducts(promotionId, options));
  };

  return {
    fetchPromotionProducts,
    state,
  };
}
