import React from "react";
import QuantityPicker from "@ui/components/ProductDetail/BigQuantityPicker";
import {renderNodeOrComponent} from "src/core/common/utils";
import useComponentOverride from "src/core/common/hooks/useComponentOverride";

export default function ProductDetailActions(props) {
  const {Component: ProductDetailActionsOverride} = useComponentOverride(
    "product-detail-actions"
  );

  if (ProductDetailActionsOverride) {
    return renderNodeOrComponent(ProductDetailActionsOverride, props);
  }

  return <QuantityPicker {...props} />;
}
