import TimeRange from "./timeRange";

class DeliverySlot {
  constructor(deliverySlotObject) {
    this.deliverySlotObject = deliverySlotObject;
  }

  get timeRange() {
    return new TimeRange(
      this.deliverySlotObject.start_time,
      this.deliverySlotObject.end_time
    );
  }

  get isAvailable() {
    return this.deliverySlotObject.is_available;
  }

  equals(other) {
    return other === this;
  }
}

export default DeliverySlot;
