const MARKETING_SOURCES = "marketing_sources";
const PAYMENT_OPTIONS = "payment_options";
const ABOUT_ITEMS = "about_items";
const DELIVERY_SLOT_GROUPS = "delivery_slot_groups";
const BRAND = "brand";
const PROMOTIONAL_BANNERS = "promotional_banners";
const PRODUCT_DETAIL = "product_detail";
const SOCIALS = "socials";
const PAGES = "pages";
const FILTERS_CATALOG = "filters_catalog";
const PRODUCTS_LIST = "products_list";
const RECOMMMENDED_PRODUCTS_LIST = "recommended_products_list";
const ORDERS_LIST = "orders_list";
const AVAILABLE_REWARDS = "available_rewards";
const AVAILABLE_PROMOTIONS = "available_promotions";
const DOCUMENTS = "documents";
const LOYALTY = "loyalty";
const REWARDS = "rewards";
const SEARCH_INPUT = "search_input";

const PATHNAME = "wp-json/sidebar-nav/store/";

export default {
  PATHNAME,

  MARKETING_SOURCES,
  PAYMENT_OPTIONS,
  ABOUT_ITEMS,
  DELIVERY_SLOT_GROUPS,
  BRAND,
  PROMOTIONAL_BANNERS,
  PRODUCT_DETAIL,
  SOCIALS,
  PAGES,
  FILTERS_CATALOG,
  PRODUCTS_LIST,
  RECOMMMENDED_PRODUCTS_LIST,
  ORDERS_LIST,
  AVAILABLE_REWARDS,
  AVAILABLE_PROMOTIONS,
  DOCUMENTS,
  LOYALTY,
  REWARDS,
  SEARCH_INPUT,
};
