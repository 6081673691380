import React from "react";
import get from "lodash/get";
import styled from "styled-components";
import {DisplayOnly, SortingProductsListHeader, Tabs, NoResultsPlaceholder} from "@ui";
import SortAndFilterHeader from "@themes/default/components/SortAndFilterHeader/index.js";
import useSearchBrowser from "@mock/hooks/useSearchBrowser";
import SearchList from "@themes/default/components/SearchBrowser/SearchList";
import useTheme from "@mock/hooks/useTheme.js";
import {
  searchBrowserStyles,
  noResultsPlaceholderStyles,
} from "@themes/default/components/SearchBrowser/styles";

export default function SearchBrowser() {
  const {
    site,
    filters,
    clearAllFilters,
    PageHead,
    TitleComponent,
    title,
    EraserIcon,
    tabs,
    showPlaceholder,
    numberOfResults,
    selected,
  } = useSearchBrowser();
  const theme = useTheme();

  const searchStyles = searchBrowserStyles(theme, site);
  const productsListHeaderStyles = get(searchStyles, "searchPage", {});

  return (
    <Container>
      {filters.search && (
        <div className="title">
          <PageHead title={title} />
          <TitleComponent
            styles={{
              fontSize: {lg: "52px", md: "52px", sm: "32px"},
              textAlign: "initial",
            }}
          >
            {title}
          </TitleComponent>
          <ClearSearch onClick={clearAllFilters} styles={searchStyles.searchPage.header}>
            <EraserIcon color={searchStyles.searchBar.input.searchIcon.color} />
            <span>Clear search</span>
          </ClearSearch>
        </div>
      )}
      <div className="tabs">
        <Tabs tabs={tabs} styles={searchStyles?.searchPage?.tabs} />
      </div>
      <div className="filters">
        <DisplayOnly dims={["lg", "md"]}>
          <SortingProductsListHeader
            styles={productsListHeaderStyles}
            numberOfResults={numberOfResults}
            SortFiltersComponent={
              <div className="filter-container">
                <SortAndFilterHeader
                  filtersDisplayOptions={selected === "brands" && {brandsVisible: false}}
                />
              </div>
            }
          />
        </DisplayOnly>
      </div>
      <div className="browser">
        {showPlaceholder() ? (
          <NoResultsPlaceholder styles={noResultsPlaceholderStyles(theme, site)} />
        ) : (
          <ListContainer>
            <SearchList selected={selected} />
          </ListContainer>
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px 32px 0 32px;

  .title {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .tabs {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      padding-top: 40px;
    }

    @media (max-width: 767px) {
      padding-top: 16px;
    }
  }

  .filter-container {
    margin-top: 32px;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding: 20px 16px 0 16px;

    .product-card__header-container,
    .product-card__skeleton {
      margin: 0 auto;
    }

    .product-card__skeleton {
      display: block;
    }
  }
`;

const ClearSearch = styled.div`
  display: flex;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
`;

const ListContainer = styled.div`
  padding-top: 20px;
`;
