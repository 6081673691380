import DefaultBrandDetailPage from "@themes/default/pages/BrandDetailPage";
import React from "react";
import {createGlobalStyle} from "styled-components";

export default function BrandDetailPage(props) {
  return (
    <>
      <GlobalStyles />
      <DefaultBrandDetailPage {...props} />;
    </>
  );
}

const GlobalStyles = createGlobalStyle`
    .sort-and-filter-header__container {
        padding: 0 !important;
    }
`;
