import React from "react";
import styled, {useTheme} from "styled-components";
import ProductsList from "src/themes/baseTheme/components/ShowCaseProductsList/ProductsList/ProductList";
import get from "lodash/get";
import {useInView} from "react-intersection-observer";

export default function BaseThemeShowCaseSection(props) {
  const {name, description, slug, url} = props;

  const theme = useTheme();

  const {ref, inView} = useInView({triggerOnce: true});

  const displayList = inView || props.forceDisplay;

  return (
    <ProductListContainer ref={ref}>
      {displayList && (
        <ProductsList
          slug={slug}
          url={url}
          categoryName={name}
          description={description}
          themeProperties={props.themeProperties}
          elementsPerRow={get(theme.v1, "components.productList.productsPerRow", null)}
          {...props}
        />
      )}
    </ProductListContainer>
  );
}

const ProductListContainer = styled.div`
  min-height: 600px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;
