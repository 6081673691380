import React from "react";
import {PromotionalBanner} from "@ui";
import usePromotionalBanners from "@mock/hooks/usePromotionalBanners";

export default function BoostThemePromotionalBanner() {
  const {banners, dimensions, AdComponent, siteUrl} = usePromotionalBanners();

  return (
    <PromotionalBanner
      siteUrl={siteUrl}
      banners={banners}
      dimensions={dimensions}
      AdComponent={AdComponent}
    />
  );
}
