import store from "@deals/state/store";
import {useEffect, useState} from "react";
import {requestDeals} from "@deals/state/actions";

export default function useDeals() {
  const dispatch = store.dispatch;
  const [state, setState] = useState(store.getState().deals);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState().deals);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const fetchDeals = options => {
    dispatch(requestDeals(options));
  };

  return {
    fetchDeals,
    state,
  };
}
