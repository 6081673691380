// action types
export const ActionTypes = {
  SEARCH: "SEARCH",
  SEARCH_SUCCESS: "SEARCH_SUCCESS",
  SEARCH_FAILURE: "SEARCH_FAILURE",

  SEARCH_PREVIEW: "SEARCH_PREVIEW",
  SEARCH_PREVIEW_SUCCESS: "SEARCH_PREVIEW_SUCCESS",
  SEARCH_PREVIEW_FAILURE: "SEARCH_PREVIEW_FAILURE",

  REQUEST_PRODUCT_GROUPS: "REQUEST_SHOWCASED_GROUPS",
  REQUEST_PRODUCT_GROUPS_SUCCESSFUL: "REQUEST_SHOWCASED_GROUPS_SUCCESSFUL",
  REQUEST_PRODUCT_GROUPS_FAILURE: "REQUEST_SHOWCASED_GROUPS_FAILURE",

  REQUEST_GROUP_PRODUCTS: "REQUEST_GROUP_PRODUCTS",
  REQUEST_GROUP_PRODUCTS_SUCCESSFUL: "REQUEST_GROUP_PRODUCTS_SUCCESSFUL",
  REQUEST_GROUP_PRODUCTS_FAILURE: "REQUEST_GROUP_PRODUCTS_FAILURE",

  REQUEST_PRODUCTS: "REQUEST_PRODUCTS",
  REQUEST_PRODUCTS_SUCCESSFUL: "REQUEST_PRODUCTS_SUCCESSFUL",
  REQUEST_PRODUCTS_FAILURE: "REQUEST_PRODUCTS_FAILURE",
};

export function requestProducts({
  type,
  slug,
  limit,
  order,
  shop,
  filters,
  deliveryInfo,
  options,
}) {
  return {
    type: ActionTypes.REQUEST_PRODUCTS,
    payload: {
      type,
      slug,
      limit,
      order,
      shop,
      filters,
      deliveryInfo,
      options,
    },
  };
}

export function requestProductsSuccess({type, slug, data}) {
  return {
    type: ActionTypes.REQUEST_PRODUCTS_SUCCESSFUL,
    payload: {
      type,
      slug,
      data,
    },
  };
}

export function requestProductsFailure({error}) {
  return {
    type: ActionTypes.REQUEST_PRODUCTS_FAILURE,
    payload: {
      error,
    },
  };
}

export function requestGroupProducts({
  limit,
  order,
  filters,
  deliveryInfo,
  slug,
  type,
  options,
}) {
  return {
    type: ActionTypes.REQUEST_GROUP_PRODUCTS,
    payload: {
      limit,
      order,
      filters,
      deliveryInfo,
      slug,
      type,
      options,
    },
  };
}

export function requestGroupProductsSuccess({slug, type, data}) {
  return {
    type: ActionTypes.REQUEST_GROUP_PRODUCTS_SUCCESSFUL,
    payload: {
      slug,
      type,
      data,
    },
  };
}

export function requestGroupProductsFailure({slug, type, error}) {
  return {
    type: ActionTypes.REQUEST_GROUP_PRODUCTS_FAILURE,
    payload: {
      slug,
      type,
      error,
    },
  };
}

export function requestProductGroups({catalog, filters}, options = {}) {
  return {
    type: ActionTypes.REQUEST_PRODUCT_GROUPS,
    payload: {
      catalog,
      filters,
      options,
    },
  };
}

export function requestProductGroupsSuccess(groups) {
  return {
    type: ActionTypes.REQUEST_PRODUCT_GROUPS_SUCCESSFUL,
    payload: {
      groups,
    },
  };
}

export function requestProductGroupsFailure(error) {
  return {
    type: ActionTypes.REQUEST_PRODUCT_GROUPS_FAILURE,
    payload: {
      error,
    },
  };
}

// action creators
export function searchPreview(term, options) {
  return {
    type: ActionTypes.SEARCH_PREVIEW,
    payload: {
      term,
      options,
    },
  };
}

export function searchPreviewSuccess(data) {
  return {
    type: ActionTypes.SEARCH_PREVIEW_SUCCESS,
    payload: data,
  };
}

export function searchPreviewFailure(error) {
  return {
    type: ActionTypes.SEARCH_PREVIEW_FAILURE,
    payload: error,
  };
}

// action creators
export function search(term, options) {
  return {
    type: ActionTypes.SEARCH,
    payload: {
      term,
      options,
    },
  };
}

export function searchSuccess(data, type) {
  return {
    type: ActionTypes.SEARCH_SUCCESS,
    payload: {data, type},
  };
}

export function searchFailure(error, type) {
  return {
    type: ActionTypes.SEARCH_FAILURE,
    payload: {error, type},
  };
}
