import React from "react";
import LazyRender from "src/core/common/components/utils/LazyRender";
import ShowCaseSection from "@menu/components/ThemedShowCaseSection";

export default function FlightShowCaseProductsList({
  type = "category",
  groups,
  hasGroups,
  keepQuery,
  themeProperties,
  styles,
}) {
  const _groups = (groups || []).filter(g => type === "all" || g.type === type);

  if (!hasGroups) {
    return [1, 2].map((v, index) => (
      <ShowCaseSection
        key={index}
        loading={true}
        keepQuery={keepQuery}
        themeProperties={themeProperties}
        styles={styles}
      />
    ));
  } else {
    return _groups.map((group, index) => (
      <LazyRender key={group.name} forceDisplay={true} minHeight={"450px"}>
        <ShowCaseSection
          {...group}
          forceDisplay={index === 0}
          keepQuery={keepQuery}
          themeProperties={themeProperties}
          styles={styles}
        />
      </LazyRender>
    ));
  }
}
