import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import {CartIcon, ChevronDownIcon} from "../Icons";
import Skeleton from "react-loading-skeleton";

function MobileHeader({styles, Icon, openTitle, closedTitle, totalPrice, open, loading}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container styles={_styles.container}>
      {Icon && <Icon size={"18px"} />}
      <Title styles={_styles.title}>{open ? openTitle : closedTitle}</Title>
      <ArrowDown open={open} size={"12px"} padding={"0px"} />
      {loading ? (
        <LoadingPriceWrapper>
          <LoadingPrice />
        </LoadingPriceWrapper>
      ) : (
        <TotalPrice styles={_styles.totalPrice}>{totalPrice}</TotalPrice>
      )}
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "mobile-header",
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({styles}) => styles.background};
  border-bottom: ${({styles}) => styles.borderBottom};
  gap: ${({styles}) => styles.gap};
  padding: ${({styles}) => styles.padding};
`;

const Title = styled.div.attrs(() => ({
  className: "mobile-header__title",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-style: ${({styles}) => styles.fontStyle};
  color: ${({styles}) => styles.color};
  font-size: ${({styles}) => styles.fontSize};
`;

const ArrowDown = styled(ChevronDownIcon).attrs(() => ({
  className: "mobile-header_arrow-down-icon",
}))`
  transition: all 0.4s;
  transform: ${({open}) => (open ? "rotate(180deg)" : "rotate(0deg)")};
`;

const LoadingPriceWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

const LoadingPrice = styled(Skeleton).attrs(() => ({
  className: "mobile-header__total-price-loading",
}))`
  width: 60px;
  height: 27px;
`;

const TotalPrice = styled.div.attrs(() => ({
  className: "mobile-header__total-price",
}))`
  flex: 1;
  text-align: end;

  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-style: ${({styles}) => styles.fontStyle};
  color: ${({styles}) => styles.color};
  letter-spacing: ${({styles}) => styles.letterSpacing};
  line-height: ${({styles}) => styles.lineHeight};
  font-size: ${({styles}) => styles.fontSize};
`;

// This component only shows up on small devices, so the values should use the SM tokens.
const defaultStyles = {
  container: {
    background: "#f5f5f5",
    borderBottom: "1px solid #d9d9d9",
    padding: "14px",
    gap: "8px",
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    color: "#0000000",
  },
  totalPrice: {
    fontFamily: "sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    color: "#333333",
    letterSpacing: "-0.2px",
    lineHeight: "28px",
  },
};

MobileHeader.defaultProps = {
  openTitle: "Hide order summary",
  closedTitle: "Show order summary",
  Icon: CartIcon,
};

MobileHeader.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  totalPrice: PropTypes.string,
  open: PropTypes.bool,
  styles: PropTypes.shape({
    container: PropTypes.shape({
      background: PropTypes.string,
      borderBottom: PropTypes.string,
      padding: PropTypes.string,
      gap: PropTypes.string,
    }),
    title: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
    }),
    totalPrice: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      letterSpacing: PropTypes.string,
    }),
  }),
};

export default MobileHeader;
