import merge from "lodash/merge";

export const DEFAULT_FLOWER_TYPE_INDICATOR_COLORS = {
  indicaColor: "#7C71AD",
  sativaColor: "#535744",
  indicaDominantColor: "#996174",
  sativaDominantColor: "#676378",
  hybridColor: "#529951",
  contrastColor: "#FFF",
};

export const getFlowerBackgroundColor = (flowerType, flowerTypeIndicatorColors) => {
  switch (flowerType) {
    case "Indica Dominant":
      return flowerTypeIndicatorColors.indicaDominantColor;
    case "Indica":
      return flowerTypeIndicatorColors.indicaColor;
    case "Sativa Dominant":
      return flowerTypeIndicatorColors.sativaDominantColor;
    case "Sativa":
      return flowerTypeIndicatorColors.sativaColor;
    case "Hybrid":
      return flowerTypeIndicatorColors.hybridColor;
    default:
      return;
  }
};

export const getFlowerIconColors = (flowerType, flowerTypeIndicatorColors) => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor,
        outerBgColor: flowerTypeIndicatorColors.contrastColor,
        innerBgColor: getFlowerBackgroundColor(flowerType, flowerTypeIndicatorColors),
      };
    case "Sativa Dominant":
    case "Sativa":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor,
        outerBgColor: getFlowerBackgroundColor(flowerType, flowerTypeIndicatorColors),
        innerBgColor: flowerTypeIndicatorColors.contrastColor,
      };
    case "Hybrid":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor,
        outerBgColor: flowerTypeIndicatorColors.contrastColor,
        innerBgColor: getFlowerBackgroundColor(flowerType, flowerTypeIndicatorColors),
      };
    default:
      return;
  }
};

export function getFlowerTypeIndicatorProps(site) {
  const flowerTypeIndicatorColors = merge(
    {},
    DEFAULT_FLOWER_TYPE_INDICATOR_COLORS,
    site.getUiConfiguration().flowerTypeIndicator
  );
  return {
    getBackground: flowerType =>
      getFlowerBackgroundColor(flowerType, flowerTypeIndicatorColors),
    getColor: () => flowerTypeIndicatorColors.contrastColor,
    getIconColors: flowerType =>
      getFlowerIconColors(flowerType, flowerTypeIndicatorColors),
    displayAbbreviation: flowerTypeIndicatorColors.displayAbbreviation,
  };
}
