import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
export default function Card({children, ...props}) {
  return (
    <Container
      styles={props.styles}
      $loading={props.loading}
      zoomOnHover={props.zoomOnHover}
      showHeader={props.showHeader}
    >
      {children}
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "card__container",
  "data-keep-cart": "true",
}))`
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background-color: white;

  margin-top: ${({showHeader}) => (showHeader ? "40px" : "0px")};

  opacity: ${({$loading}) => ($loading ? 0.75 : 1)};

  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  box-shadow: ${({styles}) => styles.boxShadow};

  font-family: ${({styles}) => styles.fontFamily};

  ${media.up("lg")} {
    min-width: ${({styles}) => styles.minWidth.lg};
    max-width: ${({styles}) => styles.maxWidth.lg};
  }

  ${media.down("md")} {
    min-width: ${({styles}) => styles.minWidth.md};
    max-width: ${({styles}) => styles.maxWidth.md};
  }

  ${media.down("sm")} {
    min-width: ${({styles}) => styles.minWidth.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
  }

  ${media.up("lg")} {
    height: ${({styles}) => styles.height?.lg || "auto"};
  }

  ${media.down("md")} {
    height: ${({styles}) => styles.height?.md || "auto"};
  }

  ${media.down("sm")} {
    height: ${({styles}) => styles.height?.sm || "auto"};
  }

  display: flex;
  flex-direction: column;

  &:hover {
    ${media.up("md")} {
      transform: scale(${({zoomOnHover}) => (zoomOnHover ? 1.05 : 1)});
      box-shadow: ${({displayShadow, styles}) =>
        styles.onHoverBoxShadow ||
        (displayShadow ? "0 2px 18px 0 rgba(0, 0, 0, 0.3)" : "none")};
      border: ${({styles}) => styles.hoverBorder};
    }
  }
`;
