import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import PropTypes from "prop-types";
import Sort from "./Sort";
import Filters from "./Filters";
import merge from "lodash/merge";
import spacing from "@ui/utils/spacing";
import {widthLimit} from "@ui/components/Decorators/widthLimited";

function SortFilters({
  enabledFilters,
  filtersCount,
  onChangeFilters,
  FiltersButtonComponent,
  orderOptions,
  currentSortOrder,
  onChangeOrder,
  DropdownComponent,
  mode,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container styles={_styles.root} mode={mode}>
      <SortWrapper styles={_styles.sortWrapper}>
        <Sort
          orderOptions={orderOptions}
          currentOrder={currentSortOrder}
          onChangeOrder={onChangeOrder}
          DropdownComponent={DropdownComponent}
          styles={_styles.sort}
        />
      </SortWrapper>
      {enabledFilters && (
        <Filters
          filtersCount={filtersCount}
          onClick={onChangeFilters}
          styles={_styles.filters}
          FiltersButtonComponent={FiltersButtonComponent}
        />
      )}
    </Container>
  );
}

const defaultStyles = {
  root: {
    padding: {
      lg: `0 0 ${spacing(4)} ${spacing(4)}`,
      md: `0 0 ${spacing(2)} ${spacing(2)}`,
      sm: `0 0 ${spacing(2)} 0`,
    },
    margin: `0 0 ${spacing(2)} 0`,
  },
  sortWrapper: {
    margin: `0 ${spacing(2)} 0 0`,
  },
  sort: {},
  filters: {},
};

const SortWrapper = styled.div.attrs(() => ({
  className: "sort-filters__wrapper",
  "data-keep-cart": "true",
}))`
  margin: ${({styles}) => styles.margin};
`;

const Container = styled.div.attrs(() => ({
  className: "sort-filters__container",
  "data-keep-cart": "true",
}))`
  ${({mode}) =>
    mode === "full-width"
      ? `
    max-width: ${widthLimit} !important;
    margin: 0 auto;
    box-sizing: border-box;`
      : ""};

  display: flex;
  padding: ${({styles}) => styles.padding.lg};
  align-items: center;

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    z-index: 4;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    gap: 8px;
    margin: ${({styles}) => styles.margin};
    padding: ${({styles}) => styles.padding.sm};

    ${SortWrapper} {
      margin-right: 0;
    }
  }
`;

SortFilters.defaultProps = {
  styles: defaultStyles,
  enabledFilters: true,
  mode: "default",
};
SortFilters.propTypes = {
  mode: PropTypes.oneOf(["default", "full-width"]),
  enabledFilters: PropTypes.bool,
  filtersCount: PropTypes.number,
  onChangeFilters: PropTypes.func,
  FiltersButtonComponent: PropTypes.elementType,
  orderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  currentSortOrder: PropTypes.string,
  onChangeOrder: PropTypes.func,
  DropdownComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      margin: PropTypes.string,
    }),
    sortWrapper: PropTypes.shape({
      margin: PropTypes.string,
    }),
    sort: PropTypes.shape({
      root: PropTypes.shape({
        width: PropTypes.shape({
          lg: PropTypes.string,
          sm: PropTypes.string,
        }),
        control: PropTypes.shape({
          padding: PropTypes.string,
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            sm: PropTypes.string,
          }),
          fontFamily: PropTypes.string,
          borderColor: PropTypes.string,
          borderRadius: PropTypes.string,
          borderWidth: PropTypes.string,
          color: PropTypes.string,
          backgroundColor: PropTypes.string,
          lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          hover: PropTypes.shape({
            borderColor: PropTypes.string,
          }),
        }),
        menu: PropTypes.shape({
          backgroundColor: PropTypes.string,
          margin: PropTypes.string,
          padding: PropTypes.string,
          borderRadius: PropTypes.string,
          border: PropTypes.string,
        }),
        options: PropTypes.shape({
          color: PropTypes.string,
          fontSize: PropTypes.shape({
            lg: PropTypes.string,
            sm: PropTypes.string,
          }),
          fontFamily: PropTypes.string,
          fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          hover: PropTypes.shape({
            backgroundColor: PropTypes.string,
          }),
          selected: PropTypes.shape({
            backgroundColor: PropTypes.string,
          }),
        }),
        arrow: PropTypes.shape({
          borderColor: PropTypes.string,
          open: PropTypes.shape({
            borderColor: PropTypes.string,
          }),
        }),
      }),
    }),
    filters: PropTypes.shape({
      root: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        border: PropTypes.string,
        borderRadius: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        textTransform: PropTypes.string,
        width: PropTypes.string,
        hoverBorderColor: PropTypes.string,
      }),
      secondary: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
      }),
      large: PropTypes.shape({
        padding: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
  }),
};

export default SortFilters;
