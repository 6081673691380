import useSite from "src/core/sites/hooks/useSite";
import React from "react";
import {get as iconSwitcher} from "@ui/utils/iconSwitcher";

export function DeliveryComponent(props) {
  const site = useSite();
  const Icon = get(site.getUiConfiguration().icons.delivery);
  return <Icon {...props} />;
}

export const get = iconSwitcher;
