import React, {useEffect, useState} from "react";
import styled from "styled-components";
import * as ReactDOM from "react-dom";
import Menu from "./Menu";

function SideMenu({logo, isOpen, close}) {
  const [container, setContainer] = useState();

  useEffect(() => {
    setContainer(document.getElementById(SIDE_MENU_PORTAL_DIV));
  }, [setContainer]);

  return container ? (
    ReactDOM.createPortal(
      <div>
        <Overlay isOpen={isOpen} onClick={close} />
        <Menu logo={logo} isOpen={isOpen} close={close} />
      </div>,
      container
    )
  ) : (
    <div />
  );
}
const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  transform: translate3d(${({isOpen}) => (isOpen ? "0" : "100%")}, 0, 0);
  opacity: ${({isOpen}) => (isOpen ? "0.6" : "0")};
  transition: opacity 300ms ease-out;
`;

const SIDE_MENU_PORTAL_DIV = "side-menu";

export default SideMenu;
