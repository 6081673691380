import {makeThemeProps} from "@themes/boost/props/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getShowcasedProductListProps(theme, site);
};

export function categoryCardStyles(theme, site) {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getShowcasedProductListCategoryCardProps?.(theme, site) || {};
}
