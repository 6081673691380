import React from "react";
import styled, {useTheme} from "styled-components";
import Link from "src/core/common/components/modules/Link";
import {ArrowRightShort} from "@styled-icons/bootstrap/ArrowRightShort";
import useIsDesktop from "@ui/utils/useIsDesktop";
import merge from "lodash/merge";

export default function BaseThemeViewAllLink({
  to,
  keepQuery,
  accessibilityIdentifier,
  themeProperties,
  text = "View All",
  showIcon = true,
  ...props
}) {
  const isDesktop = useIsDesktop();

  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.viewAll)};

  return (
    <CustomLink
      className={props.className}
      aria-label={`${text} ${
        to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
      } products`}
      to={to}
      keepQuery={keepQuery}
      color={props.color}
      underlined={
        props.underlined !== undefined ? props.underlined : props.defaultUnderlined
      }
    >
      <LinkContainer themeProperties={themeProperties}>
        <ViewAllText color={props.color} showIcon={showIcon} theme={theme}>
          <span>{isDesktop || !showIcon ? text : ""}</span>
          {showIcon && (
            <ArrowRightShortIcon
              color={props.color}
              role="img"
              title={text}
              aria-label={`${text} ${
                to && to.params
                  ? to.params.category
                  : `category ${accessibilityIdentifier}`
              } products`}
            />
          )}
        </ViewAllText>
      </LinkContainer>
    </CustomLink>
  );
}

const LinkContainer = styled.div`
  border: solid;
  border-color: ${({theme}) =>
    !theme.v1.components.viewAll
      ? theme.v2.color.border
      : theme.v1.components.viewAll.borderColor ?? theme.v2.color.border};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  border-width: ${({theme}) =>
    !theme.v1.components.viewAll
      ? "3px"
      : theme.v1.components.viewAll.borderWidth ?? "2px"};

  padding: 8px;
  display: flex;

  @media (min-width: 1025px) {
    min-width: 100px;
    height: 38px;
    padding: 8px 18px;
    border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  }
`;

const CustomLink = styled(Link)`
  text-decoration: ${({underlined}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;

const ViewAllText = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: ${({theme}) => theme.v2.typography.title5.size.lg};
  font-family: ${({theme}) => theme.v2.typography.title5.family};
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  align-items: center;
  white-space: nowrap;
  @media (min-width: 1025px) {
    > span {
      padding-left: ${({theme, showIcon}) => (showIcon ? theme.v2.spacing(2) : 0)};
    }
  }
`;

const ArrowRightShortIcon = styled(ArrowRightShort)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  height: 24px;
  width: 24px;
`;
