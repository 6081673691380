import React from "react";
import styled, {useTheme} from "styled-components";
import {CarouselProductsList, ProductListHeader} from "@ui";
import styles from "src/themes/baseTheme/components/ShowCaseProductsList/ProductsList/styles";
import ThemedViewAllLink from "@menu/components/ThemedViewAllLink";
import useSite from "src/core/sites/hooks/useSite";
import {BaseThemeTitle} from "src/themes/baseTheme/elements/Title";
import useShowcaseProductsList from "src/core/common/hooks/useShowcaseProductsList";

export default function BaseThemeProductsList({
  categoryName,
  description,
  url,
  slug,
  type,
  ...props
}) {
  const theme = useTheme();
  const site = useSite();

  const baseProps = useShowcaseProductsList({
    inView: true,
    slug,
    type,
    url,
    filters: props.filters,
  });

  const productsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm,
  };

  return (
    <CarouselProductsList
      {...props}
      {...baseProps}
      styles={styles(theme, site)}
      maxProductsPerSlide={productsPerSlide}
      HeaderComponent={
        <ProductListHeader
          ViewAllComponent={
            <CustomViewAllLink
              accessibilityIdentifier={slug}
              to={url}
              keepQuery={true}
              defaultUnderlined={false}
            />
          }
          TitleComponent={BaseThemeTitle}
          title={categoryName}
          description={description}
        />
      }
    />
  );
}

let CustomViewAllLink = styled(ThemedViewAllLink)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  text-decoration: ${({underlined = false}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;
