import TymberAPIConfig from "./config";
import {makeJsonAPIPayload, v1, v3} from "./utils";

const Endpoints = {
  v1: {
    login: () => v1("auth/login/"),
    register: () => v1("auth/register/"),
    recoverPassword: () => v1("auth/recover_password/"),
    resetPassword: resetToken => v1(`auth/reset_password/${resetToken}`),
    changePassword: () => v1(`auth/reset_password/`),
    user: () => v1("users/me/"),
    billing: () => v1("users/me/billing/"),
    identityDocuments: () => v1(`users/me/documents/`),
    rewards: () => v1(`users/me/rewards/`),
    identityDocument: type => v1(`users/me/documents/${type}/`),
    accountVerification: () => v1("auth/verification/"),
    accountVerificationCheck: () => v1("auth/verification-check/"),
    identityDocumentUploadUrl: type => v1(`users/me/documents/${type}/upload-url/`),
    identityDocumentUpdateUrl: type => v1(`users/me/documents/${type}/url/`),
    identityDocumentUploadStatus: (type, token) =>
      v1(`users/me/documents/${type}/url/${token}/`),
    identityVerification: () => v1("users/me/identity-verification/"),
    identityVerificationTransaction: service =>
      v1(`users/me/identity-verification/${service}`),
    identityVerificationConfiguration: service =>
      v1(`store/integrations/identity-verification/${service}/configuration`),
  },
  v3: {
    loyalty: () => v3(`users/me/loyalty/`),
  },
};

export function identityDocumentUploadUrl({type, filename}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    filename: filename,
  });
  return axios
    .post(Endpoints.v1.identityDocumentUploadUrl(type), jsonApiPayload)
    .then(response => response.data);
}

export function identityDocumentUpdateUrl({type, key}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    key: key,
  });
  return axios
    .post(Endpoints.v1.identityDocumentUpdateUrl(type), jsonApiPayload)
    .then(response => response.data);
}

export function identityDocumentUploadStatus({type, token}) {
  const axios = TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.identityDocumentUploadStatus(type, token))
    .then(response => response.data);
}

export function login({email, phoneNumber, password, accessToken}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    email: email || undefined,
    phone_number: phoneNumber,
    password: password,
    password_confirmation: password,
    accessToken,
  });
  return axios.post(Endpoints.v1.login(), jsonApiPayload).then(response => response.data);
}

export function register({
  email,
  password,
  firstName,
  lastName,
  zipCode,
  phoneNumber,
  dateOfBirth,
  medicalIdNumber,
  medicalIdExpirationDate,
  medicalIdState,
  driversLicenseIdNumber,
  driversLicenseIdExpirationDate,
  marketingSource,
  marketingEmailOptIn,
  marketingSmsOptIn,
  signature,
  stateResidency,
}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    email: email || undefined,
    password: password,
    password_confirmation: password,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    zip_code: zipCode,
    date_of_birth: dateOfBirth ? dateOfBirth.getTime() : null,
    marketing_source: marketingSource,
    marketing_email_opt_in: marketingEmailOptIn,
    marketing_sms_opt_in: marketingSmsOptIn,
    state_residency: stateResidency,
    medical_id: medicalIdNumber
      ? {
          number: medicalIdNumber,
          //state: medicalIdState,
          expiration_date: medicalIdExpirationDate
            ? medicalIdExpirationDate.toISOString().split("T")[0]
            : null,
        }
      : undefined,
    drivers_license_id: driversLicenseIdNumber
      ? {
          number: driversLicenseIdNumber,
          expiration_date: driversLicenseIdExpirationDate
            ? driversLicenseIdExpirationDate.toISOString().split("T")[0]
            : null,
        }
      : undefined,
    base64_signature: signature,
  });
  return axios
    .post(Endpoints.v1.register(), jsonApiPayload)
    .then(response => response.data);
}

export function recoverPassword({email, phoneNumber}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    email: email || undefined,
    phone_number: phoneNumber || undefined,
  });
  return axios
    .post(Endpoints.v1.recoverPassword(), jsonApiPayload)
    .then(response => response.data);
}

export function resetPassword({token, password, phoneNumber, email, code}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    code: code || undefined,
    phone_number: phoneNumber || undefined,
    password,
    password_confirmation: password,
    email,
  });
  return axios
    .post(
      token ? Endpoints.v1.resetPassword(token) : Endpoints.v1.changePassword(),
      jsonApiPayload
    )
    .then(response => response.data);
}

export function changePassword(currentPassword, password, confirmPassword) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    current_password: currentPassword,
    password,
    password_confirmation: confirmPassword,
  });
  return axios
    .post(Endpoints.v1.changePassword(), jsonApiPayload)
    .then(response => response.data);
}

export function addIdentityDocument(formData) {
  const axios = TymberAPIConfig.axios();
  return axios.post(Endpoints.v1.identityDocuments(), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

async function getBase64(url) {
  const axios = TymberAPIConfig.axios();
  const Buffer = await import("buffer").then(mod => mod.Buffer);
  return axios
    .get(url, {
      responseType: "arraybuffer",
    })
    .then(response => Buffer.from(response.data, "binary").toString("base64"));
}

export function getIdentityDocument(type) {
  return getBase64(Endpoints.v1.identityDocument(type))
    .then(response => `data:image/png;base64, ${response}`)
    .catch(() => null);
}

export function user(payload) {
  const _axios =
    payload && payload.jwt
      ? TymberAPIConfig.createAxiosInstance({
          ...TymberAPIConfig.headers,
          Authorization: `Bearer ${payload.jwt}`,
        })
      : TymberAPIConfig.axios();
  return _axios.get(Endpoints.v1.user()).then(response => response.data);
}

export function updateBilling({address}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload(
    "billing",
    {
      address: address,
    },
    undefined,
    ""
  );
  return axios
    .patch(Endpoints.v1.billing(), jsonApiPayload)
    .then(response => response.data);
}

export function getBilling() {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.billing()).then(response => response.data);
}

export function updateUser({
  firstName,
  lastName,
  zipCode,
  address,
  billingAddress,
  dateOfBirth,
  marketingSmsOptIn,
  marketingEmailOptIn,
  driversLicenseIdNumber,
  driversLicenseIdExpirationDate,
  medicalIdNumber,
  medicalIdState,
  medicalIdExpirationDate,
  signature,
  customerType,
}) {
  const axios = TymberAPIConfig.axios();
  // exclude email and phone number for now since backend does not support it
  const jsonApiPayload = makeJsonAPIPayload(
    "users",
    {
      first_name: firstName,
      last_name: lastName,
      zip_code: zipCode,
      address: address,
      billing_address: billingAddress,
      date_of_birth: dateOfBirth ? dateOfBirth.getTime() : undefined,
      marketing_email_opt_in: marketingEmailOptIn,
      marketing_sms_opt_in: marketingSmsOptIn,
      medical_id: medicalIdNumber
        ? {
            number: medicalIdNumber,
            //state: medicalIdState,
            expiration_date: medicalIdExpirationDate
              ? medicalIdExpirationDate.toISOString().split("T")[0]
              : null,
          }
        : undefined,
      drivers_license_id: driversLicenseIdNumber
        ? {
            number: driversLicenseIdNumber,
            expiration_date: driversLicenseIdExpirationDate
              ? driversLicenseIdExpirationDate.toISOString().split("T")[0]
              : null,
          }
        : undefined,
      customer_type: customerType || undefined,
      base64_signature: signature,
    },
    undefined,
    ""
  );
  return axios.put(Endpoints.v1.user(), jsonApiPayload).then(response => response.data);
}

export function deactivateUser() {
  const axios = TymberAPIConfig.axios();
  return axios.delete(Endpoints.v1.user());
}

export function accountVerification({phoneNumber, email}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    phone_number: phoneNumber,
    email: email,
  });
  return axios
    .post(Endpoints.v1.accountVerification(), jsonApiPayload)
    .then(response => response.data);
}

export function accountVerificationCheck(params) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("users", {
    code: params.code,
    email: params.email,
    phone_number: params.phoneNumber,
    password: params.password,
    password_confirmation: params.password,
    first_name: params.firstName,
    last_name: params.lastName,
    date_of_birth: params.dateOfBirth ? params.dateOfBirth.getTime() : undefined,
    marketing_source: params.marketingSource,
    marketing_email_opt_in: params.marketingEmailOptIn,
    marketing_sms_opt_in: params.marketingSmsOptIn,
    state_residency: params.stateResidency,
    identity_verification_id: params.identityVerificationId,
    drivers_license_id: params.driversLicenseIdNumber
      ? {
          number: params.driversLicenseIdNumber,
          expiration_date: params.driversLicenseIdExpirationDate
            ? params.driversLicenseIdExpirationDate.toISOString().split("T")[0]
            : null,
        }
      : undefined,
  });
  return axios
    .post(Endpoints.v1.accountVerificationCheck(), jsonApiPayload)
    .then(response => response.data);
}

export function rewards() {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.rewards()).then(response => response.data);
}

export function loyalty() {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v3.loyalty()).then(response => response.data);
}

export function identityVerification() {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.identityVerification()).then(response => response.data);
}

export function getBerbixTransaction() {
  const axios = TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.identityVerificationTransaction("berbix"))
    .then(response => response.data);
}

export function createIdentityVerificationTransaction({service, identityVerificationId}) {
  const axios = TymberAPIConfig.axios();
  const jsonApiPayload = makeJsonAPIPayload("identity_verifications", {
    identity_verification_id: identityVerificationId,
  });
  return axios
    .post(Endpoints.v1.identityVerificationTransaction(service), jsonApiPayload)
    .then(response => response.data);
}

export function getIdentityServiceConfiguration(service) {
  const axios = TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.identityVerificationConfiguration(service))
    .then(response => response.data);
}
