import React, {useMemo} from "react";
import useRouter from "src/core/common/hooks/useRouter";
import BaseAppProvider from "src/apps/common/BaseAppProvider";
import useSiteInitialization from "src/apps/common/useSiteInitialization";

function AppProvider({initialSite, host, siteGroupName, children}) {
  const {shop} = useRouter().query;

  const identifier = shop ? `${host?.host}-${shop}` : host?.host;

  const {site} = useSiteInitialization({identifier, initialSite, siteGroupName});

  const routeParams = useMemo(() => ({shop: shop}), [shop]);

  return (
    <BaseAppProvider
      appIdentifier={identifier}
      site={site}
      host={host}
      routeParams={routeParams}
    >
      {children}
    </BaseAppProvider>
  );
}

export default AppProvider;
