import {formatPrice} from "src/core/common/price";
import routes from "src/core/common/routes";
import useBrandPath from "src/menu/hooks/useBrandPath";
import {
  getFlowerBackgroundColor,
  getFlowerIconColors,
} from "src/themes/baseTheme/elements/flowerType";
import useSite from "src/core/sites/hooks/useSite";

export default function useProductInfo({
  product,
  quantity,
  weight,
  priceDisplay = false,
}) {
  const [getBrandPath] = useBrandPath();
  const site = useSite();

  if (!product) return {};

  const image = {
    url: product.getMainImage(),
    name: `${product.getBrandName()} - ${product.getName()}`,
  };
  const brand = {
    brandName: product.getBrandName(),
    brandPath: getBrandPath(product),
  };
  const productName = product.getName();
  const sizes = {
    position: site.getUiConfiguration().productDetail.sizes.position,
    content: product.getSizes()?.map(size => size.toString()) || [],
  };
  const mainTags = {
    flowerTypeBefore: site.getUiConfiguration().productDetail.flowerTypeIndicator.before,
    flowerType: product.getFlowerType(),
    hideNameOnSmallerScreens: false,
    showFlowerTypeIcon:
      site.getUiConfiguration().productDetail.flowerTypeIndicator.showIcon,
    tags: product.getDetailedComposition(),
    flowerTypeIndicatorProps: {
      getFlowerIconColors,
      getBackground: getFlowerBackgroundColor,
      getColor: () => "#FFF",
    },
  };
  const description = {
    text: product.getDescription(),
    readMoreText: "Show more",
  };
  const price =
    !priceDisplay &&
    formatPrice(product.getFinalPrice(weight ? weight.value : null, quantity));
  const validForSale = product.validForSale();
  const secondaryTags = product && [
    {
      name: product.getMainCategory().getName(),
      url: {
        pathname: routes.productCategory,
        params: {category: product.getMainCategory().getSlug()},
      },
      keepQuery: true,
    },
    ...product.getTags().map(tag => ({
      name: tag.getName(),
      url: {
        pathname: routes.products,
        query: {tag: tag.getName()},
      },
      keepQuery: false,
    })),
  ];

  return {
    image,
    brand,
    productName,
    sizes,
    mainTags,
    description,
    price,
    validForSale,
    secondaryTags,
  };
}
