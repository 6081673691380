import {getDealDetailUrl} from "../../meta";

function makeDealSchemaObject(deal, site, ctx) {
  return {
    "@context": "https://schema.org",
    "@type": "Offer",
    url: getDealDetailUrl(deal, ctx),
    name: deal.title,
    description: deal.description,
    image: deal.image,
    category: deal.type,
  };
}

export default makeDealSchemaObject;
