import styles, {
  noResultsPlaceholderStyles,
} from "src/themes/flight/components/ProductsList/styles";
import {MultiRowProductsList} from "@ui";
import React from "react";
import styled, {useTheme} from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import Link from "src/core/common/components/modules/Link";
import BaseRecommendedProductsList from "@menu/components/BaseRecommendedProductsList";
import useThemeConfig from "src/themes/useThemeConfig";

export default function FlightThemeRecommendedProductsList({filters, title, linkToAll}) {
  const theme = useTheme();
  const site = useSite();

  const config = useThemeConfig();

  const Header = title ? (
    <HeaderContainer>
      <Link to={linkToAll} underlined={true}>
        {title}
      </Link>
    </HeaderContainer>
  ) : null;

  const _styles = styles(theme, site);
  _styles.root.elementsPerRow = {
    lg: 3,
    md: 3,
    sm: 1,
  };

  return (
    <BaseRecommendedProductsList
      limit={3}
      offset={0}
      filters={filters}
      render={({meta, products}) => (
        <>
          {(meta.loading || products.length) > 0 && (
            <Container skeleton={meta.loading}>
              <MultiRowProductsList
                loading={false}
                styles={_styles}
                products={products}
                skeleton={meta.loading}
                ProductCardComponent={config.components.ProductCard}
                next={() => {}}
                hasMore={false}
                noResultsPlaceholderProps={{
                  styles: noResultsPlaceholderStyles(theme, site),
                }}
                LoadingComponent={CustomLoader}
                HeaderComponent={Header}
              />
            </Container>
          )}
        </>
      )}
    />
  );
}

const Container = styled.div`
  max-width: 700px;
  align-self: flex-start;

  .product-card__header-container {
    margin: 0 auto;
  }
  li {
    text-align: ${({skeleton}) => (skeleton ? "center" : "unset")};
  }
`;
const HeaderContainer = styled.div`
  > a {
    color: ${({theme}) => theme.v2.color.typography.bodyText2} !important;
  }
`;
const CustomLoader = () => null;
