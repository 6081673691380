import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useSite from "src/core/sites/hooks/useSite";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import useAddons from "src/core/common/hooks/useAddons";

const getCategoryBarPosition = theme => {
  switch (theme) {
    case "boost":
    case "colourful":
      return "top";
    case "medleaf":
    case "garden":
    case "sense":
    case "default":
    default:
      return "bottom";
  }
};

export default function useBasePage(currentPage) {
  const toggles = useFeatureToggles();
  const site = useSite();
  const {showAdBanner} = useAdBanner();
  const {addons} = useAddons("base-page");

  const themeName = site.getBaseTheme().name;
  const showAnnouncementMessage =
    site.getOptions().hasAnnouncementMessage() && currentPage !== "deal-detail";
  const showBanner =
    themeName !== "sense" && themeName !== "garden" && currentPage !== "deal-detail";
  const showCategoryBar = currentPage !== "deal-detail";
  const categoryBarPosition = getCategoryBarPosition(themeName);
  return {
    kioskMode: toggles.kioskMode(),
    showAnnouncementMessage,
    showBanner,
    showAdBanner,
    showCategoryBar,
    categoryBarPosition,
    addons,
  };
}
