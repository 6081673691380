import responsiveStyle from "@ui/utils/responsiveStyle";

function fontStyles(fontProperties) {
  let styles = "";

  Object.keys(fontProperties).forEach(key => {
    const cssProperty = cssProperties[key];
    if (cssProperty) {
      styles += responsiveStyle(cssProperty, fontProperties[key]);
    }
  });

  return styles;
}

const cssProperties = {
  fontWeight: "font-weight",
  fontSize: "font-size",
  color: "color",
  fontFamily: "font-family",
  lineHeight: "line-height",
};

export default fontStyles;
