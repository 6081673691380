import {createSelector} from "reselect";
import AuthenticationConfig from "../config";
import memoize from "lodash/memoize";

export function authenticationStateSelector(state) {
  return state.authentication;
}

export const isLoggingInSelector = createSelector(authenticationStateSelector, state =>
  Boolean(state.loading)
);

export const isLoggedInSelector = createSelector(authenticationStateSelector, state =>
  Boolean(state.data)
);

export const isValidatingSelector = createSelector(
  authenticationStateSelector,
  state => state.validating
);

export function profileStateSelector(state) {
  return state.profile;
}

export const profileSelector = createSelector(profileStateSelector, state => {
  return state.data ? makeUser(state.data) : null;
});
const makeUser = memoize(profileData => {
  const UserClass = AuthenticationConfig.getUserClass();
  return new UserClass(profileData);
});

export function recoverPasswordStateSelector(state) {
  return state.recoverPassword;
}

export const isInitialized = state => state.initialized;
