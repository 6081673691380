import store from "src/menu/state/store";
import {useEffect, useState} from "react";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import {requestProductGroups} from "src/menu/state/actions";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";
import useDeliveryInfo from "src/core/deliveries/hooks/useDeliveryInfo";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import {getInitialGroups} from "@menu/utils/getInitialGroups";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

function useProductGroups(options = {displayBrands: false}, type) {
  const toggles = useFeatureToggles();
  const [filters] = useAppliedFilters();
  const {data} = useServerContext();

  const {dispatch} = store;

  const [state, setState] = useState(
    getInitialGroups({
      prefetchedGroups: data[PrefetchedDataKeys.SHOWCASED_GROUPS],
      currentParams: filters?.serialize(),
      currentGroups: store.getState().productGroups,
    })
  );

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const newState = store.getState().productGroups;

      if (!newState.groups.length && state?.prefetched) return;

      setState(store.getState().productGroups);
    });
    return () => {
      unsubscribe();
    };
  }, [state]);

  const [catalog] = useFiltersCatalog({filters: filters?.serialize()});
  const deliveryInfo = useDeliveryInfo();

  useEffect(() => {
    if (!deliveryInfo) return;
    dispatch(requestProductGroups({catalog, filters}, {reset: true, ...options}));
  }, [catalog?.toString(), deliveryInfo]);

  if (type) {
    state.groups = (state.groups || []).filter(g => g.type === type);
  }

  return {...state, disableLazyRender: toggles.isKiosk()};
}

export default useProductGroups;
