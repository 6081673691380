import React from "react";
import {ModalCloseButton, ModalExternalContainer} from "./modalComponents";
import {ModalContext} from "./modalContext";
import useThemeConfig from "src/themes/useThemeConfig";

function StyledModal(props) {
  const config = useThemeConfig();
  const ThemedModal = config.components.Modal;

  return <ThemedModal {...props} />;
}

function Modal({
  children,
  isOpen,
  container = ModalExternalContainer,
  onRequestClose,
  closeModalOverride,
  allowedToClose = () => true,
  styles = {},
}) {
  const closeModal = useCloseModal({allowedToClose, onRequestClose});
  const Container = container;
  const closeModalFunction = closeModalOverride || closeModal;
  return (
    <ModalContext.Consumer>
      {({hidden, allowedToClose}) => (
        <StyledModal
          isOpen={isOpen}
          hidden={hidden}
          onRequestClose={allowedToClose ? closeModalFunction : null}
          styles={styles}
        >
          <Container>
            {allowedToClose && <ModalCloseButton alt={""} onClick={closeModalFunction} />}
            {typeof children === "function" ? children({closeModal}) : children}
          </Container>
        </StyledModal>
      )}
    </ModalContext.Consumer>
  );
}

export function useCloseModal({allowedToClose = () => true, onRequestClose}) {
  function closeModal() {
    if (allowedToClose()) {
      onRequestClose && onRequestClose();
    }
  }

  return closeModal;
}

export default Modal;
