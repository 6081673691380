import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import {CloseIcon} from "@ui/components/Icons";

export default function AppliedFiltersList({
  filters,
  weights,
  setFilter,
  clearFilters,
  FilterParams,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const hasPriceFilters = filters.hasPriceFilter();
  const hasThcPotencyFilters = filters.hasThcPotencyFilter();
  const hasCbdPotencyFilters = filters.hasCbdPotencyFilter();

  const [minPrice, maxPrice] = filters.prices;
  const productPriceFilters = hasPriceFilters ? `$${minPrice} -$${maxPrice}` : null;

  function removePriceFilter() {
    setFilter(
      [FilterParams.PRODUCT_MIN_PRICE, FilterParams.PRODUCT_MAX_PRICE],
      [false, false]
    );
  }

  const hasOnSaleFilter = filters.onSale;

  function removeFilter(filterString, paramName, value) {
    const newFilter = getFilters(filterString)
      .filter(v => v !== value)
      .join(",");
    setFilter(paramName, newFilter);
  }

  const [minThcPotency, maxThcPotency] = filters.potencyThc;
  const productThcPotencyFilters = hasThcPotencyFilters
    ? `THC: ${minThcPotency}${filters.thcUnit} - ${maxThcPotency}${filters.thcUnit} `
    : null;

  function removeThcPotencyFilter() {
    setFilter(
      [FilterParams.PRODUCT_MIN_THC_POTENCY, FilterParams.PRODUCT_MAX_THC_POTENCY],
      [false, false]
    );
  }

  const [minCbdPotency, maxCbdPotency] = filters.potencyCbd;
  const productCbdPotencyFilters = hasCbdPotencyFilters
    ? `CBD: ${minCbdPotency}${filters.cbdUnit}  - ${maxCbdPotency}${filters.cbdUnit} `
    : null;

  function removeCbdPotencyFilter() {
    setFilter(
      [FilterParams.PRODUCT_MIN_CBD_POTENCY, FilterParams.PRODUCT_MAX_CBD_POTENCY],
      [false, false]
    );
  }

  function removeOnSaleFilter() {
    setFilter([FilterParams.PRODUCT_ON_SALE], [false]);
  }

  const filterTypes = [
    {
      data: getFilters(filters.brands),
      removeHandler: filter =>
        removeFilter(filters.brands, FilterParams.PRODUCT_BRANDS, filter),
    },
    {
      data: getFilters(filters.types),
      removeHandler: filter =>
        removeFilter(filters.types, FilterParams.PRODUCT_TYPE, filter),
    },
    {
      data: getFilters(filters.tags),
      removeHandler: filter =>
        removeFilter(filters.tags, FilterParams.PRODUCT_TAG, filter),
    },
    {
      data: weights,
      removeHandler: filter =>
        removeFilter(filters.weights, FilterParams.PRODUCT_WEIGHT, extractValue(filter)),
    },
  ];

  return (
    <AppliedFiltersContainer styles={styles.root}>
      {hasOnSaleFilter && (
        <AppliedFilter styles={_styles} name={"On Sale"} remove={removeOnSaleFilter} />
      )}
      {filterTypes.map(({data, removeHandler}, index) => (
        <React.Fragment key={index}>
          {data.map(filter => (
            <AppliedFilter
              styles={_styles}
              key={filter}
              name={filter}
              remove={() => removeHandler(filter)}
            />
          ))}
        </React.Fragment>
      ))}
      {productThcPotencyFilters && (
        <AppliedFilter
          styles={_styles}
          name={productThcPotencyFilters}
          remove={removeThcPotencyFilter}
        />
      )}
      {productCbdPotencyFilters && (
        <AppliedFilter
          styles={_styles}
          name={productCbdPotencyFilters}
          remove={removeCbdPotencyFilter}
        />
      )}
      {productPriceFilters && (
        <AppliedFilter
          styles={_styles}
          name={productPriceFilters}
          remove={removePriceFilter}
        />
      )}
      {filters.hasFilters() && <ClearFilters styles={_styles} clear={clearFilters} />}
    </AppliedFiltersContainer>
  );
}

function getFilters(filterString) {
  return (filterString || "").split(",").filter(name => name !== "");
}

function extractValue(input) {
  const value = input.match(/\d+(\.\d+)?/);
  return value ? value[0] : null;
}

function AppliedFilter({name, remove, styles}) {
  return (
    <FilterButtonContainer styles={styles.container}>
      <FilterText styles={styles.text}>{name}</FilterText>
      <CloseIcon
        onClick={remove}
        color={styles.closeIcon.color}
        margin={styles.closeIcon.margin}
        size={styles.closeIcon.size}
      />
    </FilterButtonContainer>
  );
}

AppliedFilter.propTypes = {
  name: PropTypes.string,
  remove: PropTypes.func,
  styles: PropTypes.shape({
    container: PropTypes.shape({}),
    text: PropTypes.shape({}),
    closeIcon: PropTypes.shape({
      color: PropTypes.string,
      margin: PropTypes.string,
      size: PropTypes.string,
    }),
  }),
};

function ClearFilters({clear, styles}) {
  return (
    <FilterButtonContainer onClick={clear} styles={styles.container}>
      <FilterText styles={styles.text}>Clear All</FilterText>
    </FilterButtonContainer>
  );
}

ClearFilters.propTypes = {
  clear: PropTypes.func,
  styles: PropTypes.shape({
    container: PropTypes.shape({}),
    text: PropTypes.shape({}),
  }),
};

const FilterButtonContainer = styled.div.attrs(() => ({
  className: "applied-filters-list__filter-button",
  "data-keep-cart": "true",
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({styles}) => styles.padding};
  margin-right: 16px;
  border: 0;
  border-radius: ${({styles}) => styles.borderRadius};
  cursor: pointer;
  background-color: ${({styles}) => styles.backgroundColor};
  color: ${({styles}) => styles.color};
`;
const AppliedFiltersContainer = styled.div.attrs(() => ({
  className: "applied-filters-list__applied-filters",
  "data-keep-cart": "true",
}))`
  display: flex;
  align-items: center;
  flex-flow: wrap;

  ${FilterButtonContainer} {
    margin: ${({styles}) => styles.margin};
  }
`;

const FilterText = styled.span.attrs(() => ({
  className: "applied-filters-list__filter-text",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  root: {
    margin: `0 ${spacing(4)} 0 0`,
  },
  container: {
    padding: `${spacing(1)} ${spacing(2)}`,
    borderRadius: "20px",
    backgroundColor: "#E0E0E0",
    color: "#000000",
  },
  text: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "14px",
      sm: "12px",
    },
    fontWeight: "400",
  },
  closeIcon: {
    size: "16px",
    margin: `0 0 0 ${spacing(1)}`,
  },
};

AppliedFiltersList.defaultProps = {
  styles: defaultStyles,
};

AppliedFiltersList.propTypes = {
  filters: PropTypes.shape({
    hasPriceFilter: PropTypes.func,
    hasThcPotencyFilter: PropTypes.func,
    hasCbdPotencyFilter: PropTypes.func,
    hasFilters: PropTypes.func,
    prices: PropTypes.arrayOf(PropTypes.number),
    potencyThc: PropTypes.arrayOf(PropTypes.number),
    potencyCbd: PropTypes.arrayOf(PropTypes.number),
    onSale: PropTypes.bool,
    brands: PropTypes.string,
    types: PropTypes.string,
    tags: PropTypes.string,
  }),
  filterParams: PropTypes.object,
  setFilter: PropTypes.func,
  clearFilters: PropTypes.func,
  FilterParams: PropTypes.shape({
    CATEGORY: PropTypes.string,
    PRODUCT_TYPE: PropTypes.string,
    PRODUCT_BRANDS: PropTypes.string,
    PRODUCT_TAG: PropTypes.string,
    PRODUCT_SEARCH: PropTypes.string,
    PRODUCT_MIN_PRICE: PropTypes.string,
    PRODUCT_MAX_PRICE: PropTypes.string,
    PRODUCT_MIN_THC_POTENCY: PropTypes.string,
    PRODUCT_MAX_THC_POTENCY: PropTypes.string,
    PRODUCT_ON_SALE: PropTypes.string,
    PRODUCT_ORDER: PropTypes.string,
  }),
  styles: PropTypes.shape({
    root: PropTypes.shape({
      margin: PropTypes.string,
    }),
    container: PropTypes.shape({
      padding: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
    }),
    text: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    closeIcon: PropTypes.shape({
      margin: PropTypes.string,
    }),
  }),
};
