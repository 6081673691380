import {useEffect, useState} from "react";
import {useRouter} from "next/router";
import autoBind from "auto-bind";
import EventBus from "src/core/common/eventBus";
import {isClient} from "src/server/utils/isClient";
import useSite from "src/core/sites/hooks/useSite";

class CustomPageManager {
  constructor() {
    autoBind(this);
    this.eventBus = new EventBus();
    this.previous = this.getInitialDisplayCustomPage();
    this.displayCustomPage = this.getInitialDisplayCustomPage();
  }

  subscribe(handler) {
    return this.eventBus.subscribe(handler);
  }

  getInitialDisplayCustomPage() {
    if (!isClient) return false;

    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get("instore_experience") === "true";
  }

  setDisplayCustomPage(displayCustomPage, allowRestore = false) {
    if (allowRestore) {
      this.previous = this.displayCustomPage;
    } else {
      this.previous = displayCustomPage;
    }
    this.displayCustomPage = displayCustomPage;
    this.eventBus.notify({displayCustomPage: this.displayCustomPage});
  }

  restore() {
    this.setDisplayCustomPage(this.previous, false);
  }
}

const customPageManager = new CustomPageManager();

export default function useCustomHome() {
  const site = useSite();
  const options = site.getOptions();
  const router = useRouter();

  const [displayCustomHome, setDisplayCustomHome] = useState(
    customPageManager.displayCustomPage
  );

  useEffect(() => {
    return customPageManager.subscribe(({displayCustomPage}) => {
      setDisplayCustomHome(displayCustomPage);
    });
  }, []);

  useEffect(() => {
    const handleRouteChange = url => {
      if (!options.hasInStoreExperienceEventFlow()) return;
      if (!isUrlAllowed(url)) {
        throw new Error(
          "Prevented linking to categories or brands, when in event flow mode."
        );
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  return [
    displayCustomHome,
    customPageManager.setDisplayCustomPage,
    customPageManager.restore,
  ];
}

const isUrlAllowed = url => {
  const ignoredPathNames = ["/categories", "/brands"];
  const ignoredQueryParams = ["tag"];

  const pathUrl = new URL(url, "https://dummy");
  const queryParams = new URLSearchParams(pathUrl.search);

  return (
    !ignoredPathNames.some(url => pathUrl.pathname.includes(url)) &&
    !ignoredQueryParams.some(queryParam => queryParams.has(queryParam))
  );
};
