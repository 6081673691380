import BoostThemeShowcaseProductsList from "@themes/boost/components/ShowcaseProductsList";
import React from "react";

export default function ShowcaseProductsList(props) {
  return (
    <BoostThemeShowcaseProductsList
      showIndicatorsDesktop={true}
      showCategoryHeader={true}
      HeaderComponent={null}
      minHeight="450px"
      sectionSpacing="40px"
      {...props}
    />
  );
}
