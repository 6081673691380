import {isClient} from "src/server/utils/isClient";
import {getEnv} from "src/server/utils/getEnv";
import pickBy from "lodash/pickBy";
import * as Sentry from "@sentry/nextjs";
import {RELEASE_LEVELS} from "src/core/constants";
import path from "path";
import fs from "fs";
import uuid from "uuid/v4";
import absoluteUrl from "next-absolute-url";
import {getSiteInCluster} from "src/server/clusters";
import {getAxiosInstance} from "src/server/utils/getAxiosInstance";
import {getSite} from "src/server/preloader/site";
import {getPromotionalBanners} from "src/server/preloader/promotionalBanners";
import {getFilters} from "src/server/preloader/filters";
import {PrefetchedDataKeys} from "src/server/constants";
import makeSiteFromSiteData from "src/apps/common/makeSiteFromSiteData";

export function getIdFromSlug(slug) {
  if (!slug || typeof slug !== "string") return null;

  return parseInt(slug.split("-").slice(-1)[0]);
}

export function preloadEnabled() {
  return !isClient && process.env.REACT_APP_MODE !== "deliveries";
}

export function filterCacheKeys(shop, host, cache) {
  const env = getEnv(shop, host);
  return pickBy(cache, function (value, key) {
    return key.indexOf(env.REACT_APP_TYMBER_ECOMMERCE_ID) > -1;
  });
}

export const withCache =
  (argumentFunction, filename, resolver = defaultResolver) =>
  async (...params) => {
    if (
      isClient ||
      process.env.NODE_ENV !== RELEASE_LEVELS.DEVELOPMENT ||
      process.env.DISABLE_CACHE === "true"
    )
      return argumentFunction(...params);

    const CACHE_PATH = path.resolve("devCache/" + filename);

    const id = resolver(...params);

    let currentCache = {};

    try {
      currentCache = JSON.parse(fs.readFileSync(CACHE_PATH, "utf8"));
    } catch (error) {
      console.log("Home cache not initialized!");
    }

    if (!currentCache[id]) {
      currentCache[id] = await argumentFunction(...params);

      try {
        fs.writeFileSync(CACHE_PATH, JSON.stringify(currentCache), "utf8");
        console.log("Wrote to cache");
      } catch (error) {
        console.log("ERROR WRITING CACHE TO FILE");
        console.log(error);
      }
    }
    return currentCache[id];
  };

function defaultResolver(...params) {
  return JSON.stringify(params);
}

export const withTiming =
  (makeLabel, makeContext, argumentFunction) =>
  async (...params) => {
    if (process.env.DISABLE_TIMINGS === "true") {
      return argumentFunction(...params);
    } else {
      const timeoutValue = parseInt(process.env.NEXT_PUBLIC_TIMEOUT_THRESHOLD) || 7000;
      const label = makeLabel(...params);
      const ctx = makeContext(...params) || {};
      const timingLabel = `${label} ${uuid()}`;
      const timeoutId = setTimeout(() => {
        Sentry.withScope(function (scope) {
          scope.setLevel("warning");
          scope.setTag("next-instrumentation", true);
          if (ctx.storeId) {
            scope.setTag("store", ctx.storeId);
          }
          const scopeCtx = {
            timeout: timeoutValue,
            label: label,
            ...ctx,
          };
          scope.setContext("next-instrumentation-extra", scopeCtx);
          if (ctx.fingerprint) {
            scope.setFingerprint(ctx.fingerprint);
          }
          Sentry.captureMessage(ctx.message || "Timeout threshold exceeded");
        });
      }, timeoutValue);
      try {
        console.time(timingLabel);
        return await argumentFunction(...params);
      } finally {
        clearTimeout(timeoutId);
        console.timeEnd(timingLabel);
      }
    }
  };

export const makeContextForTimed = functionName => ctx => {
  const storeId = getStoreIdFromContext(ctx) || "";

  return {
    fingerprint: [functionName, storeId],
    storeId: storeId,
    message: `Timeout: ${functionName} ${storeId}`,
  };
};

export const makeTimedGetServerSideProps = fn => {
  const functionName = "getServerSideProps";
  return withTiming(
    ctx => `${functionName} @ ${absoluteUrl(ctx.req).origin}${ctx.resolvedUrl}`,
    makeContextForTimed(functionName),
    fn
  );
};

export const makeTimedGetStaticProps = fn => {
  const functionName = "getStaticProps";
  return withTiming(ctx => functionName, makeContextForTimed(functionName), fn);
};

export const makeTimedGetInitialProps = fn => {
  const functionName = "getInitialProps";
  return withTiming(
    ({router, ctx}) =>
      `${functionName} @ ${absoluteUrl(ctx.req).origin}${ctx.req?.url ?? "unknown"}`,
    makeContextForTimed(functionName),
    fn
  );
};

export const getStoreIdFromContext = ctx => {
  const context = ctx?.ctx;
  const router = ctx?.router;
  const params = ctx?.params || ctx?.query;
  if (context && router) {
    const {host} = absoluteUrl(context.req);
    const {shop} = router.query;

    const env = getEnv(shop, host);
    return env.REACT_APP_TYMBER_ECOMMERCE_ID;
  } else if (params) {
    const env = getEnv(params.shop, null);
    return env.REACT_APP_TYMBER_ECOMMERCE_ID;
  }

  return null;
};

export const getTags = (functionName, ctx) => ({
  server_function: functionName,
  store_id: getStoreIdFromContext(ctx),
});

export async function getEssentialData(shop, host, params = {}) {
  const siteConfig = getSiteInCluster(host);
  const axiosInstance = getAxiosInstance(shop, host);

  console.log(`Fetching essential data. Shop: ${shop}, Host: ${host}`);

  const site = await getSite(shop, host);

  const deliveryType = getDefaultDeliveryType(site);

  const [promotionalBanners, filters] = await Promise.all([
    getPromotionalBanners(axiosInstance),
    getFilters(axiosInstance, {delivery_type: deliveryType, ...params}),
  ]);

  return {
    prerenderData: {
      site,
      shop,
      host,
      siteGroupName: siteConfig.name,
      [PrefetchedDataKeys.FILTERS_CATALOG]: filters.data,
      [PrefetchedDataKeys.PROMOTIONAL_BANNERS]: promotionalBanners,
      metadata: {
        siteImage: site.site?.banner_url || site.site?.logo_url,
        siteTitle: site.site?.html_title,
        siteDescription: site.site?.meta_description,
        favicon: site.site?.favicon_url,
      },
    },
    prefetchedData: {
      site,
      promotionalBanners,
      filters,
    },
  };
}

export const getCacheRevalidateInterval = () => 300;

export const getDefaultDeliveryType = siteData => {
  const siteOptions = makeSiteFromSiteData(siteData).getOptions();
  if (siteOptions.pickupEnabled() && !siteOptions.deliveriesEnabled()) {
    return "pickup";
  }

  return undefined;
};
