export default function applyShadow(shadow) {
  if (!shadow || typeof shadow === "string" || !shadow.length) return shadow || "none";

  const s2 = shadow[1] || shadow[0];
  const s1 = shadow[0];

  if (!s1) return "none";

  return `${s2.x}px ${s2.y}px ${s2.blur}px ${s2.spread}px  ${s2.color},
  ${s1.x}px ${s1.y}px ${s1.blur}px ${s1.spread}px ${s1.color}`;
}
