import makeSite from "src/core/common/tymberFactory/makeSite";
import {SiteEnv} from "src/core/common/models/siteEnv";

export default function makeSiteFromSiteData(siteData, extras = {}) {
  if (siteData.site) siteData.site.group_name = extras.siteGroupName;
  const site = makeSite(
    siteData.site || {},
    siteData.settings,
    siteData.groupSettings,
    siteData.theme
  );

  const env =
    extras.env ||
    new SiteEnv({...siteData.env, REACT_APP_TYMBER_KIOSK_ID: extras.kioskId});
  site.setEnv(env);
  return site;
}
