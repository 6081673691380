import React from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import styled from "styled-components";
import PromoTag from "../PromoTag";
import media from "@ui/utils/media";
import DropdownSelectInput from "../DropdownSelectInput";
import InfoNotification from "../CheckoutOrderInfo/InfoNotification";

function SelectRewardInput({
  styles,
  value,
  onChange,
  loading,
  isMulti,
  disabled,
  availableRewards,
  rewardsError,
  appliedRewards,
  onRemoveReward,
  showRewards,
  selectKey,
}) {
  const _styles = merge({}, defaultStyles, styles);

  if (!showRewards) {
    return null;
  }

  return (
    <div>
      <DropdownSelectInput
        placeholder={"Select Reward..."}
        key={selectKey}
        value={value}
        disabled={disabled}
        isLoading={loading}
        isMulti={isMulti}
        options={availableRewards}
        onChange={onChange}
        styles={_styles.dropdownSelectInput}
      />
      {!rewardsError && Boolean(appliedRewards) && appliedRewards.length > 0 && (
        <PromotionRow styles={_styles.promotionRow}>
          {appliedRewards.map(promo => (
            <PromoTag
              styles={_styles.promoTag}
              key={promo.value}
              label={promo.label}
              error={promo.error}
              disabled={loading}
              onRemove={() => onRemoveReward(promo)}
            />
          ))}
        </PromotionRow>
      )}
      {rewardsError && (
        <PromotionRow styles={_styles.promotionRow}>
          <InfoNotification
            title="Rewards unavailable"
            description={rewardsError}
            status="info"
            styles={styles.infoNotification}
          />
        </PromotionRow>
      )}
    </div>
  );
}

const PromotionRow = styled.div.attrs(() => ({
  className: "promo-codes__promotion-row",
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${media.up("lg")} {
    gap: ${({styles}) => styles.gap.lg};
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.between("md", "lg")} {
    gap: ${({styles}) => styles.gap.md};
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    gap: ${({styles}) => styles.gap.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;

// This component only shows up on small devices, so the values should use the SM tokens.
const defaultStyles = {
  promotionRow: {
    gap: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    padding: {
      lg: "14px 0px 0px 0px",
      md: "14px 0px 0px 0px",
      sm: "14px 0px 0px 0px",
    },
  },
};

SelectRewardInput.defaultProps = {
  selectKey: "1",
  showRewards: true,
};

SelectRewardInput.propTypes = {
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  showRewards: PropTypes.bool,
  onRemoveReward: PropTypes.func,
  availableRewards: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  rewardsError: PropTypes.string,
  appliedRewards: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      label: PropTypes.string,
      error: PropTypes.shape({
        message: PropTypes.string,
      }),
    })
  ),
  styles: PropTypes.shape({
    infoNotification: PropTypes.shape({
      icon: PropTypes.shape({
        color: PropTypes.string,
        error: PropTypes.shape({
          color: PropTypes.string,
        }),
        info: PropTypes.shape({
          color: PropTypes.string,
        }),
      }),
      title: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      description: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
      }),
      action: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
        background: PropTypes.string,
        border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    promotionRow: PropTypes.shape({
      gap: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    promoTag: PromoTag.propTypes,
  }),
};

export default SelectRewardInput;
