import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";

const CreditCardLabelComponent = ({styles, card}) => (
  <LabelContainer styles={styles.optionPicker.optionRow.labelContainer}>
    {card.icon}
    <div styles={{display: "flex", flexDirection: "row"}}>
      <Title expired={card.isExpired} styles={styles.optionPicker.optionRow.title}>
        {card.label}
      </Title>
      <Subtitle expired={card.isExpired} styles={styles.optionPicker.optionRow.subtitle}>
        {card.expiryDate}
      </Subtitle>
    </div>
  </LabelContainer>
);

const LabelContainer = styled.div`
  padding: ${({styles}) => styles.padding};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({expired, styles}) => (expired ? styles.expired.color : styles.color)};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Subtitle = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({expired, styles}) => (expired ? styles.expired.color : styles.color)};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

export default CreditCardLabelComponent;
