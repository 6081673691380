import get from "lodash/get";
import DeliveryConstants from "../../deliveries/constants";
import {DeliveryTypes} from "../../common/models/DeliveryType";
import {
  DeliveryAlternativeMessage,
  DeliveryModes,
  DeliveryModeUnavailableMessage,
  DeliveryModeUnavailableReason,
} from "./deliveryMode";
import Shop from "../../common/models/shop";

class AddressVerification {
  constructor(obj) {
    this.obj = obj;
  }

  getPreferredDeliveryLocation = (currentShop, mode, deliveryLocations) => {
    const currentDeliveryLocation = deliveryLocations.find(
      deliveryLocation =>
        currentShop &&
        this.getDeliveryLocationStoreId(deliveryLocation) === currentShop.getId()
    );
    if (currentDeliveryLocation) {
      return currentDeliveryLocation;
    } else {
      return deliveryLocations[0];
    }
  };
  getNumberOfProductsAvailable(currentShop, mode) {
    const deliveryLocations = this.getDeliveryLocations(mode);
    const location = this.getPreferredDeliveryLocation(
      currentShop,
      mode,
      deliveryLocations
    );
    return get(location, "attributes.available_products_at_zip_code_count", 0);
  }
  getAnyDeliveryShop(mode) {
    const deliveryLocations = this.getDeliveryLocations(mode);
    if (deliveryLocations && deliveryLocations.length) {
      const deliveryLocationStoreId = this.getDeliveryLocationStoreId(
        deliveryLocations[0]
      );
      return this.getShops(mode).find(store => store.getId() === deliveryLocationStoreId);
    } else {
      return null;
    }
  }
  getDeliveryLocationStoreId = deliveryLocation =>
    get(deliveryLocation, "relationships.store.data.id");

  getAnyDeliveryShopUrl({deliveryType, deliveryMode, address}, toggles) {
    const anyShop = this.getAnyDeliveryShop(deliveryMode);
    if (anyShop) {
      const baseUrl = toggles.pickupOnly()
        ? toggles.deliveriesPath()
        : anyShop.getMenuUrl();

      if (!baseUrl) return undefined;

      return `${baseUrl}?${DeliveryConstants.DELIVERY_TYPE_QUERY_PARAM}=${deliveryType}&${
        DeliveryConstants.DELIVERY_ADDRESS_QUERY_PARAM
      }=${address.base64()}&${
        DeliveryConstants.DELIVERY_MODE_QUERY_PARAM
      }=${deliveryMode}`;
    } else {
      return null;
    }
  }
  deliversToShop(shop, mode) {
    const shops = this.getShops(mode);
    if (shops && shops.length > 0 && shop) {
      const id = typeof shop === "string" ? shop : shop.getId();
      const thisShop = shops.find(otherShop => otherShop.getId() === id);
      return Boolean(thisShop);
    } else {
      return false;
    }
  }
  deliversToAny(mode) {
    return this.getShops(mode).length > 0;
  }

  getDeliveryLocations = mode => {
    return this.getPossibleDeliveryLocation(mode).filter(
      deliveryLocation =>
        deliveryLocation.attributes.available_products_at_zip_code_count > 0 &&
        deliveryLocation.attributes.delivers_to_zip_code
    );
  };

  getShops(mode) {
    return this.getDeliveryLocations(mode).map(deliveryLocation => {
      const deliveryLocationStoreId = this.getDeliveryLocationStoreId(deliveryLocation);
      const shopObj = this.obj.included.find(
        included => included.type === "stores" && included.id === deliveryLocationStoreId
      );

      return new Shop({data: shopObj, included: this.obj.included});
    });
  }

  getUnavailableReason(currentShop, mode) {
    const deliveryLocations = this.getPossibleDeliveryLocation(mode);
    const location = this.getPreferredDeliveryLocation(
      currentShop,
      mode,
      deliveryLocations
    );
    const isScheduleOpen = get(location, "attributes.open_schedule");
    const deliversToZipCode = get(location, "attributes.delivers_to_zip_code");

    if (mode === DeliveryModes.SCHEDULED) {
      return this.getScheduledUnavailableReason(location, isScheduleOpen);
    }

    if (mode === DeliveryModes.EXPRESS) {
      return this.getExpressUnavailableReason(
        location,
        deliversToZipCode,
        isScheduleOpen
      );
    }
  }

  getPossibleDeliveryLocation = mode =>
    this.obj.data.filter(
      deliveryLocation =>
        deliveryLocation.attributes.mode === mode || !deliveryLocation.attributes.mode
    );

  getScheduledUnavailableReason = (location, isScheduleOpen) =>
    !location || isScheduleOpen
      ? DeliveryModeUnavailableReason.NO_PRODUCTS
      : DeliveryModeUnavailableReason.CLOSED;

  getExpressUnavailableReason = (location, deliversToZipCode, isScheduleOpen) => {
    if (!location || !deliversToZipCode) {
      return DeliveryModeUnavailableReason.RESTRICTED_ZIP_CODE;
    } else if (isScheduleOpen && deliversToZipCode) {
      return DeliveryModeUnavailableReason.NO_PRODUCTS;
    } else {
      return DeliveryModeUnavailableReason.CLOSED;
    }
  };

  getAlternativeMode(currentShop, mode) {
    let alternativeMode;
    if (mode === DeliveryModes.SCHEDULED) {
      alternativeMode = DeliveryModes.EXPRESS;
    } else {
      alternativeMode = DeliveryModes.SCHEDULED;
    }
    const alternativeDeliveryLocations = this.getDeliveryLocations(alternativeMode);
    const alternativeLocation = this.getPreferredDeliveryLocation(
      currentShop,
      alternativeMode,
      alternativeDeliveryLocations
    );
    if (
      alternativeLocation &&
      alternativeLocation.attributes.delivers_to_zip_code &&
      alternativeLocation.attributes.available_products_at_zip_code_count > 0 &&
      alternativeLocation.attributes.open_schedule
    ) {
      return alternativeLocation.attributes.mode;
    }
    return "pickup";
  }

  getAlternativeText(alternativeMode, fallbackUrl) {
    const messages = {
      [DeliveryModes.EXPRESS]: DeliveryAlternativeMessage.DELIVERIES.EXPRESS,
      [DeliveryModes.SCHEDULED]: DeliveryAlternativeMessage.DELIVERIES.SCHEDULED,
      [DeliveryTypes.PICK_UP]: DeliveryAlternativeMessage.PICKUP,
    };
    if (messages[alternativeMode]) return messages[alternativeMode];
    return fallbackUrl
      ? DeliveryAlternativeMessage.FALLBACK.LINK
      : DeliveryAlternativeMessage.FALLBACK.NONE;
  }

  shopHandlesAddress(toggles, shop, mode) {
    if (toggles.pickupOnly()) return false;

    return (
      this.deliversToShop(shop, mode) ||
      (toggles.deliveriesOnly() && this.deliversToAny(mode))
    );
  }

  getSuccessMessage(shop, mode) {
    if (!this.deliversToShop(shop, mode) || !shop) return null;

    const productsAvailable = this.getNumberOfProductsAvailable(shop, mode);

    return `Good news, we deliver ${
      productsAvailable > 0 ? `${productsAvailable} products` : ""
    } to that address.`;
  }

  getErrorMessageWithoutAlternative(shop, mode) {
    const alternativeMode = this.getAlternativeMode(shop, mode);
    const unavailableReason = this.getUnavailableReason(shop, mode);

    if (alternativeMode === DeliveryTypes.PICK_UP)
      return DeliveryModeUnavailableMessage.DELIVERIES;

    if (mode === DeliveryModes.EXPRESS) {
      if (unavailableReason === DeliveryModeUnavailableReason.CLOSED)
        return DeliveryModeUnavailableMessage.EXPRESS.CLOSED;
      if (unavailableReason === DeliveryModeUnavailableReason.RESTRICTED_ZIP_CODE)
        return DeliveryModeUnavailableMessage.EXPRESS.RESTRICTED_ZIP_CODE;

      return DeliveryModeUnavailableMessage.EXPRESS.NO_PRODUCTS;
    } else if (unavailableReason === DeliveryModeUnavailableReason.CLOSED) {
      return DeliveryModeUnavailableMessage.SCHEDULED.CLOSED;
    } else {
      return DeliveryModeUnavailableMessage.SCHEDULED.NO_PRODUCTS;
    }
  }

  getErrorMessage(address, shop, mode, fallbackUrl) {
    if (address && !address.isValid()) {
      return "Please specify an address with a building number.";
    }

    const alternativeMode = this.getAlternativeMode(shop, mode);

    return `${this.getErrorMessageWithoutAlternative(
      shop,
      mode
    )}\n${this.getAlternativeText(alternativeMode, fallbackUrl)}`;
  }
}

export default function makeAddressVerification(addressVerificationObj) {
  return new AddressVerification(addressVerificationObj);
}
