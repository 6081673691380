import {PaymentOptions} from "src/core/payments/constants";
import Stronghold from "src/core/payments/stronghold/Stronghold";
import {BankPaymentSource} from "src/core/payments/models/paymentSource";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const StrongholdPaymentForm = suspensify(
  lazy(() => import("src/core/payments/stronghold/StrongholdPaymentForm"))
);
const SavingsIndicatorLabel = suspensify(
  lazy(() => import("src/core/payments/stronghold/SavingsIndicatorLabel"))
);
const PaymentPromotionBanner = suspensify(
  lazy(() => import("src/core/payments/stronghold/PaymentPromotionBanner"))
);

export default {
  type: PaymentOptions.STRONGHOLD,
  PaymentClass: Stronghold,
  PaymentForm: StrongholdPaymentForm,
  PaymentSource: {
    class: BankPaymentSource,
    options: {
      retryLabel: "Add new account",
      displayConvenienceFee: false,
    },
  },
  Promotions: {
    BannerComponent: PaymentPromotionBanner,
    SavingsLabelComponent: SavingsIndicatorLabel,
  },
};
