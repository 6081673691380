import React from "react";
import {CartButton, TopNavigationBar} from "@ui";
import DetailedSearchInputSection from "src/themes/baseTheme/elements/SearchInputSection/DetailedSearchInputSection";
import styles, {
  cartButtonBadgeStyles,
  cartButtonStyles,
  loginButtonStyles,
  searchButtonStyles,
} from "src/themes/baseTheme/components/Header/styles";
import {useTheme} from "styled-components";
import useHeader from "src/core/common/hooks/useHeader";
import merge from "lodash/merge";
import Link from "src/core/common/components/modules/Link";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useSite from "src/core/sites/hooks/useSite";
import {SEARCH_COMPONENT_DISPLAY} from "@ui/components/TopNavigationBar/TopNavigationBar";

function SenseCartButton(props) {
  return (
    <CartButton
      {...props}
      cartText={"cart"}
      hideIconInLargerViewport={true}
      highlightIfCartHasProducts={false}
    />
  );
}

export default function SenseHeader() {
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.appBar)};
  const site = useSite();

  const {
    logoUrl,
    logoLinkUrl,
    largeScreenOptions,
    sideMenu,
    hideMenuButton,
    onClickMenuButton,
    loginButtonProps,
    cartButtonProps,
  } = useHeader();

  const toggles = useFeatureToggles();

  return (
    <>
      <TopNavigationBar
        searchBarFirst={true}
        logoUrl={logoUrl}
        mobileLogoUrl={logoUrl}
        hideMenuButton={hideMenuButton}
        hideMenuButtonOnLargerViewport={
          site.getUiConfiguration().header.hideMenuButtonOnLargerViewport
        }
        hideLoginButton={toggles.isKiosk()}
        styles={styles(theme, site)}
        largeScreenOptions={largeScreenOptions}
        largeScreenOptionsAlignment={"right"}
        logoPosition={"left"}
        searchComponentDisplay={SEARCH_COMPONENT_DISPLAY.full}
        LinkComponent={Link}
        logoLinkUrl={logoLinkUrl}
        CartButtonComponent={SenseCartButton}
        SearchComponent={DetailedSearchInputSection}
        onClickMenuButton={onClickMenuButton}
        cartButtonProps={{
          ...cartButtonProps,
          styles: cartButtonStyles(theme, site),
          badgeStyles: cartButtonBadgeStyles(theme, site),
        }}
        searchButtonProps={{
          outline: false,
          styles: searchButtonStyles(theme, site),
        }}
        loginButtonProps={{
          ...loginButtonProps,
          styles: loginButtonStyles(theme, site),
        }}
      />
      {sideMenu}
    </>
  );
}
