import merge from "lodash/merge";

const DEFAULT_FLOWER_TYPE_INDICATOR_COLORS = {
  indicaColor: "#707EFD",
  sativaColor: "#E8C752",
  hybridColor: "#D352E8",
  contrastColor: "#ffffff",
};

const {indicaColor, sativaColor, hybridColor} = DEFAULT_FLOWER_TYPE_INDICATOR_COLORS;

export const getFlowerBackgroundColor = (
  flowerType,
  flowerTypeIndicatorColors,
  theme
) => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return flowerTypeIndicatorColors?.indicaColor || indicaColor;
    case "Sativa Dominant":
    case "Sativa":
      return flowerTypeIndicatorColors?.sativaColor || sativaColor;
    case "Hybrid":
      return flowerTypeIndicatorColors?.hybridColor || hybridColor;
    default:
      return;
  }
};

export const getFlowerIconColors = (flowerType, flowerTypeIndicatorColors, theme) => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor || "#FFF",
        outerBgColor: flowerTypeIndicatorColors.contrastColor || "#FFF",
        innerBgColor: flowerTypeIndicatorColors.indicaColor || indicaColor,
      };
    case "Sativa Dominant":
    case "Sativa":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor || "#FFF",
        outerBgColor: flowerTypeIndicatorColors.sativaColor || sativaColor,
        innerBgColor: flowerTypeIndicatorColors.contrastColor || "#FFF",
      };
    case "Hybrid":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor || "#FFF",
        outerBgColor: flowerTypeIndicatorColors.contrastColor || "#FFF",
        innerBgColor: flowerTypeIndicatorColors.hybridColor || hybridColor,
      };
    default:
      return;
  }
};

export function getFlowerTypeIndicatorProps(site) {
  const flowerTypeIndicatorColors = merge(
    {},
    DEFAULT_FLOWER_TYPE_INDICATOR_COLORS,
    site.getUiConfiguration?.().flowerTypeIndicator
  );
  return {
    getBackground: flowerType =>
      getFlowerBackgroundColor(flowerType, flowerTypeIndicatorColors),
    getColor: () => flowerTypeIndicatorColors.contrastColor,
    getIconColors: flowerType =>
      getFlowerIconColors(flowerType, flowerTypeIndicatorColors),
    displayAbbreviation: flowerTypeIndicatorColors.displayAbbreviation,
  };
}
