import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";
import merge from "lodash/merge";
import CategoryButton from "@ui/components/ScrollCategoryBar/CategoryButton";
import ScrollRightIcon from "./ScrollRightIcon";
import ScrollLeftIcon from "./ScrollLeftIcon";
import {useCurrentWidth} from "./WindowResizer";
import widthLimited from "../Decorators/widthLimited";
import {hexToRgbA} from "@ui/utils/colors";

function ScrollCategoryBar({
  styles,
  categories,
  CategoryButtonComponent,
  isCategorySelected,
  showIcon,
  fixed,
}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const listRef = useRef();
  const containerRef = useRef();
  const scrollOffset = 200;
  const _styles = merge({}, defaultStyles, styles);

  const buttonStyles = merge({}, _styles.button, {
    root: {height: _styles.root.height},
  });
  const currentWidth = useCurrentWidth();
  useEffect(() => {
    if (currentWidth) {
      setMaxScroll(currentWidth);
      if (containerRef.current) {
        containerRef.current.scroll(0, 0);
      }
    }
  }, [currentWidth, categories]);
  useEffect(() => {
    if (listRef.current && containerRef.current) {
      const listRect = listRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      setMaxScroll(listRect.width - containerRect.width);
    }
  }, [maxScroll]);

  const scrollTo = offset => {
    const nextPosition = Math.max(Math.min(scrollPosition + offset, maxScroll), 0);
    setScrollPosition(nextPosition);
  };

  return (
    <>
      <OuterContainer styles={_styles.root} fixed={fixed}>
        <Container ref={containerRef} styles={_styles.root}>
          <ScrollLeftButtonContainer
            showIcon={showIcon}
            scrollPosition={scrollPosition}
            styles={_styles.root}
          >
            <ScrollLeftButton
              showIcon={showIcon}
              onClick={() => scrollTo(-scrollOffset)}
              styles={_styles.root}
            >
              <ScrollLeftIcon />
            </ScrollLeftButton>
          </ScrollLeftButtonContainer>
          <CategoryList
            styles={_styles.root}
            scrollPosition={scrollPosition}
            maxScroll={maxScroll}
            ref={listRef}
          >
            {categories.map((category, index) => (
              <CategoryItemContainer
                styles={_styles.button.root}
                selected={isCategorySelected(category)}
                key={index}
              >
                <CategoryButtonComponent
                  selected={isCategorySelected(category)}
                  categoryName={category.name}
                  categoryUrl={category.url}
                  categoryImageUrl={category.imageUrl}
                  category={category}
                  styles={buttonStyles}
                />
              </CategoryItemContainer>
            ))}
          </CategoryList>
          <ScrollRightButtonContainer
            scrollPosition={scrollPosition}
            maxScroll={maxScroll}
            styles={_styles.root}
            showIcon={showIcon}
          >
            <ScrollRightButton
              showIcon={showIcon}
              onClick={() => scrollTo(scrollOffset)}
              styles={_styles.root}
            >
              <ScrollRightIcon />
            </ScrollRightButton>
          </ScrollRightButtonContainer>
        </Container>
      </OuterContainer>
      {fixed && <FixedPlaceholder styles={_styles.root} />}
    </>
  );
}

const defaultStyles = {
  root: {
    backgroundColor: "rgb(242, 242, 242)",
    minHeight: {
      lg: "60px",
      md: "60px",
      sm: "50px",
    },
    columnGap: {
      lg: spacing(2),
      md: spacing(2),
      sm: spacing(2),
    },
    fontWeight: "400",
    height: {
      lg: "60px",
      md: "60px",
      sm: "50px",
    },
  },
  button: {
    root: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "18px",
        md: "14px",
        sm: "12px",
      },
      color: "rgb(131, 131, 131)",
      textTransform: "capitalize",
      borderWidth: "4px",
      borderColor: "red",
    },
  },
};

const OuterContainer = styled.div.attrs(() => ({
  className: "category-button__outer-container",
}))`
  width: 100%;
  background-color: ${({styles}) => styles.backgroundColor};
  position: ${({fixed}) => (fixed ? "fixed" : "relative")};
  left: 0;
  z-index: ${({fixed}) => (fixed ? 2 : 0)};
`;

const Container = widthLimited(styled.div.attrs(({selected}) => ({
  className:
    "category-button__container" +
    (selected ? " category-button__container--selected" : ""),
}))`
  position: relative;
  overflow: hidden;
  height: ${({styles}) => styles.height.lg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
  }
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    overflow-x: scroll;
  }
`);

const CategoryList = styled.ul`
  margin: 0;
  list-style-type: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  flex-flow: nowrap;
  column-gap: ${({styles}) => styles.columnGap.lg};
  transform: translate3d(${({scrollPosition}) => -scrollPosition}px, 0, 0);
  position: absolute;
  overflow-y: hidden;
  transition: transform 0.5s;
  font-weight: ${({styles}) => styles.fontWeight};
  padding: 0 32px;
  top: 0;
  left: 0;

  ${media.down("md")} {
    column-gap: ${({styles}) => styles.columnGap.md};
    row-gap: ${({styles}) => styles.columnGap.md};
    padding: 0 16px;
  }
  ${media.down("sm")} {
    column-gap: ${({styles}) => styles.columnGap.sm};
    row-gap: ${({styles}) => styles.columnGap.sm};
    transform: translate3d(0, 0, 0);
  }
`;

const CategoryItemContainer = styled.div.attrs(() => ({
  className: "category-bar__category-item-container",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  color: ${({styles}) => styles.color};
  text-transform: ${({styles}) => styles.textTransform};
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;
const ScrollButton = styled.button`
  display: flex;
  align-items: center;
  z-index: 1;
  border: 0;
  height: 100%;
  background-color: ${({styles}) => styles.backgroundColor};
`;

const ScrollLeftButton = styled(ScrollButton)`
  margin-left: -1px;
`;
const ScrollLeftButtonContainer = styled.div`
  z-index: 1;
  visibility: ${({scrollPosition}) => (scrollPosition === 0 ? "hidden" : "visible")};
  display: flex;
  height: 100%;
  background-color: transparent;
  transform: ${({showIcon}) =>
    showIcon ? "translate(-10px, 0px)" : "translate(-32px, 0px)"};
  ${media.down("md")} {
    transform: translate(-16px, 0px);
  }
  &:after {
    width: 25px;
    height: 100%;
    content: "";
    margin-left: -5px;
    background: linear-gradient(
      to right,
      ${({styles}) => styles.backgroundColor} 20%,
      ${({styles}) => hexToRgbA(styles.backgroundColor, 0)} 80%
    );
  }

  ${media.down("sm")} {
    display: none;
  }
`;
const ScrollRightButton = styled(ScrollButton)`
  margin-right: -1px;
`;
const ScrollRightButtonContainer = styled.div`
  visibility: ${({scrollPosition, maxScroll}) =>
    scrollPosition >= maxScroll ? "hidden" : "visible"};
  display: flex;
  height: 100%;
  background-color: transparent;
  transform: ${({showIcon}) =>
    showIcon ? "translate(5px, 0px)" : "translate(32px, 0px)"};
  ${media.down("md")} {
    transform: translate(16px, 0px);
  }
  &:before {
    width: 50px;
    height: 100%;
    content: "";
    margin-right: -5px;
    background: linear-gradient(
      to left,
      ${({styles}) => styles.backgroundColor} 20%,
      ${({styles}) => hexToRgbA(styles.backgroundColor, 0)} 80%
    );
  }

  ${media.down("sm")} {
    display: none;
  }
`;
const FixedPlaceholder = styled.div`
  width: 100%;
  height: ${({styles}) => styles.height.lg};
  box-sizing: border-box;

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    padding: ${spacing(1)} 0;
  }

  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    padding: ${spacing(1)} 0;
  }
`;

ScrollCategoryBar.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      backgroundColor: PropTypes.string,
      minHeight: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      columnGap: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    button: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
      textTransform: PropTypes.string,
      borderBottom: PropTypes.string,
    }),
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ),
  CategoryButtonComponent: PropTypes.elementType,
  isCategorySelected: PropTypes.func,
  arrowOverflow: PropTypes.bool,
  fixed: PropTypes.bool,
  showIcon: PropTypes.bool,
};

ScrollCategoryBar.defaultProps = {
  styles: defaultStyles,
  categories: [],
  CategoryButtonComponent: CategoryButton,
  fixed: true,
};

export default ScrollCategoryBar;
