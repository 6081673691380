import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "./reducers";
import * as Sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(Sagas.verifyAddressSaga);
sagaMiddleware.run(Sagas.searchAddressSaga);
sagaMiddleware.run(Sagas.selectDeliveryAddressSaga);
sagaMiddleware.run(Sagas.selectDeliveryTypeSaga);
sagaMiddleware.run(Sagas.setDeliveryModeSaga);

export default store;
