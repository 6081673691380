import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import {Smartphone} from "@styled-icons/material-rounded/Smartphone";
import Link from "@ui/components/Footer/LinkComponent/LinkComponent";

export default function DownloadApp({url, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container>
      <TitleContainer styles={_styles}>
        <Smartphone />
        <Link to={{pathname: url}} external underlined>
          <Title styles={_styles.title}>Track your order with our APP</Title>
        </Link>
      </TitleContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
    color: ${({styles}) => styles.iconColor};
  }

  > a {
    color: ${({styles}) => styles.title.color};
  }
`;

const Title = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
`;

const defaultStyles = {
  iconColor: "#fff",
  title: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
    },
    fontWeight: "400",
    color: "#fff",
  },
};

DownloadApp.defaultProps = {
  styles: defaultStyles,
};

DownloadApp.propTypes = {
  url: PropTypes.string,
  styles: PropTypes.object,
};
