import React from "react";
import DropdownSelectInput from "./DropdownSelectInput";
import {Field} from "react-final-form";
import styled from "styled-components";
import {Error} from "@ui/components/FormFields/Error";

export default function DropdownSelectInputField({
  name,
  errorStyles,
  validate,
  onChange,
  ...props
}) {
  return (
    <Field
      name={name}
      initialValue={props.initialValue}
      validate={validate}
      render={({input, meta}) => (
        <FieldContainer>
          <DropdownSelectInput
            {...props}
            hasError={meta.touched && meta.error}
            errorStyles={errorStyles}
            onChange={(...params) => {
              input.onChange(...params);
              onChange && onChange(...params);
            }}
            value={input.value}
          />
          {meta.touched && meta.error && <Error styles={errorStyles}>{meta.error}</Error>}
        </FieldContainer>
      )}
    />
  );
}

const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  > * {
    width: 100%;
  }
`;
