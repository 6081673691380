import EmptyPlaceholderImage from "./assets/empty-placeholder.png";
import React from "react";
import styled from "styled-components";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

function EmptyPlaceholder({title, text, buttonText, action, ActionsComponent, imageUrl}) {
  return (
    <EmptyPlaceholderContainer>
      <img src={imageUrl || EmptyPlaceholderImage} alt={""} />
      <h1>{title}</h1>
      {text && <h2>{text}</h2>}
      {action &&
        renderNodeOrComponent(ActionsComponent, {
          onClick: action,
          label: buttonText,
          children: buttonText,
        })}
    </EmptyPlaceholderContainer>
  );
}

const EmptyPlaceholderContainer = styled.div`
  box-sizing: border-box;
  padding: ${({theme}) => theme.v2.spacing(4)};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
  > h1 {
    margin-top: 0;
    font-size: ${({theme}) => theme.v2.typography.sizing["3xl"].lg};
    font-weight: ${({theme}) => theme.v2.typography.titles.weight.light};
  }
  > h2 {
    margin-top: 0;
    font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
    font-weight: ${({theme}) => theme.v2.typography.titles.weight.light};
  }
  > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
  > div {
    width: 50%;
  }
`;

export default EmptyPlaceholder;
