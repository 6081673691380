import React from "react";
import {TopNavigationBar, CartButton} from "@ui";
import merge from "lodash/merge";
import {SEARCH_COMPONENT_DISPLAY} from "@ui/components/TopNavigationBar/TopNavigationBar";
import headerStyles, {
  cartButtonBadgeStyles,
  cartButtonStyles,
  loginButtonStyles,
  searchButtonStyles,
} from "@themes/default/components/Header/styles.js";
import useHeader from "@mock/hooks/useHeader.js";
import useTheme from "@mock/hooks/useTheme.js";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

function Header() {
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.appBar)};

  const {
    site,
    loginButtonProps,
    cartButtonProps,
    sideMenu,
    hideMenuButton,
    logoLinkUrl,
    homeLinkUrl,
    ...headerProps
  } = useHeader();

  const headerUIConfig = site.getUiConfiguration().header;
  const searchUIConfig = site.getUiConfiguration().search || {};
  const loginUIConfig = site.getUiConfiguration().login || {};
  const cartUIConfig = headerUIConfig?.cart || {};

  const toggles = useFeatureToggles();

  return (
    <>
      <TopNavigationBar
        logoLinkUrl={toggles.isKiosk() ? homeLinkUrl : logoLinkUrl}
        styles={headerStyles(theme, site)}
        hideMenuButtonOnLargerViewport={false}
        hideMenuButton={hideMenuButton}
        largeScreenOptionsAlignment={"left"}
        searchComponentDisplay={SEARCH_COMPONENT_DISPLAY.full}
        CartButtonComponent={CartButton}
        loginButtonProps={{
          ...loginButtonProps,
          ...loginUIConfig,
          styles: loginButtonStyles(theme, loginButtonProps, site),
        }}
        cartButtonProps={{
          ...cartButtonProps,
          ...cartUIConfig,
          highlight: headerUIConfig.highlight,
          highlightIfCartHasProducts: headerUIConfig.highlightIfCartHasProducts,
          hideIconInLargerViewport: headerUIConfig.hideIconInLargerViewport,
          cartIcon: headerUIConfig.cartIcon,
          cartText: headerUIConfig.cartText,
          styles: cartButtonStyles(theme, site),
          badgeStyles: cartButtonBadgeStyles(theme, site),
        }}
        searchButtonProps={{
          outline: false,
          ...searchUIConfig,
          styles: searchButtonStyles(theme, site),
        }}
        {...headerProps}
      />
      {sideMenu}
    </>
  );
}

export default Header;
