import {getSize} from "src/themes/utils/sizing";

export default {
  spacing: n => `${8 * n}px`,
  font: {
    sizes: {
      larger: "32px",
      xxxLarge: "24px",
      xxLarge: "20px",
      xLarge: "18px",
      large: "16px",
      medium: "14px",
      small: "12px",
      xSmall: "10px",
      xxSmall: "8px",
    },
    family: "'sofia-pro', sans-serif",
    secondaryFamily: "'sofia-pro', sans-serif",
    headers: "'sofia-pro', sans-serif",
    secondaryHeaders: "'sofia-pro', sans-serif",
    weight: {
      extraLight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
  },
  border: {
    radius: "4px",
  },
  colors: {
    buttonsRed: "#e80c17", //"#ff6c6c",
    shoppingCartIconColor: "#333333",
    green: "#0b8535", //"#70cf98",
    green1: "#1E4526",
    yellow: "#F2C94C",
    red: "#f07178",
    black: "#020202",
    primary: "#000",
    primaryLight: "#838383",
    primaryLighter: "#838383",
    primaryDark: "#000",
    primaryText: "#000",
    textInPrimary: "#020202",
    textInPrimaryDark: "#ffffff",
    lightGray: "#fafafa",
    gray: "#f0f0f0",
    gray1: "#333333",
    gray2: "#6d6d6d",
    gray3: "#757575",
    gray4: "#BDBDBD",
    gray6: "#F2F2F2",
    darkGray: "#333333",
  },
  media: {
    desktop: "min-width: 1025px",
    mobile: "max-width: 1024px",
  },
  content: {
    maxWidth: "1440px",
    margin: {
      desktop: "32px",
      mobile: "16px",
    },
    headerWidth: "content",
  },
  icons: {
    shopping: "shopping-cart",
    history: "history",
    search: "search",
    user: "user",
    delivery: "delivery",
    home: "home",
    mapPin: "map-pin",
  },
  components: {
    loginButton: "login-button-outlined",
  },
};

export const v2 = {
  static: {
    spacing: {
      size: {
        "3xl": "40px",
        "4xl": "48px",
        "5xl": "56px",
        "6xl": "64px",
        "7xl": "80px",
        "8xl": "120px",
        l: "20px",
        m: "16px",
        s: "12px",
        xl: "24px",
        xs: "8px",
        xxl: "32px",
        xxs: "4px",
      },
    },
  },
  typography: {
    title1: {
      weight: "{typography.titles.weight.heavy}",
      size: "{typography.sizing.3xl}",
      family: "{typography.titles.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.titles.letterSpacing}",
    },
    title2: {
      weight: "{typography.titles.weight.heavy}",
      size: "{typography.sizing.xxl}",
      family: "{typography.titles.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.titles.letterSpacing}",
    },
    title3: {
      weight: "{typography.titles.weight.heavy}",
      size: "{typography.sizing.xl}",
      family: "{typography.titles.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.titles.letterSpacing}",
    },
    title4: {
      weight: "{typography.titles.weight.heavy}",
      size: "{typography.sizing.l}",
      family: "{typography.titles.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.titles.letterSpacing}",
    },
    title5: {
      weight: "{typography.titles.weight.heavy}",
      size: "{typography.sizing.l}",
      family: "{typography.titles.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.titles.letterSpacing}",
    },
    title6: {
      weight: "{typography.titles.weight.heavy}",
      size: "{typography.sizing.l}",
      family: "{typography.titles.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.titles.letterSpacing}",
    },

    bodyText1: {
      weight: "{typography.body.weight.medium}",
      size: "{typography.sizing.m}",
      family: "{typography.body.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.body.letterSpacing}",
    },
    bodyText2: {
      weight: "{typography.body.weight.medium}",
      size: "{typography.sizing.m}",
      family: "{typography.body.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.body.letterSpacing}",
    },

    caption1: {
      weight: "{typography.body.weight.light}",
      size: "{typography.sizing.s}",
      family: "{typography.body.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.body.letterSpacing}",
    },
    caption2: {
      weight: "{typography.body.weight.light}",
      size: "{typography.sizing.s}",
      family: "{typography.body.family}",
      decoration: "",
      lineHeight: 1.5,
      textCase: "",
      letterSpacing: "{typography.body.letterSpacing}",
    },

    titles: {
      family: "'sofia-pro', sans-serif",
      weight: {
        default: "{typography.titles.weight.medium}",
        light: "300",
        medium: "400",
        heavy: "700",
      },
      letterSpacing: "",
    },
    body: {
      family: "'sofia-pro', sans-serif",
      weight: {
        default: "{typography.titles.weight.medium}",
        light: "300",
        medium: "400",
        heavy: "700",
      },
      letterSpacing: "",
    },

    sizing: {
      xxs: "static-font-size-25",
      xs: "static-font-size-25",
      s: "static-font-size-75",
      m: "static-font-size-75",
      l: "static-font-size-100",
      xl: "static-font-size-200",
      xxl: "static-font-size-300",
      "3xl": "static-font-size-400",
      "4xl": "static-font-size-600",
      "5xl": "static-font-size-600",
    },

    primary: {
      family: "'sofia-pro', sans-serif",
      weight: {
        default: "400",
        light: "300",
        medium: "500",
        heavy: "600",
      },
    },

    secondary: {
      family: "'sofia-pro', sans-serif",
      weight: {
        default: "400",
        light: "300",
        medium: "500",
        heavy: "600",
      },
    },
  },

  text: {
    decorations: {
      none: "none",
      underline: "underline",
      "line-through": "line-through",
    },
    case: {
      none: "none",
      uppercase: "uppercase",
      lowercase: "lowercase",
      capitalize: "capitalize",
    },
  },

  color: {
    primary: "{color.base.primary.400}",
    primaryVariant: "{color.base.primary.300}",
    onPrimary: "#272727",

    secondary: "",
    secondaryVariant: "",
    onSecondary: "#000",

    surface: "#fff",
    onSurface: "#000",

    background: "#fff",
    onBackground: "#000",

    error: "#f44336",
    onError: "#fff",

    border: "{color.primary}",

    typography: {
      title1: "{color.primary}",
      title2: "{color.primary}",
      title3: "{color.primary}",
      title4: "{color.primary}",
      title6: "{color.primary}",

      bodyText1: "{color.onSurface}",
      bodyText2: "{color.onSurface}",

      caption1: "{color.onSurface}",
      caption2: "{color.onSurface}",
    },

    base: {
      grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#424242",
        900: "#212121",
      },
      primary: {
        50: "",
        100: "",
        200: "#838383",
        300: "#838383",
        400: "#000",
        500: "#000",
        600: "",
        700: "",
        800: "",
        900: "",
      },
    },
  },

  decoration: {
    border: {
      width: {
        xs: "1px",
      },
      radius: "4px",
    },
    shadow: {},
  },
  spacing: n => {
    return getSize(`size-${n}`);
  },
};
