import {createSelector} from "reselect";

export function shopStateSelector(state) {
  return state.shop;
}

export const shopSelector = createSelector(
  shopStateSelector,
  shotState => shotState.data
);

export function shopGroupStateSelector(state) {
  return state.group;
}

export const shopGroupSelector = createSelector(
  shopGroupStateSelector,
  shotGroupState => shotGroupState.data
);
