import React, {lazy, useEffect, useState} from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import {
  ModalExternalContainer,
  ModalCloseButton,
} from "@ui/components/Modal/ModalComponents";
import {MODAL_ELEMENT_ID} from "@ui/components/Modal/constants";
import suspensify from "@ui/utils/suspensify";

const StyledModal = suspensify(
  lazy(() => import("@ui/components/Modal/StyledReactModal"))
);

export default function Modal({
  children,
  isOpen,
  hidden,
  ContainerComponent,
  onRequestClose,
  allowedToClose,
  closeModalOverride,
  showCloseButton = false,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const [element, setElement] = useState(null);
  useEffect(() => {
    const el = document.getElementById(MODAL_ELEMENT_ID);
    if (!el) {
      const newElement = document.createElement("div");
      newElement.id = MODAL_ELEMENT_ID;
      document.body.append(newElement);
      setElement(newElement);
    } else {
      setElement(el);
    }
  }, []);

  const closeModal = () => {
    if (allowedToClose()) {
      onRequestClose();
    }
  };

  if (!element) return null;
  if (!isOpen) return null;

  return (
    <StyledModal
      isOpen={isOpen}
      hidden={hidden}
      onRequestClose={closeModalOverride || closeModal}
      styles={_styles}
    >
      <ContainerComponent>
        {showCloseButton && (
          <ModalCloseButton alt={""} onClick={closeModalOverride || closeModal} />
        )}
        {typeof children === "function" ? children({closeModal}) : children}
      </ContainerComponent>
    </StyledModal>
  );
}

const defaultStyles = {
  content: {
    top: {
      lg: "50%",
      md: "70px",
      sm: "70px",
    },
    translateY: {
      lg: "-50%",
      md: "0",
      sm: "0",
    },
    maxWidth: {
      lg: "85%",
      md: "100%",
      sm: "100%",
    },
    maxHeight: {
      lg: "100%",
      md: "100%",
      sm: "100%",
    },
    padding: {
      lg: "20px",
      md: "20px",
      sm: "20px 8px",
    },
    borderRadius: {
      lg: "4px",
      md: "4px 4px 0 0",
      sm: "4px 4px 0 0",
    },
  },
  overlay: {
    color: "#000000",
    opacity: 0.75,
  },
};

Modal.defaultProps = {
  hidden: false,
  ContainerComponent: ModalExternalContainer,
  onRequestClose: () => {},
  allowedToClose: () => true,
  styles: defaultStyles,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  isOpen: PropTypes.bool,
  hidden: PropTypes.bool,
  ContainerComponent: PropTypes.elementType,
  onRequestClose: PropTypes.func,
  allowedToClose: PropTypes.func,
  closeModalOverride: PropTypes.func,
  styles: PropTypes.shape({}),
};
