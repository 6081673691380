import React, {useState, useRef} from "react";
import {Field} from "react-final-form";
import styled, {createGlobalStyle} from "styled-components";
import {CalendarAlt} from "@styled-icons/boxicons-regular";
import "dayjs/locale/en";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import Input from "@ui/components/Inputs";
import {Error} from "@ui/components/FormFields/Error";
import {FakeInput} from "@ui/components/Inputs/FakeInput";
import {isMobile} from "@ui/utils/isMobile";

dayjs.extend(LocalizedFormat);

function NativeDatePicker({
  hasErrors,
  name,
  minDate,
  maxDate,
  placeholder,
  showAsDisabled,
  disabled,
  styles,
  errorStyles,
  ...props
}) {
  const [isFocused, setFocused] = useState(false);
  const inputRef = useRef();

  function onFocus() {
    setFocused(true);
  }
  function onBlur() {
    setFocused(false);
  }
  function focusDateField() {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click && inputRef.current.click();
    }
  }

  const handleChange = (callback) => (evt) => {
    callback(evt.target.value);
  };

  function stringifyDate(date) {
    return date ? date.toISOString().split("T")[0] : "";
  }
  const min = minDate ? stringifyDate(minDate) : null;
  const max = maxDate ? stringifyDate(maxDate) : null;

  function formatDate(dateStr) {
    const date = dayjs(dateStr);
    if (!date.isValid()) {
      return undefined;
    } else {
      return date.locale("en").format("L");
    }
  }

  function _hasErrors(meta, input) {
    const metaErrors = meta?.touched && meta?.error;
    if (input.value) {
      return metaErrors;
    }

    return metaErrors || hasErrors;
  }
  return (
    <Field
      name={name}
      {...props}
      render={({input, meta}) => (
        <DatePickerContainer
          data-cy="datePickerContainer"
          onClick={focusDateField}
          hasValue={Boolean(input.value)}
          hasErrors={_hasErrors(meta, input)}
          placeholder={props.placeholder}
        >
          <GlobalStyles />
          <CustomInput
            name={name}
            ref={inputRef}
            type={"date"}
            hasErrors={_hasErrors(meta, input)}
            placeholder={placeholder}
            disabled={disabled || showAsDisabled}
            value={
              input.value &&
              (typeof input.value === "string" ? input.value : stringifyDate(input.value))
            }
            onChange={
              props.onChange
                ? handleChange(props.onChange(input.onChange))
                : handleChange(input.onChange)
            }
            min={min}
            max={max}
            onFocus={onFocus}
            onBlur={onBlur}
            pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
            locale={"en-us"}
            isMobile={isMobile()}
            styles={styles}
          />
          {isMobile() && (
            <CustomFakeInput
              hasErrors={(meta?.error && meta?.touched) || hasErrors}
              placeholder={placeholder}
              value={formatDate(input.value)}
              icon={<CalendarIcon />}
              styles={styles}
            />
          )}
          {!isFocused && !input.value && !isMobile() && (
            <PlaceholderContainer hasErrors={_hasErrors(meta, input)} styles={styles}>
              {placeholder}
            </PlaceholderContainer>
          )}
          {meta.touched && <Error styles={errorStyles}>{meta.error}</Error>}
        </DatePickerContainer>
      )}
    />
  );
}

const GlobalStyles = createGlobalStyle`
  .ui.popup {
    z-index: 99;
  }
`;

const PlaceholderContainer = styled.div`
  position: absolute;
  top: 1px;
  left: 12px;
  background-color: ${({styles}) => styles?.backgroundColor || "#ffffff"};
  width: 75% !important;
  font-size: ${({styles}) => styles?.fontSize};
  color: ${({hasErrors}) => (hasErrors ? "red" : "#727272")};
  height: ${({styles}) => styles?.placeholderHeight || "41px"};
  font-family: ${({styles}) => styles?.fontFamily || "sans-serif"};
  display: flex;
  align-items: center;
`;
const CustomInput = styled(Input)`
  font-size: ${({styles}) => styles?.fontSize};
  height: ${({styles}) => styles?.height || "44px"};
  box-sizing: border-box;
  -webkit-appearance: none;

  color: ${({isMobile}) => (isMobile ? "transparent" : "")};
`;
const CalendarIcon = styled(CalendarAlt)`
  width: 22px;
  height: 22px;
  color: black;
`;
const CustomFakeInput = styled(FakeInput)`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({styles}) => styles?.height || "44px"};
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  > * {
    width: 100%;
  }
`;
const DatePickerContainer = styled(FieldContainer)`
  position: relative;
`;

export default NativeDatePicker;
