import React from "react";
import DefaultBrandRecommendations from "@themes/default/components/RecommendedProductsList";
import {CarouselProductsList} from "@ui";

export default function BrandRecommendations(props) {
  return (
    <DefaultBrandRecommendations
      {...props}
      ProductsListComponent={
        <CarouselProductsList maxProductsPerSlide={{lg: 3, md: 3, sm: 1}} /> // rest of props are passed by BrandRecommendations
      }
    />
  );
}
