import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import ClearFilters from "@ui/components/FiltersPanel/ClearFilters";
import SelectedFilters from "@ui/components/FiltersPanel/SelectedFiltersSection";
import FilterGroup from "@ui/components/FiltersPanel/FilterGroup";
import isEmpty from "lodash/isEmpty";
import Separator from "@ui/components/Separator/Separator";

export default function FiltersPanel({
  label,
  showClearFilters,
  clearFiltersLabel,
  onClearFiltersHandler,
  showSelectedFiltersSection,
  selectedFiltersSectionLabel,
  disabled,
  showSeeMore,
  seeMoreShowLabeL,
  seeMoreHideLabeL,
  seeMoreFiltersCount,
  filterGroups,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container styles={_styles.root}>
      <Header styles={_styles.header}>
        <Label styles={_styles.header}>{label}</Label>
        {showClearFilters && (
          <ClearFilters
            label={clearFiltersLabel}
            onClick={onClearFiltersHandler}
            styles={_styles.clearFilters}
          />
        )}
      </Header>
      <Separator styles={_styles.separator} />
      {showSelectedFiltersSection && (
        <SelectedFilters
          label={selectedFiltersSectionLabel}
          filterGroups={filterGroups}
          styles={_styles}
        />
      )}
      <FilterGroups>
        {filterGroups.map(filterGroup => {
          const showGroup =
            filterGroup.options.length > 0 || !isEmpty(filterGroup.options);

          if (!showGroup) return null;

          return (
            <FilterGroup
              key={filterGroup.label}
              type={filterGroup.type}
              label={filterGroup.label}
              isOpen={filterGroup.isOpen}
              onClick={filterGroup.onGroupClickHandler}
              options={filterGroup.options}
              onChange={filterGroup.onChange}
              showSeeMore={showSeeMore}
              seeMoreShowLabeL={seeMoreShowLabeL}
              seeMoreHideLabeL={seeMoreHideLabeL}
              seeMoreFiltersCount={seeMoreFiltersCount}
              disabled={disabled}
              styles={_styles.filterGroup}
            />
          );
        })}
      </FilterGroups>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "filters-panel__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  width: ${({styles}) => styles.width.lg};
  min-width: ${({styles}) => styles.minWidth.lg};
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("sm")} {
    width: ${({styles}) => styles.width.sm};
    min-width: ${({styles}) => styles.minWidth.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const Header = styled.div.attrs(() => ({
  className: "filters-panel__header",
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({styles}) => styles.margin.lg};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Label = styled.span.attrs(() => ({
  className: "filters-panel__header-label",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const FilterGroups = styled.div`
  display: flex;
  flex-direction: column;
`;

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    width: {
      lg: "180px",
      sm: "180px",
    },
    minWidth: {
      lg: "180px",
      sm: "180px",
    },
    padding: {
      lg: "0px",
      sm: "0px",
    },
  },
  header: {
    margin: {
      lg: "10px 0 20px",
      sm: "10px 0 20px",
    },
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      sm: "16px",
    },
    fontWeight: 600,
  },
  separator: {
    color: "#000",
    height: "2px",
  },
  filterGroup: {
    group: {
      margin: {
        lg: "10px 0",
        sm: "10px 0",
      },
    },
    options: {
      lg: "0 0 10px",
      sm: "0 0 10px",
    },
  },
};

FiltersPanel.defaultProps = {
  label: "FILTER BY",
  clearFiltersLabel: "Clear All",
  onClearFiltersHandler: () => {},
  showSelectedFiltersSection: true,
  selectedFiltersSectionLabel: "Now Shopping by",
  showSeeMore: true,
  filterGroups: [],
  styles: defaultStyles,
};

FiltersPanel.propTypes = {
  label: PropTypes.string,
  showClearFilters: PropTypes.bool,
  clearFiltersLabel: PropTypes.string,
  onClearFiltersHandler: PropTypes.func,
  showSelectedFiltersSection: PropTypes.bool,
  selectedFiltersSectionLabel: PropTypes.string,
  disabled: PropTypes.bool,
  filterGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      isOpen: PropTypes.bool,
      onGroupClickHandler: PropTypes.func,
      openGroupIcon: PropTypes.elementType,
      closeGroupIcon: PropTypes.elementType,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          count: PropTypes.number,
          isSelected: PropTypes.bool,
        })
      ),
      onChange: PropTypes.func,
    })
  ),
  showSeeMore: PropTypes.bool,
  seeMoreShowLabeL: PropTypes.string,
  seeMoreHideLabeL: PropTypes.string,
  seeMoreFiltersCount: PropTypes.number,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      width: PropTypes.shape({
        lg: PropTypes.string,
        sm: PropTypes.string,
      }),
      minWidth: PropTypes.shape({
        lg: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    header: PropTypes.shape({
      label: PropTypes.shape({}),
      clearFilters: PropTypes.shape({}),
    }),
    filterGroup: PropTypes.shape({
      separator: PropTypes.shape({}),
      group: PropTypes.shape({
        margin: PropTypes.shape({
          lg: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
      options: PropTypes.shape({
        margin: PropTypes.shape({
          lg: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
    }),
    separator: PropTypes.shape({
      color: PropTypes.string,
    }),
  }),
};
