import {clearDeal, requestDeal} from "@deals/state/actions";
import store from "@deals/state/store";
import {useEffect, useState} from "react";

export default function useDeal({dealType, dealId}) {
  const dispatch = store.dispatch;
  const [state, setState] = useState(store.getState().deal);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState().deal);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    clearDeal: () => dispatch(clearDeal()),
    fetchDeal: () => dispatch(requestDeal(dealType, dealId)),
    state,
  };
}
