import React from "react";
import styled from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import MenuItem from "./MenuItem";

function MenuItems({isUserLoggedIn, items, close, className, children}) {
  const toggles = useFeatureToggles();
  return (
    <MenuItemsContainer className={className}>
      {items
        .filter(item => isUserLoggedIn || !item.requiresAuthentication)
        .filter(item => !item.requiresDelivery || toggles.deliveriesEnabled())
        .map((item, index) => (
          <li key={index}>
            <MenuItem onClick={close} item={item} key={index} />
          </li>
        ))}
      {children}
    </MenuItemsContainer>
  );
}

const MenuItemsContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: ${({theme}) => theme.v2.spacing(4)} 0 0 0;
  > li {
    margin: 20px 0;
  }
`;

export default MenuItems;
