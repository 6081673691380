import React from "react";
import PropTypes from "prop-types";
import MultiRowCardList from "@ui/components/MultiRowCardList";

function MultiRowProductsList(props) {
  return (
    <MultiRowCardList
      {...props}
      CardComponent={props.ProductCardComponent}
      entryCardProp={"product"}
      entries={props.products}
    />
  );
}

MultiRowProductsList.propTypes = {
  ...MultiRowCardList.propTypes,
  ProductCardComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      brandName: PropTypes.string,
      brandUrl: PropTypes.string,
      productDetailUrl: PropTypes.string,
      sizes: PropTypes.arrayOf(PropTypes.string),
      potencyTags: PropTypes.arrayOf(PropTypes.string),
      onSale: PropTypes.bool,
      flowerType: PropTypes.shape({
        icon: PropTypes.elementType,
        color: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
};
MultiRowProductsList.defaultProps = MultiRowCardList.defaultProps;

export default MultiRowProductsList;
