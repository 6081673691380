import {call, put, takeLatest} from "redux-saga/effects";
import * as Actions from "./actions";
import ActionTypes from "./actionTypes";
import * as ShopsApi from "src/core/api/shops";
import ShopFullSettings from "src/core/common/models/shopFullSettings";
import {getShopDetails} from "src/core/shops/utils";

function* loadShop() {
  try {
    const shop = yield call(getShopDetails);
    yield put(Actions.requestShopDetailsSuccessful(shop));
  } catch (error) {
    yield put(Actions.requestShopDetailsFailure(error));
  }
}

export function* loadShopSaga() {
  yield takeLatest(ActionTypes.REQUEST_SHOP_DETAILS, loadShop);
}

function* loadShopGroup() {
  try {
    const shops = yield call(getShopGroup);
    yield put(Actions.requestShopGroupSuccessful(shops));
  } catch (error) {
    yield put(Actions.requestShopGroupFailure(error));
  }
}

export function* loadShopGroupSaga() {
  yield takeLatest(ActionTypes.REQUEST_SHOP_GROUP, loadShopGroup);
}

const getShopGroup = () => {
  return ShopsApi.fullSettings()
    .then(data => new ShopFullSettings(data))
    .then(fullSettings => {
      return fullSettings.group;
    });
};
