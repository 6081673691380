import React from "react";
import SearchBrowser from "@themes/default/components/SearchBrowser";
import AppliedFilters from "@themes/default/components/AppliedFilters";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

function SearchPage({children, ...props}) {
  const {BasePage} = useThemeComponents();
  return (
    <BasePage {...props}>
      <AppliedFilters />
      <SearchBrowser />
    </BasePage>
  );
}

export default SearchPage;
