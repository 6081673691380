import {DealTypes} from "@deals/constants";

export const getRewardsOffset = state => {
  const dealsState = state.deals;
  return dealsState.deals.filter(deal => deal.type === DealTypes.REWARD).length;
};

export const getPromotionsOffset = state => {
  const dealsState = state.deals;
  return dealsState.deals.filter(deal => deal.type !== DealTypes.REWARD).length;
};

export const getOnSaleProductsOffset = state => {
  const onSaleProductsState = state.onSaleProducts;
  return onSaleProductsState.products.length;
};

export const getPromotionProductsOffset = state => {
  const promotionProductsState = state.promotionProducts;
  return promotionProductsState.products.length;
};
