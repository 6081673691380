import React from "react";
import Head from "next/head";
import {createPageDescription, createPageTitle} from "src/core/seo/utils";
import useSite from "src/core/sites/hooks/useSite";
import EventBus from "src/core/common/eventBus";
import {isClient} from "src/server/utils/isClient";
import uuid from "uuid/v4";

function Metadata(props) {
  const site = useSite();

  const siteProps = {
    title: site?.getTitle() || props.siteTitle,
    description: site?.getDescription() || props.siteDescription,
    image: site?.getBannerUrl() || site?.getLogoUrl() || props.siteImage,
    favicon: site?.getFavicon() || props.favicon,
  };

  const title = createPageTitle(props.title, siteProps.title, props.titleTemplate);
  const description = createPageDescription(props.description || siteProps.description);
  const image = props.image || siteProps.image;

  const source = isClient ? "client" : "server";

  return (
    <Head>
      {title && <title key={"title"}>{title}</title>}
      {description && (
        <meta
          name="description"
          content={description}
          key={"description"}
          data-source={source}
        />
      )}

      {title && (
        <meta property="og:title" content={title} key={"og:title"} data-source={source} />
      )}
      {description && (
        <meta
          property="og:description"
          content={description}
          key={"og:description"}
          data-source={source}
        />
      )}
      {image && (
        <meta property="og:image" content={image} key={"og:image"} data-source={source} />
      )}
      <meta property="og:type" content="website" key={"og:type"} data-source={source} />
      <meta property="og:locale" content="en-US" key={"og:locale"} data-source={source} />

      {title && (
        <meta
          name="twitter:title"
          content={title}
          key={"twitter:title"}
          data-source={source}
        />
      )}
      {description && (
        <meta
          property="twitter:description"
          content={description}
          key={"twitter:description"}
          data-source={source}
        />
      )}
      {image && (
        <meta
          name="twitter:image"
          content={image}
          key={"twitter:image"}
          data-source={source}
        />
      )}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key={"twitter:card"}
        data-source={source}
      />

      {siteProps.favicon && (
        <link rel="icon" href={siteProps.favicon} key={"favicon"} data-source={source} />
      )}

      <meta
        data-source={source}
        key={"viewport"}
        name="viewport"
        content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
      />
    </Head>
  );
}

export default class MetadataWrapper extends React.Component {
  id = uuid();

  componentDidMount() {
    this.unsub = metadataRegistry.register(this.id, this.props.priority, () => {
      this.setState({timeStamp: new Date().getTime()});
    });
  }

  componentWillUnmount() {
    this.unsub();
  }

  render() {
    if (!metadataRegistry.shouldRender(this.id)) return null;

    return <Metadata {...this.props} />;
  }
}

class MetadataRegistry {
  registry = {};
  eventBus = new EventBus();

  register(key, priority, handler) {
    if (priority) this.registry[key] = priority;
    const unsub = this.eventBus.subscribe(handler);
    this.eventBus.notify({});
    return () => {
      this.unregister(key);
      unsub();
    };
  }

  unregister(key) {
    delete this.registry[key];
    this.eventBus.notify({});
  }

  getMaxPriority() {
    return Math.max(...Object.values(this.registry));
  }

  shouldRender(key) {
    return (
      !isClient ||
      !Object.values(this.registry).length ||
      this.registry[key] === this.getMaxPriority()
    );
  }
}

const metadataRegistry = new MetadataRegistry();
