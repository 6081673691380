import React from "react";
import {useKioskValidation} from "src/apps/kiosk/useKioskValidation";
import KiosksList from "src/apps/kiosk/KioskList";
import BaseAppProvider from "src/apps/common/BaseAppProvider";
import useSiteInitialization from "src/apps/common/useSiteInitialization";
import {isClient} from "src/server/utils/isClient";
import {useRouter} from "next/router";

function KioskAppProvider({initialSite, host, siteGroupName, children}) {
  const identifier = host;

  const {shop} = useRouter().query;

  const kioskId = sanitizeKioskId(shop);
  const kioskSlug = getKioskSlug(shop);

  const {site} = useSiteInitialization({kioskId, identifier, initialSite, siteGroupName});

  useKioskValidation(site.env, kioskId);

  const routeParams = {shop: kioskSlug};

  if (!kioskId) {
    return <KiosksList env={site.env} />;
  }

  return (
    <BaseAppProvider
      appIdentifier={identifier}
      site={site}
      host={host}
      routeParams={routeParams}
    >
      {children}
    </BaseAppProvider>
  );
}

function getKioskSlug(path) {
  let _path = path;
  if (!_path && isClient) {
    _path = window.location.pathname
      .split("/")
      .filter(part => part !== "")
      .slice(0, 1)[0];
  }
  return _path;
}

function sanitizeKioskId(path) {
  let _path = getKioskSlug(path);
  return (_path || "")
    .split("-")
    .filter(part => part !== "")
    .slice(-1)[0];
}

export default KioskAppProvider;
