import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import merge from "lodash/merge";
import RegexInput from "@ui/components/Inputs/RegexInput";
import media from "@ui/utils/media";

const Input = React.forwardRef(({index, onKeyUp, styles, ...props}, ref) => {
  const _styles = merge({}, defaultStyles, styles);

  function _onKeyUp(evt) {
    if (index && evt.keyCode === ENTER) {
      const nextElement = document.querySelector(`input[data-index="${index + 1}"]`);
      if (nextElement) {
        nextElement.focus();
      } else {
        evt.target.blur();
      }
    } else if (evt.keyCode === ENTER) {
      evt.target.blur();
    }

    if (onKeyUp) {
      onKeyUp(evt);
    }
  }

  return (
    <InputElement
      onKeyUp={_onKeyUp}
      data-index={index}
      styles={_styles}
      {...props}
      ref={ref}
    />
  );
});

const ENTER = 13;

export const sharedStyle = css`
  width: 100%;
  height: ${({styles}) => styles.height || ""};
  font-family: ${({styles}) => styles.fontFamily};
  outline: none;
  background: ${({styles}) => styles.background};
  border: ${({styles, hasErrors}) => (hasErrors ? styles.error?.border : styles.border)};
  &::placeholder {
    color: ${({styles, hasErrors}) =>
      hasErrors ? styles.error?.placeholderColor : styles.placeholderColor};
  }
  box-sizing: border-box;
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    padding: ${({styles}) => styles.padding.sm};
  }

  transition: 100ms border-color ease-out;

  cursor: ${({disabled, clickable}) =>
    disabled ? (clickable ? "pointer" : "not-allowed") : "auto"};
  color: ${({styles, disabled, clickable}) =>
    disabled && !clickable ? "grey" : styles.color};

  &:hover {
    border-color: ${({styles}) => styles.hover?.borderColor};
  }

  &:focus {
    border: ${({styles}) => styles.focus?.border};
  }

  margin: 0 !important;

  &:disabled {
    background-color: ${({styles}) => styles.disabled?.background};
    &:hover {
      border: ${({styles, hasErrors}) =>
        hasErrors ? styles.error?.border : styles.disabled?.border};
    }
  }
`;

const InputElement = styled(RegexInput)`
  ${sharedStyle}
`;

const defaultStyles = {
  fontFamily: "sans-serif",
  fontSize: {
    lg: "16px",
    md: "16px",
    sm: "16px",
  },
  background: "#fff",
  border: "1px solid #D9D9D9",
  borderRadius: "5px",
  placeholderColor: "#737373",
  padding: {
    lg: "12px",
    md: "12px",
    sm: "12px",
  },
  hover: {
    borderColor: "hsl(0, 0%, 70%)",
  },
  focus: {
    border: "1px solid hsl(0, 0%, 70%)",
  },
  error: {
    border: "1px solid red",
    placeholderColor: "red",
  },
  disabled: {
    background: "hsl(0, 0%, 95%)",
    border: "1px solid #D9D9D9",
  },
};

Input.defaultProps = {
  styles: defaultStyles,
};

Input.propTypes = {
  index: PropTypes.number,
  onKeyUp: PropTypes.func,
  styles: PropTypes.shape({
    fontFamily: PropTypes.string,
    fontSize: PropTypes.shape({
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
    }),
  }),
};

export default Input;
