import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Filter from "../Elements/Filter";
import FiltersCheckbox from "@ui/components/FiltersList/Elements/FiltersCheckbox";

export default function MultiOptionFilter({
  isGridLayout,
  collapsedOptionsToDisplay,
  name,
  value,
  options,
  noOptionsText,
  onChange,
  disabled,
  collapseFilters,
  checkedMarkColor,
  styles,
}) {
  const [expanded, setExpanded] = useState(false);

  const hasMoreOptionsToBeDisplayed = () => options.length > collapsedOptionsToDisplay;
  const collapsedOptions = options.slice(0, collapsedOptionsToDisplay);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {options.length > 0 ? (
        <>
          <Filter
            name={name}
            disabled={disabled}
            value={value}
            onChange={onChange}
            collapseFilters={collapseFilters}
            color={checkedMarkColor}
          >
            {({onChange, value, disabled, color}) => (
              <>
                <OptionsContainer isGridLayout={isGridLayout}>
                  {expanded
                    ? options.map((option, index) => (
                        <li key={index}>
                          {renderOption(
                            isGridLayout,
                            option,
                            onChange,
                            value,
                            disabled,
                            color
                          )}
                        </li>
                      ))
                    : collapsedOptions.map((option, index) => (
                        <li key={index}>
                          {renderOption(
                            isGridLayout,
                            option,
                            onChange,
                            value,
                            disabled,
                            color
                          )}
                        </li>
                      ))}
                </OptionsContainer>
                {hasMoreOptionsToBeDisplayed() && (
                  <ReadMoreButton role="button" onClick={toggleExpanded}>
                    {expanded ? "hide" : "see more"}
                  </ReadMoreButton>
                )}
              </>
            )}
          </Filter>
        </>
      ) : (
        <FilterContainer>
          <FilterTitle styles={styles?.title}>{name}</FilterTitle>
          <NoFilterText>{noOptionsText}</NoFilterText>
        </FilterContainer>
      )}
    </>
  );
}

const renderOption = (isGridLayout, option, onChange, values = "", disabled, color) => {
  const {name, title, count, value} = {
    name: option.getName(),
    title: option.getTitle(),
    count: option.getCount(),
    value: option.getFilterValue?.() || option.getName(),
  };
  const allTypes = values ? values.split(",") : [];
  const checked = allTypes.includes(value);

  function toggleOption(e) {
    e.preventDefault();
    if (checked) {
      onChange(allTypes.filter(t => t !== value).join(","));
    } else {
      onChange([...allTypes, value].join(","));
    }
  }

  return (
    <FilterOption onClick={toggleOption} disabled={disabled}>
      {!isGridLayout && (
        <>
          <FiltersCheckbox id={value} checked={checked} color={color} />
          <label htmlFor={name}>
            {title} ({count})
          </label>
        </>
      )}
      {isGridLayout && (
        <FilterOption onClick={toggleOption} disabled={disabled}>
          <GridContainer color={color} checked={checked}>
            {name}
          </GridContainer>
        </FilterOption>
      )}
    </FilterOption>
  );
};

const OptionsContainer = styled.ul`
  display: ${({isGridLayout}) => isGridLayout && "flex"};
  flex-wrap: ${({isGridLayout}) => isGridLayout && "wrap"};
  gap: ${({isGridLayout}) => isGridLayout && "10px"};
  list-style-type: none;
  margin-top: 15px;
  padding: ${({theme}) => theme.v2.spacing(2)} 0;

  > li {
    padding: ${({theme}) => theme.v2.spacing(2)} 0;
  }
`;

const FilterContainer = styled.div`
  padding: ${({theme}) => theme.v2.spacing(4)} 0;
  user-select: none;
  cursor: pointer;
`;

const FilterTitle = styled.span`
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-size: ${({theme}) => theme.v2.typography.sizing.xxl.md};
  color: ${({styles}) => styles?.color};
`;

const NoFilterText = styled.div`
  padding: 8px 0;
  margin-top: 14px;
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.default};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  color: #333;
`;

const ReadMoreButton = styled.span`
    color ${({color}) => color};
    text-decoration: ${({underlined = true}) => (underlined ? "underline" : "none")};
    cursor: pointer;
`;

const FilterOption = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({disabled}) => (disabled ? "0.6" : "1.0")};

  > label {
    cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
    font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
    font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.default};
    font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
    color: #333;
  }

  > input {
    margin-right: 5px;
  }

  text-transform: capitalize;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
`;

const GridContainer = styled.div`
  width: 80px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  border: 2px solid;
  border-color: ${({color, checked}) => (checked ? color : "#BFCAD1")};
  background-color: ${({color, checked}) => (checked ? color : "#fff")};
  color: ${({color, checked}) => (checked ? "#fff" : color)};
`;

const defaultStyles = {};

MultiOptionFilter.defaultProps = {
  isGridLayout: false,
  collapsedOptionsToDisplay: 5,
  options: [],
  noOptionsText: "",
  onChange: () => {},
  collapseFilters: false,
  styles: defaultStyles,
};

MultiOptionFilter.propTypes = {
  isGridLayout: PropTypes.bool,
  collapsedOptionsToDisplay: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  noOptionsText: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  collapseFilters: PropTypes.bool,
  checkedMarkColor: PropTypes.string,
  styles: PropTypes.shape({}),
};
