import React from "react";
import styled from "styled-components";
import Link from "src/core/common/components/modules/Link";
import * as ThemedComponents from "src/themes/utils/themedComponents";
import useSite from "src/core/sites/hooks/useSite";

function MenuItem({className, item, onClick}) {
  return (
    <MenuItemContainer
      className={className}
      onClick={onClick}
      external={item.external}
      to={{pathname: item.linkTo}}
    >
      {item.icon && <MenuItemIcon icon={item.icon} />}
      <MenuItemLabel>{item.label}</MenuItemLabel>
    </MenuItemContainer>
  );
}
const MenuItemContainer = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: ${({theme}) => theme.v2.color.base.grey["900"]};
  }
`;

function MenuItemIcon({icon}) {
  const site = useSite();
  const Icon = ThemedComponents.get(site.getUiConfiguration().icons[icon]);
  return (
    <MenuItemIconImage>
      <Icon />
    </MenuItemIconImage>
  );
}
const MenuItemIconImage = styled.div`
  > *:first-child {
    width: 24px;
    height: 24px;
    color: ${({theme}) => theme.v2.color.base.grey["900"]};
  }
  margin-right: ${({theme}) => theme.v2.spacing(2)};
`;
const MenuItemLabel = styled.span`
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
`;

export default MenuItem;
