import {makeThemeProps} from "src/themes/utils/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getDeliveryBannerProps(theme);
};

export const deliveryModePickerStyles = (theme, site, showInventoryPicker) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getDeliveryModePickerProps(theme, showInventoryPicker);
};
