import React, {useEffect, useState} from "react";
import * as ReactDOM from "react-dom";
import styled, {createGlobalStyle} from "styled-components";
import dayjs from "dayjs";
import {ModalContextProvider} from "src/core/common/components/modules/modal/modalContext";
import routes from "src/core/common/routes";
import useRouter from "src/core/common/hooks/useRouter";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import Link from "src/core/common/components/modules/Link";
import {ErrorTags} from "src/core/api/errorHandlers";
import useIdentityVerificationProps, {
  ERROR_CODE,
} from "src/integrations/identityVerification/hooks/useIdentityVerificationProps";
import {isClient} from "src/server/utils/isClient";

const GlobalStylesCSS = createGlobalStyle`
  .product-card__image, .product-info__image, .cart__item-image, .checkout-product-item__image {
    filter: ${({isVerified}) =>
      isVerified ? "none !important" : "blur(16px) !important"}
  }
  
  .potency-tag {
    filter: ${({isVerified}) => (isVerified ? "none !important" : "blur(8px) !important")}
  }
  
  .promotional-banner__image-container {
    filter: ${({isVerified}) =>
      isVerified ? "none !important" : "blur(16px) !important"}
  }
`;

const MainComponent = ({ageCheckerInstance}) => {
  const router = useRouter();
  const [, authApi] = useAuthentication();
  const [deliveryType] = useDeliveryType();
  const {hasVerificationRequiredQueryParam, loginProps} = useIdentityVerificationProps();
  const [showWarning, setShowWarning] = useState(false);

  const userProfile = authApi.userProfile();
  const isLoggedIn = authApi.isLoggedIn();
  const isVerified = userProfile?.canSeeMenu(deliveryType?.code);
  const signupUrl = {
    pathname: routes.signup,
    query: {
      [ERROR_CODE]: ErrorTags.VERIFICATION_REQUIRED,
    },
  };

  const onVerify = () => {
    ageCheckerInstance.show(
      {
        firstName: userProfile.getFirstName(),
        lastName: userProfile.getLastName(),
        dateOfBirth: dayjs(userProfile.getDateOfBirth()),
      },
      ({verificationId}) => {
        ageCheckerInstance
          .postValidation({
            identityVerificationId: verificationId,
          })
          .then(() => {
            authApi.loadUserProfile();
          });
      },
      () => {}
    );
  };

  useEffect(() => {
    if (userProfile && !isVerified && !ageCheckerInstance.attemptedVerification()) {
      onVerify();
    }
    // eslint-disable-next-line
  }, [userProfile, isVerified]);

  useEffect(() => {
    if (
      !isLoggedIn &&
      authApi.isInitialized() &&
      !routes.isAuthenticationRoute(router.pathname)
    ) {
      router.push(signupUrl);
    }
  }, [isLoggedIn, isVerified, authApi.isInitialized()]);

  useEffect(() => {
    if (!authApi.isInitialized()) return;
    setShowWarning((!isLoggedIn || !isVerified) && !hasVerificationRequiredQueryParam);
  }, [
    authApi.isInitialized(),
    isLoggedIn,
    isVerified,
    hasVerificationRequiredQueryParam,
  ]);

  const portal = isClient
    ? ReactDOM.createPortal(
        <ModalContextProvider
          value={{
            modalBasePath: router.pathname,
            modalParams: {},
          }}
        >
          {showWarning && (
            <Warning loginProps={loginProps} onVerifyClickHandler={onVerify} />
          )}
        </ModalContextProvider>,
        document.getElementById(TRIGGER_AGE_CHECKER)
      )
    : null;

  return (
    <>
      <GlobalStylesCSS isVerified={isVerified} />
      {portal}
    </>
  );
};
export default MainComponent;

const Warning = ({loginProps, onVerifyClickHandler}) => {
  const verifyLink = loginProps.verifyUrl ? (
    <CustomLink to={loginProps.verifyUrl}>{loginProps.verifyLabel}</CustomLink>
  ) : (
    <DummyLink onClick={onVerifyClickHandler}>{loginProps.verifyLabel}</DummyLink>
  );

  const loginLink = loginProps.loginUrl ? (
    <CustomLink to={loginProps.loginUrl}>{loginProps.loginLabel}</CustomLink>
  ) : (
    ""
  );

  const or = loginLink ? " or " : " ";

  return (
    <WarningContainer>
      <p>
        You must {verifyLink}
        {or}
        {loginLink} to view ungated cannabis content in Alberta, per AGLC regulation.
      </p>
    </WarningContainer>
  );
};

const WarningContainer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: 100vw;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #ea3323;
  color: white;
  padding: 0 8px;

  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const CustomLink = styled(Link)`
  text-decoration: underline !important;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;

const DummyLink = styled.span`
  text-decoration: underline !important;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;

const TRIGGER_AGE_CHECKER = "trigger-age-checker";
