import get from "lodash/get";
import dayjs from "dayjs";

export class SpenceTransactionDetails {
  constructor(transactionDetailsObj) {
    this._obj = transactionDetailsObj;
  }
  get paymentApproved() {
    return get(this._obj, "paymentApproved");
  }
  get transactionStatus() {
    return get(this._obj, "paymentDetails.transactionStatus");
  }
  get transactionID() {
    return get(this._obj, "paymentDetails.transactionID");
  }
  get integratorTransactionID() {
    return get(this._obj, "paymentDetails.integratorTransactionID");
  }
  get amount() {
    return get(this._obj, "paymentDetails.details.amount");
  }
  get tip() {
    return get(this._obj, "paymentDetails.details.tip");
  }
  get internalStatus() {
    return get(this._obj, "paymentDetails.details.internalStatus");
  }
  get effectiveDate() {
    return get(this._obj, "paymentDetails.details.effectiveDate");
  }
  get createdAt() {
    return dayjs(get(this._obj, "paymentDetails.details.createdAt"));
  }
  get payerFullName() {
    return `${this.payerFirstName} ${this.payerLastName}`;
  }
  get payerFirstName() {
    return get(this._obj, "paymentDetails.details.payer.firstName");
  }
  get payerLastName() {
    return get(this._obj, "paymentDetails.details.payer.lastName");
  }
  get payerEmail() {
    return get(this._obj, "paymentDetails.details.payer.email");
  }
  get payerPhoneNumber() {
    return get(this._obj, "paymentDetails.details.payer.phoneNumber");
  }
  get merchantName() {
    return get(this._obj, "paymentDetails.details.merchant.name");
  }
  get merchantId() {
    return get(this._obj, "paymentDetails.details.merchant.merchantID");
  }
  getRetryLabel() {
    return "Add new account";
  }
}
export default SpenceTransactionDetails;
