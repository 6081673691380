import React from "react";
import styled from "styled-components";
import merge from "lodash/merge";
import PropTypes from "prop-types";

const Separator = ({styles}) => {
  const _styles = merge({}, defaultStyles, styles);

  return <SeparatorContainer styles={_styles} />;
};
const SeparatorContainer = styled.div`
  background-color: ${({styles}) => styles.color};
  height: ${({styles}) => styles.height};
  max-height: ${({styles}) => styles.height};
  width: 100%;
`;

const defaultStyles = {
  color: "#D9D9D9",
  height: "1px",
};

Separator.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
};

export default Separator;
