import {CartButton, TopNavigationBar} from "@ui";
import React from "react";
import {useTheme} from "styled-components";
import styles, {
  cartButtonBadgeStyles,
  cartButtonStyles,
  loginButtonStyles,
  searchButtonStyles,
} from "src/themes/baseTheme/components/Header/styles";
import DetailedSearchInputSection from "src/themes/baseTheme/elements/SearchInputSection/DetailedSearchInputSection";
import useHeader from "src/core/common/hooks/useHeader";
import Link from "src/core/common/components/modules/Link";
import merge from "lodash/merge";
import get from "lodash/get";
import useSite from "src/core/sites/hooks/useSite";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import {SEARCH_COMPONENT_DISPLAY} from "@ui/components/TopNavigationBar/TopNavigationBar";

function MedLeafThemeCartButton(props) {
  return <CartButton {...props} />;
}

function MedLeafHeader() {
  const site = useSite();
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.appBar"))};
  const toggles = useFeatureToggles();

  const {
    logoUrl,
    logoLinkUrl,
    largeScreenOptions,
    sideMenu,
    hideMenuButton,
    loginButtonProps,
    cartButtonProps,
    onClickMenuButton,
  } = useHeader();

  return (
    <>
      <TopNavigationBar
        logoUrl={logoUrl}
        mobileLogoUrl={logoUrl}
        LinkComponent={Link}
        logoLinkUrl={logoLinkUrl}
        hideMenuButton={hideMenuButton}
        hideMenuButtonOnLargerViewport={true}
        onClickMenuButton={onClickMenuButton}
        hideLoginButton={toggles.isKiosk()}
        styles={styles(theme, site)}
        largeScreenOptions={largeScreenOptions}
        largeScreenOptionsAlignment={"right"}
        logoPosition={site.getUiConfiguration().header.logoPosition}
        SearchComponent={DetailedSearchInputSection}
        searchComponentDisplay={SEARCH_COMPONENT_DISPLAY.full}
        CartButtonComponent={MedLeafThemeCartButton}
        onHoverOption={true}
        isExternalPage={true}
        cartButtonProps={{
          ...cartButtonProps,
          styles: cartButtonStyles(theme, site),
          badgeStyles: cartButtonBadgeStyles(theme, site),
          cartIcon: "bag-with-slot",
          outline: false,
          highlight: cartButtonProps.numberOfItemsInCart !== null,
        }}
        searchButtonProps={{
          outline: site.getUiConfiguration().search.outline,
          highlight: site.getUiConfiguration().search.highlight,
          searchIcon: "bold-search-icon",
          styles: searchButtonStyles(theme, site),
        }}
        loginButtonProps={{
          ...loginButtonProps,
          styles: loginButtonStyles(theme, site),
          outline: true,
          iconOnly: site.getUiConfiguration().login.iconOnly,
        }}
      />
      {sideMenu}
    </>
  );
}

export default MedLeafHeader;
