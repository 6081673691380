import React, {useState} from "react";
import styled, {createGlobalStyle} from "styled-components";
import {CloseIcon} from "@ui/components/Icons";
import ConfigurableImage from "@ui/components/ConfigurableImage";

export default function AndroidSmartBanner({
  appInfo = {},
  position = "top",
  onClose = () => {},
}) {
  const [show, setShow] = useState(true);
  const {imageUrl, name, linkButtonText, linkUrl} = appInfo;

  return (
    show && (
      <>
        <GlobalStyles />
        <BannerContainer position={position}>
          <AppInfoContainer>
            <CloseButton
              onClick={() => {
                setShow(false);
                onClose();
              }}
            >
              <CloseIcon size={"30px"} />
            </CloseButton>
            <AppImage source={imageUrl} width={"70px"} height={"70px"} />
            <AppName>{name}</AppName>
          </AppInfoContainer>
          <AppLinkButton href={linkUrl} rel="noopener noreferrer">
            {linkButtonText}
          </AppLinkButton>
        </BannerContainer>
      </>
    )
  );
}

const GlobalStyles = createGlobalStyle`
  body {
    overflow: scroll;
    padding-top: 100px;
  }
`;

const BannerContainer = styled.div`
  z-index: 9999;
  box-sizing: border-box;
  position: fixed;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
  top: ${({position}) => (position === "top" ? "0" : "85%")};
  left: ${({position}) => (position === "top" ? "0" : "20px")};
  right: ${({position}) => (position === "top" ? "0" : "20px")};
  width: ${({position}) => (position === "top" ? "100%" : "auto")};
  height: 100px;
  color: #000;
  background: #f2f2f2;
  box-shadow: ${({position}) =>
    position === "top"
      ? "0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3)"
      : "5px 5px 15px 0px rgba(138, 138, 138, 0.6)"};
  padding: 16px;
  column-gap: 8px;
  border-radius: ${({position}) => (position === "top" ? "0" : "10px")};
`;

const CloseButton = styled.button`
  border: none;
  border: 0;
  color: #b1b1b3;
  background: #efefef;
  cursor: pointer;
  padding: 0;
  display: flex;
  width: 30px;
  height: 30px;
`;

const AppImage = styled(ConfigurableImage)`
  border-radius: 15px;
  object-fit: contain;
  background: #fff;
`;

const AppInfoContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const AppName = styled.div`
  margin-top: 0;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 35vw;
  overflow: hidden;
`;

const AppLinkButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 40px;
  align-self: center;
  min-width: 70px;
  color: #2cb2dc;
  padding: 0;
  background: transparent;
  border-radius: 10px;
  border: 2px solid #2cb2dc;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
`;
