import React from "react";
import {BaseShowcaseProductsList} from "@themes/default/components/ShowcaseProductsList";
import useShowcaseSearchResults from "@mock/hooks/useShowcaseSearchResults";
import {Loader} from "@ui";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ShowcaseSearchResults({type}) {
  const {groups, hasMore, requestNextPage} = useShowcaseSearchResults(type);

  return (
    <InfiniteScroll
      dataLength={groups.length}
      next={requestNextPage}
      hasMore={hasMore}
      style={{overflow: "unset"}}
      loader={
        <LoaderContainer key={"loader"}>
          <Loader />
        </LoaderContainer>
      }
    >
      <BaseShowcaseProductsList groups={groups} keepQuery={false} />
    </InfiniteScroll>
  );
}

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
