import React from "react";
import styled, {useTheme} from "styled-components";
import merge from "lodash/merge";
import get from "lodash/get";
import {DisplayOnly, MultiRowProductsList, SortingProductsListHeader} from "@ui";
import styles, {
  headerStyles,
  noResultsPlaceholderStyles,
} from "src/themes/flight/components/ProductsList/styles";
import Loader from "src/core/common/components/elements/Loader";
import useSite from "src/core/sites/hooks/useSite";
import {useProductsOrderSelector} from "src/menu/hooks/useSortBy";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import {searchStyles} from "src/themes/baseTheme/components/Header/styles";
import useProductSortingOptions from "src/menu/hooks/useProductSortOptions";
import useDetailProductsList from "src/core/common/hooks/useDetailProductsList";

const Dropdown = dynamic(() =>
  import("react-dropdown").catch(logModuleLoadError("react-dropdown"))
);

export default function FlightProductsList() {
  const theme = useTheme();
  const site = useSite();
  const productsListHeaderStyles = merge(
    {},
    get(searchStyles(theme, site), "searchPage", {}),
    headerStyles(theme, site)
  );

  const props = useDetailProductsList();

  const sortOptions = useProductSortingOptions();
  const {onChangeOrder, currentOrder} = useProductsOrderSelector(sortOptions);

  const headerComponent = (
    <DisplayOnly dims={["lg", "md"]}>
      <SortingProductsListHeader
        styles={productsListHeaderStyles}
        orderOptions={sortOptions}
        currentOrder={currentOrder}
        onChangeOrder={onChangeOrder}
        DropdownComponent={Dropdown}
        numberOfResults={props.totalCount}
      />
    </DisplayOnly>
  );

  return (
    <ListContainer>
      <MultiRowProductsList
        {...props}
        styles={styles(theme, site)}
        noResultsPlaceholderProps={{
          styles: noResultsPlaceholderStyles(theme, site),
        }}
        LoadingComponent={CustomLoader}
        HeaderComponent={headerComponent}
      />
    </ListContainer>
  );
}

const CustomLoader = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
);

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  margin-bottom: 60px;
`;
