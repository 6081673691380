import store from "../state/store";
import {searchPreview} from "../state/actions";
import {useEffect, useState} from "react";

export default function useSearchPreview() {
  const dispatch = store.dispatch;

  const [state, setState] = useState(store.getState().searchPreview);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState().searchPreview);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const performSearchPreview = (term, options = {filters: {}, reset: false}) => {
    dispatch(searchPreview(term, options));
  };

  return {
    search: performSearchPreview,
    loading: state.products.loading,
    state: state,
  };
}
