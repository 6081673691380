import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {InformationCircle} from "@styled-icons/heroicons-outline/InformationCircle";
import {Close} from "@styled-icons/evil/Close";
import merge from "lodash/merge";
import {DisplayOnly, Modal} from "@ui";
import media from "@ui/utils/media";
import {PlusIcon} from "@ui/components/Icons";

export default function AnnouncementMessage({title, description, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const sanitizeDescription = () => ({
    __html: description,
  });

  return (
    <OuterContainer styles={_styles.container}>
      <Container styles={_styles.container}>
        <InnerContainer onClick={onOpenModal} styles={_styles.root}>
          <TitleContainer>
            <InformationCircleIcon />
            <Title styles={_styles.root}>{title}</Title>
          </TitleContainer>
          <DisplayOnly dims={["sm"]}>
            <PlusIconRounded styles={_styles.plusIcon} />
          </DisplayOnly>
          <DisplayOnly dims={["lg", "md"]}>
            <ShowMessage styles={_styles.button}>Show</ShowMessage>
          </DisplayOnly>
        </InnerContainer>
      </Container>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onCloseModal}
        ContainerComponent={ModalContent}
      >
        <CloseIcon onClick={onCloseModal} />
        <ModalTitle styles={_styles.title}>{title}</ModalTitle>
        <Description
          dangerouslySetInnerHTML={sanitizeDescription()}
          styles={_styles.description}
        />
      </Modal>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({styles}) => styles.backgroundColor};
`;

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 8px 32px;
  box-sizing: border-box;
  background-color: ${({styles}) => styles.backgroundColor};

  ${media.down("md")} {
    padding: 8px 16px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  padding: 12px 24px;
  background-color: ${({styles}) => styles.backgroundColor};
  border-radius: ${({styles}) => styles.borderRadius};
  cursor: pointer;

  ${media.down("md")} {
    flex-direction: row;
  }
`;

const InformationCircleIcon = styled(InformationCircle)`
  width: 24px;
  height: 24px;
  min-width: 24px;
`;

const PlusIconRounded = styled(PlusIcon)`
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: ${({styles}) => styles.backgroundColor};
  color: ${({styles}) => styles.color};
  border-radius: ${({styles}) => styles.borderRadius};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${media.down("md")} {
    align-self: flex-start;
  }
`;

const ShowMessage = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  padding-left: 16px;

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Title = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
    -webkit-line-clamp: 2;
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Description = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 612px;
  height: 100%;
  padding: 12px 70px 50px;

  ${media.down("md")} {
    width: 100%;
    padding: 12px 24px 24px;
  }
`;

const ModalTitle = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const CloseIcon = styled(Close)`
  width: 24px;
  height: 24px;
  align-self: flex-end;
  cursor: pointer;
`;

const defaultStyles = {
  root: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#000",
    backgroundColor: "#FFF",
    borderRadius: "4px",
  },
  container: {
    backgroundColor: "transparent",
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 700,
    color: "#000",
  },
  plusIcon: {
    color: "white",
    backgroundColor: "black",
    borderRadius: "40px",
  },
  description: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#000",
  },
  button: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 700,
    color: "#000",
  },
};

AnnouncementMessage.defaultProps = {
  styles: defaultStyles,
};

AnnouncementMessage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    container: PropTypes.shape({
      backgroundColor: PropTypes.string,
    }),
    title: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
    plusIcon: PropTypes.shape({
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    description: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
    button: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
  }),
};
