import get from "lodash/get";

export class MenuPages {
  constructor(pagesObject, basePath) {
    this.obj = pagesObject;
    this.basePath = basePath;
  }

  get pages() {
    return this.obj.map(page => new Page(page, this.basePath));
  }

  get footerPages() {
    return this.pages.filter(page => page.isActive && page.showInFooter);
  }

  get headerPages() {
    return this.pages.filter(page => page.isActive && page.showInHeader);
  }

  get sidebarPages() {
    return this.pages.filter(page => page.isActive && page.showInSidebar);
  }
}

class Page {
  static PreDefinedPages = {
    DEALS: "/deals",
  };

  constructor(pageObject, basePath) {
    this.obj = pageObject;
    this.basePath = basePath;
  }

  get id() {
    return get(this.obj, "id");
  }

  get type() {
    return get(this.obj, "type");
  }

  get description() {
    return get(this.obj, "description");
  }

  get isActive() {
    return get(this.obj, "is_active");
  }

  get slug() {
    return get(this.obj, "link");
  }

  get external() {
    if (this.linkTo && this.linkTo.indexOf("http") === -1) {
      return false;
    }
    return get(this.obj, "is_external");
  }

  get linkTo() {
    let link = get(this.obj, "link");
    if (link && link.indexOf("http") === -1) {
      if (link.indexOf("/") !== 0) {
        link = "/" + link;
      }

      switch (link) {
        case Page.PreDefinedPages.DEALS:
          return link + "/:shop";
        default:
          return this.basePath + link;
      }
    }
    return get(this.obj, "link");
  }

  get label() {
    return get(this.obj, "name");
  }

  get showInFooter() {
    return get(this.obj, "show_in_footer");
  }

  get showInHeader() {
    return get(this.obj, "show_in_header");
  }

  get showInSidebar() {
    return get(this.obj, "show_in_side_bar");
  }
}
