import {makeThemeProps} from "src/themes/utils/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getProductDetailProps(theme);
};

export const separatorStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getProductDetailSeparatorProps(theme);
};
