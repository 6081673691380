import {useEffect, useState} from "react";
import makeSiteFromSiteData from "src/apps/common/makeSiteFromSiteData";

function useSiteInitialization({kioskId, initialSite, siteGroupName}) {
  const [site, setSite] = useState(
    makeSiteFromSiteData(initialSite, {siteGroupName, kioskId})
  );

  useEffect(() => {
    const newSite = makeSiteFromSiteData(initialSite, {siteGroupName, kioskId});
    setSite(newSite);
  }, [initialSite, kioskId]);

  return {site};
}

export default useSiteInitialization;
