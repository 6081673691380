import React from "react";
import styled from "styled-components";
import {useKiosks} from "src/apps/kiosk/useKiosks";

function KiosksList({env}) {
  const [state] = useKiosks(env);
  const kiosks = state.data;
  if (!state.requested || state.loading) {
    return <></>;
  }

  return (
    <>
      {kiosks.length && kiosks.length > 0 ? (
        <Container>
          <ListContainer>
            {kiosks.map(
              (kiosk, idx) =>
                kiosk.is_active && (
                  <li key={idx}>
                    <a href={`/${kiosk.slug}`}>{kiosk.name}</a>
                  </li>
                )
            )}
          </ListContainer>
        </Container>
      ) : (
        <Placeholder>There are no kiosks to be listed!</Placeholder>
      )}
    </>
  );
}

const Placeholder = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 36px;
  transform: translate(-50%, -50%);
`;

const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  > li {
    display: flex;
  }
  > li > a {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
  }
`;
const Container = styled.div`
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.3);
  transform: translate3d(-50%, -50%, 0);
  max-height: 250px;
  overflow-y: scroll;
`;

export default KiosksList;
