import {CartButton, Sidemenu, TopNavigationBar} from "@ui";
import React from "react";
import {useTheme} from "styled-components";
import styles, {
  cartButtonBadgeStyles,
  cartButtonStyles,
  loginButtonStyles,
  searchButtonStyles,
  sidemenuStyles,
} from "src/themes/baseTheme/components/Header/styles";
import DetailedSearchInputSection from "src/themes/baseTheme/elements/SearchInputSection/DetailedSearchInputSection";
import useHeader from "src/core/common/hooks/useHeader";
import Link from "src/core/common/components/modules/Link";
import merge from "lodash/merge";
import get from "lodash/get";
import useSite from "src/core/sites/hooks/useSite";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import HeaderShopOptions from "src/core/common/components/collections/menu/simple/HeaderShopOptions";
import {SEARCH_COMPONENT_DISPLAY} from "@ui/components/TopNavigationBar/TopNavigationBar";

function FlightCartButton(props) {
  return <CartButton {...props} />;
}

export default function FlightHeader() {
  const site = useSite();
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.appBar"))};
  const toggles = useFeatureToggles();

  const {
    logoUrl,
    logoLinkUrl,
    isMenuOpen,
    largeScreenOptions,
    onClickMenuButton,
    onClickCloseMenuButton,
    showShopMenu,
    hideMenuButton,
    loginButtonProps,
    cartButtonProps,
    groups,
  } = useHeader();

  let shopOptions = {};
  if (showShopMenu) {
    shopOptions = HeaderShopOptions(groups, styles(theme, site));
  }

  const allOptions = [shopOptions, ...largeScreenOptions];

  return (
    <>
      <TopNavigationBar
        logoUrl={logoUrl}
        mobileLogoUrl={logoUrl}
        LinkComponent={Link}
        logoLinkUrl={logoLinkUrl}
        hideMenuButtonOnLargerViewport={true}
        hideMenuButton={hideMenuButton}
        hideLoginButton={toggles.isKiosk()}
        styles={styles(theme, site)}
        largeScreenOptions={allOptions}
        largeScreenOptionsAlignment={"left"}
        logoPosition={site.getUiConfiguration().header.logoPosition}
        SearchComponent={DetailedSearchInputSection}
        searchComponentDisplay={SEARCH_COMPONENT_DISPLAY.full}
        CartButtonComponent={FlightCartButton}
        onClickMenuButton={onClickMenuButton}
        cartButtonProps={{
          ...cartButtonProps,
          highlight: true,
          styles: cartButtonStyles(theme, site),
          badgeStyles: cartButtonBadgeStyles(theme, site),
        }}
        searchButtonProps={{
          outline: site.getUiConfiguration().search.outline,
          highlight: site.getUiConfiguration().search.highlight,
          searchIcon: site.getUiConfiguration().search.searchIcon,
          styles: searchButtonStyles(theme, site),
        }}
        loginButtonProps={{
          ...loginButtonProps,
          styles: loginButtonStyles(theme, site),
          outline: site.getUiConfiguration().login.outline,
          iconOnly: site.getUiConfiguration().login.iconOnly,
        }}
      />
      {!hideMenuButton && (
        <Sidemenu
          image={logoUrl}
          isOpen={isMenuOpen}
          close={onClickCloseMenuButton}
          options={groups}
          LinkComponent={Link}
          styles={sidemenuStyles(theme, site)}
        />
      )}
    </>
  );
}
