const {getEnv} = require("src/server/utils/getEnv");
const {default: axios} = require("axios");
const {isClient} = require("src/server/utils/isClient");

function getAxiosInstance(shop, host, timeout = 10000) {
  const env = getEnv(shop, host);
  const baseUrl = env.REACT_APP_TYMBER_ECOMMERCE_HOST;
  return axios.create({
    timeout,
    baseURL: getBaseUrl(baseUrl),
    headers: {"X-Store": env.REACT_APP_TYMBER_ECOMMERCE_ID},
  });
}

function getBaseUrl(originalBaseUrl) {
  if (isClient || originalBaseUrl.includes("blaze")) return originalBaseUrl;

  return originalBaseUrl.replace("api", "app");
}

module.exports = {
  getAxiosInstance,
};
