import React from "react";
import autoBind from "auto-bind";
import dynamic from "next/dynamic";
import IdentityVerification from "src/integrations/identityVerification/IdentityVerification";
import {logModuleLoadError} from "src/core/common/utils";
import * as Accounts from "src/core/api/accounts";
import * as AccountsAPI from "src/core/api/accounts";
import MainComponent from "./MainComponent";

const AgeCheckerForm = dynamic(
  () => import("./AgeCheckerForm").catch(logModuleLoadError("AgeCheckerForm")),
  {ssr: false}
);

class AgeChecker extends IdentityVerification {
  static INSTANCE = null;
  static HANDLERS = {};
  static ATTEMPTED_VERIFICATION = false;

  constructor(identityVerificationObject) {
    super();
    autoBind(this);
    this.identityVerificationObject = identityVerificationObject;
  }

  show(userData, onSuccess, onCancel) {
    this.init("#trigger-age-checker", userData, onSuccess, onCancel).then(instance => {
      instance.show();
      AgeChecker.ATTEMPTED_VERIFICATION = true;
    });
  }

  async init(element, userData, onSuccess, onCancel) {
    const ageCheckerScript = document.getElementById("age-checker");

    AgeChecker.HANDLERS = {onSuccess, onCancel};

    if (ageCheckerScript) {
      return Promise.resolve(AgeChecker.INSTANCE);
    }

    const key = await this.getApiKey();

    return new Promise((resolve, reject) => {
      ((w, d) => {
        let verificationId = null;
        const config = {
          key: key.value,
          autoload: false,
          show_close: true,
          element: element,
          onclosed: () => {
            AgeChecker.HANDLERS.onSuccess({verificationId});
          },
          onhide: () => {
            AgeChecker.HANDLERS.onCancel();
          },
          onstatuschanged: ({status, uuid}) => {
            if (status === "accepted") {
              verificationId = uuid;
            }
          },
          mode: "manual",
          data: {
            first_name: userData?.firstName,
            last_name: userData?.lastName,
            ...this.extractDobFromUserData(userData),
          },
        };

        w.AgeCheckerConfig = config;
        if (config.path && (w.location.pathname + w.location.search).indexOf(config.path))
          return;
        const h = d.getElementsByTagName("head")[0];
        const a = d.createElement("script");
        a.id = "age-checker";
        a.src = "https://cdn.agechecker.net/static/popup/v1/popup.js";
        a.crossOrigin = "anonymous";
        a.onerror = function (a) {
          reject();
        };
        a.onload = () => {
          AgeChecker.INSTANCE = this.createAgeCheckerInstance(config);
          resolve(AgeChecker.INSTANCE);
        };
        h.insertBefore(a, h.firstChild);
      })(window, document);
    });
  }

  extractDobFromUserData(userData) {
    // assuming date was created with dayjs
    const dob = userData?.dateOfBirth;
    if (!dob || !dob.isValid()) return {};

    return {
      dob_day: dob.get("date"),
      dob_month: dob.get("month") + 1,
      dob_year: dob.get("year"),
    };
  }

  createAgeCheckerInstance(config) {
    return window.AgeCheckerAPI.createInstance(config);
  }

  displayVerificationStatusOnUserProfile() {
    return false;
  }

  async getClientToken() {
    return {
      clientToken: null,
    };
  }

  async postValidation({identityVerificationId}) {
    const transaction = await AccountsAPI.createIdentityVerificationTransaction({
      service: "agechecker",
      identityVerificationId,
    });
    return {
      clientToken: transaction.data.attributes.public_token_1,
    };
  }

  async getIdVerificationStatus() {
    return {
      status: undefined,
    };
  }

  requiresVerificationOnSignUp() {
    return true;
  }

  renderForm(props) {
    return <AgeCheckerForm ageCheckerInstance={this} {...props} />;
  }

  async getApiKey() {
    const response = await Accounts.getIdentityServiceConfiguration("agechecker");
    return response.data.attributes.keys?.find(key => key.label === "API Key");
  }

  attemptedVerification() {
    return AgeChecker.ATTEMPTED_VERIFICATION;
  }

  renderMainComponent() {
    return <MainComponent ageCheckerInstance={this} />;
  }
}

export default AgeChecker;
