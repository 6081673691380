const useQuantityEvents = (quantity, allowQuantityChange, onRemove, onChangeQuantity) => {
  const onIncrease = () => {
    onChangeQuantity && onChangeQuantity(quantity + 1);
  };
  const onDecrease = () => {
    onChangeQuantity && onChangeQuantity(quantity - 1);
  };
  const showRemove = quantity <= 1 || !quantity || allowQuantityChange;
  const lessQuantityHandler = showRemove ? onRemove : onDecrease;

  return {
    onIncrease,
    onDecrease: lessQuantityHandler,
    showRemove,
  };
};

export default useQuantityEvents;
