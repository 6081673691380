import * as DealsApi from "src/core/api/deals";
import Reward from "src/core/common/models/reward";
import Promotion from "src/core/common/models/promotion";
import {DealTypes} from "src/deals/constants";
import {mapReward, mapPromotion} from "src/deals/utils";

export async function fetchDeal(dealType, dealId) {
  if (dealType === DealTypes.REWARDS) {
    const response = await DealsApi.reward(dealId);
    const reward = new Reward({...response.data, included: response.included});
    return mapReward(reward);
  } else {
    const response = await DealsApi.promotion(dealId);
    const promotion = new Promotion({...response.data, included: response.included});
    return mapPromotion(promotion);
  }
}

export async function fetchDeals(rewardsOffset = 0, promotionsOffset = 0) {
  const [rewards, promotions] = await Promise.all([
    fetchRewards(rewardsOffset),
    fetchPromotions(promotionsOffset),
  ]);

  return {
    rewards: rewards.data,
    promotions: promotions.data,
    hasMore: rewards.hasMore || promotions.hasMore,
  };
}

async function fetchRewards(offset) {
  const response = await DealsApi.rewards({
    "filter[is_featured]": true,
    "page[offset]": offset,
    "page[limit]": 10,
  });

  const rewards = response.data
    .map(element => new Reward({...element, included: response.included}))
    .map(mapReward);

  return {
    data: rewards,
    hasMore: response.meta.total_count > offset + response.meta.limit,
  };
}

async function fetchPromotions(offset) {
  const response = await DealsApi.promotions({
    "filter[is_featured]": true,
    "page[offset]": offset,
    "page[limit]": 10,
  });

  const promotions = response.data
    .map(element => new Promotion({...element, included: response.included}))
    .map(mapPromotion);

  return {
    data: promotions,
    hasMore: response.meta.total_count > offset + response.meta.limit,
  };
}
