import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";
import CartActions from "@ui/components/ProductCard/Actions/CartIconActions/CartActions";
import {MinusIcon, PlusIcon, TrashIcon} from "@ui/components/Icons";
import merge from "lodash/merge";

function CartIconActions({
  styles,
  price,
  discountedPrice,
  showQuantityPicker,
  hasWeightSizes,
  ...props
}) {
  const _styles = merge({}, defaultStyles, styles);
  return (
    <Container styles={_styles.root} pricePosition={_styles.prices.position}>
      <PriceContainer styles={_styles.prices}>
        <PriceText crossed={!!discountedPrice} styles={_styles.prices}>
          {price}
        </PriceText>
        {discountedPrice && (
          <DiscountedPriceText styles={_styles.prices}>
            {discountedPrice}
          </DiscountedPriceText>
        )}
      </PriceContainer>
      <ButtonsContainer
        allowQuantityChange={props.allowQuantityChange}
        pricePosition={_styles.prices.position}
        showQuantityPicker={showQuantityPicker}
        hasWeightSizes={hasWeightSizes}
      >
        <CartActions
          styles={_styles.actions}
          showQuantityPicker={showQuantityPicker}
          {...props}
          hasWeightSizes={hasWeightSizes}
        />
      </ButtonsContainer>
    </Container>
  );
}

const defaultStyles = {
  root: {
    fontWeight: "600",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
  },
  actions: {
    fontWeight: "400",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    backgroundColor: "#000",
    boxShadow: "none",
    color: "#fff",
    size: "24px",
    padding: spacing(1),
    borderRadius: "16px",
    height: {
      lg: "32px",
      md: "32px",
      sm: "32px",
    },
  },
  prices: {
    position: {
      lg: "top",
      md: "top",
      sm: "top",
    },
    alignment: "row",
    fontSize: {
      lg: "20px",
      md: "20px",
      sm: "20px",
    },
  },
};

const Container = styled.div.attrs(() => ({
  className: "cart-icon-actions cart-icon-actions__container",
  "data-keep-cart": "true",
}))`
  display: flex;

  justify-content: space-between;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
    flex-direction: ${({pricePosition}) =>
      pricePosition.lg === "top" ? "column" : "row"};
    direction: ${({pricePosition}) => (pricePosition.lg === "right" ? "rtl" : "ltr")};
    align-items: ${({pricePosition}) =>
      pricePosition.lg === "top" ? "flex-start" : "center"};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
    flex-direction: ${({pricePosition}) =>
      pricePosition.md === "top" ? "column" : "row"};
    direction: ${({pricePosition}) => (pricePosition.md === "right" ? "rtl" : "ltr")};
    align-items: ${({pricePosition}) =>
      pricePosition.md === "top" ? "flex-start" : "center"};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    flex-direction: ${({pricePosition}) =>
      pricePosition.sm === "top" ? "column" : "row"};
    direction: ${({pricePosition}) => (pricePosition.sm === "right" ? "rtl" : "ltr")};
    align-items: ${({pricePosition}) =>
      pricePosition.sm === "top" ? "flex-start" : "center"};
  }

  * {
    user-select: none;
  }
`;
const PriceContainer = styled.div.attrs(() => ({
  className: "cart-icon-actions cart-icon-actions__price-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: ${({styles}) => styles.alignment};
  direction: ltr;
  > span {
    margin-right: ${spacing(1)};
  }
`;
const ButtonsContainer = styled.div.attrs(() => ({
  className: "cart-icon-actions cart-icon-buttons__container",
  "data-keep-cart": "true",
}))`
  direction: ${({pricePosition, allowQuantityChange}) =>
    !allowQuantityChange && pricePosition.lg === "left" ? "rtl" : "ltr"};
  display: flex;
  > *:first-child {
    flex: ${({showQuantityPicker}) => (showQuantityPicker ? 1 : 0)};
  }

  ${media.up("lg")} {
    margin-top: ${({pricePosition}) => (pricePosition.lg === "top" ? spacing(1) : 0)};
    justify-content: ${({pricePosition}) =>
      pricePosition.lg === "left" ? "flex-end" : "flex-start"};
    min-width: ${({pricePosition, showQuantityPicker, hasWeightSizes}) =>
      getButtonsContainerWidth(pricePosition.lg, showQuantityPicker, hasWeightSizes)};
  }

  ${media.down("md")} {
    margin-top: ${({pricePosition}) => (pricePosition.md === "top" ? spacing(1) : 0)};
    justify-content: ${({pricePosition}) =>
      pricePosition.md === "left" ? "flex-end" : "flex-start"};
    min-width: ${({pricePosition, showQuantityPicker, hasWeightSizes}) =>
      getButtonsContainerWidth(pricePosition.md, showQuantityPicker, hasWeightSizes)};
  }

  ${media.down("sm")} {
    margin-top: ${({pricePosition}) => (pricePosition.sm === "top" ? spacing(1) : 0)};
    justify-content: ${({pricePosition}) =>
      pricePosition.sm === "left" ? "flex-end" : "flex-start"};
    min-width: ${({pricePosition, showQuantityPicker, hasWeightSizes}) =>
      getButtonsContainerWidth(pricePosition.sm, showQuantityPicker, hasWeightSizes)};
  }
`;
const getButtonsContainerWidth = (pricePosition, showQuantityPicker, hasWeightSizes) => {
  if (pricePosition === "top") return "100%";
  if (hasWeightSizes) return "auto";
  if (showQuantityPicker) return "90px";
  return "auto";
};

const PriceText = styled.span.attrs(() => ({
  className: "cart-icon-actions cart-icon-actions__price-text",
  "data-keep-cart": "true",
}))`
  text-decoration: ${({crossed}) => (crossed ? "line-through" : "none")};
  opacity: ${({crossed}) => (crossed ? "0.75" : "1")};
  color: ${({styles, crossed}) => (crossed ? styles.crossed.color : styles.color)};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.fontSize.lg : styles.fontSize.lg};
  font-weight: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.fontWeight : styles.fontWeight};
  ${media.down("md")} {
    font-size: ${({crossed, styles}) =>
      crossed && styles.crossed ? styles.crossed.fontSize.md : styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({crossed, styles}) =>
      crossed && styles.crossed ? styles.crossed.fontSize.sm : styles.fontSize.sm};
  }
`;

const DiscountedPriceText = styled.span.attrs(() => ({
  className: "cart-icon-actions cart-icon-actions__discounted-price-text",
  "data-keep-cart": "true",
}))`
  font-size: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.fontSize.lg : styles.fontSize.lg};
  font-weight: ${({crossed, styles}) =>
    crossed && styles.crossed ? styles.crossed.fontWeight : styles.fontWeight};
  color: ${({styles}) => styles.discountColor || styles.color};
  ${media.down("md")} {
    font-size: ${({crossed, styles}) =>
      crossed && styles.crossed ? styles.crossed.fontSize.md : styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({crossed, styles}) =>
      crossed && styles.crossed ? styles.crossed.fontSize.sm : styles.fontSize.sm};
  }
`;

CartIconActions.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    actions: PropTypes.shape({
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      backgroundColor: PropTypes.string,
      boxShadow: PropTypes.string,
      color: PropTypes.string,
      padding: PropTypes.string,
      borderRadius: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    prices: PropTypes.shape({
      position: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
      discountColor: PropTypes.string,
      alignment: PropTypes.oneOf(["row", "column", "row-reverse"]),
    }),
  }),
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  showQuantityPicker: PropTypes.bool,
  showText: PropTypes.bool,
  cartText: PropTypes.string,
  RemoveIconComponent: PropTypes.elementType,
  DecreaseIconComponent: PropTypes.elementType,
  IncreaseIconComponent: PropTypes.elementType,
  hasWeightSizes: PropTypes.bool,
  price: PropTypes.string,
  discountedPrice: PropTypes.string,
  cartIcon: PropTypes.string,
};

CartIconActions.defaultProps = {
  styles: defaultStyles,
  cartIcon: "default",
  RemoveIconComponent: TrashIcon,
  DecreaseIconComponent: MinusIcon,
  IncreaseIconComponent: PlusIcon,
};
export default CartIconActions;
