import axios from "axios";

export const kioskCache = {};
export const getKiosk = ({basePath, kioskId, storeId}) => {
  if (kioskCache[kioskId]) return Promise.resolve(kioskCache[kioskId]);

  const url = `${basePath}api/v1/store/kiosk/`;
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      "X-Store": storeId,
      "X-Kiosk": kioskId,
    },
  };
  return axios.get(url, options).then(response => {
    const kiosk = mapKiosk(response.data.data);
    kioskCache[kioskId] = kiosk;
    return kiosk;
  });
};

export function getKiosks({basePath, storeId}) {
  const url = `${basePath}api/v1/store/kiosks/`;
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      "X-Store": storeId,
    },
  };
  return axios.get(url, options);
}

export function mapKiosk(kiosk) {
  return {
    id: kiosk.attributes.id,
    name: kiosk.attributes.name,
    is_active: kiosk.attributes.is_active,
    slug: kiosk.attributes.slug,
    guest_checkout: kiosk.attributes.guest_checkout,
  };
}
