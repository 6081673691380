import React from "react";
import styled from "styled-components";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import {PrimaryLoadingButton} from "src/core/common/components/elements/button/PrimaryButton";
import OutlinedPrimaryButton from "src/core/common/components/elements/button/OutlinedPrimaryButton";
import routes from "src/core/common/routes";
import useRouter from "src/core/common/hooks/useRouter";
import MenuItem from "./MenuItem";

function AccountSection({close}) {
  const router = useRouter();
  const [, authApi] = useAuthentication();
  const userProfile = authApi.userProfile();
  const isUserLogged = authApi.isLoggedIn();

  const myAccountItem = {
    label: "My Account",
    icon: "user",
    linkTo: routes.myAccount,
  };

  function onClickLogout() {
    close();
    authApi.logout();
  }

  function onClickLogin() {
    close();
    router.push(routes.login);
  }

  function onClickSignUp() {
    close();
    router.push(routes.signup);
  }

  return (
    <AccountSectionContainer>
      {isUserLogged && userProfile && (
        <>
          <MenuItemLabel>Hi, {userProfile.getName()}</MenuItemLabel>
          <MyAccountLink onClick={close} item={myAccountItem} />
          <ButtonContainer>
            <OutlinedPrimaryButton onClick={onClickLogout} label="Log out" />
          </ButtonContainer>
        </>
      )}

      {!isUserLogged && (
        <>
          <PrimaryLoadingButton onClick={onClickLogin} label="Log in" />
          <ButtonContainer>
            <OutlinedPrimaryButton onClick={onClickSignUp} label="Sign up" />
          </ButtonContainer>
        </>
      )}
    </AccountSectionContainer>
  );
}

const MenuItemLabel = styled.span`
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
`;
const MyAccountLink = styled(MenuItem)`
  margin-top: ${({theme}) => theme.v2.spacing(2)};
`;
const AccountSectionContainer = styled.div`
  padding: 40px 0;
`;
const ButtonContainer = styled.div`
  margin-top: ${({theme}) => theme.v2.spacing(4)};
`;

export default AccountSection;
