import {PaymentOptions} from "src/core/payments/constants";
import Merrco from "src/core/payments/merrco/Merrco";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const MerrcoPaymentForm = suspensify(
  lazy(() => import("src/core/payments/merrco/MerrcoPaymentForm"))
);

export default {
  type: PaymentOptions.MERRCO,
  PaymentClass: Merrco,
  PaymentForm: MerrcoPaymentForm,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
