import {ActionTypes} from "@menu/state/actions";

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_PRODUCTS_SUCCESSFUL:
    case ActionTypes.REQUEST_GROUP_PRODUCTS_SUCCESSFUL:
      return addProductsToState(state, action.payload.data);
    case ActionTypes.SEARCH_PREVIEW_SUCCESS:
      return addProductsToState(state, action.payload.products);
    case ActionTypes.SEARCH_SUCCESS:
      return addProductsToState(state, action.payload.data.products);
    default:
      return state;
  }
}

function addProductsToState(state, productsPage) {
  if (!productsPage) {
    return state;
  }

  return productsPage.getElements().reduce((acc, product) => {
    acc[product.getSlug()] = product;
    return acc;
  }, state);
}
