import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import PromotionalImage from "@ui/components/PromotionalBanner/PromotionalImage";
import SwipeableViews from "react-swipeable-views";
import {autoPlay} from "react-swipeable-views-utils";
import DotSlideIndicator from "@ui/components/SlideIndicator/DotSlideSindicator";
import PromotionalBannerSkeleton from "@ui/components/PromotionalBanner/PromotionalBannerSkeleton";
import media from "@ui/utils/media";
import {DisplayOnly} from "@ui/components/Media";
import NavigationButton from "@ui/components/ProductsList/CarouselProductsList/NavigationButton";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function PromotionalBanner({
  styles,
  LinkComponent,
  skeleton,
  banners,
  dimensions,
  onChangeIndicator,
  SlideIndicatorComponent,
  siteUrl,
}) {
  const _styles = merge({}, defaultStyles, styles);
  const [index, setIndex] = useState(0);

  const bannerContainerRef = useRef();
  const numSlides = banners.length;

  const onChangeIndex = ({newIndex}) => {
    let _index = newIndex;
    if (_index < 0) {
      _index = numSlides - 1;
    } else if (_index > numSlides - 1) {
      _index = 0;
    }
    setIndex(_index);
  };

  const swipeableViewsProps = {
    enableMouseEvents: true,
    index: index,
    interval: 5000,
    onChangeIndex: newIndex => {
      setIndex(newIndex);
      onChangeIndicator && onChangeIndicator(index);
    },
  };

  if (skeleton) {
    return <PromotionalBannerSkeleton />;
  }

  if (numSlides === 0) {
    return null;
  }

  return (
    <BackgroundContainer styles={_styles.root}>
      {numSlides > 1 && (
        <DisplayOnly dims={["lg"]}>
          <NavigationButton
            styles={_styles.navigationButton}
            back={true}
            onClick={() => onChangeIndex({newIndex: index - 1})}
          />
        </DisplayOnly>
      )}
      <Container ref={bannerContainerRef}>
        <AutoPlaySwipeableViews {...swipeableViewsProps}>
          {banners.map((banner, index) => (
            <MaybeWithLink
              key={index}
              banner={banner}
              dimensions={dimensions}
              LinkComponent={LinkComponent}
              siteUrl={siteUrl}
            />
          ))}
        </AutoPlaySwipeableViews>
        {numSlides > 1 && (
          <SlideIndicatorContainer>
            <SlideIndicatorComponent
              numSlides={numSlides}
              maxIndicators={numSlides}
              currentSlide={index}
              onSelectSlide={setIndex}
            />
          </SlideIndicatorContainer>
        )}
      </Container>
      {numSlides > 1 && (
        <DisplayOnly dims={["lg"]}>
          <NavigationButton
            styles={_styles.navigationButton}
            onClick={() =>
              onChangeIndex({
                newIndex: index + 1,
              })
            }
          />
        </DisplayOnly>
      )}
    </BackgroundContainer>
  );
}

const openInNewTab = (banner, destinationUrl) => {
  if (banner?.link) {
    try {
      const url = new URL(banner.link);
      const siteUrl = new URL(destinationUrl);
      return url.hostname !== siteUrl.hostname;
    } catch (e) {
      return true;
    }
  }
  return false;
};

const MaybeWithLink = ({LinkComponent, banner, dimensions, siteUrl}) => {
  const image = (
    <PromotionalImage hasLink={!!banner.link} dimensions={dimensions} image={banner} />
  );
  if (!banner.link) return image;

  return (
    <LinkComponent
      to={banner.link}
      external={true}
      target={openInNewTab(banner, siteUrl) ? "_blank" : ""}
    >
      {image}
    </LinkComponent>
  );
};

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    backgroundImage: "none",
    padding: "16px 0",
  },
  navigationButton: {
    root: {
      backgroundColor: "#eeeeee",
      color: "#000000",
      width: "40px",
      height: "40px",
      borderRadius: "30px",
      position: "relative",
      side: "50%",
    },
    icon: {
      size: "16px",
    },
  },
};

const BackgroundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: ${({styles}) => styles?.height?.lg};
  padding: ${({styles}) => styles.padding};
  background-color: ${({styles}) => styles.backgroundColor};
  background-image: ${({styles}) => styles.backgroundImage};

  ${media.down("sm")} {
    height: ${({styles}) => styles?.height?.sm};
  }
`;

const Container = styled.div`
  width: 100%;
  display: block;
  box-sizing: border-box;
  max-width: 1440px;
  position: relative;

  ${media.down("md")} {
    padding: 0 !important;
  }
`;

const SlideIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
`;

const metaProps = PropTypes.shape({
  height: PropTypes.number,
  width: PropTypes.number,
});

PromotionalBanner.defaultProps = {
  SlideIndicatorComponent: DotSlideIndicator,
  skeleton: false,
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({to, children, target}) => (
    <a target={target} href={to}>
      {children}
    </a>
  ),
};

PromotionalBanner.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      backgroundImage: PropTypes.string,
    }),
  }),
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      link: PropTypes.string,
      srcDesktop: PropTypes.string,
      srcMobile: PropTypes.string,
    })
  ),
  dimensions: PropTypes.shape({
    lg: metaProps,
    md: metaProps,
    sm: metaProps,
  }),
  onChangeIndicator: PropTypes.func,
  SlideIndicatorComponent: PropTypes.elementType,
  skeleton: PropTypes.bool,
  LinkComponent: PropTypes.elementType,
  siteUrl: PropTypes.string,
};
