import {Footer} from "@ui";
import React from "react";
import Link from "src/core/common/components/modules/Link";
import useSite from "src/core/sites/hooks/useSite";
import {useTheme} from "styled-components";
import styles from "src/themes/baseTheme/components/Footer/styles";

function BaseThemeFooter({socialLinks, footerPages, version, licenseNumber}) {
  const logo = useSite().getLogoUrl();
  const theme = useTheme();
  const _socialLinks = socialLinks.filter(s => s.linkTo);
  const site = useSite();
  const siteName = site.getPoweredByName();
  const showWarning = site.getUiConfiguration().footer.showWarning;

  return (
    <Footer
      logo={logo}
      socialLinks={_socialLinks}
      footerPages={footerPages}
      ExternalLinkComponent={Link}
      version={version}
      licenseNumber={licenseNumber}
      siteName={siteName}
      styles={styles(theme, site)}
      warning={showWarning ? <FooterP65Warning site={site} /> : null}
    />
  );
}

const FooterP65Warning = ({site}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      paddingTop: "10px",
    }}
  >
    <div
      style={{
        width: "990px",
        textAlign: "center",
        fontFamily: "sans-serif",
        fontSize: "14px",
      }}
      dangerouslySetInnerHTML={{
        __html: `${site.getOptions().getCheckoutLegalWarning()}`,
      }}
    />
  </div>
);

export default BaseThemeFooter;
