import * as ProductsApi from "src/core/api/products";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import Page from "src/core/common/models/page";
import apiConfig, {TymberAPIConfig} from "src/core/api/config";

export async function fetchProducts({limit, offset, order, filters, deliveryInfo}) {
  if (!deliveryInfo) return null;

  return ProductsApi.list(
    prepareParams({limit, offset, order, filters, deliveryInfo})
  ).then(response => {
    return new Page({
      meta: response.meta,
      objects: response.data.map(
        element => new Product(populateRelations(element, response.included))
      ),
    });
  });
}

export function getCachedProducts({limit, order, filters, deliveryInfo}) {
  const config = {
    method: "get",
    url: "/api/v1/products/",
    headers: apiConfig.headers,
    params: prepareParams({
      limit,
      order,
      filters,
      deliveryInfo,
    }),
  };

  const cached = TymberAPIConfig.cacheAdapter.get(config);
  if (cached) {
    return new Page({
      meta: cached.data.meta,
      objects: cached.data.data.map(
        element => new Product(populateRelations(element, cached.data.included))
      ),
    });
  }

  return null;
}

function prepareParams({limit, offset, order, filters}) {
  const _filters = filters?.serialize ? filters.serialize() : filters;
  return pickBy({limit, offset, ..._filters, order}, identity);
}
