import dayjs from "dayjs";

class TimeRange {
  constructor(start, end) {
    this._start = dayjs(start);
    this._end = dayjs(end);
    this._startStr = start;
    this._endStr = end;
  }

  get start() {
    return this._start.toDate();
  }

  get end() {
    return this._end.toDate();
  }

  getStart(format) {
    if (!format) return this.start.toISOString();

    return this._start.format(format);
  }

  getEnd(format) {
    if (!format) return this.end.toISOString();

    return this._end.format(format);
  }

  toString(format = "h:mm A") {
    return `${this._start.format(format)} - ${this._end.format(format)}`;
  }

  isSameDay(other) {
    return dayjs(other).isSame(this._start, "day");
  }
}

export default TimeRange;
