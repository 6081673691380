import ProductType from "src/core/common/models/productType";
import ProductBrand from "src/core/common/models/productBrand";
import ProductCategory from "src/core/common/models/productCategory";
import ProductTag from "src/core/common/models/productTag";
import ProductWeight from "src/core/common/models/productWeight";
import ProductPricesRange from "src/core/common/models/productPricesRange";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import PotencyRange from "src/core/common/models/potencyRange";
import identity from "lodash/identity";
import memoize from "lodash/memoize";

class ProductFiltersCatalog {
  constructor(obj) {
    this._obj = obj;
  }

  get types() {
    return this.filterByType("product_types", entry => new ProductType(entry));
  }

  get brands() {
    return this.filterByType("product_brands", entry => new ProductBrand(entry));
  }

  get categories() {
    return this.filterByType("product_categories", entry => new ProductCategory(entry));
  }

  get tags() {
    return this.filterByType("tags", entry => new ProductTag(entry));
  }

  get weights() {
    return this.filterByType("product_weights", entry => new ProductWeight(entry));
  }

  get thcRange() {
    return this.filterByType("thc_ranges", entry => new PotencyRange(entry))[0];
  }

  get cbdRange() {
    return this.filterByType("cbd_ranges", entry => new PotencyRange(entry))[0];
  }

  get priceRange() {
    return this.filterByType("price_ranges", entry => new ProductPricesRange(entry))[0];
  }

  get hasOnSaleProducts() {
    return this.filterByType("on_sale_filter")[0].attributes.count > 0;
  }

  get subcategories() {
    return this.categories.filter(obj => obj.getParentCategoryId() !== null);
  }

  filterByType = memoize(
    (type, mapFunction = identity) => {
      const filtered = this._obj.included.filter(entry => entry.type === type);

      return uniqBy(filtered, function (entry) {
        return get(entry, "id");
      }).map(mapFunction);
    },
    type => type
  );

  toString() {
    return JSON.stringify(this._obj);
  }
}

export default ProductFiltersCatalog;
