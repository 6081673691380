import React from "react";
import Message from "@ui/components/AnnouncementMessage";
import useTheme from "@mock/hooks/useTheme";
import useAnnouncementMessage from "@mock/hooks/useAnnouncementMessage";
import styles from "@themes/default/components/AnnouncementMessage/styles";

export default function AnnouncementMessage() {
  const theme = useTheme();
  const {site, title, description} = useAnnouncementMessage();

  const _styles = styles(theme, site);

  return <Message title={title} description={description} styles={_styles} />;
}
