import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import NavigationButton from "@ui/components/TopNavigationBar/NavigationButton";
import spacing from "@ui/utils/spacing";
import {getUserIconComponent} from "@ui/components/Icons";

function LoginButton({
  styles,
  template,
  user,
  iconOnly,
  loading,
  loginUrl,
  LinkComponent,
  loginIcon,
  loginText,
  ...props
}) {
  const getText = () => {
    if (loading) {
      return "";
    } else if (user) {
      return template({user});
    } else {
      return loginText;
    }
  };

  const hideIcon = !user && !loading && !iconOnly;
  const text = getText();
  const LoginIcon = getUserIconComponent(loginIcon);

  return (
    <LinkComponent to={loginUrl}>
      <Container styles={styles}>
        <NavigationButton
          styles={styles}
          hideTextInSmallerViewport={!!user || loading}
          dataCy="openLoginButton"
          {...props}
        >
          {text && !iconOnly && (
            <Text styles={styles} isIconHidden={hideIcon}>
              {text}
            </Text>
          )}
          {!hideIcon && <LoginIcon size={"24px"} color={styles.color} />}
        </NavigationButton>
      </Container>
    </LinkComponent>
  );
}

const Text = styled.span.attrs(() => ({
  className: "login-btn login-btn__text",
}))`
  margin-right: ${({isIconHidden}) => (isIconHidden ? 0 : spacing(1))};
  text-transform: ${({styles}) => styles.textTransform};
  font-size: ${({styles}) => styles.fontSize};
  font-family: ${({styles}) => styles.fontFamily};
`;

const Container = styled.div.attrs(() => ({
  className: "navigation-button navigation-button__container",
}))`
  &:hover {
    background-color: ${({styles}) => styles.hoverLoginBackgroundColor};
    border-radius: ${({styles}) => styles.borderRadius};
  }
`;

LoginButton.propTypes = {
  template: PropTypes.func,
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }),
  loading: PropTypes.bool,
  iconOnly: PropTypes.bool,
  loginIcon: PropTypes.string,
  LinkComponent: PropTypes.elementType,
  loginText: PropTypes.string,
  loginUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  styles: NavigationButton.propTypes.styles,
};

LoginButton.defaultProps = {
  template: ({user}) => `Hi, ${user.firstName}`,
  loading: false,
  iconOnly: false,
  loginIcon: "default",
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({to, children}) => (
    <a style={{display: "flex", textDecoration: "none"}} href={to}>
      {children}
    </a>
  ),
  loginText: "Login",
  styles: {},
};
export default LoginButton;
