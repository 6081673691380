import {makeThemeProps} from "@themes/default/props/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getProductDetailProps(theme, site);
};

export const separatorStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getProductDetailSeparatorProps(theme);
};

export const modalStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getModalProps(theme);
};
