import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import Filters from "@ui/components/SortFilters/Filters";
import DeliveryBannerButton from "@ui/components/FiltersAndDeliveryBanner/DeliveryBannerButton";

export default function FiltersAndDeliveryBannerSection({
  filtersCount,
  onChangeFilters,
  FiltersButtonComponent,
  onClickDeliveryBanner,
  DeliveryBannerTextComponent,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container>
      <FiltersContainer>
        <Filters
          filtersCount={filtersCount}
          onClick={onChangeFilters}
          styles={_styles.filters}
          FiltersButtonComponent={FiltersButtonComponent}
        />
      </FiltersContainer>
      <DeliveryBannerButton
        DeliveryBannerTextComponent={DeliveryBannerTextComponent}
        onClick={onClickDeliveryBanner}
        styles={_styles.deliveryBanner}
      />
    </Container>
  );
}

const defaultStyles = {
  root: {},
  filters: {
    button: {
      root: {
        border: "2px solid #58b856",
        hoverBorderColor: "#fff",
        borderRadius: "20px",
        fontSize: "12px",
        fontWeight: "600",
        width: "110px",
        fontFamily: "sofia-pro, sans-serif",
        textTransform: "uppercase",
        padding: "4px 8px",
      },
      secondary: {
        backgroundColor: "#E8F9EB",
        color: "#58217E",
      },
      large: {
        padding: "4px 8px",
        height: "40px",
      },
    },
  },
  deliveryBanner: {
    button: {
      root: {
        fontSize: "16px",
        fontFamily: "sofia-pro, sans-serif",
        border: "3px solid white",
        borderRadius: "20px",
        fontWeight: "700",
        padding: "0px 20px",
        hoverBorderColor: "#fff",
        iconColor: "#58217E",
        iconWidth: "24px",
        iconHeight: "24px",
        boxShadow: {
          lg: "rgba(35, 13, 49, 0.2) 0px 7px 29px 0px",
        },
        height: "48px",
      },
      secondary: {
        backgroundColor: "#fff",
        color: "#838383",
      },
      large: {
        padding: "0px 20px",
        height: "48px",
      },
    },
  },
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 32px 32px;
  position: relative;
  max-width: 1440px;
  height: 0;

  ${media.down("sm")} {
    flex-direction: column-reverse;
    width: 100%;
    padding: 16px 16px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: auto;
  }

  ${media.up("md")} {
    &:before {
      border-top: 1px solid #e8e8e8;
      content: "";
      margin: 0 auto;
      position: absolute;
      top: calc(50% - 2px / 2);
      left: 0;
      right: 0;
      bottom: 0;
      width: 95%;
      z-index: -1;
    }
  }
`;

const FiltersContainer = styled.div`
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    justify-content: start;
  }
`;

FiltersAndDeliveryBannerSection.propTypes = {
  filtersCount: PropTypes.number,
  onChangeFilters: PropTypes.func,
  FiltersButtonComponent: PropTypes.elementType,
  DeliveryBannerTextComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    filters: PropTypes.shape({
      root: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        border: PropTypes.string,
        borderRadius: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        textTransform: PropTypes.string,
        width: PropTypes.string,
        hoverBorderColor: PropTypes.string,
      }),
      secondary: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
      }),
      large: PropTypes.shape({
        padding: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
    deliveryBanner: PropTypes.shape({
      root: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        border: PropTypes.string,
        borderRadius: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        textTransform: PropTypes.string,
        width: PropTypes.string,
        hoverBorderColor: PropTypes.string,
      }),
      secondary: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
      }),
      large: PropTypes.shape({
        padding: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
  }),
};
