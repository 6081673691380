import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Close} from "@styled-icons/remix-line/Close";

export default function DrawerHeader({
  hasAppliedFilters,
  filtersCounter,
  clearFilters,
  onClose,
  styles,
}) {
  return (
    <>
      <DrawerHeaderContainer>
        <CustomHeaderContainer styles={styles.title}>
          <DrawerTitle styles={styles.title}>Filters</DrawerTitle>
          {hasAppliedFilters && (
            <FiltersCounter styles={styles.counter}>{filtersCounter}</FiltersCounter>
          )}
        </CustomHeaderContainer>
        <HeaderControls>
          <CloseIcon onClick={onClose} />
        </HeaderControls>
      </DrawerHeaderContainer>
      <DrawerClearButton onClick={clearFilters}>Clear</DrawerClearButton>
    </>
  );
}

const DrawerHeaderContainer = styled.div`
  margin-bottom: ${({theme}) => theme.v2.spacing(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderContainer = styled.h2`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.v2.color.filters};
  font-family: ${({theme}) => theme.v2.typography.titles.family};
  font-size: ${({theme}) => theme.v2.typography.sizing["3xl"].lg};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.light};
  column-gap: ${({theme}) => theme.v2.spacing(4)};
  margin: 0;
`;

const CustomHeaderContainer = styled(HeaderContainer)`
  font-weight: ${({theme}) => theme.v2.typography.titles.weight.heavy};
  color: ${({styles}) => styles.color};
`;

const DrawerTitle = styled.div`
  padding: 6px 10px 6px ${({styles}) => (styles.backgroundColor ? "6px" : "0px")};
  background-color: ${({styles}) => styles.backgroundColor};
`;

const FiltersCounter = styled.span`
  color: ${({styles}) => styles.color};
  font-family: ${({theme}) => theme.v2.typography.titles.family};
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-left: ${({theme}) => theme.v2.spacing(4)};
  border: 0;
  outline: 0;
  box-sizing: border-box;
  text-align: center;
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  box-shadow: none;
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  background-color: #fafafa;
  min-width: 30px;
  height: 27px;
  justify-content: center;
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: ${({theme}) => theme.v2.spacing(2)};
  }
`;

const CloseIcon = styled(Close)`
  height: 32px;
  width: 32px;
  cursor: pointer;
`;

const DrawerClearButton = styled.a`
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  color: #999999;
  cursor: pointer;
`;

const defaultStyles = {
  title: {
    backgroundColor: "#fff",
    color: "#000",
  },
  counter: {
    color: "#000",
  },
};

DrawerHeader.defaultProps = {
  clearFilters: () => {},
  onClose: () => {},
  styles: defaultStyles,
};

DrawerHeader.propTypes = {
  hasAppliedFilters: PropTypes.bool,
  filtersCounter: PropTypes.number,
  clearFilters: PropTypes.func,
  onClose: PropTypes.func,
  styles: PropTypes.object,
};
