import React, {Suspense, lazy} from "react";

const BankIcon = lazy(() => import("src/core/payments/stronghold/bank-icons/index"));

function LazyBankIcon(props) {
  return (
    <Suspense fallback={<div />}>
      <BankIcon {...props} />
    </Suspense>
  );
}

export default LazyBankIcon;
