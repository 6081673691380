import useSchemaContext from "src/core/seo/components/useSchemaContext";
import React, {useMemo} from "react";
import Head from "next/head";
import makeProductDetailSchema from "src/core/seo/schema-objects/factories/productDetail";
import useSite from "src/core/sites/hooks/useSite";
import {getProductDetailUrl} from "src/core/seo/meta";
import {isClient} from "src/server/utils/isClient";
import useCanonicalUrl from "src/core/seo/hooks/useCanonicalUrl";

function ProductDetailSchema({product}) {
  const ctx = useSchemaContext();
  const site = useSite();

  const canonicalUrl = useMemo(() => {
    if (product) {
      return getProductDetailUrl(product, ctx);
    }
    return "";
  }, [product, ctx]);

  const schemas = makeProductDetailSchema(product, site, ctx);

  useCanonicalUrl(canonicalUrl);

  return (
    <Head>
      {!isClient && <link rel="canonical" href={canonicalUrl} key={"canonical"} />}
      {schemas.map((schema, index) => (
        <script
          key={`head-link-${index}`}
          type={schema.type}
          dangerouslySetInnerHTML={{__html: schema.innerHTML}}
        />
      ))}
    </Head>
  );
}

export default ProductDetailSchema;
