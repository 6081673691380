import {makeAction} from "src/core/common/utils";
import ActionTypes from "./actionTypes";

export const initialized = () => makeAction(ActionTypes.INITIALIZED);

export const requestUserRegistration = payload =>
  makeAction(ActionTypes.REQUEST_USER_REGISTRATION, payload);
export const requestUserRegistrationSuccess = successPayload =>
  makeAction(ActionTypes.REQUEST_USER_REGISTRATION_SUCCESS, successPayload);
export const requestUserRegistrationFailure = error =>
  makeAction(ActionTypes.REQUEST_USER_REGISTRATION_FAILURE, {
    error,
  });

export const requestUserLogin = payload =>
  makeAction(ActionTypes.REQUEST_USER_LOGIN, payload);
export const requestUserLoginSuccess = successPayload =>
  makeAction(ActionTypes.REQUEST_USER_LOGIN_SUCCESS, successPayload);
export const requestUserLoginFailure = error =>
  makeAction(ActionTypes.REQUEST_USER_LOGIN_FAILURE, {
    error,
  });

export const loadUser = payload => makeAction(ActionTypes.LOAD_USER, payload);

export const loadUserProfile = payload =>
  makeAction(ActionTypes.LOAD_USER_PROFILE, payload);
export const loadUserProfileSuccess = profile =>
  makeAction(ActionTypes.LOAD_USER_PROFILE_SUCCESS, {profile});
export const loadUserProfileFailure = error =>
  makeAction(ActionTypes.LOAD_USER_PROFILE_FAILURE, {error});

export const requestRecoverPassword = params =>
  makeAction(ActionTypes.REQUEST_RECOVER_PASSWORD, params);
export const requestRecoverPasswordSuccess = data =>
  makeAction(ActionTypes.REQUEST_RECOVER_PASSWORD_SUCCESS, {data});
export const requestRecoverPasswordFailure = error =>
  makeAction(ActionTypes.REQUEST_RECOVER_PASSWORD_FAILURE, {error});

export const requestResetPassword = params =>
  makeAction(ActionTypes.REQUEST_RESET_PASSWORD, params);
export const requestResetPasswordSuccess = data =>
  makeAction(ActionTypes.REQUEST_RESET_PASSWORD_SUCCESS, {data});
export const requestResetPasswordFailure = error =>
  makeAction(ActionTypes.REQUEST_RESET_PASSWORD_FAILURE, {error});

export const requestChangePassword = ({currentPassword, password, confirmPassword}) =>
  makeAction(ActionTypes.REQUEST_CHANGE_PASSWORD, {
    currentPassword,
    password,
    confirmPassword,
  });
export const requestChangePasswordSuccess = data =>
  makeAction(ActionTypes.REQUEST_CHANGE_PASSWORD_SUCCESS, {data});
export const requestChangePasswordFailure = error =>
  makeAction(ActionTypes.REQUEST_CHANGE_PASSWORD_FAILURE, {error});

export const requestUpdateUser = payload =>
  makeAction(ActionTypes.REQUEST_UPDATE_USER, payload);
export const requestUpdateUserSuccess = successPayload =>
  makeAction(ActionTypes.REQUEST_UPDATE_USER_SUCCESS, successPayload);
export const requestUpdateUserFailure = error =>
  makeAction(ActionTypes.REQUEST_UPDATE_USER_FAILURE, {
    error,
  });

export const requestDeactivateUser = payload =>
  makeAction(ActionTypes.REQUEST_DEACTIVATE_USER, payload);
export const requestDeactivateUserSuccess = successPayload =>
  makeAction(ActionTypes.REQUEST_DEACTIVATE_USER_SUCCESS, successPayload);
export const requestDeactivateUserFailure = error =>
  makeAction(ActionTypes.REQUEST_DEACTIVATE_USER_FAILURE, {
    error,
  });

export const requestAccountVerification = payload =>
  makeAction(ActionTypes.REQUEST_ACCOUNT_VERIFICATION, payload);
export const requestAccountVerificationSuccess = successPayload =>
  makeAction(ActionTypes.REQUEST_ACCOUNT_VERIFICATION_SUCCESS, successPayload);
export const requestAccountVerificationFailure = error =>
  makeAction(ActionTypes.REQUEST_ACCOUNT_VERIFICATION_FAILURE, {
    error,
  });
export const requestAccountVerificationCheck = payload =>
  makeAction(ActionTypes.REQUEST_ACCOUNT_VERIFICATION_CHECK, payload);
export const requestAccountVerificationCheckSuccess = successPayload =>
  makeAction(ActionTypes.REQUEST_ACCOUNT_VERIFICATION_CHECK_SUCCESS, successPayload);
export const requestAccountVerificationCheckFailure = error =>
  makeAction(ActionTypes.REQUEST_ACCOUNT_VERIFICATION_CHECK_FAILURE, {
    error,
  });

export const requestUserLogout = () => makeAction(ActionTypes.REQUEST_USER_LOGOUT);
export const requestUserLogoutSuccess = () =>
  makeAction(ActionTypes.REQUEST_USER_LOGOUT_SUCCESS);
export const requestUserLogoutFailure = error =>
  makeAction(ActionTypes.REQUEST_USER_LOGOUT_FAILURE, {
    error,
  });

export const resetErrors = () => makeAction(ActionTypes.RESET_ERRORS);
