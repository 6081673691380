import styled from "styled-components";
import media from "@ui/utils/media";
import {CloseIcon} from "@ui/components/Icons";

export const ModalExternalContainer = styled.div.attrs(() => ({
  className: "modal-external-container",
  "data-keep-cart": "true",
}))`
  position: relative;
  max-width: 90%;
  width: 900px;
  height: 670px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > form {
    max-width: 90%;
    display: flex;
    justify-content: center;

    ${media.down("md")} {
      width: 100%;
      height: 100%;
      max-width: 450px;
    }
  }

  ${media.down("md")} {
    max-width: 100%;
    height: 100%;
  }

  .title {
    margin: 16px 0 !important;
    text-align: center;
  }
`;

export const ModalCloseButton = styled(CloseIcon)`
  position: absolute;
  right: 0;
  top: 4px;
  width: 30px;
  height: 30px;
  color: #212121;
  cursor: pointer;
  z-index: 2;
`;
