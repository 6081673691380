export const ActionTypes = {
  REQUEST_DEALS: "deals/request_deals",
  REQUEST_DEALS_SUCCESS: "deals/request_deals_success",
  REQUEST_DEALS_FAILURE: "deals/request_deals_failure",

  REQUEST_DEAL: "deals/request_deal",
  REQUEST_DEAL_SUCCESS: "deals/request_deal_success",
  REQUEST_DEAL_FAILURE: "deals/request_deal_failure",

  REQUEST_ON_SALE_PRODUCTS: "deals/request_on_sale_products",
  REQUEST_ON_SALE_PRODUCTS_SUCCESS: "deals/request_on_sale_products_success",
  REQUEST_ON_SALE_PRODUCTS_FAILURE: "deals/request_on_sale_products_failure",

  REQUEST_PROMOTION_PRODUCTS: "deals/request_promotion_products",
  REQUEST_PROMOTION_PRODUCTS_SUCCESS: "deals/request_promotion_products_success",
  REQUEST_PROMOTION_PRODUCTS_FAILURE: "deals/request_promotion_products_failure",

  CLEAR_DEAL: "deals/clear_deal",
};

export function requestPromotionProducts(promotionId, options = {}) {
  return {
    type: ActionTypes.REQUEST_PROMOTION_PRODUCTS,
    payload: {promotionId, options},
  };
}

export function requestPromotionProductsSuccess(productsPage) {
  return {
    type: ActionTypes.REQUEST_PROMOTION_PRODUCTS_SUCCESS,
    payload: {productsPage},
  };
}

export function requestPromotionProductsFailure(error) {
  return {
    type: ActionTypes.REQUEST_PROMOTION_PRODUCTS_FAILURE,
    payload: {error},
  };
}

export function requestOnSaleProducts(options = {}) {
  return {
    type: ActionTypes.REQUEST_ON_SALE_PRODUCTS,
    payload: {options},
  };
}

export function requestOnSaleProductsSuccess(productsPage) {
  return {
    type: ActionTypes.REQUEST_ON_SALE_PRODUCTS_SUCCESS,
    payload: {productsPage},
  };
}

export function requestOnSaleProductsFailure(error) {
  return {
    type: ActionTypes.REQUEST_ON_SALE_PRODUCTS_FAILURE,
    payload: {error},
  };
}

export function clearDeal() {
  return {
    type: ActionTypes.CLEAR_DEAL,
  };
}

export function requestDeal(dealType, dealId) {
  return {
    type: ActionTypes.REQUEST_DEAL,
    payload: {dealType, dealId},
  };
}

export function requestDealSuccess(deal) {
  return {
    type: ActionTypes.REQUEST_DEAL_SUCCESS,
    payload: {deal},
  };
}

export function requestDealFailure(error) {
  return {
    type: ActionTypes.REQUEST_DEAL_FAILURE,
    payload: {error},
  };
}

export function requestDeals(options = {}) {
  return {
    type: ActionTypes.REQUEST_DEALS,
    payload: {options},
  };
}

export function requestDealsSuccess(deals, hasMore) {
  return {
    type: ActionTypes.REQUEST_DEALS_SUCCESS,
    payload: {
      deals,
      hasMore,
    },
  };
}

export function requestDealsFailure(error) {
  return {
    type: ActionTypes.REQUEST_DEALS_FAILURE,
    payload: {error},
  };
}
