import OnlinePayment from "src/core/payments/OnlinePayment";
import autoBind from "auto-bind";
import * as PaymentsAPI from "src/core/api/payments";
import axios from "axios";
import {makePaymentSource} from "src/core/payments/factories/paymentSource";
import {getCardType} from "src/core/payments/utils";
import {PaymentOptions} from "src/core/payments/constants";

class LedgerGreen extends OnlinePayment {
  static BASE_URL = "https://sandbox-api.ledgergreen.com";
  constructor(code) {
    super(code);

    this.axiosInstance = axios.create({
      baseURL: LedgerGreen.BASE_URL,
    });

    autoBind(this);
  }

  getToken() {
    return PaymentsAPI.getToken(PaymentOptions.LEDGERGREEN);
  }

  async getConfiguration() {
    const config = await PaymentsAPI.getIntegrationConfiguration(
      PaymentOptions.LEDGERGREEN
    );
    return {
      publicKey: config.data.attributes.public_key,
    };
  }

  async addAccount(paymentData) {
    const paymentSource = {
      mask: paymentData.payment.cardNumber.slice(-4),
      provider: getCardType(paymentData.payment.cardNumber),
      expiry_date: paymentData.payment.expirationDate,
      cardholder_name: paymentData.payment.holderName,
      payment_source_token: paymentData.cardToken,
    };

    if (paymentData.signature) {
      paymentSource.extra_content = {
        signature: paymentData.signature,
        auth_key: paymentData.agreement.authKey,
      };
    }

    await PaymentsAPI.addPaymentSource(PaymentOptions.LEDGERGREEN, paymentSource);
  }

  async listAccounts() {
    return PaymentsAPI.paymentSources("ledgergreen").then(({data}) =>
      data.map(obj => makePaymentSource(obj, "ledgergreen"))
    );
  }

  async createCardToken(
    publicKey = "dev_pub_18fc9b2c41ff9440383d75a5ff886b71a96b6b75",
    cardInfo = {}
  ) {
    const [month, year] = cardInfo.expirationDate.split("/");

    const response = await this.axiosInstance.post(`v1/token/${publicKey}`, {
      card_number: cardInfo.cardNumber.replace(/ /g, ""),
      card_holder_name: cardInfo.holderName,
      month: month,
      year: `20${year}`,
      cvv: cardInfo.cardCvc,
      postal_code: cardInfo.cardPostalCode,
    });

    return response.data.token;
  }

  async createCustomer(customerInfo) {
    const result = await PaymentsAPI.createCustomer(
      PaymentOptions.LEDGERGREEN,
      customerInfo.cartId,
      this.getCustomerAttributes(customerInfo)
    );
    return {
      agreement: {
        agreementText: result.data.attributes.extra_content.agreement_text,
        authKey: result.data.attributes.extra_content.auth_key,
        iAgree: result.data.attributes.extra_content.i_agree,
      },
    };
  }
  getCustomerAttributes(customerInfo) {
    return {
      gender: customerInfo.gender.label.toLowerCase(),
      date_of_birth: customerInfo.idDateOfBirth,
      identity_document: {
        id_type: customerInfo.documentType.value,
        id_number: customerInfo.idNumber,
        expiration_date: customerInfo.idExpirationDate,
        issue_date: customerInfo.idIssueDate,
      },
      address: {
        country: customerInfo.payment.billingCountry,
        state: customerInfo.payment.billingState.value,
        city: customerInfo.payment.billingCity,
        address: customerInfo.payment.billingAddress,
        zip_code: customerInfo.payment.cardPostalCode,
      },
    };
  }

  async preparePayment(orderInfo, paymentData) {
    const result = {
      ...orderInfo,
      payment_specification: {
        payment_source_id: paymentData?.source?.id,
      },
    };
    this.maybeAttachTip(result, paymentData);
    this.maybeAttachFailPayment(result);
    return result;
  }

  async pay(orderId, paymentData) {
    const {payment_specification} = await this.preparePayment({}, paymentData);
    try {
      await PaymentsAPI.pay(PaymentOptions.LEDGERGREEN, orderId, payment_specification);
    } catch (e) {
      await this.maybeHandle(payment_specification, e);
    }
  }

  async removeAccount(paymentSource) {
    return PaymentsAPI.removePaymentSource(
      PaymentOptions.LEDGERGREEN,
      paymentSource.internalId
    );
  }

  isOnline() {
    return true;
  }
}

export default LedgerGreen;
