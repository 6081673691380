import React from "react";
import PropTypes from "prop-types";
import CarouselCardList from "@ui/components/CarouselCardList";

function CarouselProductsList({
  products,
  maxProductsPerSlide,
  ProductCardComponent,
  CategoryCardComponent,
  categoryCardProps,
  showHeader = false,
  headerProps = {},
  ...props
}) {
  return (
    <CarouselCardList
      {...props}
      entries={products}
      categoryCardProps={categoryCardProps}
      maxEntriesPerSlide={maxProductsPerSlide}
      renderCard={({element, skeleton, isFirst, dim}) => {
        if (element?.isCategoryCard && dim !== "sm") {
          return <CategoryCardComponent {...categoryCardProps} />;
        }
        return (
          <ProductCardComponent
            skeleton={skeleton}
            {...element}
            product={element}
            showHeader={showHeader}
            isFirst={isFirst}
            headerProps={headerProps}
          />
        );
      }}
    />
  );
}

CarouselProductsList.propTypes = {
  styles: CarouselCardList.propTypes.styles,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      brandName: PropTypes.string,
      brandUrl: PropTypes.string,
      productDetailUrl: PropTypes.string,
      sizes: PropTypes.arrayOf(PropTypes.string),
      potencyTags: PropTypes.arrayOf(PropTypes.string),
      onSale: PropTypes.bool,
      flowerType: PropTypes.shape({
        icon: PropTypes.elementType,
        color: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  maxProductsPerSlide: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
  }),
  ProductCardComponent: PropTypes.elementType,
  CategoryCardComponent: PropTypes.elementType,
  categoryCardProps: PropTypes.object,
  showHeader: PropTypes.bool,
  headerProps: PropTypes.object,
};

export default CarouselProductsList;
