import {lazy} from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import suspensify from "@ui/utils/suspensify";
import {
  ShoppingBag,
  ShoppingBagAdd,
  ShoppingBagAddPlus,
  ShoppingBagWithSlot,
  ShoppingBagWithCurve,
  FilledUserIcon,
  RoundedUserIcon,
  BoldSearchIcon,
  CartAddPlus,
} from "@ui/components/IconsSVGs";
import dynamic from "next/dynamic";

const Trash = suspensify(
  lazy(() =>
    import("@styled-icons/boxicons-regular/Trash").then(module => ({
      default: module.Trash,
    }))
  )
);
const Plus = dynamic(() =>
  import("@styled-icons/boxicons-regular/Plus").then(module => ({
    default: module.Plus,
  }))
);
const Minus = suspensify(
  lazy(() =>
    import("@styled-icons/boxicons-regular/Minus").then(module => ({
      default: module.Minus,
    }))
  )
);
const ShoppingCartOutline = suspensify(
  lazy(() =>
    import("@styled-icons/evaicons-outline/ShoppingCartOutline").then(module => ({
      default: module.ShoppingCartOutline,
    }))
  )
);
const ArrowBack = dynamic(() =>
  import("@styled-icons/boxicons-regular/ArrowBack").then(module => ({
    default: module.ArrowBack,
  }))
);
const ArrowUpRight = suspensify(
  lazy(() =>
    import("@styled-icons/bootstrap/ArrowUpRight").then(module => ({
      default: module.ArrowUpRight,
    }))
  )
);
const CheckCircle = suspensify(
  lazy(() =>
    import("@styled-icons/boxicons-regular/CheckCircle").then(module => ({
      default: module.CheckCircle,
    }))
  )
);
const ChevronDown = suspensify(
  lazy(() =>
    import("@styled-icons/bootstrap/ChevronDown").then(module => ({
      default: module.ChevronDown,
    }))
  )
);
const ChevronRight = dynamic(() =>
  import("@styled-icons/bootstrap/ChevronRight").then(module => ({
    default: module.ChevronRight,
  }))
);
const ChevronLeft = suspensify(
  lazy(() =>
    import("@styled-icons/bootstrap/ChevronLeft").then(module => ({
      default: module.ChevronLeft,
    }))
  )
);
const Store = dynamic(() =>
  import("@styled-icons/boxicons-regular/Store").then(module => ({
    default: module.Store,
  }))
);
const Car = dynamic(() =>
  import("@styled-icons/boxicons-regular/Car").then(module => ({
    default: module.Car,
  }))
);
const X = suspensify(
  lazy(() =>
    import("@styled-icons/bootstrap/X").then(module => ({
      default: module.X,
    }))
  )
);
const Cannabis = suspensify(
  lazy(() =>
    import("@styled-icons/fa-solid/Cannabis").then(module => ({
      default: module.Cannabis,
    }))
  )
);
const User = suspensify(
  lazy(() =>
    import("@styled-icons/boxicons-regular/User").then(module => ({
      default: module.User,
    }))
  )
);
const CheckCircleFill = suspensify(
  lazy(() =>
    import("@styled-icons/bootstrap/CheckCircleFill").then(module => ({
      default: module.CheckCircleFill,
    }))
  )
);
const SearchOutline = suspensify(
  lazy(() =>
    import("@styled-icons/evaicons-outline/SearchOutline").then(module => ({
      default: module.SearchOutline,
    }))
  )
);

const size = ({size}) => size || "16px";
const color = ({color}) => color || "#272727";
const padding = ({padding}) => padding || "0px 0px 0px 6px";
const margin = ({margin}) => margin || "0px";
const display = ({display}) => display || "";

export const TrashIcon = styled(Trash).attrs(() => ({
  "data-keep-cart": "true",
}))`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
`;

export const PlusIcon = styled(Plus).attrs(() => ({
  "data-keep-cart": "true",
}))`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
`;

export const MinusIcon = styled(Minus).attrs(() => ({
  "data-keep-cart": "true",
}))`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
`;

export const CartIcon = styled(ShoppingCartOutline)
  .withConfig({
    shouldForwardProp: prop => !["hideIconInLargerViewport"].includes(prop),
  })
  .attrs(() => ({
    "data-keep-cart": "true",
  }))`
    min-width: ${size};
    min-height: ${size};
    width: ${size};
    height: ${size};
    color: ${color};
    display: ${display};
    cursor: pointer;

    ${media.up("md")} {
        display: ${({hideIconInLargerViewport}) =>
          hideIconInLargerViewport ? "none" : "block"};
    }
`;

export const CheckCircleIcon = styled(CheckCircle).attrs(() => ({
  "data-keep-cart": "true",
}))`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
`;

export const BackIcon = styled(ArrowBack)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
`;

export const ArrowUpRightIcon = styled(ArrowUpRight)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
`;

export const ChevronDownIcon = styled(ChevronDown)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  padding: ${padding};
`;

export const ChevronRightIcon = styled(ChevronRight)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  padding: ${padding};
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  padding: ${padding};
`;

export const CloseIcon = styled(X)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  margin: ${margin};
`;

export const PickupIcon = styled(Store)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  margin: ${margin};
`;

export const DeliveryIcon = styled(Car)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  margin: ${margin};
`;

export const CheckmarkIcon = styled(CheckCircleFill)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  margin: ${margin};
`;

export const RecreationalIcon = styled(Cannabis)`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  margin: ${margin};
`;

const UserIcon = styled(User).attrs(() => ({
  className: "login-btn login-btn__icon",
}))`
  min-width: ${size};
  min-height: ${size};
  width: ${size};
  height: ${size};
  color: ${color};
  margin: ${margin};
  cursor: pointer;
`;

export const SearchIcon = styled(SearchOutline).attrs(() => ({
  className: "search-btn search-btn__icon",
}))`
  height: 24px;
  cursor: pointer;
  color: ${color};
`;

export function getCartIconComponent(iconName) {
  switch (iconName) {
    case "empty":
      return "empty";
    case "bag":
      return ShoppingBag;
    case "bag-add":
      return ShoppingBagAdd;
    case "bag-add-plus":
      return ShoppingBagAddPlus;
    case "bag-with-curve":
      return ShoppingBagWithCurve;
    case "bag-with-slot":
      return ShoppingBagWithSlot;
    case "cart-add-plus":
      return CartAddPlus;
    default:
      return CartIcon;
  }
}

export function getUserIconComponent(iconName) {
  switch (iconName) {
    case "filled":
      return FilledUserIcon;
    case "rounded":
      return RoundedUserIcon;
    default:
      return UserIcon;
  }
}

export function getSearchIconComponent(iconName) {
  switch (iconName) {
    case "bold-search-icon":
      return BoldSearchIcon;
    default:
      return SearchIcon;
  }
}
