import get from "lodash/get";
import autoBind from "auto-bind";

class IdentityVerification {
  constructor(identityVerificationObject) {
    autoBind(this);
    this.identityVerificationObject = identityVerificationObject;
  }

  filterByDeliveryType(deliveryType) {
    const data = get(this, "identityVerificationObject.data", []);
    return data.filter(id => id.attributes.delivery_type === deliveryType)[0];
  }

  getIsVerifiedBy(deliveryType) {
    const data = this.filterByDeliveryType(deliveryType);
    return get(data, "attributes.verified_by");
  }

  getIdentityVerificationService(deliveryType) {
    const data = this.filterByDeliveryType(deliveryType);
    return get(data, "attributes.service");
  }

  isVerified(deliveryType) {
    const data = this.filterByDeliveryType(deliveryType);
    return get(data, "attributes.is_verified");
  }

  hasDriversLicense(deliveryType) {
    const data = this.filterByDeliveryType(deliveryType);
    return get(data, "attributes.has_drivers_license");
  }

  hasSelfieId(deliveryType) {
    const data = this.filterByDeliveryType(deliveryType);
    return get(data, "attributes.has_selfie_id");
  }

  getVerificationData(deliveryType, verificationStatus) {
    return {
      isVerifiedBy: this.getIsVerifiedBy(deliveryType?.code),
      idVerificationService: this.getIdentityVerificationService(deliveryType?.code),
      idVerification: this,
      idVerificationStatus: verificationStatus,
    };
  }

  displayVerificationStatusOnUserProfile() {
    return false;
  }

  requiresVerificationOnSignUp() {
    return false;
  }

  async getIdVerificationStatus() {
    return {
      status: undefined,
    };
  }

  renderForm() {
    return null;
  }

  renderMainComponent() {
    return null;
  }
}

export default IdentityVerification;
