export const PrefetchedDataKeys = {
  FILTERS_CATALOG: "filtersCatalog",
  SHOWCASED_GROUPS: "showcasedGroups",
  PROMOTIONAL_BANNERS: "promotionalBanners",
  PRODUCTS: "products",
  PROMOTION_PRODUCTS: "promotionProducts",
  DEAL: "deal",
  DEALS: "deals",
  PRODUCT: "product",
};

export const ALL_PRODUCTS_DELIVERY_TYPE = "valid_for_sale_only";
