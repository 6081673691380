import autoBind from "auto-bind";
import get from "lodash/get";

class ProductTag {
  constructor(tagData) {
    this._tag = tagData;
    autoBind(this);
  }

  getName() {
    return get(this._tag, "attributes.name");
  }

  getSlug() {
    return this.getName();
  }

  getCount() {
    return get(this._tag, "attributes.count");
  }

  getTitle() {
    return get(this._tag, "attributes.title") || this.getName();
  }

  getDescription() {
    return get(this._tag, "attributes.description");
  }

  isFeatured() {
    return get(this._tag, "attributes.is_featured", false);
  }
}

export default ProductTag;
