import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import merge from "lodash/merge";
import makeFilter from "./makeFilter";
import Wrapper from "./Elements/Wrapper";
import InlineHeader from "./Elements/InlineHeader";
import DrawerHeader from "./Elements/DrawerHeader";

export default function FiltersList({
  wrapperRef,
  isInline,
  isOpen,
  header,
  options,
  hasAppliedFilters,
  filtersCounter,
  clearFilters,
  onClose,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Wrapper wrapperRef={wrapperRef} isInline={isInline} isOpen={isOpen}>
      {isInline && header}
      {isInline ? (
        <InlineHeader clearFilters={clearFilters} />
      ) : (
        <DrawerHeader
          hasAppliedFilters={hasAppliedFilters}
          filtersCounter={filtersCounter}
          clearFilters={clearFilters}
          onClose={onClose}
          styles={_styles}
        />
      )}
      <Container isInline={isInline} styles={_styles}>
        {options.map(option => makeFilter(option))}
      </Container>
    </Wrapper>
  );
}

const BaseFiltersList = styled.ul`
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
  > li {
    border-top: 1px solid;
    border-color: ${({theme}) => theme.v2.color.base.grey[300]};
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const drawerStyle = css`
  > li {
    border-color: ${({styles}) => styles.section?.separatorColor};
    border-width: ${({styles}) => styles.section?.separatorWidth};
  }
  > li:first-child {
    border: 0;
  }

  > li:last-child {
    padding-bottom: ${({theme}) => theme.v2.spacing(8)};
  }
`;

const inlineStyle = css`
> li {
    border: 0;
    padding: 0;
  }
  .filter--title {
    font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  }

  label {
    color: ${({theme}) => theme.v2.color.onSurface} !important;
    font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy} !important;
  }

  .checkbox__container {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: ${({theme}) => theme.v2.spacing(1)};

    &.checkbox__container--checked {
      background-color: ${({theme}) => theme.v2.color.primary};
      border: 1px solid ${({theme}) => theme.v2.color.primary};
    }

    svg {
      stroke: white;
    }
`;

const Container = styled(BaseFiltersList).attrs(() => ({
  className: "filters-list__container",
  "data-keep-cart": "true",
}))`
  ${({isInline}) => (isInline ? inlineStyle : drawerStyle)}
`;

const defaultStyles = {};

FiltersList.defaultProps = {
  isInline: false,
  options: [],
  onClose: () => {},
  styles: defaultStyles,
};

FiltersList.propTypes = {
  wrapperRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isInline: PropTypes.bool,
  isOpen: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      renderCondition: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      isSingleOption: PropTypes.bool,
      id: PropTypes.string,
      value: PropTypes.any,
      options: PropTypes.array,
      onChange: PropTypes.func,
      collapseFilters: PropTypes.bool,
      checkedMarkColor: PropTypes.string,
      noOptionsText: PropTypes.string,
      isGridLayout: PropTypes.bool,
      collapsedOptionsToDisplay: PropTypes.number,
      ranges: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.number,
          range: PropTypes.arrayOf(PropTypes.number),
          onChange: PropTypes.func,
          hideIcon: PropTypes.bool,
          unit: PropTypes.string,
          unitPosition: PropTypes.string,
          disabled: PropTypes.bool,
        })
      ),
    })
  ),
  clearFilters: PropTypes.func,
  hasAppliedFilters: PropTypes.bool,
  filtersCounter: PropTypes.number,
  onClose: PropTypes.func,
  styles: PropTypes.shape({}),
};
