const defaultMessagesDictionary = {
  scheduled_pickup: "Scheduled Pickup at",
  asap_pickup: "Pickup at",
  kiosk: "Delivery at Kiosk",
  express_delivery: "Express Delivery to",
  scheduled_delivery: "Scheduled Delivery to",
  delivery: "Delivery at",
  pickup: "Pickup at",
  unavailable: "Service Unavailable",
};

function getDeliveryTypeModeText(
  type,
  mode,
  messagesDictionary = defaultMessagesDictionary
) {
  let typeModeText = "";
  if (type === deliveryTypes.PICKUP && mode === deliveryModes.SCHEDULED) {
    typeModeText = messagesDictionary.scheduled_pickup;
  } else if (type === deliveryTypes.PICKUP && mode === deliveryModes.ASAP) {
    typeModeText = messagesDictionary.asap_pickup;
  } else if (type === deliveryTypes.KIOSK) {
    typeModeText = messagesDictionary.kiosk;
  } else if (type === deliveryTypes.DELIVERY && mode === deliveryModes.EXPRESS) {
    typeModeText = messagesDictionary.express_delivery;
  } else if (type === deliveryTypes.DELIVERY && mode === deliveryModes.SCHEDULED) {
    typeModeText = messagesDictionary.scheduled_delivery;
  } else if (type === deliveryTypes.DELIVERY) {
    typeModeText = messagesDictionary.delivery;
  } else if (type === deliveryTypes.PICKUP) {
    typeModeText = messagesDictionary.pickup;
  } else {
    typeModeText = messagesDictionary.unavailable;
  }

  return typeModeText;
}

const deliveryTypes = {
  PICKUP: "pickup",
  DELIVERY: "delivery",
  KIOSK: "kiosk",
  UNAVAILABLE: "unavailable",
};

const deliveryModes = {
  SCHEDULED: "scheduled",
  EXPRESS: "express",
  ASAP: "asap",
};

export const SERVICE_UNAVAILABLE = "Service Unavailable";
export default getDeliveryTypeModeText;
