import React from "react";
import {Field} from "react-final-form";
import Signature from "@ui/components/Signature/Signature";
import styled from "styled-components";
import {Error} from "@ui/components/FormFields/Error";

function SignatureField({name, validate, errorStyles, ...props}) {
  return (
    <Field
      name={name}
      validate={validate}
      render={({input, meta}) => (
        <FieldContainer>
          <Signature
            {...props}
            onChange={input.onChange}
            errorStyles={errorStyles}
            hasError={meta.touched && meta.error}
          />
          {meta.touched && meta.error && <Error styles={errorStyles}>{meta.error}</Error>}
        </FieldContainer>
      )}
    />
  );
}

const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  > * {
    width: 100%;
  }
`;

export default SignatureField;
