import * as PaymentsAPI from "src/core/api/payments";
import {asyncHandleError} from "src/core/common/utils";
import {makePaymentSource} from "src/core/payments/factories/paymentSource";
import {formatPrice} from "src/core/common/price";
import get from "lodash/get";
import {isClient} from "src/server/utils/isClient";
import OnlinePayment from "src/core/payments/OnlinePayment";
import autoBind from "auto-bind";
import Page from "src/core/common/models/page";
import {populateRelations} from "src/core/api/utils";
import PaymentMethodPromotion from "src/core/common/models/paymentMethodPromotion";
import {PaymentOptions} from "src/core/payments/constants";

class Stronghold extends OnlinePayment {
  constructor(code) {
    if (isClient && !window.Stronghold) {
      const loadScript = (src, callback) => {
        let script = document.createElement("script");
        script.defer = true;
        script.src = src;
        script.onload = callback;
        document.body.appendChild(script);
      };

      loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js",
        () => {
          loadScript("https://api.strongholdpay.com/v2/js");
        }
      );
    }
    super(code);
    autoBind(this);
  }

  async getPromotions() {
    const response = await PaymentsAPI.getPromotions(PaymentOptions.STRONGHOLD, {
      limit: 10,
      offset: 0,
    });

    return new Page({
      meta: response.meta,
      objects: response.data.map(
        element =>
          new PaymentMethodPromotion(populateRelations(element, response.included))
      ),
    });
  }

  async getPromotionSavings(chargeAmount) {
    const response = await PaymentsAPI.getPromotionSavings(PaymentOptions.STRONGHOLD, {
      chargeAmount,
    });
    if (response.data.length) {
      return new PaymentMethodPromotion(
        populateRelations(response.data[0], response.included)
      );
    } else {
      return null;
    }
  }

  async markAsDefaultPaymentMethod(paymentSource) {
    await PaymentsAPI.markAsDefaultPaymentSource(
      PaymentOptions.STRONGHOLD,
      paymentSource.internalId
    );
    this.notify("payment-source-marked-as-default", paymentSource);
  }

  async getConfiguration() {
    const config = await PaymentsAPI.getConfiguration(PaymentOptions.STRONGHOLD);
    return {
      publishableKey: config.data.attributes.public_key,
      environment: config.data.attributes.env || "sandbox",
    };
  }

  async getClient() {
    const config = await this.getConfiguration();
    return window.Stronghold.Pay(config);
  }

  async preparePayment(orderInfo, paymentData) {
    if (!paymentData || !paymentData.source) await this.linkAccount();
    const _paymentSource = this.paymentSource
      ? this.paymentSource
      : get(paymentData, "source");

    const result = {
      ...orderInfo,
      payment_specification: {
        payment_source_id: _paymentSource ? _paymentSource.id : null,
      },
    };

    this.maybeAttachTip(result, paymentData);
    this.maybeAttachFailPayment(result);
    return result;
  }

  async linkAccount() {
    try {
      const customerToken = await this.getToken();
      const paymentSource = await this.addPaymentSource(customerToken);
      const result = await PaymentsAPI.addPaymentSource(PaymentOptions.STRONGHOLD, {
        payment_source_id: paymentSource.id,
      });
      this.paymentSource = makePaymentSource(result.data, "stronghold");
      this.notify("link-account-success", this.paymentSource);
    } catch (e) {
      this.notify("link-account-fail", this.paymentSource);
      throw e;
    }
    return this.paymentSource;
  }

  async removeAccount(paymentSource) {
    await PaymentsAPI.removePaymentSource(
      PaymentOptions.STRONGHOLD,
      paymentSource.internalId
    );
    this.notify("removed-account-success", paymentSource);
  }

  async pay(orderId, paymentData) {
    let _paymentSource =
      paymentData && paymentData.source ? paymentData.source : this.paymentSource;
    if (!_paymentSource) {
      _paymentSource = await this.preparePayment();
    }
    try {
      await this.doPay(orderId, _paymentSource.id);
    } catch (e) {
      await this.maybeHandle(orderId, _paymentSource, e);
    }
  }

  async maybeHandle(paymentData, e) {
    const [handled] = await asyncHandleError(
      "payment_source_login_required",
      e,
      async () => {
        const customerToken = await this.getToken();
        await this.updatePaymentSource(customerToken, paymentData.source);
      }
    );
    if (!handled) {
      throw e;
    }
  }

  getToken = async () => {
    const response = await PaymentsAPI.getToken(PaymentOptions.STRONGHOLD);
    return response.data?.attributes?.token;
  };

  addPaymentSource = async customerToken => {
    let _paymentSource = null;
    let _err = null;
    const client = await this.getClient();
    return new Promise((resolve, reject) => {
      return client.addPaymentSource(customerToken, {
        onSuccess: function (paymentSource) {
          _err = null;
          _paymentSource = paymentSource;
        },
        onExit: () => {
          if (_paymentSource) {
            resolve(_paymentSource);
          } else {
            reject(_err);
          }
        },
        onError: function (err) {
          _paymentSource = null;
          _err = err;
        },
      });
    });
  };

  async updatePaymentSource(customerToken, paymentSource) {
    let _success = false;
    let _err = null;
    const client = await this.getClient();
    return new Promise((resolve, reject) => {
      return client.updatePaymentSource(customerToken, {
        paymentSourceId: paymentSource.id,
        onSuccess: function () {
          _err = null;
          _success = true;
        },
        onExit: () => {
          if (_success) {
            resolve(paymentSource);
          } else {
            reject(_err);
          }
        },
        onError: function (err) {
          _success = false;
          _err = err;
        },
      });
    });
  }

  doPay = (orderId, paymentSourceId) => {
    return PaymentsAPI.pay(PaymentOptions.STRONGHOLD, orderId, {
      payment_source_id: paymentSourceId,
    });
  };

  async getPaymentDisclaimer({shop, cart, order, payment}) {
    const prices = cart ? cart.getPrices() : order ? order.getPrices() : null;
    if (!shop || !prices || !payment || !payment?.source) return "";

    const config = await PaymentsAPI.getConfiguration(PaymentOptions.STRONGHOLD);
    const paymentDisclaimer = get(config, "data.attributes.payment_disclaimer") || "";
    return `By clicking pay above I authorize ${shop.getName()} to debit ${formatPrice(
      prices.totalPrice
    )} from my account ending in ${payment.source.mask}. ${paymentDisclaimer}`;
  }

  async addTip({amount, percent, user, paymentSpecification}) {
    try {
      const tip = await this.doAddTip({amount, user, paymentSpecification});
      await this.saveTip({...tip, percentage: percent});
      this.notify("tip-success", tip);
    } catch (e) {
      console.log(e);
      this.notify("tip-failure", e);
    }
  }

  saveTip = tip => {
    return PaymentsAPI.saveTip(PaymentOptions.STRONGHOLD, tip);
  };

  doAddTip = async ({amount, user, paymentSpecification}) => {
    const client = await this.getClient();
    const customerToken = await this.getToken();
    const pSource = paymentSpecification.getPaymentInfo();
    const pCharge = paymentSpecification.getLastCharge();
    let _tip = null;
    let _err = null;
    return new Promise((resolve, reject) => {
      client.tip(customerToken, {
        authorizeOnly: false, // Set to true to not capture the charge immediately and create it as `authorized`.
        tip: {
          amount: amount, // $3
          currency: "usd",
          paymentSourceId: pSource.id,
          chargeId: pCharge.id,
          beneficiaryName: user.getName(),
        },
        onSuccess: function (tip) {
          _tip = tip;
          _err = null;
        },
        onExit: function () {
          if (_tip) {
            resolve(_tip);
          } else {
            reject(_err);
          }
        },
        onError: function (err) {
          _err = err;
          _tip = null;
        },
      });
    });
  };
}

export default Stronghold;
