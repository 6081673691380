import makeProductSchemaObject from "../objects/product";
import makeBreadCrumbList from "../objects/breadcrumbList";
import {
  getProductCatalogUrl,
  getProductCategoryUrl,
  getProductDetailUrl,
} from "../../meta";
import makeSiteDetailSchema from "src/core/seo/schema-objects/factories/siteDetail";
import makeBreadcrumbsProductDetailSchema from "src/core/seo/schema-objects/factories/breadcrumbsProductDetail";

function makeProductDetailSchema(product, site, ctx) {
  if (!product) return [];

  const items = makeProductDetailBreadCrumbItems(product, ctx);
  return [
    {
      "@context": "https://schema.org/",
      "@graph": [
        makeBreadCrumbList(items, ctx),
        makeProductSchemaObject(product, site, ctx),
      ],
    },
    makeSiteDetailSchema(product, site, ctx),
    makeBreadcrumbsProductDetailSchema(product, ctx),
  ].map(schema => ({
    type: "application/ld+json",
    innerHTML: JSON.stringify(schema),
  }));
}
export function makeProductDetailBreadCrumbItems(product, ctx) {
  if (!product) return [];
  return [
    {
      id: getProductCatalogUrl(ctx),
      name: "Home",
    },
    {
      id: getProductCategoryUrl(product.getMainCategory().getSlug(), ctx),
      name: product.getMainCategory().getName() || "No Category",
    },
    {
      id: getProductDetailUrl(product, ctx),
      name: product.getName(),
    },
  ];
}
export default makeProductDetailSchema;
