import {Authentication} from "src/core/authentication";
import localStorage from "src/core/common/localStorage";
import {LOCAL_STORAGE_USER} from "../constants";
import TymberAuthenticationBackend from "./backend";
import {
  CartValidationHandler,
  CheckUserAddressAndDeliveryType,
  InitializeAnalyticsHandler,
  InitializeApiHandler,
  InventoryTypeSelectionHandler,
  LoginFromAccessTokenHandler,
  MarketingConsentVerification,
  SaveUserHandler,
  UserNotificationsHandler,
} from "./handlers";
import AuthenticationConfig from "src/core/authentication/config";
import TymberUser from "../common/models/tymberUser";
import TymberAPIConfig from "src/core/api/config";
import once from "lodash/once";
import {initStore} from "src/core/authentication/state/store";

let handlers = [];
export function init(env, router, featureToggles) {
  const filteredHandlers = AuthenticationConfig.handlers.filter(
    h => handlers.indexOf(h) === -1
  );
  handlers = makeHandlers(env, router);
  authenticationBackend.setFeatureToggles(featureToggles);
  AuthenticationConfig.setConfig({
    backend: authenticationBackend,
    handlers: [...filteredHandlers, ...handlers],
    userClass: TymberUser,
  });

  initStore().then(callback => {
    initUser(env, router);
    callback();
  });
}

const initUser = once((env, router) => {
  if (hasDefaultUser(env)) {
    loadDefaultUser(env);
  } else if (router.query.access_token) {
    loginUserFromAccessToken(router.query.access_token);
  } else {
    maybeLoadStoredUser();
  }
});

function loadDefaultUser(env) {
  Authentication.login({
    email: env.getDefaultUserEmail(),
    password: env.getDefaultUserPassword(),
  });
}
function loginUserFromAccessToken(accessToken) {
  Authentication.login({
    accessToken,
  });
}
function maybeLoadStoredUser() {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
  if (user) {
    TymberAPIConfig.setAuthorizationToken(user.jwt);
    Authentication.loadUser(user);
  }
}

const authenticationBackend = new TymberAuthenticationBackend();
function makeHandlers(env, router) {
  return [
    new SaveUserHandler(LOCAL_STORAGE_USER),
    new InventoryTypeSelectionHandler(),
    new CartValidationHandler(),
    new InitializeApiHandler(),
    new InitializeAnalyticsHandler(),
    new CheckUserAddressAndDeliveryType(env.getEcommerceId(), router),
    new MarketingConsentVerification(router),
    new UserNotificationsHandler(),
    new LoginFromAccessTokenHandler(router),
  ];
}

function hasDefaultUser(env) {
  return env.getDefaultUserEmail() && env.getDefaultUserPassword();
}
