import {useCallback, useContext, useEffect, useState} from "react";
import routes from "src/core/common/routes";
import {BreadcrumbsContext} from "src/core/common/components/BreadcrumbsContext";

const HOME = [{text: "Home", pathname: "/"}];

export default function useBreadcrumbs() {
  const [linkList, setLinkList] = useState(HOME);
  const {product, category, brand, search, setCategory, setProduct, setBrand, setSearch} =
    useContext(BreadcrumbsContext);

  const replacePlaceholder = (pathname, placeholder, value) => {
    const lowerCaseValue = value.toLowerCase();
    return pathname.replace(placeholder, lowerCaseValue);
  };

  const prepareProductPathname = useCallback(() => {
    const productName = product.getName().toLowerCase().replace(/ /g, "-");
    const productNameId = productName + "-" + product.getId();
    const tempPathname = replacePlaceholder(
      routes.noBrandProductDetail,
      ":category",
      category[0]
    );
    return replacePlaceholder(tempPathname, ":productId", productNameId);
  }, [category, product]);

  useEffect(() => {
    let newLinkList = HOME;
    if (brand) {
      let brandLink = {
        text: brand.getName(),
        pathname: replacePlaceholder(routes.brandDetail, ":brand", brand.getName()),
      };
      newLinkList = [...newLinkList, brandLink];
    } else if (search) {
      const searchLink = {
        text: `SEARCH RESULTS FOR: '${search}'`,
        pathname: routes.search,
      };
      newLinkList = [...newLinkList, searchLink];
    } else {
      let shopByCategoryLink = {
        text: "Shop by category",
        pathname: routes.home,
      };
      newLinkList = [...newLinkList, shopByCategoryLink];
      if (category) {
        let categoryLink = {
          text: category[0],
          pathname: replacePlaceholder(routes.productCategory, ":category", category[0]),
        };
        newLinkList = [...newLinkList, categoryLink];
        if (product) {
          let productLink = {
            text: product.getName(),
            pathname: prepareProductPathname(),
          };
          newLinkList = [...newLinkList, productLink];
        }
      }
    }
    setLinkList(newLinkList);
  }, [product, category, brand, search, prepareProductPathname]);

  return [linkList, setCategory, setProduct, setBrand, setSearch];
}
