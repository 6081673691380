import React from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import CheckoutOptionPicker from "@ui/components/CheckoutOptionPicker";

export default function CheckoutPaymentMethod({paymentMethods, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  return <CheckoutOptionPicker data={paymentMethods} styles={_styles} />;
}

const defaultStyles = {};

CheckoutPaymentMethod.defaultProps = {
  paymentMethods: [],
  styles: defaultStyles,
};

CheckoutPaymentMethod.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.bool,
      LabelComponent: PropTypes.elementType,
      label: PropTypes.string,
      value: PropTypes.string,
      onClick: PropTypes.func,
      Icon: PropTypes.elementType,
      action: PropTypes.shape({
        label: PropTypes.string,
      }),
      ExpandedComponent: PropTypes.elementType,
    })
  ),
  styles: PropTypes.shape({}),
};
