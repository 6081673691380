import {call, put, select, takeLatest} from "redux-saga/effects";
import {
  ActionTypes,
  requestDealFailure,
  requestDealsFailure,
  requestDealsSuccess,
  requestDealSuccess,
  requestOnSaleProductsFailure,
  requestOnSaleProductsSuccess,
  requestPromotionProductsSuccess,
  requestPromotionProductsFailure,
} from "@deals/state/actions";
import {fetchDeal, fetchDeals} from "@deals/services/deals";
import {
  getOnSaleProductsOffset,
  getPromotionProductsOffset,
  getPromotionsOffset,
  getRewardsOffset,
} from "@deals/state/selectors";
import {fetchOnSaleProducts, fetchPromotionProducts} from "@deals/services/products";

function* handleRequestOnSaleProducts() {
  try {
    const offset = yield select(getOnSaleProductsOffset);
    const productsPage = yield call(fetchOnSaleProducts, offset);
    yield put(requestOnSaleProductsSuccess(productsPage));
  } catch (error) {
    yield put(requestOnSaleProductsFailure(error));
  }
}

export function* watchOnSaleProducts() {
  yield takeLatest(ActionTypes.REQUEST_ON_SALE_PRODUCTS, handleRequestOnSaleProducts);
}

function* handleRequestPromotionProducts(action) {
  try {
    const offset = yield select(getPromotionProductsOffset);
    const productsPage = yield call(
      fetchPromotionProducts,
      action.payload.promotionId,
      offset
    );
    yield put(requestPromotionProductsSuccess(productsPage));
  } catch (error) {
    yield put(requestPromotionProductsFailure(error));
  }
}

export function* watchPromotionProducts() {
  yield takeLatest(
    ActionTypes.REQUEST_PROMOTION_PRODUCTS,
    handleRequestPromotionProducts
  );
}

function* handleRequestDeals() {
  try {
    const rewardsOffset = yield select(getRewardsOffset);
    const promotionsOffset = yield select(getPromotionsOffset);
    const {rewards, promotions, hasMore} = yield call(
      fetchDeals,
      rewardsOffset,
      promotionsOffset
    );
    const deals = rewards.concat(promotions);
    yield put(requestDealsSuccess(deals, hasMore));
  } catch (error) {
    yield put(requestDealsFailure(error));
  }
}

export function* watchRequestDeals() {
  yield takeLatest(ActionTypes.REQUEST_DEALS, handleRequestDeals);
}

function* handleRequestDeal(action) {
  try {
    const deal = yield call(fetchDeal, action.payload.dealType, action.payload.dealId);
    yield put(requestDealSuccess(deal));
  } catch (error) {
    yield put(requestDealFailure(error));
  }
}

export function* watchRequestDeal() {
  yield takeLatest(ActionTypes.REQUEST_DEAL, handleRequestDeal);
}
