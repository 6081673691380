import {DealTypes} from "@deals/constants";
import {Placeholders} from "@ui/components/DealCard/constants";

export function mapReward(reward) {
  return {
    title: reward.name || "",
    type: DealTypes.REWARD,
    image: reward.image || Placeholders.rewards,
    slug: reward.slug || "",
    id: reward.id,
    description: reward.description || "",
  };
}

export function mapPromotion(promo) {
  return {
    title: promo.title || "",
    type: promo.type || "",
    image:
      promo.image ||
      (promo.promoCodes?.length > 0 ? Placeholders.coupons : Placeholders.promotions),
    slug: promo.slug,
    id: promo.id,
    description: promo.description || "",
    disclaimer: promo.disclaimer || "",
    promoCodes: promo.promoCodes || [],
  };
}
