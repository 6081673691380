import routes from "../../../routes";

export const items = site => [
  {
    label: "Home",
    icon: "home",
    linkTo: site?.getHomeLinkUrl() || "/",
    external: true,
  },
  {
    label: "Shop",
    icon: "shopping",
    linkTo: routes.products,
  },
  {
    label: "Order History",
    icon: "history",
    linkTo: routes.userOrders,
    requiresAuthentication: true,
  },
  {
    label: "Check your delivery address",
    icon: "mapPin",
    linkTo: routes.deliveryCheck,
    requiresDelivery: true,
  },
];

export class SideMenuItem {
  constructor(obj) {
    this.obj = obj;
  }

  get label() {
    return this.obj.label;
  }
  get linkTo() {
    return this.obj.linkTo;
  }
  get external() {
    return this.obj.external;
  }
  get requiresAuthentication() {
    return this.obj.requiresAuthentication;
  }
  get requiresDelivery() {
    return this.obj.requiresDelivery;
  }
}
