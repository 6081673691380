import {ActionTypes} from "@deals/state/actions";

const initialState = {
  deals: [],
  hasMore: true,
  loading: true,
  error: null,
  noResults: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_DEALS:
      const reset = action.payload.options?.reset || false;
      return {
        ...state,
        deals: reset ? [] : state.deals,
        loading: true,
        error: null,
        noResults: false,
      };
    case ActionTypes.REQUEST_DEALS_SUCCESS:
      return {
        ...state,
        deals: state.deals.concat(action.payload.deals),
        hasMore: action.payload.hasMore,
        loading: false,
        noResults: state.deals.length === 0 && action.payload.deals.length === 0,
      };
    case ActionTypes.REQUEST_DEALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
