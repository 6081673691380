import React from "react";
import {Text} from "@ui";
import merge from "lodash/merge";
import useTheme from "@mock/hooks/useTheme";
import styles from "@themes/boost/components/Title/styles";
import useTitle from "@mock/hooks/useTitle";

export default function Title({children, ...props}) {
  const theme = useTheme();
  const {site} = useTitle();

  return (
    <Text
      {...props}
      text={children}
      styles={merge({}, styles(theme, site), props.styles)}
    />
  );
}
