import React from "react";
import CategoryBar from "@menu/components/CategoryBar";
import useRouter from "src/core/common/hooks/useRouter";
import {useBrandResource} from "src/state/hooks/useAsyncResource";
import BrandProductsBrowser from "src/themes/medleaf/components/BrandProductsBrowser";
import Header from "src/core/common/components/collections/menu/simple";
import OptionsList from "src/core/common/components/collections/menu/menu-elements/OptionsList";
import styled from "styled-components";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import DeliveryBanner from "src/core/deliveries/components/DeliveryBanner";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

const AdBanner = dynamic(() =>
  import("@themes/default/components/AdBanner").catch(logModuleLoadError("adBanner"))
);

export default function MedLeafBrandPage({themeProperties = {}}) {
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const {data: brand} = useBrandResource({brandSlug});
  const {showAdBanner} = useAdBanner();
  const toggles = useFeatureToggles();

  return (
    <div>
      {brand && <Header isSearchEnabled={true} options={<OptionsList />} />}
      {showAdBanner && <AdBanner />}
      <Content>
        <CategoryBar />
        {!toggles.kioskMode() && <DeliveryBanner />}
        <BrandProductsBrowser showFilters showSort themeProperties={themeProperties} />
      </Content>
    </div>
  );
}

const Content = styled.div`
  margin: 0 auto;
  max-width: ${({theme}) => theme.v1.content.maxWidth};
`;
