import React from "react";
import NativeDatePicker from "./NativeDatePicker";
import FallbackDatePicker from "./FallbackDatePicker";

function DatePicker(props) {
  if (isInputDateSupported()) {
    return <NativeDatePicker {...props} />;
  } else {
    return <FallbackDatePicker {...props} />;
  }
}

function isInputDateSupported() {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes("wv")) return false;

  const input = document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  return input.value !== value;
}

export default DatePicker;
