import * as ProductsApi from "src/core/api/products";
import Page from "src/core/common/models/page";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";

export async function fetchOnSaleProducts(offset) {
  return await ProductsApi.list({
    offset,
    on_sale: true,
  }).then(response => {
    return new Page({
      meta: response.meta,
      objects: response.data.map(
        element => new Product(populateRelations(element, response.included))
      ),
    });
  });
}

export async function fetchPromotionProducts(promotionId, offset) {
  return await ProductsApi.list({
    offset,
    promotion: promotionId,
  }).then(response => {
    return new Page({
      meta: response.meta,
      objects: response.data.map(
        element => new Product(populateRelations(element, response.included))
      ),
    });
  });
}
