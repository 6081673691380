import React from "react";
import DetailProductsList from "@themes/default/components/DetailProductsList/index.js";
import useDetailProductsList from "@mock/hooks/useDetailProductsList";
import ShowcaseSearchResults from "@themes/default/components/SearchBrowser/ShowcaseSearchResults";

export default function SearchList({selected}) {
  const listProps = useDetailProductsList();

  switch (selected) {
    case "categories":
    case "brands":
      return <ShowcaseSearchResults type={selected} />;
    case "products":
    default:
      return <DetailProductsList {...listProps} Header={DummyHeader} />;
  }
}

const DummyHeader = () => null;
