import dynamic from "next/dynamic";
import IntegrationComponentRegistry from "src/integrations/IntegrationComponentRegistry";

const MenuAddon = dynamic(() => import("./addons/Menu"));
const BasePageAddon = dynamic(() => import("./addons/BasePage"));
const InStoreExperienceProductDetailActions = dynamic(() =>
  import("./components/ProductDetailActions")
);
const InStoreExperienceProductDetailHeaderActions = dynamic(() =>
  import("./components/ProductDetailHeaderActions")
);

export default function initInStoreExperience(siteOptions) {
  if (!siteOptions.inStoreExperienceEnabled()) return;

  IntegrationComponentRegistry.registerComponentOverride("product-detail-actions", {
    Component: InStoreExperienceProductDetailActions,
    enabled: true,
  });
  IntegrationComponentRegistry.registerComponentOverride(
    "product-detail-header-actions",
    {
      Component: InStoreExperienceProductDetailHeaderActions,
      enabled: true,
    }
  );
  IntegrationComponentRegistry.registerMenuAddon({
    component: MenuAddon,
    props: {
      key: "in-store-experience-menu-item",
    },
  });
  IntegrationComponentRegistry.registerBasePageAddon({
    component: BasePageAddon,
    props: {
      key: "exclusive-mobile-content",
    },
  });
}
