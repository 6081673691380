import {Deliveries} from "../api";
import {useCallback, useEffect, useState} from "react";
import {DeliveryModes} from "../../common/models/deliveryMode";
import {useRefresh} from "../../common/hooks/useRefresh";
import store from "../../deliveries/state/store";

function useDeliveryMode() {
  const refresh = useRefresh();
  const [state, setState] = useState(store.getState());
  const {deliveryMode} = state;

  const setDeliveryMode = useCallback(
    payload => {
      const mode = Deliveries.getDeliveryMode();
      const type = Deliveries.getDeliveryType();
      Deliveries.setDeliveryMode({
        mode: DeliveryModes.SCHEDULED,
        deliveryType: type,
        ...payload,
      });
      if (
        (mode === DeliveryModes.EXPRESS && payload.mode !== DeliveryModes.EXPRESS) ||
        (payload.mode === DeliveryModes.EXPRESS && mode !== DeliveryModes.EXPRESS)
      ) {
        setTimeout(refresh, 0);
      }
    },
    [refresh]
  );

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return [deliveryMode, setDeliveryMode];
}

export default useDeliveryMode;
