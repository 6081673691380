import {combineReducers} from "redux";
import searchPreviewReducer from "./reducers/searchPreview";
import searchReducer from "./reducers/search";
import productGroupsReducer from "./reducers/productGroups";
import productsListReducer from "./reducers/productsList";
import allProductsReducer from "./reducers/allProducts";

export const reducer = combineReducers({
  searchPreview: searchPreviewReducer,
  search: searchReducer,
  productGroups: productGroupsReducer,
  productsList: productsListReducer,
  allProducts: allProductsReducer,
});
