import get from "lodash/get";
import {findRelation} from "../../api/utils";
import dayjs from "dayjs";
import {PaymentCharge} from "src/core/payments/models/paymentCharge";
import {PaymentOptions} from "src/core/payments/constants";
import SpenceTransactionDetails from "src/core/payments/spence/spenceTransactionDetails";

class PaymentSpecification {
  constructor(obj) {
    this._obj = obj;
  }

  isProcessing() {
    if (!this.isOnline()) return undefined;

    const processingStatus = ["authorized"];
    const status = this.getLastCharge()?.status;

    return (
      processingStatus.indexOf(status) > -1 &&
      get(this._obj, "data.attributes.deferred_capture")
    );
  }

  isPendingRefund() {
    if (!this.isOnline()) return undefined;

    const pendingRefundStatus = ["refund_pending"];
    const status = this.getStatus();
    return pendingRefundStatus.indexOf(status) > -1;
  }

  isRefunded() {
    if (!this.isOnline()) return undefined;

    const refundedStatus = ["refunded"];
    const status = this.getStatus();
    return refundedStatus.indexOf(status) > -1;
  }

  isPaid() {
    if (!this.isOnline()) return undefined;

    const paidStatus = ["completed"];
    const status = this.getStatus();
    return paidStatus.indexOf(status) > -1;
  }

  isCanceled() {
    if (!this.isOnline()) return undefined;

    const paidStatus = ["canceled"];
    const status = this.getStatus();
    return paidStatus.indexOf(status) > -1;
  }

  getPaymentOption() {
    return get(this._obj, "data.attributes.payment_option");
  }

  isOnline() {
    return get(this._obj, "data.attributes.online_payment");
  }

  getPaymentDate() {
    const lastCharge = this.getLastCharge();
    if (lastCharge) {
      return dayjs(lastCharge.createdAt);
    }
    return null;
  }

  getPaymentInfo() {
    switch (this.getPaymentOption()) {
      case PaymentOptions.SPENCE:
        return this.getSpencePaymentInfo();
      default:
        return this.getPaymentSource();
    }
  }

  getPaymentSource() {
    const lastCharge = this.getLastCharge();
    return lastCharge?.getPaymentSource(this.getPaymentOption());
  }

  getLastCharge() {
    const lastChargeRelation = get(this._obj, "data.relationships.last_charge.data");
    if (lastChargeRelation) {
      const lastCharge = findRelation(this._obj.included, lastChargeRelation);
      return new PaymentCharge({...lastCharge, included: this._obj.included});
    }
    return null;
  }

  getSpencePaymentInfo() {
    const lastCharge = this.getLastCharge();
    const mappedInfo = {
      paymentApproved: "true",
      paymentDetails: {
        transactionStatus: get(this._obj, "data.attributes.status"),
        details: {
          amount: get(this._obj, "data.attributes.amount.value"),

          createdAt: lastCharge.insertedAt,
          merchant: {
            name: "Spencer", // TODO update merchant name
          },
          payer: {
            firstName: get(lastCharge.customer, "attributes.first_name"),
            lastName: get(lastCharge.customer, "attributes.last_name"),
            phoneNumber: get(lastCharge.customer, "attributes.phone_number"),
            email: get(lastCharge.customer, "attributes.email"),
          },
        },
      },
    };
    return new SpenceTransactionDetails(mappedInfo);
  }

  hasTip() {
    return get(this._obj, "data.attributes.has_tip");
  }

  getStatus() {
    return get(this._obj, "data.attributes.status");
  }
}

export default PaymentSpecification;
