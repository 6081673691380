import get from "lodash/get";
import merge from "lodash/merge";
import {v2} from "src/themes/utils/base";
import {makeSizeTriad, populateSize} from "src/themes/utils/sizing";
import {populateReference} from "src/themes/utils/reference";

export default function makeTheme({spacing, ...themeObj}, base = null) {
  const _themeObj = merge({}, v2, base || {}, themeObj || {});
  populate(_themeObj);

  _themeObj.spacing = n => {
    if (isNaN(n)) {
      return get(spacing, `size.${n}`, null);
    } else {
      return v2.spacing(n);
    }
  };
  return _themeObj;
}

export function populate(obj, basePath = []) {
  const _basePath = basePath.join(".");
  const _obj = _basePath ? get(obj, _basePath, {}) : obj;

  for (let key in _obj) {
    if (_obj.hasOwnProperty(key)) {
      const currentPropPath = _basePath ? `${_basePath}.${key}` : key;
      populateReference(obj, currentPropPath);
      populateSize(obj, currentPropPath);

      if (typeof _obj[key] === "object") {
        populate(obj, [...basePath, key]);
      }
    }
  }
  return obj;
}

export function fromV1(v1) {
  return populate(
    makeTheme({
      overrides: {
        productCard: {
          color: {
            onPrimary: v1.colors.textInPrimary,
          },
          typography: {
            body: {
              weight: {
                medium: v1.font.weight.semiBold,
              },
            },
          },
        },
        primaryButton: {
          color: {
            onPrimary: v1.colors.textInPrimaryDark,
          },
        },
        productDetail: {
          color: {
            onPrimary: v1.colors.textInPrimary,
          },
          typography: {
            sizing: {
              l: makeSizeTriad(v1.font.sizes.xxLarge),
              xxl: makeSizeTriad(v1.font.sizes.xxxLarge),
              "3xl": makeSizeTriad(v1.font.sizes.larger),
            },
          },
          navigationActions: {
            color: {
              onPrimary: v1.colors.textInPrimaryDark,
            },
          },
        },
        deliveryBanner: {
          label: {
            color: {
              default: v1.colors.primary,
            },
          },
          input: {
            color: {
              surface: v1.colors.gray,
              typography: {
                default: v1.colors.primaryText,
              },
            },
          },
          deliveryButton: {
            active: {
              color: {
                surface: v1.colors.gray1,
                typography: {
                  default: v1.colors.primaryText,
                },
              },
              decoration: {
                border: {
                  radius: {
                    default: v1.border.radius,
                  },
                },
              },
            },
          },
        },
      },
      typography: {
        title2: {
          lineHeight: get(v1, "font.lineHeight.title", undefined),
        },

        bodyText1: {
          lineHeight: get(v1, "font.lineHeight.normal", undefined),
        },
        bodyText2: {
          lineHeight: get(v1, "font.lineHeight.normal", undefined),
        },

        caption1: {
          lineHeight: get(v1, "font.lineHeight.normal", undefined),
        },
        caption2: {
          lineHeight: get(v1, "font.lineHeight.normal", undefined),
        },

        titles: {
          family: v1.font.headers,
          weight: {
            default: v1.font.weight.normal,
            light: v1.font.weight.light,
            medium: v1.font.weight.medium,
            heavy: v1.font.weight.bold,
          },
          letterSpacing: get(v1, "font.letterSpacing.title", undefined),
        },
        body: {
          family: v1.font.family,
          weight: {
            default: v1.font.weight.normal,
            light: v1.font.weight.light,
            medium: v1.font.weight.medium,
            heavy: v1.font.weight.bold,
          },
          letterSpacing: get(v1, "font.letterSpacing.normal", undefined),
        },
        sizing: {
          xxs: makeSizeTriad(v1.font.sizes.xxSmall),
          xs: makeSizeTriad(v1.font.sizes.xSmall),
          s: makeSizeTriad(v1.font.sizes.medium),
          m: makeSizeTriad(v1.font.sizes.medium),
          l: makeSizeTriad(v1.font.sizes.large),
          xl: makeSizeTriad(v1.font.sizes.xLarge),
          xxl: makeSizeTriad(v1.font.sizes.xxLarge),
          "3xl": makeSizeTriad(v1.font.sizes.xxxLarge),
          "4xl": makeSizeTriad(v1.font.sizes.larger), // deprecated
        },
      },
      color: {
        primary: v1.colors.primary,
        primaryVariant: v1.colors.primaryLight,
        onPrimary: v1.colors.textInPrimaryDark,

        secondary: v1.colors.secondary,
        secondaryVariant: v1.colors.secondaryLight,
        onSecondary: v1.colors.onSecondary,

        onSurface: v1.colors.primaryText,

        base: {
          primary: {
            200: v1.colors.primaryLighter,
            300: v1.colors.primaryLight,
            400: v1.colors.primary,
            500: v1.colors.primaryDark,
          },
        },
      },
      decoration: {
        border: {
          radius: {
            default: v1.border.radius,
          },
        },
      },
    })
  );
}
