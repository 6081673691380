import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import * as ProductsApi from "src/core/api/products";
import Page from "src/core/common/models/page";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import ProductCategory from "src/core/common/models/productCategory";
import ProductBrand from "src/core/common/models/productBrand";
import get from "lodash/get";

export async function searchPreview(term, options = {}) {
  return Promise.all([
    searchProducts(term, options),
    searchCategories(term, options),
    searchBrands(term, options),
  ]).then(response => ({
    products: response[0],
    categories: response[1],
    brands: response[2],
  }));
}

export async function search(term, options = {}) {
  switch (options.type) {
    case "categories":
      return searchCategories(term, options);
    case "products":
      return searchProducts(term, options);
    case "brands":
      return searchBrands(term, options);
    default:
      return searchPreview(term, options);
  }
}

const makeSearchFunction = (type, limit, apiCall, Clazz) => async (term, options) => {
  const params = {
    limit: options?.limit || limit,
    offset: get(options, `offsets.${type}`, 0),
    q: term || undefined,
    ...getFilters(options),
  };

  const response = await apiCall(params);
  return new Page({
    meta: response.meta,
    objects: response.data.map(
      element => new Clazz(populateRelations(element, response.included))
    ),
  });
};
const searchProducts = makeSearchFunction("products", 20, ProductsApi.list, Product);
const searchCategories = makeSearchFunction(
  "categories",
  3,
  ProductsApi.categories,
  ProductCategory
);
const searchBrands = makeSearchFunction("brands", 3, ProductsApi.brandsV2, ProductBrand);

const getFilters = options => {
  const filters = options.filters?.serialize
    ? options.filters.serialize()
    : options.filters || {};
  return pickBy(filters, identity);
};
