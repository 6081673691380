import React from "react";
import Sort from "@ui/components/SortFilters/Sort";
import {useTheme} from "styled-components";
import styles from "src/themes/medleaf/elements/filters/SortFilters/styles";
import {logModuleLoadError} from "src/core/common/utils";
import dynamic from "next/dynamic";
import "react-dropdown/style.css";
import useSite from "src/core/sites/hooks/useSite";
import useSortAndFilterHeader from "src/core/common/hooks/useSortAndFilterHeader";

const Dropdown = dynamic(() =>
  import("react-dropdown").catch(logModuleLoadError("react-dropdown"))
);

export default function MedleafSortFilters() {
  const theme = useTheme();
  const site = useSite();
  const {onChangeOrder, currentOrder, orderOptions} = useSortAndFilterHeader();

  return (
    <Sort
      styles={styles(theme, site, {
        withBorder: site.getUiConfiguration().sortFilters?.withBorder,
      })}
      orderOptions={orderOptions}
      currentOrder={currentOrder}
      onChangeOrder={onChangeOrder}
      DropdownComponent={Dropdown}
    />
  );
}
