import React from "react";
import {useTheme} from "styled-components";
import {PromotionalBanner} from "@ui";
import useSite from "src/core/sites/hooks/useSite";
import usePromotionalBanners, {
  parseDimensions,
  parseImages,
} from "src/core/common/hooks/usePromotionalBanners";
import styles from "src/themes/medleaf/components/PromotionalBanner/styles";

export default function MedLeafPromotionalBanner() {
  const {promotionalBanners} = usePromotionalBanners();
  const bannerInfo = promotionalBanners.getBannerInfo();
  const theme = useTheme();
  const site = useSite();
  const siteUrl = site.getSiteUrl();

  return (
    <PromotionalBanner
      siteUrl={siteUrl}
      styles={styles(theme, site)}
      banners={parseImages(bannerInfo.images)}
      dimensions={parseDimensions(bannerInfo.dimensions)}
    />
  );
}
