import {useMemo} from "react";
import {useProductDetailResource} from "src/state/hooks/useAsyncResource";
import {ProductFilters} from "src/core/common/hooks/useAppliedFilters";
import useBrandPath from "src/menu/hooks/useBrandPath";
import {useRecommendedProductsListResource} from "src/state/hooks/useAsyncResource";
import useRouter from "src/core/common/hooks/useRouter";
import useSite from "src/core/sites/hooks/useSite";

export default function useRecommendedProductsList(limit, offset = 0) {
  const site = useSite();
  const router = useRouter();
  const {productId: pId, noBrandProductId} = router.query;
  const productId = pId || noBrandProductId;

  const {data: product} = useProductDetailResource({productId});

  const brandRecommendationFilters = useMemo(
    () =>
      new ProductFilters({
        brand: product?.getBrandName(),
        excludes: product?.getSlug(),
      }),
    [product]
  );
  const [getBrandPath] = useBrandPath();

  const {data: products, meta} = useRecommendedProductsListResource({
    limit,
    offset,
    filters: brandRecommendationFilters,
  });
  const _products = products?.getElements() ?? [];

  return {
    site,
    product,
    products: _products,
    meta,
    getBrandPath,
  };
}
