import {useEffect} from "react";
import {getKiosk} from "src/apps/kiosk/utils";

export function useKioskValidation(env, kioskId) {
  useEffect(() => {
    if (kioskId) {
      getKiosk({
        basePath: env.getTymberEcommerceHost(),
        kioskId: kioskId,
        storeId: env.getEcommerceId(),
      }).catch(() => {
        window.location.href = "/?retry=true";
      });
    }
  }, [env, kioskId]);
}
