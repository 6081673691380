import {PaymentOptions} from "src/core/payments/constants";
import LedgerGreen from "src/core/payments/ledgerGreen/LedgerGreen";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const LedgerGreenPaymentForm = suspensify(
  lazy(() => import("src/core/payments/ledgerGreen/LedgerGreenPaymentForm"))
);

export default {
  type: PaymentOptions.LEDGERGREEN,
  PaymentClass: LedgerGreen,
  PaymentForm: LedgerGreenPaymentForm,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
