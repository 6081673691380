import * as PaymentsAPI from "src/core/api/payments";
import autoBind from "auto-bind";
import {isClient} from "src/server/utils/isClient";
import OnlinePayment from "src/core/payments/OnlinePayment";
import {formatPrice} from "src/core/common/price";
import {PaymentOptions} from "src/core/payments/constants";

class Swifter extends OnlinePayment {
  static scriptPromise = null;
  static loadScript(isProduction) {
    if (Swifter.scriptPromise) return Swifter.scriptPromise;

    Swifter.scriptPromise = new Promise((resolve, reject) => {
      if (!window.SwifterPay) {
        let script = document.createElement("script");
        script.defer = true;
        script.src = isProduction
          ? `https://js.payswifter.com/v1.js`
          : `https://js.preprod.payswifter.com/v1.js`;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      }
    });

    return Swifter.scriptPromise;
  }
  constructor(code) {
    super(code);

    if (isClient) {
      Swifter.loadScript(this.isProduction());
    }

    autoBind(this);
  }

  async pay(orderId) {
    try {
      await PaymentsAPI.pay(PaymentOptions.SWIFTER, orderId, {});
    } catch (e) {
      await this.maybeHandle({}, e);
    }
  }

  async getConfiguration() {
    return PaymentsAPI.getConfiguration(PaymentOptions.SWIFTER);
  }

  async preparePayment(orderInfo, paymentData, cartId) {
    await Swifter.loadScript(this.isProduction());

    const customer = await PaymentsAPI.createCustomer(PaymentOptions.SWIFTER, cartId);
    const sessionId = customer?.data?.attributes?.extra_content?.sessionId;

    await this.getConfiguration();

    return new Promise((resolve, reject) => {
      const sp = new window.SwifterPay();

      sp.startSession({
        sessionID: sessionId,
        selector: "body",
        renderInModal: true,
        onComplete: response => {
          const result = {
            ...orderInfo,
            payment_specification: {
              payment_session_id: sessionId,
            },
          };
          this.maybeAttachFailPayment(result);

          resolve(result);
        },
        onExit: function (response) {
          const {code} = response;
          if (code === "requested_pay_later") {
            reject();
          } else if (code === "init_error") {
            reject(response);
          } else if (!code) {
            reject();
          }
        },
      });
    });
  }

  async getPaymentDisclaimer({shop, cart, order}) {
    const prices = cart ? cart.getPrices() : order ? order.getPrices() : null;
    if (!shop || !prices) return "";
    return `By clicking pay I authorize ${shop.getName()} to debit ${formatPrice(
      prices.totalPrice
    )} from my account.`;
  }
}

export default Swifter;
