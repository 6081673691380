import React, {Suspense} from "react";

export default function suspensify(Component) {
  return props => {
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );
  };
}
