import {PaymentOptions} from "src/core/payments/constants";
import Spence from "src/core/payments/spence/Spence";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const SpencePaymentForm = suspensify(
  lazy(() => import("src/core/payments/spence/SpencePaymentForm"))
);

export default {
  type: PaymentOptions.SPENCE,
  PaymentClass: Spence,
  PaymentForm: SpencePaymentForm,
};
