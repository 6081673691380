import usePages from "src/core/shops/hooks/usePages";

export default function useDealsPageInfo() {
  const {headerPages} = usePages();

  const dealsPage = headerPages
    .map(page => ({
      url: {pathname: page.linkTo},
      label: page.label,
      external: page.external,
      description: page.description,
      slug: page.slug,
    }))
    .find(page => page.slug === "deals");

  return {
    dealsListTitle: dealsPage?.label || "Current Deals",
    dealsListDescription: dealsPage?.description || "Check out all of our current deals",
  };
}
