import styled from "styled-components";
import media from "@ui/utils/media";

export const Error = styled.div`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  letter-spacing: ${({styles}) => styles.letterSpacing};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;
