import SocialNetworkLogo from "./FooterImages/SocialNetworkLogo";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export function Socials({
  useCustomIcons,
  socialLinks,
  useCustomBackground,
  ExternalLinkComponent,
  styles,
}) {
  const getItemBackground = item =>
    useCustomBackground ? item.backgroundColor : "white";

  return (
    <SocialLinksContainer styles={styles.root}>
      {socialLinks
        .filter(item => item.linkTo && item.isActive)
        .map(item => (
          <SocialLinkElementContainer
            styles={styles.element}
            key={item.name}
            color={getItemBackground(item)}
          >
            <ExternalLinkComponent
              to={{pathname: item.linkTo}}
              external={true}
              openInNewTab
            >
              <SocialNetworkLogo useCustomIcons={useCustomIcons} item={item} />
            </ExternalLinkComponent>
          </SocialLinkElementContainer>
        ))}
    </SocialLinksContainer>
  );
}

const SocialLinksContainer = styled.div.attrs(() => ({
  className: "social-links__container",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${({styles}) => styles.marginBottom};
`;

const SocialLinkElementContainer = styled.div.attrs(() => ({
  className: "social-link-element__container",
}))`
  margin: ${({styles}) => styles.margin};
  background-color: ${({color}) => color};
  border-radius: 24px;
  height: 38px;
  width: 98px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  top: 0;
  transition: top 0.5s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 16px 40px rgba(0, 0, 0, 0.08);
  > a {
    display: flex;
    justify-content: center;
    align-content: center;
  }
`;
Socials.propTypes = {
  styles: PropTypes.object,
  useCustomIcons: PropTypes.bool,
  useCustomBackground: PropTypes.bool,
  ExternalLinkComponent: PropTypes.elementType,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      linkTo: PropTypes.string,
      external: PropTypes.bool,
      backgroundColor: PropTypes.string,
      useCustomBackground: PropTypes.bool,
      icon: PropTypes.string,
      height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isActive: PropTypes.bool,
      openInNewTab: PropTypes.bool,
    })
  ),
};
