import {useEffect} from "react";
import {
  addBeforePopStateListener,
  removeBeforePopStateListener,
} from "src/core/common/hooks/useRouter";

let history = [];

export function useHistoryManager(router) {
  useEffect(() => {
    const handleRouteChange = (url, {shallow}) => {
      if (!shallow && url !== history[history.length - 1]) {
        history = [...history, url];
      }
    };

    const onBeforePopState = () => {
      history = history.slice(0, -2);
    };
    addBeforePopStateListener(onBeforePopState, router);

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
      removeBeforePopStateListener(onBeforePopState, router);
    };
  }, []);
}

export function canGoBack() {
  return history.length > 1;
}
