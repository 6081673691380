import {initStore} from "./state/store";
import config from "./config";
import Actions from "./actions";
import localStorage from "src/core/common/localStorage";

export {default as store} from "./state/store";

let handler;
export async function init({backend, middlewares, router}) {
  return initStore().then(() => {
    config.setConfig({
      backend,
    });
    middlewares.forEach(middleware => {
      config.addMiddleware(middleware);
    });
    Actions.loadCart(router.query.cartId);

    if (handler) window.removeEventListener("storage", handler);
    handler = event => {
      if (event.key === backend.getCartKey()) {
        handleLocalStorageCartChange(backend);
      }
    };

    window.addEventListener("storage", handler, false);
  });
}

const handleLocalStorageCartChange = backend => {
  const currentCart = Actions.getCart();
  const newCartId = localStorage.getItem(backend.getCartKey());

  if (newCartId && newCartId !== currentCart?.getId()) {
    Actions.loadCart();
  } else if (!backend.hasCart()) {
    Actions.clearCart();
  }
};

export {Actions};
