import React, {useRef} from "react";
import styled, {withTheme} from "styled-components";
import dynamic from "next/dynamic";
import "react-dropdown/style.css";
import {logModuleLoadError} from "src/core/common/utils";
import {useProductsOrderSelector} from "src/menu/hooks/useSortBy";
import {getThemeProperty} from "src/themes/utils/utils";
import useProductSortingOptions from "src/menu/hooks/useProductSortOptions";

const Dropdown = dynamic(() =>
  import("react-dropdown").catch(logModuleLoadError("react-dropdown"))
);

export default function ProductsOrderSelector({themeProperties}) {
  const sortOptions = useProductSortingOptions();
  const {onChangeOrder, currentOrder} = useProductsOrderSelector(sortOptions);

  return (
    <ThemedSelect
      options={sortOptions}
      themeProperties={themeProperties}
      onChange={onChangeOrder}
      value={currentOrder}
    />
  );
}

const ThemedSelect = withTheme(Select);

function Select({themeProperties, value, onChange, options, width}) {
  const wrapperRef = useRef();

  return (
    <Wrapper
      ref={wrapperRef}
      themeProperties={themeProperties}
      style={{width: width || "190px"}}
    >
      <Dropdown
        options={options}
        value={value}
        onChange={onChange}
        themeProperties={themeProperties}
        placeholder="Sort"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .Dropdown-root {
    position: relative;
    box-sizing: border-box;
  }
  & .Dropdown-control {
    padding: 0 ${({theme}) => theme.v2.spacing(4)};
    border: solid;
    border-color: ${({theme}) =>
      !theme.v1.components.filtersNavigation
        ? theme.v2.color.border
        : theme.v1.components.filtersNavigation.button.borderColor ??
          theme.v2.color.border};
    border-width: ${({theme}) =>
      !theme.v1.components.filtersNavigation
        ? "3px"
        : theme.v1.components.filtersNavigation.button.borderWidth ?? "2px"};
    cursor: pointer;
    @media (max-width: 529px) {
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.2);
    }
    color: ${({theme}) => theme.v2.color.onSurface};
    background-color: #fff;
    font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
    font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
    line-height: calc(${({theme}) => theme.v2.typography.sizing.m.lg} + 3px);
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: ${({theme}) =>
      getThemeProperty(
        theme,
        "v2.decoration.border.radius.default",
        "v1.components.filtersNavigation.button.borderRadius"
      )};
    transition: 100ms border-color ease-out;
  }
  & .Dropdown-control:hover {
    border-color: ${({theme}) => theme.v2.color.primary};
  }
  & .Dropdown-menu {
    max-height: 300px;
    height: 255px;
    margin-top: ${({theme}) => theme.v2.spacing(2)};
    padding-top: ${({theme}) => theme.v2.spacing(1)};
    padding-bottom: ${({theme}) => theme.v2.spacing(1)};
    border: ${({theme}) =>
        !theme.v1.components.filtersNavigation
          ? ""
          : theme.v1.components.filtersNavigation.button.borderWidth ?? "2px"}
      solid
      ${({theme}) =>
        !theme.v1.components.filtersNavigation
          ? ""
          : theme.v1.components.filtersNavigation.button.borderColor ??
            theme.v2.color.border};
    border-radius: ${({theme}) =>
      getThemeProperty(
        theme,
        "v2.decoration.border.radius.default",
        "v1.components.filtersNavigation.button.borderRadius"
      )};
    background-color: #fff;

    @media (max-width: 529px) {
      top: 0;
      transform: translateY(-110%);
    }
  }
  & .Dropdown-placeholder,
  .Dropdown-option {
    color: ${({theme}) =>
      theme.v2.color.onSurface ? theme.v2.color.onSurface : "#3b4342"};
    font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
    font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
    font-weight: ${({theme}) => theme.v2.typography.title1.weight};
  }
  .Dropdown-option:hover {
    background-color: ${({theme}) => theme.v2.color.border};
  }
  & .Dropdown-option.is-selected {
    background-color: ${({theme}) => theme.v2.color.border};
    color: ${({theme}) => theme.v2.color.background};
  }
  & .Dropdown-arrow {
    border-color: ${({theme}) => theme.v2.color.onSurface} transparent transparent;
    margin-top: 6px;
    @media (max-width: 529px) {
      margin-top: 8px;
    }
    right: 16px;
  }
  & .is-open .Dropdown-arrow {
    border-color: transparent transparent ${({theme}) => theme.v2.color.border};
  }
`;
