import useThemeConfig from "src/themes/useThemeConfig";
import useSite from "src/core/sites/hooks/useSite";

export function useThemeComponents() {
  const config = useThemeConfig();
  const site = useSite();
  const forcedTheme = site.getUiConfiguration().categoryBar?.theme;
  const overrideConfig = useThemeConfig(forcedTheme);
  const CategoryBar = overrideConfig?.components?.CategoryBar;

  if (CategoryBar) {
    config.components.CategoryBar = CategoryBar;
  }

  return {...config.components, ...config.pages};
}
