import {useEffect, useMemo, useState} from "react";
import store from "../state/store";
import {Deliveries} from "../../deliveries/api";
import useShop from "../../shops/hooks/useShop";
import useDeliveryType from "./useDeliveryType";

function useDeliveryAddress() {
  const [state, setState] = useState(store.getState());
  const [shop] = useShop();
  const [deliveryType] = useDeliveryType();

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const address = state.deliveryAddress;
  const addressString = address ? address.toString() : "";

  /* eslint-disable */
  const _deliveryAddress = useMemo(() => {
    return deliveryType && deliveryType.pickupAtShop()
      ? shop.data && shop.data.getAddress()
      : address;
  }, [deliveryType, addressString, shop.data]);
  /* eslint-enable */

  return [_deliveryAddress, Deliveries.selectDeliveryAddress];
}

export default useDeliveryAddress;
