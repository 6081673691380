import React from "react";
import styled, {useTheme} from "styled-components";
import Link from "src/core/common/components/modules/Link";
import {ArrowRightUp} from "@styled-icons/remix-line/ArrowRightUp";
import merge from "lodash/merge";
import {DisplayOnly} from "@ui/components/Media";

export default function SenseViewAllLink({
  to,
  keepQuery,
  accessibilityIdentifier,
  ...props
}) {
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.viewAll)};

  return (
    <CustomLink
      aria-label={`View all ${
        to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
      } products`}
      to={to}
      className={props.className}
      keepQuery={keepQuery}
      color={props.color}
    >
      <ViewAllText color={props.color} theme={theme}>
        <ArrowRightUpIcon
          theme={theme}
          color={props.color}
          role="img"
          title="View all"
          aria-label={`View all ${
            to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
          } products`}
        />

        <DisplayOnly dims={["lg", "md"]}>
          <span>View all</span>
        </DisplayOnly>
      </ViewAllText>
    </CustomLink>
  );
}

const CustomLink = styled(Link)`
  text-decoration: none;
  text-decoration-color: ${({color}) => `${color} !important`};
`;

const ViewAllText = styled.div`
  display: flex;
  align-items: center;

  span {
    padding-left: ${({theme}) => theme.v2.spacing(1)};
    font-size: ${({theme}) => theme.v2.typography.title5.size.lg};
    color: ${({theme, color}) => (color ? color : theme.v2.color.onBackground)};
    font-family: ${({theme}) => theme.v2.typography.title5.family};
    font-weight: ${({theme}) => theme.v2.typography.title5.weight};
    text-transform: ${({theme}) => theme.v2.typography.title5.textCase};
    white-space: nowrap;
  }
`;

const ArrowRightUpIcon = styled(ArrowRightUp)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.primary)};
  height: 24px;
  width: 24px;
`;
