import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import TranslucentOnHover from "@ui/components/Decorators/TranslucentOnHover";
import IconButton from "@ui/components/ProductCard/Actions/IconButton";
import useQuantityEvents from "@ui/components/ProductCard/Actions/hooks/useQuantityEvents";
import {getCartIconComponent} from "@ui/components/Icons";

function CartActions({
  styles,
  allowQuantityChange,
  cartIcon,
  cartText,
  showText = false,
  quantity,
  onRemove,
  onAdd,
  onChangeQuantity,
  showQuantityPicker,
  RemoveIconComponent,
  DecreaseIconComponent,
  IncreaseIconComponent,
}) {
  const {onIncrease, onDecrease, showRemove} = useQuantityEvents(
    quantity,
    allowQuantityChange,
    onRemove,
    onChangeQuantity
  );
  const _onAdd = showQuantityPicker ? null : onAdd;
  const fullWidth = showQuantityPicker && allowQuantityChange;
  const CartIconComponent = getCartIconComponent(cartIcon);
  return (
    <TranslucentOnHover opacityOnHover={0.9} disableEffect={fullWidth}>
      <Container
        styles={styles}
        fullWidth={fullWidth}
        showQuantityPicker={showQuantityPicker && allowQuantityChange}
        role={"button"}
        aria-pressed={"false"}
        onClick={_onAdd}
      >
        {showQuantityPicker ? (
          <>
            <IconButton
              ButtonComponent={Button}
              onClick={onDecrease}
              AltIcon={RemoveIconComponent}
              Icon={DecreaseIconComponent}
              showAltIcon={showRemove}
              iconColor={styles.color}
            />
            {allowQuantityChange && <QuantityText>{quantity || 0}</QuantityText>}
            {allowQuantityChange && (
              <IconButton
                ButtonComponent={Button}
                onClick={onIncrease}
                Icon={IncreaseIconComponent}
                iconColor={styles.color}
              />
            )}
          </>
        ) : showText ? (
          <CartTextComponent styles={styles} text={cartText} />
        ) : (
          <CartIconComponent color={styles.color} size={styles.size} />
        )}
      </Container>
    </TranslucentOnHover>
  );
}

const CartTextComponent = ({styles, text}) => {
  return <CartTextContainer styles={styles}>{text || "Add To Cart"}</CartTextContainer>;
};

const Container = styled.div.attrs(({showQuantityPicker}) => ({
  className:
    "cart-icon-actions cart-icon-actions__actions" +
    (showQuantityPicker ? " cart-icon-actions__actions--quantity" : ""),
  "data-keep-cart": "true",
}))`
  box-sizing: border-box;
  background-color: ${({styles}) => styles.backgroundColor};
  box-shadow: ${({styles}) => styles.boxShadow};
  display: flex;
  align-items: center;
  justify-content: ${({fullWidth}) => (fullWidth ? "space-between" : "center")};

  ${media.up("lg")} {
    height: ${({styles}) => styles.height.lg};
    width: ${({fullWidth, styles}) =>
      fullWidth ? "100%" : styles.width ? styles.width.lg : styles.height.lg};
  }
  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    width: ${({fullWidth, styles}) =>
      fullWidth ? "100%" : styles.width ? styles.width.md : styles.height.md};
  }
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    width: ${({fullWidth, styles}) =>
      fullWidth ? "100%" : styles.width ? styles.width.sm : styles.height.sm};
  }

  border-radius: ${({styles}) => styles.borderRadius};
  border: ${({styles}) => `1px solid ${styles.borderColor}`};
  cursor: ${({fullWidth}) => (fullWidth ? "default" : "pointer")};
  padding: ${({fullWidth, styles}) => (fullWidth ? styles.padding : 0)};

  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};

  transition: width 0.3s;
`;

const Button = styled.div.attrs(() => ({
  className: "cart-icon-actions cart-icon-actions__button",
  role: "button",
  "aria-pressed": "false",
  "data-keep-cart": "true",
}))`
  height: 24px;
  width: 24px;
  border-radius: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const QuantityText = styled.span.attrs(() => ({
  className: "cart-icon-actions cart-icon-actions__quantity-text",
}))``;

const CartTextContainer = styled.span.attrs(() => ({
  classname: "cart-icon-actions__cart-text",
  "data-keep-cart": "true",
}))`
  display: flex;
  justify-content: center;
  font-size: ${({styles}) => styles.fontSize.lg};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

export default CartActions;
