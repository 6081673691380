import React from "react";
import PropTypes from "prop-types";

function InStockIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.06641C6.5 2.06641 2 6.56641 2 12.0664C2 17.5664 6.5 22.0664 12 22.0664C17.5 22.0664 22 17.5664 22 12.0664C22 6.56641 17.5 2.06641 12 2.06641ZM12 20.0664C7.59 20.0664 4 16.4764 4 12.0664C4 7.65641 7.59 4.06641 12 4.06641C16.41 4.06641 20 7.65641 20 12.0664C20 16.4764 16.41 20.0664 12 20.0664ZM16.59 7.64641L10 14.2364L7.41 11.6564L6 13.0664L10 17.0664L18 9.06641L16.59 7.64641Z"
        fill={color}
      />
    </svg>
  );
}

InStockIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default InStockIcon;
