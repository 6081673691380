import TymberApp from "src/apps/common/tymber";

const AppMap = {};

const getTymberApp = key => {
  if (!AppMap[key]) {
    AppMap[key] = new TymberApp();
  }

  return AppMap[key];
};

export default getTymberApp;
