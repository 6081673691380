import React from "react";
import styled, {useTheme} from "styled-components";
import {CarouselProductsList, SimpleProductsListHeader} from "@ui";
import styles, {viewAllLinkButtonStyles} from "./styles";
import useSite from "src/core/sites/hooks/useSite";
import ThemedViewAllLink from "@menu/components/ThemedViewAllLink";
import Title from "src/core/common/components/modules/Title";
import useShowcaseProductsList from "src/core/common/hooks/useShowcaseProductsList";

export default function FlightProductsList({
  categoryName,
  description,
  url,
  slug,
  keepQuery = true,
  type,
  ...props
}) {
  const theme = useTheme();
  const site = useSite();

  const baseProps = useShowcaseProductsList({
    inView: true,
    slug,
    type,
    url,
    filters: props.filters,
  });

  const productsPerSlide = props?.styles?.elementsPerRow || {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: 1,
  };

  return (
    <Container>
      <CarouselProductsList
        {...props}
        {...baseProps}
        partiallyDisplayNextElement={true}
        styles={styles(theme, site)}
        maxProductsPerSlide={productsPerSlide}
        HeaderComponent={
          <SimpleProductsListHeader title={categoryName} TitleComponent={Title} />
        }
        displayNavigationDimensions={["lg", "md", "sm"]}
        SlideIndicatorComponent={null}
      />
      {slug && categoryName && (
        <CustomViewAllLink
          styles={viewAllLinkButtonStyles(theme, site)}
          accessibilityIdentifier={slug}
          to={url}
          keepQuery={keepQuery}
          defaultUnderlined={false}
          showIcon={false}
          text={`Shop All ${categoryName.toLowerCase()}`}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > *:first-child {
    margin: 0;
  }

  margin-bottom: ${({theme}) => theme.v2.spacing(12)};

  @media (max-width: 767px) {
    .product-card__skeleton {
      display: block;
    }
  }
`;
const CustomViewAllLink = styled(ThemedViewAllLink)`
  display: inline-flex !important;
  text-decoration: ${({underlined = false}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
  margin: 0 auto;
  background-color: ${({styles}) => styles.viewAllLink.backgroundColor};
  border-radius: ${({styles}) => styles.viewAllLink.borderRadius};

  span {
    color: ${({styles}) => styles.viewAllLink.color};
    font-weight: ${({styles}) => styles.viewAllLink.fontWeight};
    text-transform: capitalize;
  }

  > div {
    border: 0;
  }
`;
