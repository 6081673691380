import React from "react";
import styled from "styled-components";
import {AppliedFiltersList, media} from "@ui";
import useAppliedFilters from "@mock/hooks/useAppliedFilters";
import appliedFiltersStyles from "@themes/default/components/AppliedFilters/styles.js";
import useTheme from "@mock/hooks/useTheme.js";

export default function AppliedFiltersSection({hideBrandFilter = false}) {
  const [
    filters,
    setFilter,
    clearFilters,
    ,
    FilterParams,
    noBrandFilters,
    site,
    weights,
  ] = useAppliedFilters();

  const theme = useTheme();

  const hasAppliedFilters = filters?.hasFilters() || noBrandFilters.hasFilters();

  return (
    <Container>
      {hasAppliedFilters && (
        <FiltersContainer theme={theme}>
          <AppliedFiltersList
            styles={appliedFiltersStyles(theme, site)}
            filters={hideBrandFilter ? noBrandFilters : filters}
            setFilter={setFilter}
            clearFilters={clearFilters}
            FilterParams={FilterParams}
            weights={weights}
          />
        </FiltersContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
`;

const FiltersContainer = styled.div`
  display: flex;
  padding: 0
    ${({theme}) => `${theme.v2.spacing(8)} ${theme.v2.spacing(8)} ${theme.v2.spacing(8)}`};

  ${media.down("md")} {
    padding: 0
      ${({theme}) =>
        `${theme.v2.spacing(4)} ${theme.v2.spacing(4)} ${theme.v2.spacing(4)}`};
  }
`;
