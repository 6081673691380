import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FiltersCheckbox from "../Elements/FiltersCheckbox";

export default function SingleOptionFilter({
  name,
  id,
  disabled,
  value,
  onChange,
  checkedMarkColor,
  styles,
}) {
  return (
    <Container onClick={() => onChange(!value)} disabled={disabled}>
      <Title htmlFor={`${id}-checkbox`} onClick={e => e.preventDefault()}>
        {name}
      </Title>
      <FiltersCheckbox
        id={`${id}-checkbox`}
        checked={value}
        onChange={() => {}}
        color={checkedMarkColor}
        styles={styles}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.v2.spacing(4)} 0;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
`;

const Title = styled.label`
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  color: #333;
  margin-right: ${({theme}) => theme.v2.spacing(2)};
`;

SingleOptionFilter.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  checkedMarkColor: PropTypes.string,
  styles: PropTypes.shape({}),
};
