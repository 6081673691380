import autoBind from "auto-bind";
import OnlinePayment from "src/core/payments/OnlinePayment";
import * as PaymentsAPI from "src/core/api/payments";
import * as Notifications from "src/core/notifications";
import {NullPaymentSource} from "src/core/payments/models/paymentSource";
import EventBus from "src/core/common/eventBus";
import get from "lodash/get";
import {ErrorMessages, PaymentOptions} from "src/core/payments/constants";
import {makePaymentSource} from "src/core/payments/factories/paymentSource";

const MonerisEventBus = new EventBus();

export const MonerisEvents = {
  CREATE_CARD_ERROR: "create-card-error",
  START_EXTERNAL_AUTH: "start-external-auth",
  EXTERNAL_AUTH_SUCCESS: "external-auth-verification-success",
  EXTERNAL_AUTH_FAILURE: "external-auth-verification-failure",
};
const ENCRYPT_TOKEN_TIMEOUT = 15000;
class Moneris extends OnlinePayment {
  constructor(code) {
    super(code);
    this.eventBus = MonerisEventBus;
    this.domain = this.isProduction() ? "www3.moneris.com" : "esqa.moneris.com";
    autoBind(this);
  }

  async getConfiguration() {
    const config = await PaymentsAPI.getIntegrationConfiguration(PaymentOptions.MONERIS);

    return {
      publicKey: get(config, "data.attributes.public_key", ""),
      hasExternalAuth: get(config, "data.attributes.has_external_auth", false),
    };
  }

  /**
   * Moneris Codes
   * 001 - Approved
   * 940 - Invalid Profile ID
   * 942 - Error generating token
   * 943 - Card data is invalid (not numeric, fails mod10, we will remove spaces)
   * 944 - Invalid expiration date (mmyy, must be current month or in the future)
   * 945 - Invalid CVD data (not 3-4 digits)
   */

  monerisSuccessCode = "001";

  monerisErrorCodes = {
    940: "Invalid Profile ID",
    942: ErrorMessages.FAILED_TOKEN_GENERATION,
    943: "Invalid card number",
    944: "Invalid expiration date",
    945: "Invalid CVC",
    missingCardholder: "Invalid cardholder name",
  };

  missingCardholderKey = "missingCardholder";

  monerisResponseListener(e, onSuccess, onFailure, cardholderName) {
    if (e.origin === `https://${this.domain}`) {
      const eventData = JSON.parse(e.data);
      let responseCodes = eventData.responseCode;

      if (!cardholderName) {
        responseCodes = [this.missingCardholderKey, ...responseCodes];
      }

      if (responseCodes.includes(this.monerisSuccessCode)) {
        if (responseCodes.includes(this.missingCardholderKey)) {
          const error = this.monerisErrorCodes[this.missingCardholderKey];
          this.notify(MonerisEvents.CREATE_CARD_ERROR, responseCodes);
          onFailure(error);
        } else {
          onSuccess(eventData.dataKey);
        }
      } else {
        const error =
          responseCodes?.length > 0 ? responseCodes : "Error processing payment";

        this.notify(MonerisEvents.CREATE_CARD_ERROR, responseCodes);
        onFailure(error);
      }
    } else {
      this.addMonerisEventListener(onSuccess, onFailure, cardholderName);
    }
  }

  async encryptToken(cardholderName) {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject("Operation timed out. Please contact support.");
      }, ENCRYPT_TOKEN_TIMEOUT);
      const onEncrypt = (...params) => {
        resolve(...params);
        clearTimeout(timeout);
      };
      this.addMonerisEventListener(onEncrypt, reject, cardholderName);

      let monFrameRef = document.getElementById("monerisFrame").contentWindow;
      monFrameRef.postMessage("", `https://${this.domain}/HPPtoken/index.php`);
    });
  }

  addMonerisEventListener(onSuccess, onFailure, cardholderName) {
    window.addEventListener(
      "message",
      e => this.monerisResponseListener(e, onSuccess, onFailure, cardholderName),
      {once: true}
    );
  }

  externalAuthResponseListener(e, challengeInfo, onSuccess, onFailure) {
    if (e?.origin === window.location.origin) {
      const eventData = e.data;
      if (eventData.code === MonerisEvents.EXTERNAL_AUTH_SUCCESS) {
        this.notify(MonerisEvents.EXTERNAL_AUTH_SUCCESS);
        const result = {
          cavv: get(eventData, "data.cavv"),
          id: get(challengeInfo, "id"),
        };
        onSuccess(result);
        return;
      } else if (eventData.code === MonerisEvents.EXTERNAL_AUTH_FAILURE) {
        this.notify(MonerisEvents.EXTERNAL_AUTH_FAILURE);
        onFailure(eventData.data || "3D Secure verification failed.");
        return;
      }
    }
    this.addExternalAuthEventListener(challengeInfo, onSuccess, onFailure);
  }

  addExternalAuthEventListener(challengeInfo, onSuccess, onFailure) {
    window.addEventListener(
      "message",
      e => this.externalAuthResponseListener(e, challengeInfo, onSuccess, onFailure),
      {once: true}
    );
  }

  async addMonerisPaymentSource(token, cardholderName) {
    return PaymentsAPI.addPaymentSource(PaymentOptions.MONERIS, {
      payment_source_token: token,
      cardholder_name: cardholderName,
    });
  }

  async getExternalAuthVerificationData(data, cartId) {
    const info = await PaymentsAPI.getExternalAuthVerification(PaymentOptions.MONERIS, {
      data: data,
      cartId: cartId,
    });

    return {
      cavv: get(info, "data.attributes.cavv"),
      challengeUrl: get(info, "data.attributes.challenge_url"),
      challengeData: get(info, "data.attributes.challenge_data"),
      isChallengeRequired: get(info, "data.attributes.is_challenge_required"),
      id: get(info, "data.id"),
    };
  }

  async preparePaymentDefault(orderInfo, paymentData, cartId) {
    try {
      const cardholderName = get(paymentData, "source.cardholderName", null);

      let paymentSource;
      let cavv;

      if (paymentData?.source?.id && !(paymentData.source instanceof NullPaymentSource)) {
        paymentSource = paymentData.source;
      } else {
        paymentSource = await this.addNewCard(cardholderName);
      }

      const config = await this.getConfiguration();
      if (config.hasExternalAuth) {
        const data = await this.performExternalAuthVerification(
          {
            payment_source_id: paymentSource.id,
            cardholder_name: cardholderName,
            ...this.maybeAttachTip(
              {
                payment_specification: {},
              },
              paymentData
            ),
          },
          cartId
        );
        cavv = data.cavv;
      }

      return {
        ...orderInfo,
        payment_specification: {
          payment_source_id: paymentSource.id || null,
          cavv,
        },
      };
    } catch (e) {
      this.handlePreparePaymentError(e);
    }
  }

  async preparePaymentGuestCheckout(orderInfo, paymentData, cartId) {
    try {
      const cardholderName = get(paymentData, "source.cardholderName", null);

      const token = await this.encryptToken(cardholderName);

      const config = await this.getConfiguration();

      if (config.hasExternalAuth) {
        return await this.preparePaymentGuestCheckoutWithExternalAuth({
          token,
          cartId,
          cardholderName,
          orderInfo,
          paymentData,
        });
      } else {
        return {
          ...orderInfo,
          payment_specification: {
            payment_source_token: token || null,
          },
        };
      }
    } catch (e) {
      this.handlePreparePaymentError(e);
    }
  }

  handlePreparePaymentError(e) {
    if (typeof e === "string") {
      Notifications.error(e);
    }
    if (Array.isArray(e)) {
      let errorMessage = "";
      e.forEach(error => {
        errorMessage = errorMessage.concat(`${this.monerisErrorCodes[error]}. `);
      });

      Notifications.error(errorMessage);
    }
    throw e;
  }

  async preparePaymentGuestCheckoutWithExternalAuth({
    token,
    cartId,
    cardholderName,
    orderInfo,
    paymentData,
  }) {
    const data = await this.performExternalAuthVerification(
      {
        payment_source_token: token, // Use moneris card token directly instead of payment source id
        cardholder_name: cardholderName,
        first_name: get(orderInfo, "first_name"),
        last_name: get(orderInfo, "last_name"),
        email: get(orderInfo, "email"),
        phone_number: get(orderInfo, "phone_number"),
        date_of_birth: get(orderInfo, "date_of_birth"),
        ...this.maybeAttachTip(
          {
            payment_specification: {},
          },
          paymentData
        ),
      },
      cartId
    );

    return {
      ...orderInfo,
      payment_specification: {
        payment_source_id: data.id || null,
        cavv: data.cavv,
      },
    };
  }

  async preparePayment(orderInfo, paymentData, cartId, isGuestCheckout) {
    let result = {};
    if (isGuestCheckout) {
      result = await this.preparePaymentGuestCheckout(orderInfo, paymentData, cartId);
    } else {
      result = await this.preparePaymentDefault(orderInfo, paymentData, cartId);
    }

    this.maybeAttachTip(result, paymentData);
    this.maybeAttachFailPayment(result);
    return result;
  }

  async performExternalAuthVerification(data, cartId) {
    return new Promise(async (resolve, reject) => {
      try {
        const challengeInfo = await this.getExternalAuthVerificationData(data, cartId);

        if (!challengeInfo.isChallengeRequired) {
          resolve(challengeInfo);
          return;
        }

        this.addExternalAuthEventListener(challengeInfo, resolve, reject);
        this.notify(MonerisEvents.START_EXTERNAL_AUTH, challengeInfo);
      } catch (e) {
        reject(e);
      }
    });
  }

  async addNewCard(cardholderName) {
    const token = await this.encryptToken(cardholderName);

    const paymentSourceData = await this.addMonerisPaymentSource(token, cardholderName);
    return makePaymentSource(paymentSourceData.data, "moneris");
  }

  async addAccount(paymentData) {
    const {payment_specification} = await this.preparePayment({}, paymentData);
    try {
      await PaymentsAPI.addPaymentSource(PaymentOptions.MONERIS, payment_specification);
    } catch (e) {
      await this.maybeHandle(payment_specification, e);
    }
  }

  async removeAccount(paymentSource) {
    await PaymentsAPI.removePaymentSource(
      PaymentOptions.MONERIS,
      paymentSource.internalId
    );
    this.notify("removed-account-success", paymentSource);
  }

  async setDefaultAccount(paymentSource) {
    await PaymentsAPI.markAsDefaultPaymentSource(
      PaymentOptions.MONERIS,
      paymentSource.internalId
    );
    this.notify("changed-default-account-success", paymentSource);
  }

  async pay(orderId, paymentData) {
    const {payment_specification} = await this.preparePayment({}, paymentData);
    try {
      await this.doPay(orderId, payment_specification);
    } catch (e) {
      await this.maybeHandle(payment_specification, e);
    }
  }

  doPay = (orderId, paymentSource) => {
    return PaymentsAPI.pay(PaymentOptions.MONERIS, orderId, paymentSource);
  };
}

export default Moneris;
