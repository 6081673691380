export function getProductAvailability(product) {
  if (product.isInStock()) {
    return "https://schema.org/InStock";
  } else {
    return "https://schema.org/OutOfStock";
  }
}

export function getPriceValidity(product) {
  const now = new Date();
  const nextYear = now.getFullYear() + 1;
  return `${nextYear}-12-31`;
}

const removeHTML = htmlText => {
  if (htmlText) {
    return htmlText.replace(/<[^>]+>/g, "");
  }
  return htmlText;
};

export function trimTag(tag, length) {
  if (tag?.length > length) {
    return `${tag.substring(0, length - 3)}...`;
  }
  return tag;
}

export function createPageDescription(tag, length = 160) {
  const cleanTag = removeHTML(tag);
  return trimTag(cleanTag, length);
}

export function createPageTitle(
  title,
  siteTitle,
  template = "%page_title% - %site_title%",
  length = 60
) {
  const cleanSiteTitle = removeHTML(siteTitle);
  if (title) {
    const cleanTitle = removeHTML(title);
    let pageTitle = template.replace("%page_title%", cleanTitle);
    pageTitle = pageTitle.replace("%site_title%", cleanSiteTitle);

    return trimTag(pageTitle, 60);
  }
  return trimTag(cleanSiteTitle, 60);
}
