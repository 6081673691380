import React from "react";
import styled from "styled-components";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import merge from "lodash/merge";
import TranslucentOnHover from "@ui/components/Decorators/TranslucentOnHover";
import PropTypes from "prop-types";
import CategoryPlaceholderImage from "@ui/components/CardCategoryButton/category-placeholder-image.jpg";

function CardCategoryButton({
  styles,
  LinkComponent,
  linkProps,
  selected,
  categoryName,
  categoryUrl,
  categoryImageUrl,
}) {
  const _styles = merge({}, defaultStyles, styles);
  return (
    <TranslucentOnHover>
      <LinkComponent to={categoryUrl} {...linkProps}>
        <Container selected={selected} styles={_styles.root}>
          {categoryImageUrl ? (
            <CategoryImage source={categoryImageUrl} styles={_styles} />
          ) : (
            <ImagePlaceholder
              src={CategoryPlaceholderImage}
              alt={categoryName}
              styles={_styles}
            />
          )}
          <LabelContainer>
            <Label styles={_styles.root}>{categoryName}</Label>
          </LabelContainer>
        </Container>
      </LinkComponent>
    </TranslucentOnHover>
  );
}

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    color: "#000",
    selectedBackgroundColor: "#000",
    selectedColor: "#fff",
    boxShadow: "0 4px 6px -4px #0000000d, 0 10px 15px -3px #0000000d",
    borderRadius: "4px",
    fontWeight: "500",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
    },
    textTransform: "capitalize",
  },
  image: {
    borderRadius: "4px",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 160px;
  height: 110px;
  padding: 10px 10px 0 10px;
  box-shadow: ${({styles}) => styles.boxShadow};
  background-color: ${({styles, selected}) =>
    selected ? styles.selectedBackgroundColor : styles.backgroundColor};
  color: ${({styles, selected}) => (selected ? styles.selectedColor : styles.color)};
  border-radius: ${({styles}) => styles.borderRadius};
`;

const CategoryImage = styled(ConfigurableImage)`
  object-fit: contain;
  height: 80px;
  width: 100%;
  border-radius: ${({styles}) => styles.image.borderRadius};
  background-color: ${({styles}) => styles.root.backgroundColor};
`;

const ImagePlaceholder = styled.img`
  object-fit: contain;
  height: 80px;
  width: 100%;
  border-radius: ${({styles}) => styles.image.borderRadius};
  background-color: ${({styles}) => styles.root.backgroundColor};
`;

const LabelContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  text-transform: ${({styles}) => styles.textTransform};
`;

CardCategoryButton.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      selectedBackgroundColor: PropTypes.string,
      selectedColor: PropTypes.string,
      boxShadow: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
      }),
      textTransform: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    image: PropTypes.shape({
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  LinkComponent: PropTypes.elementType,
  selected: PropTypes.bool,
  categoryName: PropTypes.string,
  categoryUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  categoryImageUrl: PropTypes.string,
  linkProps: PropTypes.object,
};
CardCategoryButton.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({children}) => <a href={"#"}>{children}</a>,
};

export default CardCategoryButton;
