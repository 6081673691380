import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import widthLimited from "@ui/components/Decorators/widthLimited";
import HorizontalCardList from "@ui/components/HorizontalCardList";
import merge from "lodash/merge";
import spacing from "@ui/utils/spacing";
import {DisplayOnly} from "@ui/components/Media";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

function SimpleCardList({
  HeaderComponent,
  skeleton,
  maxEntries,
  loading,
  renderCard,
  ...props
}) {
  const _styles = merge({}, defaultStyles, props.styles);
  return (
    <OuterContainer $loading={loading}>
      {HeaderComponent &&
        renderNodeOrComponent(HeaderComponent, {
          skeleton,
          styles: _styles.header,
        })}
      <Container>
        {["lg", "md", "sm"].map(dim => (
          <DisplayOnly dims={[dim]} key={dim} fullWidth={true}>
            <HorizontalCardList
              skeleton={skeleton}
              entries={props.entries}
              renderCard={renderCard}
              maxEntries={maxEntries[dim]}
              styles={_styles}
            />
          </DisplayOnly>
        ))}
      </Container>
    </OuterContainer>
  );
}

const defaultStyles = {
  list: {
    justifyContent: "flex-start",
  },
  element: {
    maxWidth: {
      lg: "250px",
      md: "250px",
      sm: "250px",
    },
    minWidth: {
      lg: "150px",
      md: "150px",
      sm: "180px",
    },
  },
  header: {},
};

const Container = widthLimited(styled.div.attrs(() => ({
  className: "simple-card-list simple-card-list__container",
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: 0 auto;
`);
const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: ${spacing(4)};
  opacity: ${({$loading}) => ($loading ? 0.6 : 1)};
`;

SimpleCardList.propTypes = {
  styles: PropTypes.shape({
    list: PropTypes.shape({
      justifyContent: PropTypes.string,
      flexDirection: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    element: PropTypes.shape({
      maxWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      minWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
  entries: PropTypes.arrayOf(PropTypes.object),
  maxEntries: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
    }),
  ]),
  skeleton: PropTypes.bool,
  loading: PropTypes.bool,
  renderCard: PropTypes.func,
  HeaderComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
};
SimpleCardList.defaultProps = {
  styles: defaultStyles,
};

export default SimpleCardList;
