import {isClient} from "src/server/utils/isClient";
import {MemoryStorage} from "src/core/common/memoryStorage";

class LocalStorage {
  constructor() {
    this.fallbackStorage = new MemoryStorage();
    this.localStorage = this.fallbackStorage;
    if (isClient) {
      this.localStorage = localStorage || this.fallbackStorage;
    }
  }
  getItem(key) {
    try {
      return this.localStorage.getItem(key);
    } catch (e) {
      return this.fallbackStorage.getItem(key);
    }
  }

  setItem(key, value) {
    try {
      return this.localStorage.setItem(key, value);
    } catch (e) {
      return this.fallbackStorage.setItem(key, value);
    }
  }

  removeItem(key) {
    try {
      return this.localStorage.removeItem(key);
    } catch (e) {
      return this.fallbackStorage.removeItem(key);
    }
  }
}

export default new LocalStorage();
