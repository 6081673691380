import * as ProductsAPI from "src/core/api/products";
export default function prefetch({href}) {
  const {pathname, query} = href;

  if (matchCategories(pathname)) {
    const parts = pathname.split("/").filter(Boolean);
    void ProductsAPI.list({limit: 20, ...query, category: parts.slice(-1)[0]});
  }
}

function matchCategories(pathname) {
  const parts = pathname.split("/").filter(Boolean);
  return parts.slice(-2)[0] === "categories";
}
