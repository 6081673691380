import {useState, useMemo, useEffect} from "react";
import useSearch from "src/menu/hooks/useSearch";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import useBreadcrumbs from "src/core/common/hooks/useBreadcrumbs";
import {PageHead} from "src/core/seo/components/Head";
import TitleComponent from "src/core/common/components/modules/Title";
import EraserIcon from "src/core/common/components/elements/icon/EraserIcon";
import useRouter from "src/core/common/hooks/useRouter";
import useSite from "src/core/sites/hooks/useSite";

export default function useSearchBrowser() {
  const site = useSite();
  const router = useRouter();
  const {query} = router;

  const [, setCategory, setProduct, setBrand, setSearch] = useBreadcrumbs();
  const [filters, , , clearAllFilters] = useAppliedFilters();
  const {search, state: searchState, hasResults} = useSearch();

  const selectedMap = {
    products: "products",
    categories: "categories",
    brands: "brands",
  };

  const [selected, setSelected] = useState(selectedMap[query.tab] || "products");

  const title = useMemo(
    () => `Search results for: '${filters.search}'`,
    [filters.search]
  );

  const tabs = getTabs(searchState, selected, setSelected);

  useEffect(() => {
    if (selectedMap[query.tab]) {
      setSelected(query.tab);
    }
    // eslint-disable-next-line
  }, [query.tab]);

  useEffect(() => {
    search(filters.search, {reset: true, filters});
    setSearch(filters.search);
    setCategory();
    setProduct();
    setBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.toString()]);

  const showPlaceholder = () => {
    const selectedTab = selectedMap[selected];

    return !searchState[selectedTab].loading && !hasResults[selectedTab];
  };

  return {
    site,
    filters,
    clearAllFilters,
    PageHead,
    TitleComponent,
    title,
    EraserIcon,
    tabs,
    showPlaceholder,
    numberOfResults: searchState[selected].totalCount,
    selected,
  };
}

function getTabs(searchState, selectedTab, setSelected) {
  const displayCounters = !["products", "categories", "brands"].reduce((acc, type) => {
    return acc && searchState[type].loading && searchState[type].data.length === 0;
  }, true);

  return [
    {
      label: displayCounters
        ? `Products (${searchState.products.totalCount || 0})`
        : "Products",
      isSelected: selectedTab === "products",
      onClick: () => setSelected("products"),
    },
    {
      label: displayCounters
        ? `Categories (${searchState.categories.totalCount || 0})`
        : "Categories",
      isSelected: selectedTab === "categories",
      onClick: () => setSelected("categories"),
    },
    {
      label: displayCounters
        ? `Brands (${searchState.brands.totalCount || 0})`
        : "Brands",
      isSelected: selectedTab === "brands",
      onClick: () => setSelected("brands"),
    },
  ];
}
