import {makeThemeProps} from "@themes/default/props/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getDeliveryBannerProps(theme);
};

export const deliveryModePickerStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getDeliveryModePickerProps(theme);
};
