import React from "react";
import styles from "@themes/boost/components/ShowcaseDealsList/styles";
import useTheme from "@mock/hooks/useTheme";
import useShowcaseDeals from "@mock/hooks/useShowcaseDeals";
import useShowcaseProductsList from "@mock/hooks/useShowcaseProductsList";
import CarouselCardList from "@ui/components/CarouselCardList";
import DealsListHeader from "@ui/components/DealsList/DealsListHeader";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import styled from "styled-components";

export default function BoostShowcaseDealsList() {
  const theme = useTheme();
  const {ViewAllLink} = useThemeComponents();
  const {
    deals,
    loading,
    site,
    DealCardComponent,
    showDealsList,
    dealsListTitle,
    allDealsLink,
    dealsListDescription,
  } = useShowcaseDeals();

  const {onLastSlide, LoadingComponent} = useShowcaseProductsList({
    url: {pathname: allDealsLink?.pathname, params: {shop: allDealsLink?.params}},
  });

  if (!showDealsList) return null;

  const dealsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm,
  };

  return (
    <Container>
      <CarouselCardList
        styles={styles(theme, site)}
        maxEntriesPerSlide={dealsPerSlide}
        skeleton={loading}
        entries={deals}
        onLastSlide={onLastSlide}
        LoadingComponent={LoadingComponent}
        renderCard={({element, skeleton, isFirst}) => (
          <DealCardComponent
            skeleton={skeleton}
            {...element}
            deal={element}
            isFirst={isFirst}
          />
        )}
        HeaderComponent={
          <DealsListHeader
            title={dealsListTitle}
            description={dealsListDescription}
            ViewAllComponent={<ViewAllLink to={allDealsLink} />}
          />
        }
      />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
`;
