import React from "react";
import AppliedFilters from "@themes/default/components/AppliedFilters/index.js";
import SortAndFilterHeader from "@themes/default/components/SortAndFilterHeader/index.js";
import DetailProductsList from "@themes/default/components/DetailProductsList/index.js";
import AppliedSearchFilter from "@themes/default/components/AppliedSearchFilter";
import useAppliedFilters from "@mock/hooks/useAppliedFilters";
import SeoHead from "@mock/components/modules/SeoHead";
import useDetailProductsList from "@mock/hooks/useDetailProductsList";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

export default function CategoryDetailPage({children, ...props}) {
  const [filters] = useAppliedFilters();
  const listProps = useDetailProductsList();
  const {BasePage} = useThemeComponents();
  return (
    <BasePage {...props}>
      <SeoHead category={listProps.category} />
      <AppliedFilters />
      {filters?.search ? <AppliedSearchFilter /> : <SortAndFilterHeader />}
      <DetailProductsList {...listProps} />
    </BasePage>
  );
}
