import get from "lodash/get";
import {findRelation} from "src/core/api/utils";
import dayjs from "dayjs";
import {makePaymentSource} from "src/core/payments/factories/paymentSource";

export class PaymentCharge {
  constructor(paymentSourceObj) {
    this._obj = paymentSourceObj;
  }
  get internalId() {
    return get(this._obj, "id");
  }
  get id() {
    return get(this._obj, "attributes.external_id");
  }
  hasTip() {
    return Boolean(get(this._obj, "relationships.payment_tip"));
  }
  getTip() {
    const tipRelation = get(this._obj, "relationships.payment_tip.data");
    if (tipRelation) {
      return findRelation(this._obj.included, tipRelation);
    }
    return null;
  }
  getConvenienceFee() {
    return get(this._obj, "attributes.convenience_fee");
  }

  getCredit() {
    return get(this._obj, "attributes.credit");
  }

  get createdAt() {
    return dayjs(get(this._obj, "attributes.created_at"));
  }
  get status() {
    return get(this._obj, "attributes.status");
  }

  get customer() {
    const customerRelation = get(this._obj, "relationships.payment_customer.data");
    if (customerRelation) {
      return findRelation(this._obj.included, customerRelation);
    }

    return null;
  }

  get insertedAt() {
    return get(this._obj, "attributes.inserted_at");
  }

  getPaymentSource(paymentOption) {
    const paymentSourceRelation = get(this._obj, "relationships.payment_source.data");
    if (paymentSourceRelation) {
      const paymentSource = findRelation(this._obj.included, paymentSourceRelation);
      if (paymentSource) {
        return makePaymentSource(paymentSource, paymentOption);
      }
    } else if (this._obj?.attributes?.guest_payment_source) {
      return makePaymentSource(
        {id: null, attributes: this._obj.attributes.guest_payment_source},
        paymentOption
      );
    }

    return null;
  }
}
