import useSite from "src/core/sites/hooks/useSite";
import useDeals from "@deals/hooks/useDeals";
import {useEffect, useMemo} from "react";
import useDealsPageInfo from "@deals/hooks/useDealsPageInfo";
import useRouter from "src/core/common/hooks/useRouter";
import useOnSaleProducts from "@deals/hooks/useOnSaleProducts";
import useThemeConfig from "src/themes/useThemeConfig";
import {subscribeEvent} from "src/events/utils";
import {EventTypes} from "src/events/constants";
import routes from "src/core/common/routes";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import Page from "src/core/common/models/page";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import usePrefetchedDeals from "@menu/hooks/usePrefetchedDeals";

export default function useDealsPage() {
  const {
    fetchDeals,
    state: {deals, noResults, loading, hasMore},
  } = useDeals();

  const {prefetchedDeals, prefetchedMeta} = usePrefetchedDeals();
  const dealsToUse = prefetchedDeals || deals;

  const dealsMeta = {
    loading: prefetchedMeta?.loading ?? loading,
    noResults: prefetchedMeta?.noResults ?? noResults,
    hasMore: prefetchedMeta?.hasMore ?? hasMore,
    skeleton: prefetchedMeta?.loading ?? (loading && deals.length === 0),
  };

  const {fetchOnSaleProducts, state: onSaleProductsState} = useOnSaleProducts();

  const {prefetchedProducts, prefetchedProductsMeta} = usePrefetchedOnSaleProducts();

  const productsToUse = prefetchedProducts || onSaleProductsState.products;

  const productsMeta = {
    hasMore: prefetchedProductsMeta.hasMore ?? onSaleProductsState.hasMore,
    skeleton: prefetchedProductsMeta.skeleton ?? onSaleProductsState.showSkeleton,
    loading: prefetchedProductsMeta.loading ?? onSaleProductsState.loading,
    noResults: onSaleProductsState.noResults,
  };

  useEffect(() => {
    return subscribeEvent(EventTypes.STORE_CHANGED, () => {
      fetchDeals({reset: true});
      fetchOnSaleProducts({reset: true});
    });
  }, []);

  useEffect(() => {
    if (deals.length === 0 && !prefetchedDeals?.length) {
      fetchDeals({reset: true});
    }
  }, []);

  useEffect(() => {
    if (onSaleProductsState.products.length === 0 && !prefetchedProducts?.length) {
      fetchOnSaleProducts({reset: true});
    }
  }, []);

  const site = useSite();

  const dealsPageInfo = useDealsPageInfo();

  const router = useRouter();

  const config = useThemeConfig();

  return {
    site,
    deals: {
      data: dealsToUse,
      title: dealsPageInfo.dealsListTitle,
      description: dealsPageInfo.dealsListDescription,
      loadMore: () => fetchDeals({reset: false}),
      ...dealsMeta,
    },
    products: {
      site,
      products: productsToUse,
      next: fetchOnSaleProducts,
      ProductCardComponent: config.components.ProductCard,
      totalCount: onSaleProductsState.totalCount,
      title: "Products On Sale Now!",
      description: "",
      ...productsMeta,
    },
    onBack: () => router.push({pathname: routes.home}),
  };
}

const usePrefetchedOnSaleProducts = () => {
  const {data} = useServerContext();
  const prefetchedProducts = data?.[PrefetchedDataKeys.PRODUCTS];
  const products = useMemo(() => {
    if (!Array.isArray(prefetchedProducts?.data) || !prefetchedProducts.data.length)
      return null;
    return new Page({
      meta: prefetchedProducts?.meta,
      objects: prefetchedProducts?.data.map(
        element => new Product(populateRelations(element, prefetchedProducts.included))
      ),
    });
  }, [prefetchedProducts]);

  if (!products) return {prefetchedProducts: null, prefetchedProductsMeta: {}};

  return {
    prefetchedProducts: products.getElements(),
    prefetchedProductsMeta: {
      hasMore: false,
      skeleton: false,
      loading: false,
    },
  };
};
