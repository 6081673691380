import useSchemaContext from "src/core/seo/components/useSchemaContext";
import React, {useMemo} from "react";
import Head from "next/head";
import makeDealDetailSchema from "src/core/seo/schema-objects/factories/dealDetail";
import {getDealDetailUrl} from "src/core/seo/meta";
import {isClient} from "src/server/utils/isClient";
import useCanonicalUrl from "src/core/seo/hooks/useCanonicalUrl";

function DealDetailSchema({deal}) {
  const ctx = useSchemaContext();

  const canonicalUrl = useMemo(() => {
    if (deal) {
      return getDealDetailUrl(deal, ctx);
    }
    return "";
  }, [deal, ctx]);

  const schemas = makeDealDetailSchema(deal, ctx);

  useCanonicalUrl(canonicalUrl);

  return (
    <Head>
      {!isClient && <link rel="canonical" href={canonicalUrl} key={"canonical"} />}
      {schemas.map((schema, index) => (
        <script
          key={`head-link-${index}`}
          type={schema.type}
          dangerouslySetInnerHTML={{__html: schema.innerHTML}}
        />
      ))}
    </Head>
  );
}

export default DealDetailSchema;
