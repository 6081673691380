import {ActionTypes} from "@deals/state/actions";

const initialState = {
  deal: null,
  error: null,
  loading: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_DEAL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.REQUEST_DEAL_SUCCESS:
      return {
        ...state,
        deal: action.payload.deal,
        loading: false,
      };
    case ActionTypes.REQUEST_DEAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ActionTypes.CLEAR_DEAL:
      return initialState;
    default:
      return state;
  }
}
