import React from "react";
import useSite from "src/core/sites/hooks/useSite";

export default function BannerPlacement({
  accountId,
  sourceId,
  containerSize,
  place,
  placementId,
}) {
  const {env} = useSite();
  const storeId = env.getEcommerceId();

  const ratio =
    containerSize?.width && containerSize?.height
      ? Math.floor(containerSize.width / containerSize.height)
      : DEFAULT_WIDTH;

  const closestRatio = WIDTHS.reduce((prev, curr) =>
    Math.abs(curr - ratio) < Math.abs(prev - ratio) ? curr : prev
  );

  return (
    <div
      class="surf-ad-placement"
      accountId={accountId}
      channelId={CHANNEL_ID}
      siteId={sourceId}
      placementId={placementId}
      keywords={place}
      zone={place}
      type="inline"
      storeId={storeId}
      adTypes="0,1"
      width={closestRatio}
      height="1"
    />
  );
}

const CHANNEL_ID = "bf9bc";
const DEFAULT_WIDTH = 8;
const WIDTHS = [1, 2, 4, 8];
