import * as Notifications from "src/core/notifications";
import {groupLog} from "src/core/common/logger";
import routes from "src/core/common/routes";
import ECommerceActions from "src/core/checkout/actions";
import localStorage from "src/core/common/localStorage";
import {LOCAL_STORAGE_USER} from "src/core/constants";
import {getError} from "src/core/common/utils";
import {showDialog} from "src/core/notifications";

export const ErrorTags = {
  DOCUMENTS: "documents",
  VERIFICATION_REQUIRED: "verification_required",
};

function sessionExpiredHandler({error, router, url}) {
  if (error.response.status === 401 && url === "/api/v1/users/me/") {
    notifyError("Your session has expired. Please login again.");
    router.push("/");
    return true; // breaks he chain
  }
}

function orderDetailForbiddenHandler({error, router, url}) {
  const user = localStorage.getItem(LOCAL_STORAGE_USER);
  if (!user && error.response.status === 403 && url.indexOf("/api/v1/orders") > -1) {
    router.push({
      pathname: routes.login,
      params: {shop: router.query.shop},
    });
    return true; // breaks he chain
  }
}

function inventoryTypeDoesNotMatchHandler({error, router}) {
  const errors = error.response?.data?.errors || [];
  if (
    error.response.status === 400 &&
    errors[0]?.extra_info?.api_error_code === "TZ00003" &&
    ECommerceActions.getCart()
  ) {
    ECommerceActions.clearCart();
    notifyError("Sorry, your cart is no longer valid. Please add items to a new cart.");
    return true; // breaks he chain
  }
}

function notFoundIntegrationsLoggerHandler({error, url, message}) {
  if (error.response.status === 404 && !url.includes("/api/v1/store/site/integrations")) {
    groupLog(`%c404 %c${message}`, error.response, "red");
  }
}

function generalHandler({error, code, apiCode, message}) {
  const errorCodeIgnoreList = [
    "phone_number_requires_confirmation",
    "email_requires_confirmation",
    "AIQ00000",
  ];
  const ignoreError =
    errorCodeIgnoreList.indexOf(code) === -1 &&
    errorCodeIgnoreList.indexOf(apiCode) === -1;
  if (error.response.status !== 404 && ignoreError) {
    const skipNotification = error.response.config.skipNotification;

    if (!skipNotification || !skipNotification(error.response, Notifications)) {
      notifyError(typeof message === "string" ? message : "An error has occurred");
    }
  }
}

function missingMedicalIdHandler({code, router}) {
  if (code === "missing_medical_id") {
    router.push({
      pathname: routes.personalInfo,
      params: {
        ...router.query,
      },
      query: {
        error: "Please review your Medical ID information before proceeding.",
        tag: ErrorTags.DOCUMENTS,
        close: true,
      },
    });
  }
}

function invalidAddressHandler({code, router}) {
  if (code === "invalid_address") {
    router.push({
      pathname: routes.deliveryCheck,
      params: {
        ...router.query,
      },
    });
  }
}

const msgs = {};
export function notifyError(msg) {
  if (msgs[msg]) return;
  Notifications.error(msg, {limit: 1});
  msgs[msg] = "displaying";
  setTimeout(() => {
    msgs[msg] = undefined;
    delete msgs[msg];
  }, 5000);
}

function notFoundAuthErrors({error, url, message}) {
  if (
    error.response.status === 404 &&
    url.includes("/api/v1/auth") &&
    typeof message === "string"
  ) {
    notifyError(message);
  }
}

function notFoundCartErrors({error, url}) {
  if (error.response.status === 404 && url.includes("/carts")) {
    notifyError(
      "Invalid cart or item. Please try again. If the error persists, try clearing your cart."
    );
  }
}

function cartAlreadySubmitted({error, router, message}) {
  const responseError = getError("cart_already_submitted", error.response);
  if (responseError?.extra_info?.order?.uuid) {
    showDialog({
      title:
        "Looks like there is a submitted order for this cart, do you wish to see it?",
      subtitle:
        "This usually happens because you submitted the cart and then closed the website or switched tabs.",
      helperText:
        "This happened because you submitted the cart and then closed the website or switched tabs",
      cancelText: "Continue shopping",
      confirmText: "See Order",
      onConfirm: () =>
        router.push({
          pathname: routes.orderDetail,
          params: {orderId: responseError.extra_info.order.uuid, shop: router.query.shop},
        }),
    });
    Notifications.info(message);
    return true; // breaks he chain
  }
}

export default [
  sessionExpiredHandler,
  notFoundIntegrationsLoggerHandler,
  notFoundAuthErrors,
  notFoundCartErrors,
  cartAlreadySubmitted,
  missingMedicalIdHandler,
  invalidAddressHandler,
  inventoryTypeDoesNotMatchHandler,
  generalHandler,
  orderDetailForbiddenHandler,
];
