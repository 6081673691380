import React, {useEffect, useRef, useState} from "react";
import {Field} from "react-final-form";
import styled, {createGlobalStyle} from "styled-components";
import findLastIndex from "lodash/findLastIndex";
import {Error} from "@ui/components/FormFields/Error";
import Input from "@ui/components/Inputs";
import {formatDate} from "@ui/utils/formatDate";

function FallbackDatePicker({
  name,
  minDate,
  initialValue,
  startingDate,
  placeholder,
  hasErrors,
  errorStyles,
  ...props
}) {
  const DATE_FORMAT = "mm/dd/yyyy";
  const [value, setValue] = useState(initialValue ? formatDate(initialValue) : null);
  const [selectionRange, setSelectionRange] = useState();
  const inputRef = useRef();

  useEffect(() => {
    if (!value) {
      return;
    } else if (value === DATE_FORMAT && inputRef.current) {
      inputRef.current.setSelectionRange(0, 0);
    } else if (selectionRange && inputRef.current) {
      inputRef.current.setSelectionRange(
        selectionRange.cursorStart,
        selectionRange.cursorEnd
      );
    }
  }, [selectionRange, value]);

  function getMasked(value) {
    const regexp1 = /^(\d?)(\d?)(\d?)(\d?)(\d?)(\d?)(\d?)(\d?)$/g;
    if (regexp1.test(value)) {
      regexp1.lastIndex = 0;
      const [, v1, v2, v3, v4, v5, v6, v7, v8] = regexp1.exec(value);
      return `${v1 || "m"}${v2 || "m"}/${v3 || "d"}${v4 || "d"}/${v5 || "y"}${v6 || "y"}${
        v7 || "y"
      }${v8 || "y"}`;
    } else {
      return value;
    }
  }

  const handleChange = (callback) => (e) => {
    const cursorStart = e.target.selectionStart,
      cursorEnd = e.target.selectionEnd;

    const {value} = e.target;
    const masked = getMasked(value.replace(/\D+/g, ""));

    let range = {cursorStart, cursorEnd};
    if (cursorEnd === cursorStart) {
      const previousChar = masked.slice(cursorEnd - 1, cursorEnd);
      const nextChar = masked.slice(cursorEnd, cursorEnd + 1);
      if ((!previousChar || isNaN(previousChar)) && (!nextChar || isNaN(nextChar))) {
        const lastDigitIndex = findLastIndex(masked.split(""), (c) => !isNaN(c)) + 1;
        range = {cursorStart: lastDigitIndex, cursorEnd: lastDigitIndex};
      }
    }

    setSelectionRange(range);
    setValue(masked);

    const date = new Date(masked);
    if (isNaN(date.getTime())) {
      callback(undefined);
    } else {
      callback(date);
    }
  };

  function handleKeyDown(evt) {
    let {selectionStart, selectionEnd} = evt.target;
    if (evt.keyCode === 8 || evt.keyCode === 46) {
      let selection;
      if (selectionStart === selectionEnd) {
        selection = evt.target.value.slice(selectionEnd - 1, selectionEnd);
      } else {
        selection = evt.target.value.slice(selectionStart, selectionEnd);
      }

      if (selection.length === 1 && isNaN(selection)) {
        evt.preventDefault();

        let newPos =
          selectionStart !== selectionEnd ? selectionStart : selectionStart - 1;
        if (isNaN(evt.target.value.slice(newPos - 1, newPos))) {
          newPos = findLastIndex(evt.target.value.split(""), (c) => !isNaN(c)) + 1;
        }
        evt.target.setSelectionRange(newPos, newPos);
      }
    } else if (!isNaN(evt.key) && selectionStart === selectionEnd) {
      const nextCharPos = selectionEnd + 1;
      const nextChar = evt.target.value.slice(selectionEnd, nextCharPos);
      if (nextChar === "/") {
        evt.target.setSelectionRange(nextCharPos, nextCharPos);
      }
    }
  }

  function onFocus() {
    if (value === "") {
      setValue("mm/dd/yyyy");
    }
  }
  function onBlur() {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      setValue("");
    }
  }

  function _hasErrors(meta, input) {
    const metaErrors = meta?.touches && meta?.error;
    if (input.value) {
      return metaErrors;
    }

    return metaErrors || hasErrors;
  }

  return (
    <Field
      name={name}
      {...props}
      render={({input, meta}) => (
        <DatePickerContainer
          hasValue={Boolean(input.value)}
          hasErrors={_hasErrors(meta, input)}
          placeholder={props.placeholder}
        >
          <GlobalStyles />
          <Input
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            onChange={
              props.onChange
                ? handleChange(props.onChange(input.onChange))
                : handleChange(input.onChange)
            }
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {meta.touched && <Error styles={errorStyles}>{meta.error}</Error>}
        </DatePickerContainer>
      )}
    />
  );
}

const GlobalStyles = createGlobalStyle`
  .ui.popup {
    z-index: 99;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  > * {
    width: 100%;
  }
`;

const DatePickerContainer = styled(FieldContainer)``;

export default FallbackDatePicker;
