import BasePage from "src/themes/flight/pages/BasePage";
import BrandPage from "src/themes/flight/pages/BrandPage";
import ProductsPage from "src/themes/flight/pages/ProductsPage";
import HomePage from "src/themes/flight/pages/HomePage";
import DealsPage from "src/themes/flight/pages/DealsPage";
import SearchPage from "src/themes/flight/pages/SearchPage";
import Header from "src/themes/flight/components/Header";
import DeliveryBanner from "src/themes/flight/components/DeliveryBanner";
import ShowCasedProductsList from "src/themes/flight/components/ShowCaseProductsList";
import ShowCaseSection from "src/themes/flight/elements/ShowCaseSection";
import ProductCard from "src/themes/flight/components/ProductCard";
import CategorizedProductsBrowser from "src/themes/flight/components/CategorizedProductsBrowser";
import ProductsList from "src/themes/flight/components/ProductsList";
import ProductDetailPage from "src/themes/flight/pages/ProductDetailPage";
import FlightProductDetail from "src/themes/flight/components/ProductDetail";
import DeliveryModal from "src/themes/flight/components/DeliveryModal";
import StoreSelectionModal from "src/themes/flight/components/StoreSelectionModal";
import ShowcaseDealsList from "src/themes/flight/components/ShowcaseDealsList";
import FlightThemeRecommendedProductsList from "src/themes/flight/elements/RecommendedProductsList";
import {FlightTitle} from "src/themes/flight/elements/Title";
import {getFlowerTypeIndicatorProps} from "src/themes/flight/elements/flowerType";

export default {
  inherits: "baseTheme",
  components: {
    Header,
    DeliveryBanner,
    ShowCasedProductsList,
    ShowCaseSection,
    ProductCard,
    CategorizedProductsBrowser,
    ProductsList,
    DeliveryModal,
    StoreSelectionModal,
    ShowcaseDealsList,
    ProductDetail: FlightProductDetail,
    RecommendedProductsList: FlightThemeRecommendedProductsList,
    Title: FlightTitle,
  },
  pages: {
    BasePage,
    BrandPage,
    ProductsPage,
    HomePage: HomePage,
    ProductDetailPage: ProductDetailPage,
    DealsPage,
    AllCategoriesPage: ProductsPage,
    SearchPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: false,
      },
    },
    flowerType: {getFlowerTypeIndicatorProps},
  },
};
