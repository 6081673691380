import makeBreadcrumbsProductDetailSchema from "src/core/seo/schema-objects/factories/breadcrumbsProductDetail";
import makeProductDetailSchema from "src/core/seo/schema-objects/factories/productDetail";
import makeSiteDetailSchema from "src/core/seo/schema-objects/factories/siteDetail";
import useSite from "src/core/sites/hooks/useSite";
import {trackProductDetailView} from "src/core/analytics/ecommerce/events";
import {useEffect} from "react";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import useQuantityPicker from "src/core/checkout/hooks/useQuantityPicker";
import useProductInfo from "src/menu/hooks/useProductInfo";
import ProductDetailSchema from "src/core/seo/components/ProductDetailSchema";
import {PageHead} from "src/core/seo/components/Head";
import EmptyPlaceholderImage from "@ui/components/EmptyPlaceholder/assets/empty-placeholder.png";
import ProductDetailHeaderActions from "src/menu/components/ProductDetailHeaderActions";
import ProductDetailActions from "src/menu/components/ProductDetailActions";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useThemeConfig from "src/themes/useThemeConfig";
import useFlowerTypeIndicatorProps from "src/core/common/hooks/useFlowerTypeIndicatorsProps";
import makeFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/makeFlowerTypeIndicator";
import usePrefetchedProduct from "@menu/hooks/usePrefetchedProduct";
import {SizeOption} from "src/core/common/models/product";

function useProductDetail({productId: pId, onAddItem}, opts = {}) {
  const site = useSite();
  const router = useRouter();
  const {productId: routingProductId, noBrandProductId} = router.query;
  const productId = pId || routingProductId || noBrandProductId;
  const toggles = useFeatureToggles();

  const config = useThemeConfig();
  const priceDisplay =
    opts?.priceDisplay || config.pageOptions.productDetail?.quantityPicker?.displayPrice;

  const {data: product, meta} = usePrefetchedProduct(productId);

  const schemas = product
    ? [
        makeProductDetailSchema(product, site),
        makeSiteDetailSchema(product, site),
        makeBreadcrumbsProductDetailSchema(product),
      ].map(schema => ({
        type: "application/ld+json",
        innerHTML: JSON.stringify(schema),
      }))
    : [];

  const onEmptyPlaceholderButtonClickHandler = () => {
    router.push(routes.home);
  };

  const quantityPickerProps = useQuantityPicker({
    product,
    onAddItem,
    priceDisplay,
  });

  const productInfoProps = useProductInfo({
    product,
    quantity: quantityPickerProps.quantity,
    weight: quantityPickerProps.weight,
    priceDisplay,
  });

  useEffect(() => {
    product && trackProductDetailView(product);
  }, [product]);

  const flowerTypeIndicatorProps = useFlowerTypeIndicatorProps(site);

  const iconType = site.getUiConfiguration().flowerTypeIndicator?.iconType;
  const FlowerTypeIndicatorComponent = makeFlowerTypeIndicator(iconType);

  const handleCustomAmountChange = (
    e,
    setCustomAmount,
    setSelectedWeightKey,
    onChange
  ) => {
    const inputValue = e.target.value;
    if (
      /^\d{0,3}$/.test(inputValue) &&
      (inputValue === "" || (parseInt(inputValue) > 0 && parseInt(inputValue) < 250))
    ) {
      setCustomAmount(inputValue);
      setSelectedWeightKey(inputValue);

      const amountInCents = inputValue ? parseInt(inputValue) * 100 : 0;
      const otherSizeOption = {
        label: `$${inputValue}`,
        value: new SizeOption({
          weight_key: inputValue,
          display_name: `$${inputValue}`,
          price: {
            amount: amountInCents,
            currency: "usd",
          },
          discount_price: null,
        }),
      };

      onChange(otherSizeOption);
    }
  };

  return {
    site,
    openModal: Boolean(productId),
    product,
    meta,
    schemas,
    onEmptyPlaceholderButtonClickHandler,
    hasError: !product && meta.error && meta.error.status === 404,
    showSkeleton: !meta.error && !product,
    quantityPickerProps,
    productInfoProps,
    ProductDetailSchema,
    PageHead,
    EmptyPlaceholderImage,
    ProductDetailHeaderActions,
    ProductDetailActions,
    showProductDetailRecommendations: toggles.showProductDetailRecommendations(),
    flowerTypeIndicatorProps,
    FlowerTypeIndicatorComponent,
    config: config.pageOptions.productDetail,
    onSale: product?.isOnSale(),
    type: product?.getType(),
    handleCustomAmountChange,
  };
}

export default useProductDetail;
