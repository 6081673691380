import autoBind from "auto-bind";
import get from "lodash/get";

class ProductBrand {
  constructor(brand) {
    this.brand = brand;
    autoBind(this);
  }

  getId() {
    return get(this.brand, "id");
  }
  getName() {
    return get(this.brand, "attributes.name");
  }

  getCount() {
    return get(this.brand, "attributes.count");
  }

  getSlug() {
    return get(this.brand, "attributes.slug");
  }

  getLogo() {
    return get(this.brand, "attributes.logo_url");
  }

  getRating() {
    return get(this.brand, "attributes.rating");
  }

  getDescription() {
    return get(this.brand, "attributes.description");
  }

  getTitle() {
    return get(this._tag, "attributes.title") || this.getName();
  }
}

export default ProductBrand;
