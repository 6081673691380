import React from "react";
import styled from "styled-components";
import * as ThemedComponents from "src/themes/utils/themedComponents";
import useSite from "src/core/sites/hooks/useSite";

export function SearchIconButton({alt = "search", color, ...rest}) {
  const site = useSite();
  const SearchIcon = ThemedComponents.get(site.getUiConfiguration().icons.search);

  return (
    <SearchImage alt={alt} color={color} {...rest}>
      <SearchIcon alt={alt} {...rest} />
    </SearchImage>
  );
}

const SearchImage = styled.div`
  > *:first-child {
    width: 24px;
    height: 24px;
    border: 0;
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    padding: ${({theme}) => theme.v2.spacing(2)};
    color: ${({theme, color}) => color || theme.v2.color.base.grey["900"]};
    &:hover {
      opacity: 0.6;
    }
  }
`;
