import React from "react";
import ProductsList from "./ProductList";

export default function SenseShowCaseProductsList({groups, hasGroups}) {
  if (!hasGroups) {
    return [1, 2].map((v, index) => <ProductsList key={index} loading={true} />);
  } else {
    return groups.map((group, index) => (
      <ProductsList key={group.name} {...group} forceDisplay={index === 0} />
    ));
  }
}
