export const dispatchEvent = (eventName, data = {}) => {
  const event = new CustomEvent(eventName, {
    detail: data,
  });

  window.dispatchEvent(event);
};

export const subscribeEvent = (eventName, callback) => {
  if (typeof window === "undefined") return;

  window.addEventListener(eventName, callback);
  return () => {
    window.removeEventListener(eventName, callback);
  };
};
